import { Button, Typography, makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buscarSiniestro,
  clearReintegros,
  clearSelectedSiniestro,
  setLocalSiniestro,
} from "../../../redux/actions/reintegros";
import SiniestroForm from "./SiniestroForm";
import SiniestroTable from "./SiniestroTable";

const initialValues = {
  nroSiniestro: "",
  nroDocumento: "",
};

const useStyles = makeStyles((theme) => ({
  titleFormContainer: {},
  warningMessageContainer: {
    display: "flex",
    padding: 15,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formContainer: {
    width: "50%",
    height: "100%",
  },
  buttonContainer: {
    padding: 20,
  },
  tableContainer: {
    width: "65%",
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
}));

const SiniestroContainer = ({ fromBread, nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedSiniestroRedux = useSelector(
    (state) => state.reintegros.localSiniestro
  );
  const searchedSiniestro = useSelector(
    (state) => state.reintegros.siniestrosBuscados
  );

  const [formModel, setFormModel] = useState(null);
  const [selectedSiniestro, setSelectedSiniestro] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const isLoading = !formModel;

  useEffect(() => {
    if (!fromBread) {
      dispatch(clearSelectedSiniestro());
      dispatch(clearReintegros());
    }
  }, []);

  useEffect(() => {
    setFormModel(() => ({
      ...initialValues,
      nroSiniestro:
        selectedSiniestroRedux && fromBread
          ? selectedSiniestroRedux.numeroSiniestroART
          : initialValues.nroSiniestro,
      nroDocumento:
        selectedSiniestroRedux && fromBread
          ? selectedSiniestroRedux.numeroDocumento
          : initialValues.nroDocumento,
    }));
  }, []);

  useEffect(() => {
    if (searchedSiniestro.data.length >= 1) {
      setSelectedSiniestro(searchedSiniestro.data[0]);
    } else {
      setSelectedSiniestro(null);
    }
  }, [searchedSiniestro.data.length]);

  const handleSubmit = () => {
    dispatch(setLocalSiniestro(selectedSiniestro));
    nextForm("Beneficiario");
  };

  const handleSubmitSearchForm = (values) => {
    if (values.nroSiniestro === "" && values.nroDocumento === "") {
      setShowErrorMessage(true);
      return;
    } else {
      setShowErrorMessage(false);
      dispatch(clearSelectedSiniestro());
      dispatch(buscarSiniestro(values));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleFormContainer}>
        <Typography variant="h6">Selección del siniestro</Typography>
      </div>
      <div className={classes.warningMessageContainer}>
        <ErrorOutlineIcon style={{ color: "red" }} fontSize="large" />
        <Typography variant="h6" style={{ paddingLeft: 10, color: "red" }}>
          Para solicitar el Reintegro debe completar CBU/CVU y adjuntar el
          comprobante
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <SiniestroForm
          handleSubmit={handleSubmitSearchForm}
          model={formModel}
          isLoading={isLoading}
        />
      </div>
      <div className={classes.errorContainer}>
        {showErrorMessage && (
          <>
            <ErrorOutlineIcon style={{ color: "red" }} fontSize="medium" />
            <Typography style={{ color: "red" }}>
              Debe completar alguno de los dos campos
            </Typography>
          </>
        )}
      </div>
      <div className={classes.tableContainer}>
        <SiniestroTable
          selectedSiniestro={selectedSiniestro}
          searchedSiniestro={searchedSiniestro}
          handleSubmit={setSelectedSiniestro}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedSiniestro}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default SiniestroContainer;
