import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React from "react";
import { useDispatch } from "react-redux";
import { downloadEstadoCuentaCorriente } from "../../redux/actions/cuentaCorriente";
import { downloadFile } from "../../util/files";


const useStyles = makeStyles({
  icon: {
    color: (props) => (!!props.color ? props.color : "#858a93"),
  },
  menuItem: {
    marginRight: -20,
  },
});

const ReporteEstadoButton = ({ data, columns, title, disabled, color }) => {
  const classes = useStyles({ color });

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(downloadEstadoCuentaCorriente()).then((response) => {
      downloadFile(response.value.data, response.value.fileName);
    });
  }

  return (
    <div style={{ display: "flex", margin: "10px" }}>
      <Tooltip title="Descargar Estado de cuenta corriente al último cierre" arrow>
        <span>
          <IconButton
            disabled={disabled}
            size="medium"
            className={classes.icon}
            onClick={handleClick}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default ReporteEstadoButton;
