import { TablePagination } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment/moment";
import {
  localization,
  tableStyles,
} from "../../../../commons/table/tableConfig";

const SiniestralidadTrabajadorTable = ({ data, loading }) => {
  const columns = [
    {
      title: "Siniestro",
      field: "siniestroART",
      width: "10%",
    },
    {
      title: "Nro. Predenuncia",
      field: "nroPreDenuncia",
      width: "10%",
    },
    {
      title: "Tipo Siniestro",
      field: "tipoSiniestro",
      width: "20%",
    },
    {
      title: "Subtipo Siniestro",
      field: "subtipoSiniestro",
      width: "20%",
    },
    {
      title: "Fecha de Ocurrencia",
      field: "fechaOcurrencia",
      width: "10%",
      render: (rowData) => {
        return moment(rowData.fechaOcurrencia).format("DD/MM/YYYY");
      },
    },
    {
      title: "Diagnóstico Principal",
      field: "diagnostico",
      width: "30%",
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={{ ...tableStyles.container }}
      options={{
        actionsColumnIndex: -1,
        headerStyle: tableStyles.headerStyle,
        rowStyle: tableStyles.rowStyle,
        pageSize: 5,
        search: false,
        filtering: true,
        exportButton: false,
      }}
      components={{
        Toolbar: () => {
          // Como esta tabla esta embebida en nueva denuncia, no necesitamos
          // titulo, y tampoco queremos que haya mucho espacio en blanco
          return null;
        },
        Pagination: (props) => {
          const { onChangePage, onChangeRowsPerPage, ...tablePaginationProps } =
            props;
          return (
            <TablePagination
              {...tablePaginationProps}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                  <props.ActionsComponent
                    {...actionsComponentProps}
                    onChangePage={onPageChange}
                  />
                );
              }}
            />
          );
        },
      }}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay datos que mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};

export default SiniestralidadTrabajadorTable;
