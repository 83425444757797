import { makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import { deleteRight, formatFormTitle } from "../../../util/breadcrumb";

const useStyles = makeStyles((theme) => ({
  normal: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: 10,
    heigth: 20,
    marginTop: 10,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
  selected: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    heigth: 20,
    marginTop: 10,
    cursor: "default",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  icon: {
    marginTop: 10,
  },
  disabled: {
    textDecoration: "line-through",
    cursor: "default",
  },
}));

const Breadcrumb = ({
  setForm,
  fields,
  form,
  setFields,
  setFromBread,
  disableNavigationBackwards,
}) => {
  const classes = useStyles();

  function handleClick(e) {
    if (!disableNavigationBackwards) {
      setFields(deleteRight(fields, e));
      setForm(e);
      setFromBread();
    }
  }

  return (
    <Stack spacing={4}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="small" className={classes.icon} />
        }
      >
        {fields.map((e, index) => {
          return (
            <div
              key={index}
              className={e === form ? classes.selected : classes.normal}
            >
              <Typography
                onClick={() => handleClick(e)}
                className={
                  disableNavigationBackwards && e !== form
                    ? classes.disabled
                    : ""
                }
              >
                {formatFormTitle(e)}
              </Typography>
            </div>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

export default Breadcrumb;
