import { Typography, makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "90%",
    display: "flex",
    justifyContent: (props) => (props.noData ? "center" : "space-between"),
    padding: 10,
    margin: 15,
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    boxShadow: "10px 10px 15px -15px",
  },
  leftText: {
    display: "flex",
    justifyContent: "space-between",
  },
  centerText: {
    textAlign: "center",
  },
  rightText: {
    textAlign: "end",
  },
}));

const DependenciaSeleccionadaLabel = ({ data }) => {
  const noData = !Object.keys(data).length;
  const classes = useStyles({ noData });
  const noDataText = "No hay datos";
  return (
    <div className={classes.container}>
      {noData ? (
        <Typography variant="body2" className={classes.centerText}>
          No hay datos disponibles
        </Typography>
      ) : (
        <>
          <div className={classes.leftText}>
            {data.nombre ? (
              <>
                <Typography variant="body2">
                  <strong>{data?.nombre?.split(":")[1]}</strong>
                </Typography>
                <Typography variant="body2" style={{ marginLeft: 20 }}>
                  <strong>{data?.nombre?.split("-")[0]}</strong>
                </Typography>
              </>
            ) : (
              <Typography>{noDataText}</Typography>
            )}
          </div>
          <Typography variant="body2" className={classes.centerText}>
            {data.domicilio ? data?.domicilio : noDataText}
          </Typography>
          <Typography variant="body2" className={classes.rightText}>
            <strong>
              Periodo:{" "}
              {data.fechaDesde
                ? format(data?.fechaDesde, "dd/MM/yyyy")
                : noDataText}{" "}
              al{" "}
              {data.fechaHasta
                ? format(data?.fechaHasta, "dd/MM/yyyy")
                : noDataText}
            </strong>
          </Typography>
        </>
      )}
    </div>
  );
};

export default DependenciaSeleccionadaLabel;
