import { Button, DialogActions, Typography } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ConfirmDialog from "../../../commons/dialog/ConfirmDialog";
import DropZone from "../../../commons/dropZone/DropZone";
import FormikTextField from "../../../commons/formik/FormikTextField";
import CircularLoading from "../../../commons/loading/CircularLoading";
import { useStyles } from "../styles/formStyle";

const validationSchema = Yup.object().shape({
  observaciones: Yup.string().max(
    5000,
    "El campo no puede superar los 5.000 caracteres"
  ),
});

const SiniestroAdjuntarDenunciaForm = ({
  open,
  handleClose,
  handleSubmit,
  siniestro,
  file,
  setFile,
}) => {
  const classes = useStyles();

  const isAdjuntandoDenuncia = useSelector(
    (state) => state.siniestralidad.denunciaFirmada.loading
  );

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const initialValues = {
    idSiniestroCabecera: siniestro.idSiniestroCabecera,
    siniestroArt: siniestro.siniestroART,
    tipoSiniestro: siniestro.tipoSiniestro,
    nroDocumento: siniestro.nroDocumento,
    apellidoNombre: siniestro.apellidoNombre,
    observaciones: "",
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Adjuntar Denuncia</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit({ ...values, file })}
      >
        {({ values }) => (
          <Form>
            {isAdjuntandoDenuncia ? (
              <div className={classes.loading}>
                <CircularLoading />
              </div>
            ) : (
              <DialogContent className={classes.content}>
                <div className={classes.row}>
                  <FormikTextField
                    fullWidth={true}
                    name="siniestroArt"
                    label="Nro Siniestro"
                    type="text"
                    disabled
                  />
                  <FormikTextField
                    fullWidth={true}
                    name="tipoSiniestro"
                    label="Tipo Siniestro"
                    type="text"
                    disabled
                  />
                </div>
                <div className={classes.row}>
                  <FormikTextField
                    fullWidth={true}
                    name="nroDocumento"
                    label="Número Documento"
                    type="text"
                    disabled
                  />
                  <FormikTextField
                    fullWidth={true}
                    name="apellidoNombre"
                    label="Apellido y Nombre"
                    type="text"
                    disabled
                  />
                </div>
                <div className={classes.observaciones}>
                  <FormikTextField
                    fullWidth={true}
                    name="observaciones"
                    label="Observaciones"
                    type="text"
                    variant="outlined"
                    multiline
                    maxRows={15}
                    minRows={2}
                  />
                  {values.observaciones.length > 0 && (
                    <Typography variant="caption">
                      {values.observaciones.length} caracteres
                    </Typography>
                  )}
                </div>
                <DropZone
                  file={file}
                  setFile={setFile}
                  loading={false}
                  maxFiles={5}
                  extension=".PDF, .JPG o .PNG"
                  accept={{
                    "application/pdf": [".pdf"],
                    "image/jpeg": [".jpg"],
                    "image/png": [".png"],
                  }}
                  dropZoneText="Arrastre aquí o haga click en el recuadro para importar información en formato PDF, JPG, PNG"
                  dropZoneEditText="Si desea subir mas archivos arrastrelos nuevamente o haga click en el recuadro."
                />
                {/* IMPORTANTE: el dialogo de confirmacion debe estar dentro de
                  DialogContent, ya que de lo contrario saltan errores relacionados
                  a la API de "Modal" de Material-UI. */}
                <ConfirmDialog
                  open={openConfirmDialog}
                  handleClose={() => {
                    setOpenConfirmDialog(false);
                  }}
                  handleConfirm={() => {
                    setOpenConfirmDialog(false);
                    handleClose();
                  }}
                  title="Cancelar Adjuntar Denuncia"
                  warningText={
                    "Al cerrar el formulario, perderá todos los datos completados. ¿Está seguro que desea cerrar el formulario?"
                  }
                  loading={false}
                />
              </DialogContent>
            )}
            <DialogActions className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                onClick={() => {
                  // Solamente mostrar el popup de confirmación
                  //  si el usuario completó algunos datos del formulario
                  if (!!values.observaciones || file) {
                    setOpenConfirmDialog(true);
                  } else {
                    handleClose();
                  }
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                // Por especificacion, es OBLIGATORIO adjuntar un archivo.
                disabled={isAdjuntandoDenuncia || !file}
              >
                Enviar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SiniestroAdjuntarDenunciaForm;
