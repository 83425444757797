import { Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificacionesYCitas } from "../../../../redux/actions/examenesPeriodicos";
import { convertDateTimeToNet } from "../../../../util/datetime";
import CardContainer from "../../../commons/cards/CardContainer";
import NotificacionesCard from "./cards/notificacionesCard/NotificacionesCard";

const Notificaciones = ({ selectedEstablecimiento }) => {
  const dispatch = useDispatch();

  const notificacionesRedux = useSelector(
    (state) => state.examenesPeriodicos.notificaciones
  );
  const searchParams = useSelector(
    (state) => state.examenesPeriodicos.searchParams
  );

  return (
    <Grid container spacing={2}>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Grid item xs={6}>
          <CardContainer
            title="Notificaciones"
            loading={notificacionesRedux.loading}
            getData={() =>
              dispatch(
                getNotificacionesYCitas(
                  searchParams.idEstablecimiento,
                  convertDateTimeToNet(searchParams.fechaDesde),
                  convertDateTimeToNet(searchParams.fechaHasta)
                )
              )
            }
            componente={
              <NotificacionesCard
                data={notificacionesRedux.data}
                idSiniestroCabecera={selectedEstablecimiento}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notificaciones;
