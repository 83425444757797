import { Grid, MenuItem, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import { useStyles } from "../styles/formStyle";
import {
  enfermedadProfesionalValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const EnfermedadProfesionalForm = ({
  handleSubmit,
  model,
  fields,
  tiposEnfermedadProfesional,
  listaAgenteMaterial,
  listaAgenteCausante,
  handleGetAgenteMaterialEnfermedadProfesional,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={model}
      onSubmit={handleSubmit}
      validationSchema={enfermedadProfesionalValidation}
      validate={(values) => validateRequiredFields(values, fields)}
    >
      {({ values }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={9} className={classes.gridContainer}>
            <Grid item xs={6}>
              <FormikSelect
                fullWidth
                name="tipoEnfermedadProfesional"
                label={`Tipo de enfermedad ${
                  fields.subtipoSiniestro?.obligatorio ? "*" : ""
                }`}
                variant="standard"
                value={tiposEnfermedadProfesional[0].id}
              >
                {tiposEnfermedadProfesional.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                fullWidth
                id="tiempoExposicionAgenteCausante"
                name="tiempoExposicionAgenteCausante"
                type="number"
                label={`Tiempo de exposición ${
                  fields.tiempoExposicionAgenteCausante?.obligatorio ? "*" : ""
                }`}
              />
              <Typography
                style={{ marginLeft: "5px", color: "grey" }}
                variant="caption"
              >
                Expresado en meses
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormikAutocomplete
                fullWidth
                name="agenteCausanteEnfermedadProfesional"
                label={`Agente causante ${
                  fields.agenteCausanteEnfermedadProfesional?.obligatorio
                    ? "*"
                    : ""
                }`}
                variant="standard"
                options={listaAgenteCausante}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
                callBack={(value) => {
                  if (value) {
                    handleGetAgenteMaterialEnfermedadProfesional(value.id);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikAutocomplete
                fullWidth
                name="agenteCausante"
                label={`Agente material ${
                  fields.agenteCausante?.obligatorio ? "*" : ""
                }`}
                variant="standard"
                options={listaAgenteMaterial}
                disable={!values.agenteCausanteEnfermedadProfesional}
                renderOption={(option) => option.nombre}
                getOptions={() => {
                  handleGetAgenteMaterialEnfermedadProfesional(
                    values.agenteCausanteEnfermedadProfesional
                  );
                }}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Siguiente" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EnfermedadProfesionalForm;
