import { makeStyles } from "@material-ui/core";
import { Grid, Paper, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  emptyState: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 20px 20px 50px",
    color: "grey",
    textAlign: "center",
  },
}));

const DinerariasCard = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      {data?.length ? (
        data?.map((liquidacion) => {
          return (
            <Paper className={classes.card} key={liquidacion.idliquidacion}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography>
                    {format(parseISO(liquidacion?.fechaLiquidacion), "yyyyMM")}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography>{liquidacion?.tipoLiquidacion}</Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography>
                    {Intl.NumberFormat("es-AR").format(liquidacion?.importe)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography>{liquidacion?.estado}</Typography>
                </Grid>
              </Grid>
            </Paper>
          );
        })
      ) : (
        <Typography className={classes.emptyState} variant="body1">
          No hay liquidaciones disponibles
        </Typography>
      )}
    </>
  );
};

export default DinerariasCard;
