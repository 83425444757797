import axios from "axios";
export const SET_LOCAL_SINIESTRO = "SET_LOCAL_SINIESTRO";
export const BUSCAR_SINIESTRO = "BUSCAR_SINIESTRO";
export const POST_BENEFICIARIO = "POST_BENEFICIARIO";
export const CLEAR_SINIESTRO = "CLEAR_SINIESTRO";
export const GET_TIPO_COMPROBANTE = "GET_TIPO_COMPROBANTE";
export const GET_RUBROS_REINTEGRO = "GET_RUBROS_REINTEGROS";
export const ADD_COMPROBANTE = "ADD_COMPROBANTE";
export const DELETE_COMPROBANTE = "DELETE_COMPROBANTE";
export const POST_DATOS_COMPROBANTE = "POST_DATOS_COMPROBANTE";
export const GET_TIPO_DESTINATARIO = "GET_TIPO_DESTINATARIO";
export const CLEAR_REINTEGROS = "CLEAR_REINTEGROS";
export const POST_REINTEGROS = "POST_REINTEGROS";
export const ERROR_DATOS_COMPROBANTE = "ERROR_DATOS_COMPROBANTE";
export const postReintegros = (values) => (dispatch) => {
  return dispatch({
    type: POST_REINTEGROS,
    payload: axios
      .post(`reintegroSiniestro/cargarReintegroSiniestro`, values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
export const getTipoComprobante = (siniestro) => (dispatch) => {
  return dispatch({
    type: GET_TIPO_COMPROBANTE,
    payload: axios
      .get(`reintegroSiniestro/tipoComprobante`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
export const getTiposDestinatario = () => (dispatch) => {
  return dispatch({
    type: GET_TIPO_DESTINATARIO,
    payload: axios
      .get(`reintegroSiniestro/tiposDestinatariosReintegros`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
export const getRubrosReintegro = () => (dispatch) => {
  return dispatch({
    type: GET_RUBROS_REINTEGRO,
    payload: axios
      .get(`reintegroSiniestro/rubrosReintegro`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
export const buscarSiniestro = (values) => (dispatch) => {
  const { nroSiniestro, nroDocumento } = values;
  return dispatch({
    type: BUSCAR_SINIESTRO,
    payload: axios
      .get(
        `reintegroSiniestro/siniestrosReintegro?numeroSiniestroArt=${
          nroSiniestro ? nroSiniestro : 0
        }&numeroDocumento=${nroDocumento ? nroDocumento : 0}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const addComprobante = (comprobante) => (dispatch) => {
  return dispatch({
    type: ADD_COMPROBANTE,
    payload: comprobante,
  });
};

export const deleteComprobante = (comprobante) => (dispatch) => {
  return dispatch({
    type: DELETE_COMPROBANTE,
    payload: comprobante,
  });
};
export const setLocalSiniestro = (siniestro) => (dispatch) => {
  return dispatch({
    type: SET_LOCAL_SINIESTRO,
    payload: siniestro,
  });
};
export const saveBeneficiario = (values) => (dispatch) => {
  return dispatch({
    type: POST_BENEFICIARIO,
    payload: values,
  });
};

export const saveDatosComprobanteForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_DATOS_COMPROBANTE,
    payload: values,
  });
};

export const errorDatosComprobanteForm = () => (dispatch) => {
  return dispatch({
    type: ERROR_DATOS_COMPROBANTE,
  });
};

export const clearSelectedSiniestro = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SINIESTRO,
  });
};

export const clearReintegros = () => (dispatch) => {
  return dispatch({
    type: CLEAR_REINTEGROS,
  });
};
