import axios from "axios";

export const GET_DATOS_SINIESTRO = "GET_DATOS_SINIESTRO";
export const GET_GESTIONES_PARTICULARES = "GET_GESTIONES_PARTICULARES";
export const GET_SECUENCIAS = "GET_SECUENCIAS";
export const GET_DIAGNOSTICOS = "GET_DIAGNOSTICOS";
export const GET_DOCUMENTACION_MEDICA_WITH_FILTER =
  "GET_DOCUMENTACION_MEDICA_WITH_FILTER";
export const GET_INFORMES_MEDICOS = "GET_INFORMES_MEDICOS";
export const CLEAR_INFORMES_MEDICOS = "CLEAR_INFORMES_MEDICOS";
export const POST_BUSCAR_SINIESTROS = "POST_BUSCAR_SINIESTROS";
export const DESCARGAR_REPORTE_PREDENUNCIA = "DESCARGAR_REPORTE_PREDENUNCIA";
export const POST_CARGAR_DENUNCIA_FIRMADA = "POST_CARGAR_DENUNCIA_FIRMADA";
export const POST_CARGAR_RECIBO_SUELDO = "POST_CARGAR_RECIBO_SUELDO";
export const POST_CARGAR_FI = "POST_CARGAR_FI";

export const getDatosSiniestro = (selectedAccident) => (dispatch) => {
  return dispatch({
    type: GET_DATOS_SINIESTRO,
    payload: axios
      .get(
        `denuncia/datosSiniestro?idSiniestroCabecera=${selectedAccident.idSiniestroCabecera}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getGestionesParticulares = (selectedAccident) => (dispatch) => {
  return dispatch({
    type: GET_GESTIONES_PARTICULARES,
    payload: axios
      .get(
        `denuncia/gestionesParticulares?idSiniestroCabecera=${selectedAccident.idSiniestroCabecera}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getSecuencias = (selectedAccident) => (dispatch) => {
  return dispatch({
    type: GET_SECUENCIAS,
    payload: axios
      .get(
        `denuncia/datosSecuenciasSiniestro?idSiniestroCabecera=${selectedAccident.idSiniestroCabecera}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getDiagnosticos = (selectedAccident) => (dispatch) => {
  return dispatch({
    type: GET_DIAGNOSTICOS,
    payload: axios
      .get(
        `denuncia/diagnosticosSiniestro?idSiniestroCabecera=${selectedAccident.idSiniestroCabecera}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getDocumentacionMedicaWithFilter = (values) => (dispatch) => {
  return dispatch({
    type: GET_DOCUMENTACION_MEDICA_WITH_FILTER,
    payload: axios
      .post(`denuncia/documentacionMedicaSiniestro`, values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getInformesMedicos = (idSiniestroCabecera) => (dispatch) => {
  return dispatch({
    type: GET_INFORMES_MEDICOS,
    payload: axios
      .get(
        `denuncia/resumenSiniestro?idSiniestroCabecera=${idSiniestroCabecera}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const descargarReportePredenuncia =
  (numeroPredenuncia) => (dispatch) => {
    return dispatch({
      type: DESCARGAR_REPORTE_PREDENUNCIA,
      payload: axios
        .post(
          `denuncia/descargarReportePreDenuncia?nroDenunciaWeb=${numeroPredenuncia}`,
          {},
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const cargarDenunciaFirmada = (body) => (dispatch) => {
  return dispatch({
    type: POST_CARGAR_DENUNCIA_FIRMADA,
    payload: axios
      .post("formularioSiniestro/cargarDenunciaFirmada", body)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const cargarReciboSueldo = (body) => (dispatch) => {
  return dispatch({
    type: POST_CARGAR_RECIBO_SUELDO,
    payload: axios
      .post("denuncia/cargarReciboSueldo", body)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const cargarFI = (body) => (dispatch) => {
  return dispatch({
    type: POST_CARGAR_FI,
    payload: axios
      .post("denuncia/cargarDIPE", body)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const buscarSiniestros = (filter) => (dispatch) => {
  return dispatch({
    type: POST_BUSCAR_SINIESTROS,
    payload: axios
      .post("denuncia/buscarSiniestro", filter)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const clearInformesMedicos = () => (dispatch) => {
  return dispatch({
    type: CLEAR_INFORMES_MEDICOS,
  })
}
