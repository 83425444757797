import { Paper, makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  clearVisualizacionFormularioPdf,
  descargarFormularioRarPdf,
  descargarFormularioRgrlPdf,
  getFormularioRarPdf,
  getFormularioRgrlPdf,
  getFormulariosPrevencionEstablecimiento,
  postFormularioRarFirmado,
  postFormularioRgrlFirmado,
} from "../../../../redux/actions/establecimiento";
import { downloadFile } from "../../../../util/files";
import BackArrowHeader from "../../../commons/arrow/BackArrowHeader";
import EstadoEstablecimientoDetalle from "./DetalleEstablecimiento";
import DialogAdjuntarFormulario from "./dialogs/DialogAdjuntarFormulario";
import DialogDescargarFormulario from "./dialogs/DialogDescargarFormulario";
import DialogVisualizarFormulario from "./dialogs/DialogVisualizarFormulario";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: theme.spacing(2),
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
  },
}));

const EstadoEstablecimientoContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const formularioVisualizacion = useSelector(
    (state) => state.establecimiento.getFormularioPdf.data?.base64
  );

  const [isModalAdjuntarFormOpen, setIsModalAdjuntarFormOpen] = useState(false);
  const [isDownloadingFormulario, setIsDownloadingFormulario] = useState(false);
  const [isAdjuntandoFormulario, setIsAdjuntandoFormulario] = useState(false);
  const [isModalVisualizarFormOpen, setIsModalVisualizarFormOpen] =
    useState(false);
  const [formularioFirmadoAdjunto, setFormularioFirmadoAdjunto] =
    useState(null);

  const formularioSeleccionadoAdjuntar = useRef(null);

  const handleAdjuntarFormularioFirmado = (file) => {
    setIsAdjuntandoFormulario(true);
    if (formularioSeleccionadoAdjuntar.current?.codigoArtFormulario === 3) {
      dispatch(
        postFormularioRgrlFirmado(file, formularioSeleccionadoAdjuntar.current)
      )
        .then((res) => {
          toast.success("El formulario fue cargado con éxito");
          setIsAdjuntandoFormulario(false);
          setIsModalAdjuntarFormOpen(false);
          // el clear es necesario para que el Dropzone no se abra
          // con un formulario viejo (de algun render anterior)
          setFormularioFirmadoAdjunto(null);
          // tambien tenemos que hacer un getFormulariosPrevencion de nuevo,
          // ya que el estado del formulario al cual se le adjunta el pdf
          // firmado, va a cambiar de pre-presentado -> revision.
          dispatch(
            getFormulariosPrevencionEstablecimiento(
              state?.idDependenciaEmpleador
            )
          );
        })
        .catch((err) => {
          toast.error(err);
          setIsAdjuntandoFormulario(false);
          setIsModalAdjuntarFormOpen(true);
          // el clear es necesario para que el Dropzone no se abra
          // con un formulario viejo (de algun render anterior)
          setFormularioFirmadoAdjunto(null);
        });
    } else if (
      formularioSeleccionadoAdjuntar.current?.codigoArtFormulario === 11
    ) {
      dispatch(
        postFormularioRarFirmado(file, formularioSeleccionadoAdjuntar.current)
      )
        .then((res) => {
          toast.success("El formulario fue cargado con éxito");
          setIsAdjuntandoFormulario(false);
          setIsModalAdjuntarFormOpen(false);
          // el clear es necesario para que el Dropzone no se abra
          // con un formulario viejo (de algun render anterior)
          setFormularioFirmadoAdjunto(null);
          // tambien tenemos que hacer un getFormulariosPrevencion de nuevo,
          // ya que el estado del formulario al cual se le adjunta el pdf
          // firmado, va a cambiar de pre-presentado -> revision.
          dispatch(
            getFormulariosPrevencionEstablecimiento(
              state?.idDependenciaEmpleador
            )
          );
        })
        .catch((err) => {
          toast.error(err);
          setIsAdjuntandoFormulario(false);
          setIsModalAdjuntarFormOpen(true);
          // el clear es necesario para que el Dropzone no se abra
          // con un formulario viejo (de algun render anterior)
          setFormularioFirmadoAdjunto(null);
        });
    }
  };

  const handleImprimirFormulario = (formulario) => {
    setIsDownloadingFormulario(true);
    if (formulario?.codigoArtFormulario === 3) {
      dispatch(
        descargarFormularioRgrlPdf(
          formulario?.idFormularioProgramaPrevencionEmpleador
        )
      )
        .then((res) => {
          downloadFile(res.value.data, `${formulario?.nombreFormulario}.pdf`);
          setIsDownloadingFormulario(false);
          toast.success("El formulario ha sido descargado con éxito");
        })
        .catch((err) => {
          toast.error(err);
          setIsDownloadingFormulario(false);
        });
    } else if (formulario?.codigoArtFormulario === 11) {
      dispatch(
        descargarFormularioRarPdf(
          formulario?.idFormularioProgramaPrevencionEmpleador
        )
      )
        .then((res) => {
          downloadFile(res.value.data, `${formulario?.nombreFormulario}.pdf`);
          setIsDownloadingFormulario(false);
          toast.success("El formulario ha sido descargado con éxito");
        })
        .catch((err) => {
          toast.error(err);
          setIsDownloadingFormulario(false);
        });
    }
  };

  const handleVisualizarFormulario = (formulario) => {
    setIsModalVisualizarFormOpen(true);
    if (formulario?.codigoArtFormulario === 3) {
      dispatch(
        getFormularioRgrlPdf(
          formulario?.idFormularioProgramaPrevencionEmpleador,
          formulario?.codigoArtFormulario
        )
      );
    } else if (formulario?.codigoArtFormulario === 11) {
      dispatch(
        getFormularioRarPdf(
          formulario?.idFormularioProgramaPrevencionEmpleador,
          formulario?.codigoArtFormulario
        )
      );
    }
  };

  return (
    <Paper className={classes.paperContainer}>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <BackArrowHeader
          onClick={() => navigate("/prevencion/establecimientos")}
        />
      </div>
      <div className={classes.contentContainer}>
        <EstadoEstablecimientoDetalle
          establecimiento={state}
          formularioSeleccionadoAdjuntar={formularioSeleccionadoAdjuntar}
          setIsModalAdjuntarFormOpen={setIsModalAdjuntarFormOpen}
          handleImprimirFormulario={handleImprimirFormulario}
          handleVisualizarFormulario={handleVisualizarFormulario}
        />
      </div>
      <DialogAdjuntarFormulario
        open={isModalAdjuntarFormOpen}
        onClose={() => {
          setIsModalAdjuntarFormOpen(false);
        }}
        title="Adjuntar formulario firmado"
        file={formularioFirmadoAdjunto}
        setFile={setFormularioFirmadoAdjunto}
        isAdjuntando={isAdjuntandoFormulario}
        handleCancelClick={() => {
          setFormularioFirmadoAdjunto(null);
          setIsModalAdjuntarFormOpen(false);
        }}
        handleConfirmClick={() => {
          handleAdjuntarFormularioFirmado(formularioFirmadoAdjunto[0]);
        }}
        isConfirmButtonDisabled={
          !formularioFirmadoAdjunto || formularioFirmadoAdjunto?.length <= 0
        }
      />
      <DialogDescargarFormulario
        open={isDownloadingFormulario}
        onClose={() => setIsDownloadingFormulario(false)}
      />
      <DialogVisualizarFormulario
        open={isModalVisualizarFormOpen}
        title="Formulario en Revisión"
        file={formularioVisualizacion}
        onClose={() => {
          // el clear es necesario para que el PdfDialog no se abra
          // con un formulario viejo (de algun render anterior)
          dispatch(clearVisualizacionFormularioPdf());
          setIsModalVisualizarFormOpen(false);
        }}
      />
    </Paper>
  );
};

export default EstadoEstablecimientoContainer;
