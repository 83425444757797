export const localization = {
  header: {
    actions: "",
  },
  pagination: {
    labelRowsSelect: "filas",
    firstTooltip: "Primera página",
    previousTooltip: "Página anterior",
    nextTooltip: "Página siguiente",
    lastTooltip: "Última página",
    labelDisplayedRows: "{from}-{to} de {count}",
  },
  toolbar: {
    searchPlaceholder: "Buscar...",
    exportTitle: "Exportar",
    exportName: "Exportar a CSV",
    nRowsSelected: `{0} fila(s) seleccionada(s)`,
  },
};

export const tableStyles = {
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    overflow: "auto",
  },
  rowStyle: {
    fontFamily: "Roboto",
    padding: 0,
  },
  headerStyle: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "1.3125rem",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
};
