import React from "react";

import { LinearProgress, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: 25,
  },
  linearProgress: { width: "85%", margin: 10 },
  displayGrupos: { fontSize: 18 },
}));

const CustomStepper = ({ contador, maxGrupos }) => {
  const classes = useStyles();

  return (
    <div className={classes.progressContainer}>
      <LinearProgress
        className={classes.linearProgress}
        variant="determinate"
        value={(contador / maxGrupos) * 100}
      />
      <Typography className={classes.displayGrupos}>
        Grupo {contador + 1} de {maxGrupos}
      </Typography>
    </div>
  );
};

export default CustomStepper;
