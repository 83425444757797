import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatosSiniestro,
  getDiagnosticos,
  getGestionesParticulares,
  getSecuencias,
} from "../../../../../../redux/actions/siniestralidad";
import CardContainer from "../../../../../commons/cards/CardContainer";
import DatosSiniestroCard from "./cards/DatosSiniestroCard";
import DiagnosticosCard from "./cards/DiagnosticosCard";
import GestionesParticularesCard from "./cards/GestionesParticularesCard";
import SecuenciaCard from "./cards/SecuenciaCard";

const DatosDelSiniestro = ({ selectedAccident }) => {
  const dispatch = useDispatch();

  const siniestralidad = useSelector((state) => state.siniestralidad);

  return (
    <>
      {/* Contenedor superior */}
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Grid item xs={6}>
            <CardContainer
              title="Datos Siniestro"
              loading={siniestralidad.datosSiniestro.loading}
              getData={() => dispatch(getDatosSiniestro(selectedAccident))}
              componente={
                <DatosSiniestroCard data={siniestralidad.datosSiniestro.data} />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CardContainer
              title="Gestiones Particulares"
              loading={siniestralidad.particulares.loading}
              getData={() =>
                dispatch(getGestionesParticulares(selectedAccident))
              }
              componente={
                <GestionesParticularesCard
                  data={siniestralidad.particulares.data}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Contenedor Inferior */}
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Grid item xs={6}>
            <CardContainer
              title="Secuencia"
              loading={siniestralidad.secuencias.loading}
              getData={() => dispatch(getSecuencias(selectedAccident))}
              componente={
                <SecuenciaCard data={siniestralidad.secuencias.data} />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CardContainer
              title="Diagnósticos"
              loading={siniestralidad.diagnosticos.loading}
              getData={() => dispatch(getDiagnosticos(selectedAccident))}
              componente={
                <DiagnosticosCard data={siniestralidad.diagnosticos.data} />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DatosDelSiniestro;
