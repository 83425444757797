import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import DropZone from "../../../../commons/dropZone/DropZone";
import RequeridoLabel from "../../../../commons/labels/RequeridoLabel";
import CircularLoading from "../../../../commons/loading/CircularLoading";

const useStyles = makeStyles((theme) => ({
  adjuntarFormularioDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loadingContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerButtonsDialog: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white",
    boxShadow: "0px 0 5px lightGrey",
    paddingRight: 10,
    zIndex: 2,
  },
}));

const DialogAdjuntarFormulario = ({
  open,
  onClose,
  title,
  file,
  setFile,
  isAdjuntando,
  handleCancelClick,
  handleConfirmClick,
  isConfirmButtonDisabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.adjuntarFormularioDialog}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{title}</DialogTitle>
      {isAdjuntando ? (
        <div className={classes.loadingContainer}>
          <CircularLoading
            style={{ color: `${theme.palette.tertiary.main}` }}
          />
          <RequeridoLabel text="Adjuntando el formulario..." />
        </div>
      ) : (
        <>
          <DropZone
            file={file}
            setFile={setFile}
            loading={false}
            maxFiles={1}
            extension=".pdf"
            accept={{
              "application/pdf": [".pdf"],
            }}
            dropZoneText="Arrastre el formulario en formato .pdf aquí o haga click en el recuadro para
          adjuntarlo."
            dropZoneEditText="Si desea cambiar el archivo arrastrelo nuevamente o haga click en
          el recuadro."
          />
          <DialogActions className={classes.containerButtonsDialog}>
            <Button color="primary" type="button" onClick={handleCancelClick}>
              Cancelar
            </Button>
            <Button
              disabled={isConfirmButtonDisabled}
              color="primary"
              variant="contained"
              onClick={handleConfirmClick}
            >
              Confirmar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogAdjuntarFormulario;
