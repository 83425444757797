import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getEstadoCumplimientoEstablecimientos } from "../../../redux/actions/establecimiento";
import HeaderForm from "../../commons/labels/HeaderForm";
import EstablecimientosTable from "./EstablecimientosTable";

const EstablecimientosContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectEstablecimientos = useSelector(
    (state) => state.establecimiento.estadoCumplimiento
  );

  const isLoading = selectEstablecimientos.loading;

  useEffect(() => {
    dispatch(getEstadoCumplimientoEstablecimientos());
  }, []);

  const handleVerEstadoEstablecimiento = (establecimiento) => {
    navigate(`/prevencion/establecimientos/${establecimiento?.matrizUnica}`, {
      state: establecimiento,
    });
  };

  return (
    <>
      <HeaderForm title="Prevención en Establecimientos" />
      <EstablecimientosTable
        loading={isLoading}
        data={selectEstablecimientos.data}
        handleVerEstadoEstablecimiento={handleVerEstadoEstablecimiento}
      />
    </>
  );
};

export default EstablecimientosContainer;
