import { Button, Typography, makeStyles } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addComprobante,
  deleteComprobante,
  getRubrosReintegro,
  getTipoComprobante,
  saveDatosComprobanteForm,
} from "../../../redux/actions/reintegros";
import DataLabel from "../../commons/labels/DataLabel";
import RequeridoLabel from "../../commons/labels/RequeridoLabel";
import DatosComprobanteForm from "./DatosComprobanteForm";
import DatosComprobanteTable from "./DatosComprobanteTable";
import AddComprobanteDialog from "./dialog/AddComprobanteDialog";

const useStyles = makeStyles((theme) => ({
  titleFormContainer: {},
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    padding: 20,
  },
  formContainer: {
    width: "100%",
  },
  tableContainer: {
    width: "65%",
  },
}));

const initialValues = {
  fechaDelComprobante: null,
  tipoComprobante: null,
  letra: "",
  ptoVenta: "",
  nro: "",
  tipo: "",
};

const DatosComprobanteContainer = ({ nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const siniestro = useSelector((state) => state.reintegros.localSiniestro);
  const postReintegro = useSelector((state) => state.reintegros.postReintegro);

  const [formModel, setFormModel] = useState(null);
  const [openAddComprobanteDialog, setOpenAddComprobantedialog] =
    useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [selectedDetalle, setSelectedDetalle] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(null);

  const isLoading = !formModel;

  useEffect(() => {
    dispatch(getTipoComprobante());
    dispatch(getRubrosReintegro());
  }, []);

  useEffect(() => {
    setFormModel(() => ({
      ...initialValues,
      fechaDelComprobante: postReintegro.datosComprobante.data
        ? postReintegro.datosComprobante.data.fechaDelComprobante
        : initialValues.fechaDelComprobante,
      tipoComprobante: postReintegro.datosComprobante.data
        ? postReintegro.datosComprobante.data.tipoComprobante
        : initialValues.tipoComprobante,
      letra: postReintegro.datosComprobante.data
        ? postReintegro.datosComprobante.data.letra
        : initialValues.letra,
      ptoVenta: postReintegro.datosComprobante.data
        ? postReintegro.datosComprobante.data.ptoVenta
        : initialValues.ptoVenta,
      nro: postReintegro.datosComprobante.data
        ? postReintegro.datosComprobante.data.nro
        : initialValues.nro,
      tipo: postReintegro.datosComprobante.data
        ? postReintegro.datosComprobante.data.tipo
        : initialValues.tipo,
    }));
  }, []);

  const handleSubmit = () => {
    if (postReintegro.datosComprobante.data != null) {
      if (
        postReintegro.datosComprobante.data.fechaDelComprobante &&
        postReintegro.datosComprobante.data.tipoComprobante &&
        postReintegro.datosComprobante.data.ptoVenta &&
        postReintegro.datosComprobante.data.nro
      ) {
        setShowErrorMessage(false);
        nextForm("Comprobante");
        return;
      }
    }
    setShowErrorMessage(true);
  };

  const handleOpenEditDialog = (row, index) => {
    setSelectedDetalle(row);
    setOnEdit(true);
    setOpenAddComprobantedialog(true);
  };

  const handleCloseAddDialog = () => {
    setOnEdit(false);
    setOpenAddComprobantedialog(false);
  };

  const handleSubmitNewComprobante = (values) => {
    dispatch(addComprobante({ ...values }));
    handleCloseAddDialog();
  };

  const handleDeleteComprobante = (values) => {
    dispatch(deleteComprobante(values));
  };

  const handleSubmitForm = (values) => {
    dispatch(saveDatosComprobanteForm(values));
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleFormContainer}>
        <Typography variant="h6">Datos del comprobante</Typography>
      </div>
      <DataLabel
        firstText={"Siniestro: " + siniestro.numeroSiniestroART}
        secondText={"DNI: " + siniestro.numeroDocumento}
        thirdText={siniestro.apellidoNombres}
      />
      <div className={classes.formContainer}>
        <DatosComprobanteForm
          model={formModel}
          isLoading={isLoading}
          handleSubmit={handleSubmitForm}
        />
        {showErrorMessage && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ErrorOutline style={{ color: "red", marginRight: 10 }} />
            <Typography style={{ color: "red" }}>
              Todos los campos son obligatorios
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.tableContainer}>
        <DatosComprobanteTable
          data={postReintegro.comprobantes.data}
          handleOpenAddDialog={() => setOpenAddComprobantedialog(true)}
          handleOpenEditDialog={handleOpenEditDialog}
          handleDelete={handleDeleteComprobante}
          totalDetalle={postReintegro.comprobantes.total}
        />
        {!postReintegro.comprobantes.data.length && (
          <RequeridoLabel text="Es obligatorio ingresar al menos un detalle." />
        )}
      </div>
      <div>
        <AddComprobanteDialog
          open={openAddComprobanteDialog}
          handleCloseAddDialog={handleCloseAddDialog}
          onEdit={onEdit}
          model={selectedDetalle}
          handleSubmit={handleSubmitNewComprobante}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={
            !postReintegro.comprobantes?.data?.length ||
            !postReintegro.datosComprobante?.done
          }
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default DatosComprobanteContainer;
