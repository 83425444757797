import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import CircularLoading from "../loading/CircularLoading";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    backgroundColor: `${theme.palette.primary.main}`,
    borderBottom: "1px solid green",
    boxSizing: "border-box",
    textAlign: "center",
    color: "white",
  },
  card: {
    marginTop: 20,
    height: "100%",
    width: "100%",
  },
  cardContent: {
    padding: 10,
    minHeight: "250px",
    maxHeight: "350px",
    overflow: "auto",
  },
}));

const CardContainer = ({ title, getData, componente, loading, style }) => {
  const classes = useStyles();

  useEffect(() => {
    if (getData) {
      getData();
    }
  }, []);

  return (
    <Card className={classes.card}>
      <div className={classes.titleContainer}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </div>
      <CardContent className={style ?? classes.cardContent}>
        {loading ? <CircularLoading /> : componente}
      </CardContent>
    </Card>
  );
};
export default CardContainer;
