import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    color: "white",
    fontWeight: "bold",
    textTransform: "upperCase",
  },
}));

const HeaderForm = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.text}>{title}</Typography>
    </div>
  );
};
export default HeaderForm;
