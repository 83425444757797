import React from "react";
import styled from "styled-components";
import { Card, CardContent } from "@material-ui/core";

const ContainerDetails = styled.div`
  padding: 16px;
  margin-left: 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 16px;
`;

const ContentDetails = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 16px;
  border-bottom: ${(props) => (props.noBorder ? 0 : "solid 1px #d1d1d1")};
`;

const ContentTextTitle = styled.div`
  font-weight: bold;
  font-family: Roboto, sans-serif;
`;

const ContentTextValue = styled.div`
  font-weight: 300;
  font-family: Roboto, sans-serif;
`;

const SiniestroTableDetail = ({ rowData }) => (
  <ContainerDetails>
    <Card>
      <CardContent>
        <ContentDetails>
          <ContentTextTitle>Modalidad de Denuncia</ContentTextTitle>
          <ContentTextValue>
            {rowData.modalidadDenuncia || "---"}
          </ContentTextValue>
        </ContentDetails>
        <ContentDetails>
          <ContentTextTitle>Prestador Inicial</ContentTextTitle>
          <ContentTextValue>
            {rowData.prestadorInicial || "---"}
          </ContentTextValue>
        </ContentDetails>
        <ContentDetails>
          <ContentTextTitle>Establecimiento</ContentTextTitle>
          <ContentTextValue>
            {rowData.establecimiento || "---"}
          </ContentTextValue>
        </ContentDetails>
      </CardContent>
    </Card>
  </ContainerDetails>
);

export default SiniestroTableDetail;
