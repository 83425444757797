import { Paper, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { importarNomina } from "../../../redux/actions/nomina";
import DropZone from "../../commons/dropZone/DropZone";
import HeaderForm from "../../commons/labels/HeaderForm";
import ErrorDialog from "./ErrorDialog";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "2em",
    borderRadius: 5,
    paddingBottom: 20,
  },
});

const NominaMasiva = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const nominaState = useSelector((state) => state.nomina.nomina);

  const [nomina, setNomina] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = () => {
    const body = {
      base64: nomina[0].value,
    };
    dispatch(importarNomina(body)).then((res) => {
      if (res?.value?.data && Array.isArray(res.value.data)) {
        setErrors(res.value.data);
        setOpenError(true);
      } else {
        toast.success("Nóminas importadas correctamente");
        setNomina(null);
      }
    });
  };

  const handleCloseError = () => {
    setOpenError(false);
    setErrors([]);
  };

  return (
    <Paper className={classes.container}>
      <HeaderForm title="Importar Nóminas" />
      <DropZone
        onUpload={handleSubmit}
        file={nomina}
        setFile={setNomina}
        loading={nominaState.loading}
        extension=".csv"
        accept={{
          "text/csv": [".csv"],
        }}
        dropZoneText="Arrastre la nómina en formato .csv aquí o haga click en el recuadro para
        adjuntarla."
        dropZoneEditText="Si desea cambiar el archivo arrastrelo nuevamente o haga click en
        el recuadro."
      />
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        errors={errors}
      />
    </Paper>
  );
};

export default NominaMasiva;
