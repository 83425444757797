import ExcelJS from "exceljs";
import { formatForNetDates } from "./datetime";

export function downloadCsv(data, filename) {
  const encoding = "data:text/csv;charset=utf-8,%EF%BB%BF";
  const link = document.createElement("a");
  link.setAttribute("href", encoding + escape(data));
  link.setAttribute("download", `${filename}.csv`);

  document.body.appendChild(link); // Required for FF

  link.click();
}

export function downloadFile(file, name) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const exportToExcel = (columns, dataExport, title) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(title);

  //creo una fila con el titulo, tomo esa fila y modifico los estilos
  const titleRow = worksheet.addRow([title]);
  const titleCell = titleRow.getCell(1);
  titleCell.font = {
    name: "Arial",
    size: 20,
    bold: true,
    color: { argb: "433F73" },
  };
  titleCell.alignment = { horizontal: "center" };
  titleCell.border = {
    bottom: {
      style: "medium",
      color: { argb: "433F73" },
    },
  };

  //modifico el tamaño de las filas por default
  worksheet.properties.defaultRowHeight = 22;

  //agrego una fila vacia entre el titulo y la tabla
  const spanRow = worksheet.addRow([""]); // eslint-disable-line no-unused-vars

  //agrego la fila con los headers de cada columna y modifico sus estilos
  const headerRow = worksheet.addRow(columns.map((column) => column.title));
  headerRow.eachCell((cell, colNumber) => {
    cell.font = {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "433F73" },
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF" },
    };
    cell.border = {
      left: { style: colNumber === 1 ? "thin" : "none" },
      right: { style: colNumber === headerRow.cellCount ? "thin" : "none" },
      bottom: {
        style: "thin",
      },
      top: {
        style: "thin",
      },
    };
  });

  //tomo las filas y columnas que necesito para mergearlas entre si
  const rowIndex = 1; // Índice de la fila que contiene la celda
  const rowIndex2 = 1; // Índice de la fila que contiene las celdas a combinar
  const startColIndex = 1; // Índice de la columna inicial a combinar
  const endColIndex = worksheet.columns.length; // Índice de la columna final a combinar

  worksheet.mergeCells(rowIndex, startColIndex, rowIndex2, endColIndex);

  // Agregar los datos a la hoja de cálculo
  const cells = dataExport.map((column) =>
    columns.map((col) => {
      const value = column[col.field] || 0;
      var cellValue = typeof value === "number" ? value : String(value);
      if (cellValue == "0") {
        cellValue = "";
      }
      if (typeof cellValue === "string" && cellValue.includes(":")) {
        cellValue = formatForNetDates(cellValue);
      }
      const cell = {
        value: cellValue,
        isNumber: typeof value === "number",
      };
      return cell;
    })
  );

  //recorro cada celda y modifico sus estilos
  cells.forEach((rowValues, rowIndex) => {
    const row = worksheet.addRow(rowValues.map((cell) => cell.value));

    row.eachCell((cell, colNumber) => {
      const cellConfig = rowValues[colNumber - 1];
      if (cellConfig.isNumber && cellConfig.value % 1 !== 0) {
        cell.numFmt = "#,##0.##";
      } else {
        // Si no tiene decimales, establecer otro formato sin coma decimal
        cell.numFmt = "#,##0";
      }
      cell.border = {
        left: { style: colNumber === 1 ? "thin" : "none" },
        right: { style: colNumber === row.cellCount ? "thin" : "none" },
        bottom: {
          style: rowIndex === cells.length - 1 ? "thin" : "none",
        },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: rowIndex % 2 === 0 ? "DDDDDD" : "FFFFFF" },
      };
    });
  });

  // Ajustar el ancho de las columnas
  worksheet.columns.forEach((column) => {
    if (column.values.find((e) => e === "Siniestro")) {
      column.width = 15;
    } else if (column.values.find((e) => e === "Nombre")) {
      column.width = 40;
    } else if (column.values.find((e) => e === "Prestador Inicial")) {
      column.width = 60;
    } else if (column.values.find((e) => e === "Establecimiento")) {
      column.width = 35;
    } else {
      column.width = 25;
    }
    column.eachCell({ includeEmpty: true }, (cell) => {
      cell.alignment = { horizontal: "center" };
    });
  });

  // Guardar el archivo
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
    downloadFile(blob, `${title}.xlsx`);
  });
};

/**
 * @param {string[]} encabezado - El encabezado de la tabla
 * @param {object[]} data - Las filas de la tabla
 * @param {string} title - El titulo del archivo exportado
 */
export const exportToCSV = (encabezado, data, title) => {
  const csvContent = [
    encabezado.map((column) => column.title),
    ...data.map((row) =>
      encabezado.map((col) => row[col.field] || "").map(String)
    ),
  ]
    .map((row) => row.join(","))
    .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

  downloadFile(blob, `${title}.csv`);
};


export async function parseBlobToObject(blob) {
  const text = await blob.text();
  try {
    return JSON.parse(text);
  } catch {

    return null
  }
}

