import { differenceInCalendarYears } from "date-fns";
import * as Yup from "yup";

export const datosTrabajadorValidation = Yup.object({
  fechaNacimiento: Yup.date()
    .test("mayorDe18", "Debes ser mayor de 18 años", function (value) {
      const today = new Date();
      const age = differenceInCalendarYears(today, value);
      return age >= 18;
    })
    .typeError("Fecha inválida"),
});

export const direccionTrabajadorValidation = Yup.object({});

export const datosNominaValidation = Yup.object({
  fechaIngresoEmpleador: Yup.date()
    .required("Campo obligatorio")
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual"),
  horaDesde: Yup.date().nullable().typeError("Fecha inválida"),
  horaHasta: Yup.date().nullable().typeError("Fecha inválida"),
  fechaIngresoDependencia: Yup.date().max(
    new Date(),
    "Debe ser menor a la fecha actual"
  ),
});

export const datosNominaValidationWithDomestica = Yup.object({
  categoriaDomestica: Yup.string().required("Campo obligatorio"),
  fechaIngresoEmpleador: Yup.date()
    .required("Campo obligatorio")
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual"),
  horaDesde: Yup.date().nullable().typeError("Fecha inválida"),
  horaHasta: Yup.date().nullable().typeError("Fecha inválida"),
  fechaIngresoDependencia: Yup.date()
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual"),
});

export const datosDenunciaValidation = Yup.object({
  cuitOcurrencia: Yup.string()
    .matches(/^\d{11}$/, "El CUIT debe contener 11 dígitos numéricos")
    .required("Campo obligatorio"),
  fechaHoraAccidente: Yup.date()
    .max(new Date(), "Debe ser menor a la fecha actual")
    .typeError("Fecha inválida"),
});

export const lugarOcurrenciaValidation = Yup.object({
  numeroOcurrencia: Yup.string()
    .test("validacion-km", "El número ingresado es inválido", function (value) {
      const km = this.parent.kmOcurrencia || false;
      if (!km) {
        return value?.match(/^\d+$/);
      } else {
        return true;
      }
    })
    .test({
      // en la tabla del back es un decimal(10, 2)
      name: "cantidadDigitosDecimales",
      message: "Máximo 2 dígitos después de la coma",
      test: function (value) {
        const km = this.parent.kmOcurrencia || false;
        if (km) {
          return value?.match(/^[0-9]+(,\d{1,2})?$/);
        } else {
          return true;
        }
      },
    })
    .test({
      name: "cantidadTotalDigitos",
      message: "Máximo 8 dígitos enteros",
      test: function (value) {
        const km = this.parent.kmOcurrencia || false;
        if (km) {
          return value?.split(",")[0]?.length <= 8;
        } else {
          return value?.length <= 8;
        }
      },
    }),
});

export const accidenteTrabajoFormValidation = Yup.object({
  subTipoAccidente: Yup.string().nullable().required("Campo obligatorio"),
});

export const agregarDiagnosticoAccidenteValidation = Yup.object({
  diagnosticoOms: Yup.string().required("Campo obligatorio").nullable(),
  gravedad: Yup.string().required("Campo obligatorio").nullable(),
  naturalezaLesion: Yup.string().required("Campo obligatorio").nullable(),
  zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
});

export const enfermedadProfesionalValidation = Yup.object({
  tipoEnfermedadProfesional: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  agenteCausante: Yup.string().required("Campo obligatorio").nullable(),
  agenteCausanteEnfermedadProfesional: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  tiempoExposicionAgenteCausante: Yup.number().min(
    1,
    "El tiempo de exposición debe ser positivo"
  ),
});

export const agregarDiagnosticoEnfermedadValidation = Yup.object({
  diagnosticoOms: Yup.string().required("Campo obligatorio").nullable(),
  gravedad: Yup.string().required("Campo obligatorio").nullable(),
  fechaInicio: Yup.date()
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual ")
    .nullable(),
  ambitoDeteccionEnfermedadProfesional: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
});

export const agregarDiagnosticoEnfermedadValidationWithFechaExamen = Yup.object(
  {
    diagnosticoOms: Yup.string().required("Campo obligatorio").nullable(),
    gravedad: Yup.string().required("Campo obligatorio").nullable(),
    fechaInicio: Yup.date()
      .typeError("Fecha inválida")
      .max(new Date(), "Debe ser menor a la fecha actual ")
      .nullable(),
    ambitoDeteccionEnfermedadProfesional:
      Yup.string().required("Campo obligatorio"),
    fechaExamen: Yup.date()
      .required("Campo obligatorio")
      .typeError("Fecha inválida")
      .max(new Date(), "Debe ser menor a la fecha actual ")
      .nullable(),
    zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
  }
);

export const validateRequiredFields = (values, model) => {
  const errors = {};
  const errorMessage = "Campo obligatorio";

  for (var value in values) {
    //valida que el campo exista en los datos de la nomina para validar su obligatoriedad
    if (model[value]) {
      if (model[value].obligatorio && !values[value]) {
        errors[value] = errorMessage;
      }
    }
  }

  return errors;
};
