import { Typography } from "@material-ui/core";

const EstadosNteLabel = () => {
  return (
    <>
      <div style={{ display: "flex", padding: 5, marginTop: 10 }}>
        <Typography>
          <span style={{ color: "blue" }}>NTE Pre-Presentado</span> cuando la{" "}
          <span style={{ color: "green" }}>
            importación de nóminas es correcta
          </span>
        </Typography>
      </div>
      <div style={{ display: "flex", padding: 5 }}>
        <Typography>
          <span style={{ color: "red" }}>NTE Presentado</span> cuando la{" "}
          <span style={{ color: "green" }}>
            importación de nóminas es correcta
          </span>{" "}
          y además se adjunta el NTE firmado
        </Typography>
      </div>
    </>
  );
};

export default EstadosNteLabel;
