import { Paper, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  downloadCertificadoCobertura,
  enviarCertificadoCobertura,
  validarImportacionNominas,
} from "../../../redux/actions/certificadoCobertura";
import { downloadFile } from "../../../util/files";
import Breadcrumb from "../../commons/breadcrumb/Breadcrumb";
import HeaderForm from "../../commons/labels/HeaderForm";
import SeleccionDeNominas from "./forms/nomina/SeleccionDeNominas";
import DatosDelViajeContainer from "./forms/viaje/DatosDelViajeContainer";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid green",
    textAlign: "center",
    color: "white",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 20,
  },
  breadcrumbContainer: { display: "flex", margin: 15 },
}));

const CoberturaViajeAlExteriorContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const datosViajeExterior = useSelector(
    (state) => state.certificadoCobertura.postCertificadoCobertura.datosDelViaje
  );

  const [form, setForm] = useState("DatosDelViaje");
  const [fromBread, setFromBread] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);

  const handleNext = (form) => {
    if (!breadcrumbFields.includes(form)) {
      const auxArray = [...breadcrumbFields, form];
      setBreadcrumbFields(auxArray);
    }
    setForm(form);
  };

  const handleSubmit = (email, nominasValue, nominaList, nominaFile) => {
    const bodyObj = {
      tiposClausulaCertificado: [],
      subrogacionempresa: [],
      nominas: nominaList,
      seleccionEmpleados: parseInt(nominasValue),
      archivoBase64: {
        base64: nominaFile ? nominaFile[0].value : "",
        extension: nominaFile ? nominaFile[0].filename.split(".").pop() : "",
      },
      email,
      ...datosViajeExterior,
    };
    if (bodyObj.archivoBase64.base64 !== "") {
      dispatch(validarImportacionNominas(bodyObj.archivoBase64)).then(() =>
        handleSubmitCertificado(bodyObj)
      );
    } else {
      handleSubmitCertificado(bodyObj);
    }
  };

  const handleSubmitCertificado = (bodyObj) => {
    if (bodyObj.email) {
      dispatch(enviarCertificadoCobertura(bodyObj)).then(() => {
        toast.success("Certificado enviado correctamente");
      });
    } else {
      dispatch(downloadCertificadoCobertura(bodyObj)).then((response) => {
        downloadFile(response.value.data, "Certificado Cobertura.pdf");
      });
    }
  };

  return (
    <Paper>
      <HeaderForm title="Cobertura por Viaje al Exterior" />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
        />
      </div>
      <div style={{ margin: 20 }}>
        {
          {
            DatosDelViaje: <DatosDelViajeContainer handleNext={handleNext} />,
            SelecciónDeEmpleados: (
              <SeleccionDeNominas handleSubmit={handleSubmit} />
            ),
          }[form]
        }
      </div>
    </Paper>
  );
};

export default CoberturaViajeAlExteriorContainer;
