import { TablePagination, useTheme } from "@material-ui/core";
import { PostAdd } from "@material-ui/icons";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import ExportButton from "../../commons/export/ExportButton";
import { localization, tableStyles } from "../../commons/table/tableConfig";
import TitleContainer from "../../commons/table/utilsTable/TitleWithFetchData";
import SiniestroFilterForm from "./SiniestroFilterForm";
import SiniestroTableDetail from "./SiniestroTableDetail";

const SiniestroTable = ({
  data,
  loading,
  fetchData,
  handleSubmitFilter,
  handleAgregarDocumentacion,
  handleEnviarReciboSueldo,
  handleDescargarReportePredenuncia,
  handleAdjuntarDenuncia,
  initialValues,
  handleAccidentDetail,
  displayAccidents,
}) => {
  const theme = useTheme();

  const columns = [
    {
      title: "Siniestro",
      field: "siniestroART",
      width: "10%",
    },
    {
      title: "DNI",
      field: "nroDocumento",
      width: "10%",
    },
    {
      title: "Nombre",
      field: "apellidoNombre",
      width: "10%",
    },
    {
      title: "Tipo Siniestro",
      field: "tipoSiniestro",
      width: "10%",
    },
    {
      title: "Sub Tipo Siniestro",
      field: "subtipoSiniestro",
      width: "10%",
    },
    {
      title: "Fecha de Ocurrencia",
      field: "fechaOcurrencia",
      width: "10%",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaOcurrencia).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) =>
        rowData.fechaOcurrencia
          ? moment(rowData.fechaOcurrencia).format("DD/MM/YYYY")
          : "",
    },
    {
      title: "Fecha Cese ILT",
      field: "fechaCeseILT",
      width: "10%",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaCeseILT).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) =>
        rowData.fechaCeseILT
          ? moment(rowData.fechaCeseILT).format("DD/MM/YYYY")
          : "",
    },
    {
      title: "Fecha de Alta",
      field: "fechaAlta",
      width: "10%",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaAlta).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) =>
        rowData.fechaAlta ? moment(rowData.fechaAlta).format("DD/MM/YYYY") : "",
    },
    {
      title: "Fecha de Rechazo",
      field: "fechaRechazo",
      width: "10%",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaRechazo).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) =>
        rowData.fechaRechazo
          ? moment(rowData.fechaRechazo).format("DD/MM/YYYY")
          : "",
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={{ ...tableStyles.container, display: displayAccidents }}
      title={
        <TitleContainer title="" noRefresh={false} fetchData={fetchData} />
      }
      actions={[
        {
          icon: "visibility",
          tooltip: "Ver siniestro",
          onClick: (_, rowData) => {
            handleAccidentDetail(rowData);
          },
        },
        {
          icon: () => (
            // por especificacion del analista, el boton de
            // agregar documentacion tiene que ser azul
            <PostAdd
              style={{
                color: `${theme.palette.tertiary.main}`,
              }}
            />
          ),
          tooltip: "Agregar documentación",
          onClick: (_, rowData) => {
            handleAgregarDocumentacion(rowData);
          },
        },
        {
          // por especificacion del analista, el boton de recibos
          // de sueldo tiene que ser verde
          icon: () => (
            <RequestQuoteIcon
              style={{ color: `${theme.palette.secondary.main}` }}
            />
          ),
          tooltip: "Enviar Recibo de Sueldo",
          onClick: (_, rowData) => {
            handleEnviarReciboSueldo(rowData);
          },
        },
        (rowData) => ({
          icon: () => (
            <SaveAltIcon
              style={{
                color: `${
                  rowData.codigoModalidadDenuncia !== 4
                    ? theme.palette.primary.grey
                    : theme.palette.quartary.main
                }`,
              }}
            />
          ),
          // Por especificacion, la funcionalidad de descargar reporte
          // solamente está habilitada para aquellos siniestros
          // que tengan la modalidad "Web"
          disabled: rowData.codigoModalidadDenuncia !== 4,
          tooltip: "Descargar reporte de predenuncia",
          onClick: () => {
            handleDescargarReportePredenuncia(rowData.nroPreDenuncia);
          },
        }),
        (rowData) => ({
          icon: () => (
            <AttachFileIcon
              style={{
                color: `${
                  rowData.codigoModalidadDenuncia !== 4
                    ? theme.palette.primary.grey
                    : "black"
                }`,
              }}
            />
          ),
          // Por especificacion, la funcionalidad de adjuntar denuncia
          // solamente está habilitada para aquellos siniestros
          // que tengan la modalidad "Web"
          disabled: rowData.codigoModalidadDenuncia !== 4,
          tooltip: "Adjuntar Denuncia",
          onClick: () => {
            handleAdjuntarDenuncia(rowData);
          },
        }),
      ]}
      options={{
        actionsColumnIndex: -1,
        headerStyle: tableStyles.headerStyle,
        rowStyle: tableStyles.rowStyle,
        pageSize: 10,
        search: false,
        filtering: true,
      }}
      components={{
        Toolbar: (props) => (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MTableToolbar {...props} />
              <ExportButton
                {...props}
                color={`${theme.palette.tertiary.main}`}
                columns={[
                  ...props.columns,
                  {
                    title: "Modalidad de Denuncia",
                    field: "modalidadDenuncia",
                  },
                  {
                    title: "Prestador Inicial",
                    field: "prestadorInicial",
                  },
                  {
                    title: "Establecimiento",
                    field: "establecimiento",
                  },
                ]}
                title="Siniestralidad"
              />
            </div>
            <SiniestroFilterForm
              handleSubmit={handleSubmitFilter}
              initialValues={initialValues}
            />
          </div>
        ),
        Pagination: (props) => {
          const { onChangePage, onChangeRowsPerPage, ...tablePaginationProps } =
            props;
          return (
            <TablePagination
              {...tablePaginationProps}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                  <props.ActionsComponent
                    {...actionsComponentProps}
                    onChangePage={onPageChange}
                  />
                );
              }}
            />
          );
        },
      }}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "Sin siniestros cargados",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
      detailPanel={[
        {
          tooltip: "Más Información",
          render: (rowData) => (
            <div style={{ backgroundColor: "#fafafa" }}>
              <SiniestroTableDetail rowData={rowData} />
            </div>
          ),
        },
      ]}
    />
  );
};

export default SiniestroTable;
