import { makeStyles } from "@material-ui/core";
import "./formStyle.css";

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingRight: 35,
    paddingLeft: 35,
    paddingTop: 15,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 25,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  inputContainer: {
    marginBottom: 50,
  },
  formMariginTop: {
    marginTop: 50,
  },
  warningMessageContainer: {
    display: "flex",
    padding: 15,
    justifyContent: "center",
  },
}));
