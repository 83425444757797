import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useState } from "react";
import { exportToCSV, exportToExcel } from "../../../util/files";

const useStyles = makeStyles({
  icon: {
    color: (props) => (!!props.color ? props.color : "#858a93"),
  },
  menuItem: {
    marginRight: -20,
  },
});

const ExportButton = ({ data, columns, title, disabled, color }) => {
  const classes = useStyles({ color });

  const [openMenu, setOpenMenu] = useState(null);

  const handleClose = (event) => {
    setOpenMenu(null);
  };

  const handleClickMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  return (
    <>
      <div style={{ display: "flex", margin: "10px" }}>
        <Tooltip title="Exportar" arrow>
          {/* la etiqueta de <span> es necesaria segun Material-UI porque
          sino el tooltip no se mostraria cuando el IconButton esta disabled */}
          <span>
            <IconButton
              size="medium"
              className={classes.icon}
              onClick={handleClickMenu}
              disabled={disabled}
            >
              <FileDownloadIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={handleClose}
        style={{ marginTop: 45, marginLeft: 0 }}
      >
        <MenuItem onClick={() => exportToCSV(columns, data, title)}>
          <ListItemText primary="Exportar CSV" />
        </MenuItem>
        <MenuItem onClick={() => exportToExcel(columns, data, title)}>
          <ListItemText primary="Exportar XLSX" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportButton;
