import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import CardContainer from "../../../../commons/cards/CardContainer";
import EstadosNteLabel from "../../commons/labels/EstadosNteLabel";
import { useStyles } from "../../styles/formStyles";
import DatosDelEstablecimientoCard from "./DatosDelEstablecimientoCard";

const EstablecimientoCard = ({ searchedNte, nextForm, validatedNte }) => {
  const classes = useStyles();

  const datosEstablecimiento = useSelector(
    (state) => state.nte.datosEstablecimiento
  );

  const handleSubmit = () => {
    nextForm("Responsables");
  };

  return (
    <>
      {searchedNte ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: 10,
          }}
        >
          <div>
            <EstadosNteLabel />
          </div>
          <div
            style={{
              width: "80%",
            }}
          >
            <CardContainer
              title="Datos del Establecimiento"
              loading={datosEstablecimiento.loading}
              componente={
                <DatosDelEstablecimientoCard data={datosEstablecimiento.data} />
              }
              getData={() => {}}
            />
          </div>
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ height: 300 }}>
            <Typography
              style={{
                padding: "150px 20px 200px 50px",
                color: "grey",
                textAlign: "center",
              }}
            >
              Busque un establecimiento.
            </Typography>
          </Grid>
        </Grid>
      )}
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !datosEstablecimiento.data?.nombre || !searchedNte
              ? true
              : false || !validatedNte
          }
        >
          SIGUIENTE
        </Button>
      </div>
    </>
  );
};

export default EstablecimientoCard;
