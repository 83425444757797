import {
  GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS,
  GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO,
  DESCARGAR_FORMULARIO_RAR_PDF,
  DESCARGAR_FORMULARIO_RGRL_PDF,
  GET_FORMULARIO_RAR_PDF,
  GET_FORMULARIO_RGRL_PDF,
  POST_FORMULARIO_RAR_FIRMADO,
  POST_FORMULARIO_RGRL_FIRMADO,
  CLEAR_VISUALIZACION_FORMULARIO_PDF,
} from "../actions/establecimiento";

const INITIAL_STATE = {
  estadoCumplimiento: {
    loading: false,
    error: false,
    data: [],
  },
  getFormularioPdf: {
    loading: false,
    error: false,
    data: {},
  },
  descargarFormularioPdf: {
    loading: false,
    error: false,
    data: {},
  },
  formularioFirmadoAdjunto: {
    loading: false,
    error: false,
    data: {},
  },
  formulariosPrevencion: {
    loading: false,
    error: false,
    data: [],
  },
};

const establecimiento = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS}_PENDING`:
      return {
        ...state,
        estadoCumplimiento: {
          ...state.estadoCumplimiento,
          error: false,
          loading: true,
        },
      };
    case `${GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS}_REJECTED`:
      return {
        ...state,
        estadoCumplimiento: {
          ...state.estadoCumplimiento,
          error: true,
          loading: false,
        },
      };
    case `${GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS}_FULFILLED`:
      return {
        ...state,
        estadoCumplimiento: {
          ...state.estadoCumplimiento,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO}_PENDING`:
      return {
        ...state,
        formulariosPrevencion: {
          ...state.formulariosPrevencion,
          error: false,
          loading: true,
        },
      };
    case `${GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO}_REJECTED`:
      return {
        ...state,
        formulariosPrevencion: {
          ...state.formulariosPrevencion,
          error: true,
          loading: false,
        },
      };
    case `${GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO}_FULFILLED`:
      return {
        ...state,
        formulariosPrevencion: {
          ...state.formulariosPrevencion,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${DESCARGAR_FORMULARIO_RAR_PDF}_PENDING`:
      return {
        ...state,
        descargarFormularioPdf: {
          ...state.descargarFormularioPdf,
          error: false,
          loading: true,
        },
      };
    case `${DESCARGAR_FORMULARIO_RAR_PDF}_REJECTED`:
      return {
        ...state,
        descargarFormularioPdf: {
          ...state.descargarFormularioPdf,
          error: true,
          loading: false,
        },
      };
    case `${DESCARGAR_FORMULARIO_RAR_PDF}_FULFILLED`:
      return {
        ...state,
        descargarFormularioPdf: {
          ...state.descargarFormularioPdf,
          error: false,
          loading: false,
          data: action.payload,
        },
      };
    case `${DESCARGAR_FORMULARIO_RGRL_PDF}_PENDING`:
      return {
        ...state,
        descargarFormularioPdf: {
          ...state.descargarFormularioPdf,
          error: false,
          loading: true,
        },
      };
    case `${DESCARGAR_FORMULARIO_RGRL_PDF}_REJECTED`:
      return {
        ...state,
        descargarFormularioPdf: {
          ...state.descargarFormularioPdf,
          error: true,
          loading: false,
        },
      };
    case `${DESCARGAR_FORMULARIO_RGRL_PDF}_FULFILLED`:
      return {
        ...state,
        descargarFormularioPdf: {
          ...state.descargarFormularioPdf,
          error: false,
          loading: false,
          data: action.payload,
        },
      };
    case `${GET_FORMULARIO_RAR_PDF}_PENDING`:
      return {
        ...state,
        getFormularioPdf: {
          ...state.getFormularioPdf,
          error: false,
          loading: true,
        },
      };
    case `${GET_FORMULARIO_RAR_PDF}_REJECTED`:
      return {
        ...state,
        getFormularioPdf: {
          ...state.getFormularioPdf,
          error: true,
          loading: false,
        },
      };
    case `${GET_FORMULARIO_RAR_PDF}_FULFILLED`:
      return {
        ...state,
        getFormularioPdf: {
          ...state.getFormularioPdf,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_FORMULARIO_RGRL_PDF}_PENDING`:
      return {
        ...state,
        getFormularioPdf: {
          ...state.getFormularioPdf,
          error: false,
          loading: true,
        },
      };
    case `${GET_FORMULARIO_RGRL_PDF}_REJECTED`:
      return {
        ...state,
        getFormularioPdf: {
          ...state.getFormularioPdf,
          error: true,
          loading: false,
        },
      };
    case `${GET_FORMULARIO_RGRL_PDF}_FULFILLED`:
      return {
        ...state,
        getFormularioPdf: {
          ...state.getFormularioPdf,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_FORMULARIO_RAR_FIRMADO}_PENDING`:
      return {
        ...state,
        formularioFirmadoAdjunto: {
          ...state.formularioFirmadoAdjunto,
          error: false,
          loading: true,
        },
      };
    case `${POST_FORMULARIO_RAR_FIRMADO}_REJECTED`:
      return {
        ...state,
        formularioFirmadoAdjunto: {
          ...state.formularioFirmadoAdjunto,
          error: true,
          loading: false,
        },
      };
    case `${POST_FORMULARIO_RAR_FIRMADO}_FULFILLED`:
      return {
        ...state,
        formularioFirmadoAdjunto: {
          ...state.formularioFirmadoAdjunto,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_FORMULARIO_RGRL_FIRMADO}_PENDING`:
      return {
        ...state,
        formularioFirmadoAdjunto: {
          ...state.formularioFirmadoAdjunto,
          error: false,
          loading: true,
        },
      };
    case `${POST_FORMULARIO_RGRL_FIRMADO}_REJECTED`:
      return {
        ...state,
        formularioFirmadoAdjunto: {
          ...state.formularioFirmadoAdjunto,
          error: true,
          loading: false,
        },
      };
    case `${POST_FORMULARIO_RGRL_FIRMADO}_FULFILLED`:
      return {
        ...state,
        formularioFirmadoAdjunto: {
          ...state.formularioFirmadoAdjunto,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case CLEAR_VISUALIZACION_FORMULARIO_PDF:
      return {
        ...state,
        getFormularioPdf: {
          loading: false,
          error: false,
          data: {},
        },
      };

    default:
      return state;
  }
};

export default establecimiento;
