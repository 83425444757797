import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingRight: 35,
    paddingLeft: 35,
    paddingTop: 15,
  },
  radioContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    marginLeft: 5,
  },
  paddingContainer: {
    padding: 20,
  },
  textInputGroupContainer: {
    marginTop: 25,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
  },
  formikInput: {
    margin: 20,
  },
  formikRadio: {
    marginTop: 20,
  },
  flexDirectionColumn: {
    display: "flex",
    flexDirection: "column",
  },
  loadingContainer: {
    height: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titulo: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: 5,
    margin: 10,
  },
  paper: {
    marginTop: 50,
    padding: 10,
    width: "80%",
    margin: "0 auto",
  },
  planillaTableButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 10,
  },
}));
