import { Paper, Typography, makeStyles } from "@material-ui/core";
import EmailIcon from "@mui/icons-material/Email";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfSharpIcon from "@mui/icons-material/PictureAsPdfSharp";
import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { downloadPDFContrato } from "../../../redux/actions/contrato";
import { downloadFile } from "../../../util/files";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "10px 20px",
    marginBottom: "10px",
    alignItems: "center",
  },

  icon: {
    marginRight: "15px",
    color: `${theme.palette.primary.main}`,
  },

  pointer: {
    cursor: "pointer",
  },

  text: {
    fontSize: "14px",
    fontWeight: "600",
    margin: "auto 0px",
  },

  containerIcon: {
    display: "flex",
  },

  containerText: {
    display: "flex",
  },
}));

const ContratoCard = ({ nombreContrato, openModal, codigoContrato }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDownloadPDF = () => {
    dispatch(downloadPDFContrato(codigoContrato)).then((response) => {
      downloadFile(response.value.data, `${nombreContrato}.pdf`);
    });
  };

  return (
    <Paper className={classes.container} elevation={3}>
      <Box className={classes.containerIcon}>
        <PictureAsPdfSharpIcon className={classes.icon} sx={{ fontSize: 40 }} />
        <Typography className={classes.text}>{nombreContrato}</Typography>
      </Box>
      <Box className={classes.containerIcon}>
        <EmailIcon
          className={`${classes.icon} ${classes.pointer}`}
          sx={{ fontSize: 30 }}
          onClick={() => openModal(codigoContrato)}
        />
        <FileDownloadIcon
          className={`${classes.icon} ${classes.pointer}`}
          sx={{ fontSize: 30 }}
          onClick={handleDownloadPDF}
        />
      </Box>
    </Paper>
  );
};

export default ContratoCard;
