import { makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../../../commons/formik/FormikTextField";

const useStyles = makeStyles({
  container: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "75%",
  },
  form: {
    display: "inline-flex",
    width: "100%",
  },
});

const initialValues = {
  cuit: "",
  razonSocial: "",
};

const validationSchema = Yup.object({
  razonSocial: Yup.string()
    .max(8000, "Máximo 8.000 caracteres")
    .required("Campo obligatorio"),
});

/**
 * Este formulario sigue la especificacion de PDCA-536.
 *
 * Permite la posibilidad de que el usuario cargue,
 * en un cuadro de texto, la lista de empresas (con
 * cuit y razonSocial) con un formato arbitrario.
 * Por ejemplo, el usuario escribe:
 * "
 *  20112223339-empresa uno
 *  20112224449-empresa dos
 * ";
 * y esto deberia resultar en 1 solo objeto:
 * {
 *   cuit: "",
 *   razonSocial: "20112223339-empresa uno\n20112224449-empresa dos"
 * }.
 * Luego, este objeto deberia ser el único de la lista de empresas
 * que se envia al backend.
 */
const EmpresaCargaPorTextoForm = ({ handleSubmit }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className={classes.form}>
            <FormikTextField
              fullWidth
              name="razonSocial"
              label="Lista de Empresas *"
              variant="outlined"
              type="text"
              autoFocus
              multiline
              minRows={5}
              maxRows={10}
              onBlur={() => handleSubmit(values)}
              // Este listener "onFocus" es necesario ya que necesitamos
              // mandar los valores apenas el usuario clickea el Switch de
              // "Carga por texto". De lo contrario, el usuario tiene que
              // clickear fuera del TextField después de haber escrito algo
              // para que se habilite el botón siguiente.
              onFocus={() => handleSubmit(values)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmpresaCargaPorTextoForm;
