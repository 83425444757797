import { Button, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  buscarSiniestro,
  clearDenuncia,
  clearHistorialSiniestros,
  existeNominaDenuncia,
} from "../../../../../redux/actions/denuncia";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import Search from "../../../../commons/searchCuil/Search";
import SiniestralidadTrabajadorTable from "./SiniestralidadTrabajadorTable";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 20,
  },
  mensaje: {
    display: "flex",
    height: "300px",
    justifyContent: "center",
    alignItems: "center",
  },
});

const initialValues = {
  nroSiniestroART: null,
  idModalidadDenuncia: null,
  idTipoSiniestro: null,
  idDependenciaEmpleador: null,
  cuil: "",
  nroDocumento: null,
  apellido: "",
  nombres: "",
  fechaDesde: null,
  fechaHasta: null,
};

const SiniestralidadTrabajadorContainer = ({ nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const historialSiniestros = useSelector((state) => state.denuncia.siniestros);
  const nomina = useSelector((state) => state.denuncia.nomina);

  const [busquedaSinResultados, setBusquedaSinResultados] = useState(false);
  const [esBusquedaNueva, setEsBusquedaNueva] = useState(true);

  const handleSearchHistorialSiniestros = (cuilBuscado) => {
    const searchModel = {
      ...initialValues,
      cuil: cuilBuscado,
    };

    dispatch(buscarSiniestro(searchModel))
      .then((res) => {
        const hayHistorial = res?.value?.data?.length > 0;
        if (!hayHistorial) {
          setBusquedaSinResultados(true);
        } else {
          setBusquedaSinResultados(false);
        }
        setEsBusquedaNueva(true);
      })
      .catch((err) => {
        toast.error(
          "Ha habido un error al buscar el historial de denuncias asociadas a este número de CUIL"
        );
      });
  };

  const handleClearBusqueda = () => {
    setBusquedaSinResultados(false);
    dispatch(clearHistorialSiniestros());
    // Necesitamos que luego de un "clear", el botón quede deshabilitado.
    // Se necesita una variable nueva para controlar si se acaba de hacer un clear
    setEsBusquedaNueva(false);
  };

  return (
    <>
      <HeaderForm title="Siniestralidad del Trabajador" />
      <Search
        clearActions={[clearDenuncia]}
        reduxAction={existeNominaDenuncia}
        callBack={handleSearchHistorialSiniestros}
        onClear={handleClearBusqueda}
        data={nomina}
      />

      {historialSiniestros.data.length > 0 ? (
        <SiniestralidadTrabajadorTable
          data={historialSiniestros.data}
          loading={historialSiniestros.loading}
        />
      ) : (
        <div className={classes.mensaje}>
          <Typography
            style={{
              color: "grey",
              textAlign: "center",
            }}
          >
            {busquedaSinResultados
              ? "No se encontraron denuncias previas asociadas a este número de CUIL"
              : "Ingrese un CUIL, sin coma ni guiones."}
          </Typography>
        </div>
      )}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          disabled={
            nomina.error ||
            !nomina.data ||
            historialSiniestros.error ||
            !esBusquedaNueva
          }
          onClick={() => nextForm("DatosTrabajadorForm")}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
};

export default SiniestralidadTrabajadorContainer;
