import { DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { Dialog, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import FormikActions from "../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../commons/formik/FormikAutocomplete";
import FormikTextField from "../../../commons/formik/FormikTextField";
import { rubrosDelReintegroValidation } from "../../validation/formValidation";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridContainer: {
    padding: 20,
  },
}));

const initialValuesRubros = {
  rubro: "",
  importe: "",
  id: "",
  nombre: "",
};

const AddComprobanteDialog = ({
  open,
  onEdit,
  model,
  handleSubmit,
  handleCloseAddDialog,
}) => {
  const classes = useStyles();

  const rubrosReintegro = useSelector(
    (state) => state.reintegros.rubrosReintegro
  );

  return (
    <Dialog open={open} className={classes.dialog}>
      <DialogTitle variant="h6" align="center" style={{ padding: 10 }}>
        Rubros del Reintegro
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={onEdit ? model : initialValuesRubros}
          onSubmit={handleSubmit}
          validationSchema={rubrosDelReintegroValidation}
        >
          {({ setFieldValue }) => (
            <Form className={classes.formContainer}>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} className={classes.inputContainer}>
                  <FormikAutocomplete
                    fullWidth
                    name="rubro"
                    label="Rubros"
                    variant="standard"
                    options={rubrosReintegro.data}
                    renderOption={(option) => option.nombre}
                    filterOptions={(option) => option.nombre}
                    callBack={(inputValue) => {
                      if (inputValue) {
                        setFieldValue("nombre", inputValue.nombre);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                  <FormikTextField
                    fullWidth
                    id="importe"
                    name="importe"
                    type="text"
                    label="Importe"
                  />
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <FormikActions
                    submit="Agregar"
                    handleCancel={handleCloseAddDialog}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddComprobanteDialog;
