import { Button, makeStyles } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getResponsables } from "../../../../../redux/actions/rgrl";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import CircularLoading from "../../../../commons/loading/CircularLoading";

const initialValues = {
  id: "",
  nombre: "",
  cuit: "",
};

const validationSchema = Yup.object({
  id: Yup.number().required("Campo obligatorio"),
});

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  input: {
    flex: 1,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
  rightInput: {
    marginLeft: 20,
  },
  dialogContainer: {
    minHeight: 180,
    minWidth: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AddResponsableDialog = ({
  open,
  handleClose,
  handleSubmit,
  handleOpenNewResponsable,
  openInNewResponsable,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const responsablesRedux = useSelector((state) => state.rgrl.responsables);

  const [formModel, setFormModel] = useState(null);

  useEffect(() => {
    if (openInNewResponsable) {
      const responsable =
        responsablesRedux.data[responsablesRedux.data.length - 1];
      setFormModel({
        ...initialValues,
        id: responsable.id,
        nombre: responsable.nombre.split("(")[0],
        cuit: responsable.nombre
          .split(" - ")[0]
          .split("(")[1]
          .replaceAll(")", ""),
      });
    } else {
      setFormModel({ ...initialValues });
    }
  }, [openInNewResponsable]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Seleccionar Responsable</DialogTitle>
      <Formik
        initialValues={formModel}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            {!isSubmitting ? (
              <>
                <DialogContent>
                  <div className={classes.rowContainer}>
                    <FormikAutocomplete
                      name="id"
                      label="Responsables"
                      className={classes.input}
                      options={responsablesRedux.data}
                      renderOption={(option) => option.nombre}
                      filterOptions={(option) => option.nombre}
                      getOptions={() => dispatch(getResponsables())}
                      callBack={(value) => {
                        if (value) {
                          setFieldValue("nombre", value.nombre.split("(")[0]);
                          setFieldValue(
                            "cuit",
                            /\(([^)]+)\)/
                              .exec(value.nombre.split(" - ")[0])[1]
                              .replaceAll("-", "")
                          );
                        }
                      }}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <FormikActions handleCancel={handleClose}></FormikActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleOpenNewResponsable}
                    endIcon={<PersonAdd />}
                  >
                    Nuevo
                  </Button>
                </DialogActions>
              </>
            ) : (
              <div className={classes.dialogContainer}>
                <CircularLoading />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddResponsableDialog;
