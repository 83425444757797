import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearExisteNomina,
  clearPostNomina,
  existeNomina,
  saveDatosParticulares,
  setSearchedCuil,
} from "../../../../../redux/actions/nomina";
import { convertDateTimeToNet } from "../../../../../util/datetime";
import Search from "../../../../commons/searchCuil/Search";
import DatosParticularesForm from "./DatosParticularesForm";

const initialValues = {
  nombres: "",
  apellido: "",
  tipoDocumento: "",
  nroDocumento: "",
  nacionalidad: "",
  fechaNacimiento: null,
  genero: "",
  estadoCivil: "",
  telefono: "",
  mail: "",
};

const DatosParticularesContainer = ({ nextForm, fromBread }) => {
  const nomina = useSelector((state) => state.nomina.existeNomina);
  const postNomina = useSelector((state) => state.nomina.postNomina);
  const dispatch = useDispatch();
  const [formModel, setFormModel] = useState(null);
  useEffect(() => {
    if (!fromBread) {
      dispatch(clearExisteNomina());
      dispatch(clearPostNomina());
    }
    return () => setFormModel(null);
  }, []);

  const isLoading = !formModel || nomina.loading || nomina.data == null;

  const handleSubmit = (data) => {
    const model = {
      ...data,
      fechaNacimiento: convertDateTimeToNet(data.fechaNacimiento),
    };
    dispatch(saveDatosParticulares(model));
    nextForm("DatosDomiciliarios");
  };

  useEffect(() => {
    if (nomina.data != null) {
      setFormModel(() => ({
        ...initialValues,
        nombres:
          postNomina.datosParticulares?.nombres ||
          nomina.data.nombres?.valor ||
          initialValues.nombres,
        apellido:
          postNomina.datosParticulares?.apellido ||
          nomina.data.apellido?.valor ||
          initialValues.apellido,
        tipoDocumento:
          postNomina.datosParticulares?.tipoDocumento ||
          nomina.data.tipoDocumento?.valor ||
          initialValues.tipoDocumento,
        nroDocumento:
          postNomina.datosParticulares?.nroDocumento ||
          nomina.data.nroDocumento?.valor ||
          initialValues.nroDocumento,
        nacionalidad:
          postNomina.datosParticulares?.pais ||
          nomina.data.pais?.valor ||
          initialValues.nacionalidad,
        fechaNacimiento:
          postNomina.datosParticulares?.fechaNacimiento ||
          nomina.data.fechaNacimiento?.valor ||
          initialValues.fechaNacimiento,
        genero:
          postNomina.datosParticulares?.genero ||
          nomina.data.genero?.valor ||
          initialValues.genero,
        estadoCivil:
          postNomina.datosParticulares?.estadoCivil ||
          nomina.data.estadoCivil?.valor ||
          initialValues.estadoCivil,
        telefono:
          postNomina.datosParticulares?.telefono ||
          nomina.data.telefono?.valor ||
          initialValues.telefono,
        mail:
          postNomina.datosParticulares?.mail ||
          nomina.data.mail?.valor ||
          initialValues.mail,
      }));
    }
  }, [nomina.data]);

  return (
    <div>
      <Search
        clearActions={[clearExisteNomina, clearPostNomina]}
        reduxAction={existeNomina}
        callBack={(value) => {
          dispatch(setSearchedCuil(value));
          setFormModel(null);
        }}
        data={nomina}
      />
      {!isLoading ? (
        <DatosParticularesForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
        />
      ) : (
        <Typography
          style={{
            padding: "150px 20px 200px 50px",
            color: "grey",
            textAlign: "center",
          }}
        >
          Ingrese un CUIL, sin coma ni guiones.
        </Typography>
      )}
    </div>
  );
};

export default DatosParticularesContainer;
