import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0.5,
    display: "block",
    wordWrap: "break-word",
  },
}));

const DatosGeneralesEnAfip = ({ data }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem key="razonSocial" className={classes.listItem}>
        <ListItemText
          primary="Razón Social"
          secondary={data?.razonSocial ? data.razonSocial : "Sin Información"}
        />
      </ListItem>
      <ListItem key="cuit" className={classes.listItem}>
        <ListItemText
          primary="CUIT"
          secondary={data?.cuit ? data.cuit : "Sin Información"}
        />
      </ListItem>
      <ListItem key="inicioActividad" className={classes.listItem}>
        <ListItemText
          primary="Inicio de Actividad"
          secondary={
            data?.inicioActividad ? data.inicioActividad : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="codigoActividad" className={classes.listItem}>
        <ListItemText
          primary="Actividad"
          secondary={
            data?.codigoActividad && data?.descripcionActividad
              ? data.codigoActividad + " - " + data.descripcionActividad
              : "Sin Información"
          }
        />
      </ListItem>
    </List>
  );
};

export default DatosGeneralesEnAfip;
