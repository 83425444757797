import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@mui/icons-material/Print";
import React, { useRef, useState } from "react";
import PdfView from "./PdfView";
import PrintWrapper from "./PrintWrapper";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20,
    overflow: "auto",
  },
  titleContainer: {
    backgroundColor: `${theme.palette.primary.main}`,
    borderBottom: "1px solid green",
    boxSizing: "border-box",
    textAlign: "center",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "default",
  },
  titleStyle: {
    flexGrow: 1,
  },
  contenedorPrincipal: {
    width: "100%",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
    textAlign: "center",
  },
  buttoncontainer: {
    display: "flex",
    padding: "5px 70px 5px 70px",
  },
  pagecontainer: {
    overflow: "hidden",
    padding: 10,
  },
  pagetext: {
    marginTop: 10,
    fontWeight: 500,
  },
}));

const PdfDialog = ({
  title,
  printTitle = "",
  open,
  handleClose,
  controllers,
  file,
  sendNotificacion,
  paginated = true,
  printable,
  initialScale = 1.5,
  closeButton = false,
  acceptButton = true,
}) => {
  const classes = useStyles();
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(initialScale);
  const [loading, setLoading] = useState(true);
  const printWrapperRef = useRef();

  function handleZoomIn() {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }

  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }

  function handleNext() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function handlePrevious() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle disableTypography className={classes.titleContainer}>
        <span className={classes.titleStyle}>
          <Typography variant="h6" >
            {title}
          </Typography>

        </span>
        {closeButton &&
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} size="small">

              <CloseIcon />

            </IconButton>
          </Tooltip>
        }
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        {!loading && controllers && (
          <div className={classes.footer}>
            <div className={classes.buttoncontainer}>
              <Button onClick={handleZoomIn} disabled={pageScale >= 3}>
                Zoom +
              </Button>
              <Button onClick={handleZoomOut} disabled={pageScale <= 0.3}>
                Zoom -
              </Button>
            </div>
            {paginated &&
              <>
                <Typography className={classes.pagetext}>
                  Página {pageNumber} de {totalPages}
                </Typography>
                <div className={classes.buttoncontainer}>
                  <Button onClick={handlePrevious} disabled={pageNumber === 1}>
                    ‹ Anterior
                  </Button>
                  <Button onClick={handleNext} disabled={pageNumber === totalPages}>
                    Siguiente ›
                  </Button>
                </div>
              </>
            }

            {printable &&

              <div className={classes.buttoncontainer}>
                <Tooltip title="Descargar/Imprimir">
                  <IconButton onClick={printWrapperRef.current?.print} >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              </div>

            }
          </div>
        )}
        {/* El componente imprime el componente como se ve en front. Necesitamos un pdf igual oculto sin zoom, paginado, etc*/}
        <PrintWrapper reactToPrintProps={{ documentTitle: printTitle }} hidden ref={printWrapperRef}>
          <PdfView
            pageScale={initialScale}
            file={file}
            paginated={false}
          />
        </PrintWrapper>

        <PdfView
          setTotalPagesProps={setTotalPages}
          pageNumberProps={pageNumber}
          pageScale={pageScale}
          file={file}
          setLoadingFalse={() => setLoading(false)}
          sendNotificacion={sendNotificacion}
          paginated={paginated}
        />
      </DialogContent>
      {acceptButton &&
        <DialogActions style={{ margin: 10 }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Aceptar
          </Button>
        </DialogActions>
      }

    </Dialog>
  );
};

export default PdfDialog;
