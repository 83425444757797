import React from "react";

import { List, ListItem, ListItemIcon, Tooltip } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { NewReleases } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  nested: {
    borderRadius: "7px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  list: {
    color: "white",
    borderRadius: "7px",
    marginTop: 5,
    marginLeft: (props) => (props.open ? 15 : 0),
    marginRight: (props) => (props.open ? 8 : 0),
  },
  listIcon: {
    color: (props) =>
      props.selected ? theme.palette.primary.contrastText : "black",
    marginLeft: 8,
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
    },
  },
  rightIcon: {
    color: (props) =>
      props.selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    marginLeft: 30,
  },
  text: {
    color: theme.palette.primary.menuItem,
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    margin: 0,
    marginLeft: -10,
  },
}));

const ListSubItemComponent = ({
  path,
  selected,
  title,
  title2,
  icon: Icon,
  open,
  id,
  isNew,
  notification,
  tooltipText,
}) => {
  const classes = useStyles({ selected, open });
  return (
    <List component="div" disablePadding className={classes.list}>
      {!open ? (
        <Tooltip title={tooltipText} placement="bottom" arrow>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            selected={selected}
            to={path}
            id={id}
          >
            <ListItemIcon className={classes.listIcon}>{Icon}</ListItemIcon>
            {open && (
              <div style={{ lineHeight: 1.3 }}>
                <p className={classes.text}>{title}</p>
                <p className={classes.text}>{title2}</p>
              </div>
            )}
            {isNew && (
              <ListItemIcon className={classes.rightIcon}>
                <NewReleases />
              </ListItemIcon>
            )}
            {notification > 0 && (
              <ListItemIcon className={classes.rightIcon}>
                <Badge badgeContent={notification} color="primary" />
              </ListItemIcon>
            )}
          </ListItem>
        </Tooltip>
      ) : (
        <ListItem
          button
          className={classes.nested}
          component={Link}
          selected={selected}
          to={path}
          id={id}
        >
          <ListItemIcon className={classes.listIcon}>{Icon}</ListItemIcon>
          {open && (
            <div style={{ lineHeight: 1.3 }}>
              <p className={classes.text}>{title}</p>
              <p className={classes.text}>{title2}</p>
            </div>
          )}
          {isNew && (
            <ListItemIcon className={classes.rightIcon}>
              <NewReleases />
            </ListItemIcon>
          )}
          {notification > 0 && (
            <ListItemIcon className={classes.rightIcon}>
              <Badge badgeContent={notification} color="primary" />
            </ListItemIcon>
          )}
        </ListItem>
      )}
    </List>
  );
};

export default ListSubItemComponent;
