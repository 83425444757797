import {
  Button,
  TablePagination,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { formatForNetDates } from "../../../../../../util/datetime";
import FormikDatePicker from "../../../../../commons/formik/FormikDatePicker";
import {
  localization,
  tableStyles,
} from "../../../../../commons/table/tableConfig";
import TitleContainer from "../../../../../commons/table/utilsTable/TitleWithFetchData";

const useStyles = makeStyles({
  filterItem: {
    margin: "10px 0px 10px 25px",
    "& p": {
      fontSize: 12,
      marginTop: 5,
      color: "red",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  buttonContainer: {
    margin: "20px 0px 0px 30px",
  },
  exportButtonContainer: {
    paddingTop: 10,
  },
  informesMedicos: {
    margin: "0 auto",
  },
});

const validationSchema = Yup.object().shape({
  fechaDesde: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaDesde",
      message: "La fecha desde debe ser menor o igual a la fecha hasta",
      test: function (value) {
        if (value <= this.parent.fechaHasta) {
          return true;
        }
        return false;
      },
    }),

  fechaHasta: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor o igual a la fecha desde",
      test: function (value) {
        if (value >= this.parent.fechaDesde) {
          return true;
        }
        return false;
      },
    }),
});

const DocumentacionMedicaTable = ({
  data,
  fetchData,
  loading,
  initialValues,
  getInformesMedicos,
  fechaDesde,
  fechaHasta,
  handleFilter,
}) => {
  const classes = useStyles();

  const esUsuarioMedico = useSelector(
    (state) => state.auth.data?.operacionRol?.esMedico
  );

  const [filterModel, setFilterModel] = useState(initialValues);
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      title: "Secuencia del Siniestro",
      field: "nroSecuencia",
      width: "10%",
    },
    {
      title: "Fecha Novedad",
      field: "fechaNovedad",
      width: "10%",
      render: (rowData) => formatForNetDates(`${rowData.fechaNovedad}`),
    },
    {
      title: "Prestador",
      field: "prestador",
      width: "10%",
    },
    {
      title: "Documentación Registrada",
      field: "documentacion",
      width: "10%",
    },
  ];

  useEffect(() => {
    if (data.length) {
      const sortedData = [...data].sort(
        (a, b) => a.nroSecuencia - b.nroSecuencia
      );
      setTableData(sortedData);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (fechaDesde && fechaHasta) {
      setFilterModel({
        ...initialValues,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
      });
    } else {
      setFilterModel(initialValues);
    }
  }, [fechaDesde, fechaHasta]);

  return (
    <MaterialTable
      columns={columns}
      data={tableData.map((e) => {
        e.nroSecuencia = e.nroSecuencia.toString();
        return e;
      })}
      isLoading={loading}
      style={{ ...tableStyles.container }}
      title={
        <TitleContainer
          title="Documentación"
          noRefresh={false}
          fetchData={fetchData}
        />
      }
      options={{
        actionsColumnIndex: -1,
        headerStyle: tableStyles.headerStyle,
        rowStyle: tableStyles.rowStyle,
        pageSize: 10,
        search: false,
        filtering: true,
        sorting: true,
        exportButton: {
          csv: true,
          pdf: false,
        },
        exportCsv: (columns, dataExport) => {
          const test = [
            [
              "Secuencia del Siniestro",
              "Fecha novedad",
              "Prestador",
              "Documentación",
            ],
            ...dataExport.map((column) => [
              column.nroSecuencia ? `${column.nroSecuencia}` : "",
              column.fechaNovedad ? `${column.fechaNovedad}` : "",
              column.prestador ? `${column.prestador}` : "",
              column.documentacion ? `${column.documentacion}` : "",
            ]),
          ];

          const encoding = "data:text/csv;charset=utf-8,%EF%BB%BF";
          let csvContent = "Documentacion medica,\n";
          csvContent += `${test.map((e) => e.join(",")).join("\n")}`;
          const link = document.createElement("a");
          link.setAttribute("href", encoding + escape(csvContent));
          link.setAttribute("download", `Siniestros.csv`);

          document.body.appendChild(link); // Required for FF

          link.click();
        },
      }}
      components={{
        Toolbar: (props) => (
          <>
            <div>
              <MTableToolbar {...props} />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Formik
                initialValues={filterModel}
                validationSchema={validationSchema}
                onSubmit={handleFilter}
              >
                {({ values }) => (
                  <Form className={classes.filterContainer}>
                    <div className={classes.filterItem}>
                      <FormikDatePicker
                        name="fechaDesde"
                        label="Fecha desde"
                        ampm={false}
                        format="dd/MM/yyyy"
                        variant="inline"
                        FormHelperTextProps={{
                          style: {
                            display: "none",
                          },
                        }}
                      />
                      {values.fechaDesde && values.fechaHasta ? (
                        <Typography>
                          <ErrorMessage
                            name="fechaDesde"
                            className={classes.error}
                          />
                        </Typography>
                      ) : null}
                    </div>
                    <div className={classes.filterItem}>
                      <FormikDatePicker
                        name="fechaHasta"
                        label="Fecha hasta"
                        ampm={false}
                        format="dd/MM/yyyy"
                        variant="inline"
                        FormHelperTextProps={{
                          style: {
                            display: "none",
                          },
                        }}
                      />
                      {values.fechaHasta && values.fechaHasta ? (
                        <Typography>
                          <ErrorMessage
                            name="fechaHasta"
                            className={classes.error}
                          />
                        </Typography>
                      ) : null}
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                          values.fechaDesde == null || values.fechaHasta == null
                        }
                      >
                        Buscar
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <Button
                color="secondary"
                variant="contained"
                className={classes.informesMedicos}
                disabled={!esUsuarioMedico}
                onClick={getInformesMedicos}
              >
                Informes Médicos
              </Button>
            </div>
          </>
        ),
        Pagination: (props) => {
          const { onChangePage, onChangeRowsPerPage, ...tablePaginationProps } =
            props;
          return (
            <TablePagination
              {...tablePaginationProps}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                  <props.ActionsComponent
                    {...actionsComponentProps}
                    onChangePage={onPageChange}
                  />
                );
              }}
            />
          );
        },
      }}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "Sin documentación",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};

export default DocumentacionMedicaTable;
