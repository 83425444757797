import {
  CLEAR_INFORMES_MEDICOS,
  DESCARGAR_REPORTE_PREDENUNCIA,
  GET_DATOS_SINIESTRO,
  GET_DIAGNOSTICOS,
  GET_DOCUMENTACION_MEDICA_WITH_FILTER,
  GET_GESTIONES_PARTICULARES,
  GET_INFORMES_MEDICOS,
  GET_SECUENCIAS,
  POST_BUSCAR_SINIESTROS,
  POST_CARGAR_DENUNCIA_FIRMADA,
  POST_CARGAR_FI,
  POST_CARGAR_RECIBO_SUELDO,
} from "../actions/siniestralidad";

const INITIAL_STATE = {
  siniestros: {
    loading: false,
    error: false,
    data: [],
  },
  datosSiniestro: {
    loading: false,
    error: false,
    data: {},
  },
  particulares: {
    loading: false,
    error: false,
    data: [],
  },
  secuencias: {
    loading: false,
    error: false,
    data: [],
  },
  diagnosticos: {
    loading: false,
    error: false,
    data: [],
  },
  documentacionMedica: {
    loading: false,
    error: false,
    data: [],
  },
  informesMedicos: {
    loading: false,
    error: false,
    data: [],
  },
  reciboSueldo: {
    loading: false,
    error: false,
    data: {},
  },
  cargarFi: {
    loading: false,
    error: false,
    data: {},
  },
  reportePredenuncia: {
    loading: false,
    error: false,
    data: {},
  },
  denunciaFirmada: {
    loading: false,
    error: false,
    data: {},
  },
};

const siniestralidad = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${POST_BUSCAR_SINIESTROS}_PENDING`:
      return {
        ...state,
        siniestros: { ...state.siniestros, error: false, loading: true },
      };
    case `${POST_BUSCAR_SINIESTROS}_REJECTED`:
      return {
        ...state,
        siniestros: { ...state.siniestros, error: true, loading: false },
      };
    case `${POST_BUSCAR_SINIESTROS}_FULFILLED`:
      return {
        ...state,
        siniestros: {
          ...state.siniestros,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_DATOS_SINIESTRO}_PENDING`:
      return {
        ...state,
        datosSiniestro: {
          ...state.datosSiniestro,
          error: false,
          loading: true,
        },
      };
    case `${GET_DATOS_SINIESTRO}_REJECTED`:
      return {
        ...state,
        datosSiniestro: {
          ...state.datosSiniestro,
          error: true,
          loading: false,
        },
      };
    case `${GET_DATOS_SINIESTRO}_FULFILLED`:
      return {
        ...state,
        datosSiniestro: {
          ...state.datosSiniestro,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_GESTIONES_PARTICULARES}_PENDING`:
      return {
        ...state,
        particulares: { ...state.particulares, error: false, loading: true },
      };
    case `${GET_GESTIONES_PARTICULARES}_REJECTED`:
      return {
        ...state,
        particulares: { ...state.particulares, error: true, loading: false },
      };
    case `${GET_GESTIONES_PARTICULARES}_FULFILLED`:
      return {
        ...state,
        particulares: {
          ...state.particulares,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_SECUENCIAS}_PENDING`:
      return {
        ...state,
        secuencias: { ...state.secuencias, error: false, loading: true },
      };
    case `${GET_SECUENCIAS}_REJECTED`:
      return {
        ...state,
        secuencias: { ...state.secuencias, error: true, loading: false },
      };
    case `${GET_SECUENCIAS}_FULFILLED`:
      return {
        ...state,
        secuencias: {
          ...state.secuencias,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_DIAGNOSTICOS}_PENDING`:
      return {
        ...state,
        diagnosticos: { ...state.diagnosticos, error: false, loading: true },
      };
    case `${GET_DIAGNOSTICOS}_REJECTED`:
      return {
        ...state,
        diagnosticos: { ...state.diagnosticos, error: true, loading: false },
      };
    case `${GET_DIAGNOSTICOS}_FULFILLED`:
      return {
        ...state,
        diagnosticos: {
          ...state.diagnosticos,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };

    case `${GET_DOCUMENTACION_MEDICA_WITH_FILTER}_PENDING`:
      return {
        ...state,
        documentacionMedica: {
          ...state.documentacionMedica,
          error: false,
          loading: true,
        },
      };
    case `${GET_DOCUMENTACION_MEDICA_WITH_FILTER}_REJECTED`:
      return {
        ...state,
        documentacionMedica: {
          ...state.documentacionMedica,
          error: true,
          loading: false,
        },
      };
    case `${GET_DOCUMENTACION_MEDICA_WITH_FILTER}_FULFILLED`:
      return {
        ...state,
        documentacionMedica: {
          ...state.documentacionMedica,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_INFORMES_MEDICOS}_PENDING`:
      return {
        ...state,
        informesMedicos: {
          ...state.informesMedicos,
          error: false,
          loading: true,
        },
      };
    case `${GET_INFORMES_MEDICOS}_REJECTED`:
      return {
        ...state,
        informesMedicos: {
          ...state.informesMedicos,
          error: true,
          loading: false,
        },
      };
    case `${GET_INFORMES_MEDICOS}_FULFILLED`:
      return {
        ...state,
        informesMedicos: {
          ...state.informesMedicos,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case CLEAR_INFORMES_MEDICOS:
      return {
        ...state,
        informesMedicos: {
          ...INITIAL_STATE.informesMedicos
        }
      }
    case `${DESCARGAR_REPORTE_PREDENUNCIA}_PENDING`:
      return {
        ...state,
        reportePredenuncia: {
          ...state.reportePredenuncia,
          error: false,
          loading: true,
        },
      };
    case `${DESCARGAR_REPORTE_PREDENUNCIA}_REJECTED`:
      return {
        ...state,
        reportePredenuncia: {
          ...state.reportePredenuncia,
          error: true,
          loading: false,
        },
      };
    case `${DESCARGAR_REPORTE_PREDENUNCIA}_FULFILLED`:
      return {
        ...state,
        reportePredenuncia: {
          ...state.reportePredenuncia,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_CARGAR_FI}_PENDING`:
      return {
        ...state,
        cargarFi: {
          ...state.cargarFi,
          error: false,
          loading: true,
        },
      };
    case `${POST_CARGAR_FI}_REJECTED`:
      return {
        ...state,
        cargarFi: {
          ...state.cargarFi,
          error: true,
          loading: false,
        },
      };
    case `${POST_CARGAR_FI}_FULFILLED`:
      return {
        ...state,
        cargarFi: {
          ...state.cargarFi,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_CARGAR_RECIBO_SUELDO}_PENDING`:
      return {
        ...state,
        reciboSueldo: {
          ...state.reciboSueldo,
          error: false,
          loading: true,
        },
      };
    case `${POST_CARGAR_RECIBO_SUELDO}_REJECTED`:
      return {
        ...state,
        reciboSueldo: {
          ...state.reciboSueldo,
          error: true,
          loading: false,
        },
      };
    case `${POST_CARGAR_RECIBO_SUELDO}_FULFILLED`:
      return {
        ...state,
        reciboSueldo: {
          ...state.reciboSueldo,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_CARGAR_DENUNCIA_FIRMADA}_PENDING`:
      return {
        ...state,
        denunciaFirmada: {
          ...state.denunciaFirmada,
          error: false,
          loading: true,
        },
      };
    case `${POST_CARGAR_DENUNCIA_FIRMADA}_REJECTED`:
      return {
        ...state,
        denunciaFirmada: {
          ...state.denunciaFirmada,
          error: true,
          loading: false,
        },
      };
    case `${POST_CARGAR_DENUNCIA_FIRMADA}_FULFILLED`:
      return {
        ...state,
        denunciaFirmada: {
          ...state.denunciaFirmada,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default siniestralidad;
