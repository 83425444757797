import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import FormikTextField from "../commons/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.tertiary.main})`,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "fit-content",
    margin: "auto",
    padding: 20,
    borderRadius: 10,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1vh",
    width: "100%",
  },
  inputLabel: {
    width: "100%",
    // que "Usuario" y "Contraseña" aparezcan en negrita
    "& label": {
      fontSize: 17.3,
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: `${theme.palette.tertiary.main}`,
  },
}));

const validationSchema = yup.object({
  username: yup
    .string()
    .max(100, "El campo no puede superar los 100 caracteres")
    .required("Campo obligatorio"),
  password: yup
    .string()
    .min(6, "La contraseña debe tener un mínimo de 6 caracteres")
    .required("Campo obligatorio"),
});

const initialValues = {
  username: "",
  password: "",
};

const Login = ({ handleSubmit }) => {
  const classes = useStyles();

  const loading = useSelector((state) => state.auth.loading);
  const activeToken = useSelector((state) => state.auth.activeToken);
  const logo = useSelector((state) => state.logo.getLogo.data);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loadingToken = activeToken ? activeToken.loading : false;

  return (
    <div className={classes.container}>
      <Paper className={classes.content} elevation={15}>
        <img src={logo} alt="logo" className={classes.logo} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className={classes.form}>
            <div>
              <FormikTextField
                className={classes.inputLabel}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Usuario"
                name="username"
                autoFocus
                type="text"
              />
            </div>
            <div>
              <FormikTextField
                className={classes.inputLabel}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              fullWidth
              type="submit"
              disabled={loading || loadingToken}
            >
              {loading || loadingToken ? (
                <CircularProgress size={24} thickness={4} color="primary" />
              ) : (
                "Ingresar"
              )}
            </Button>
          </Form>
        </Formik>
      </Paper>
    </div>
  );
};

export default Login;
