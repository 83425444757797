import { Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTiposDestinatario,
  saveBeneficiario,
} from "../../../redux/actions/reintegros";
import DataLabel from "../../commons/labels/DataLabel";
import BeneficiariosForm from "./BeneficiariosForm";

const initialValues = {
  nombre_trabajador: "",
  nombre_empleadores: "",
  trabajador: false,
  empleadores: false,
  idSeleccionado: 0,
  cbu: "",
};

const useStyles = makeStyles((theme) => ({
  titleFormContainer: {},
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    padding: 20,
  },
  formContainer: {
    width: "70%",
  },
}));

const BeneficiarioContainer = ({ fromBread, nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const siniestro = useSelector((state) => state.reintegros.localSiniestro);
  const tipoDestinatario = useSelector(
    (state) => state.reintegros.tipoDestinatario
  );
  const postReintegro = useSelector((state) => state.reintegros.postReintegro);

  const [formModel, setFormModel] = useState(null);

  useEffect(() => {
    dispatch(getTiposDestinatario());
  }, []);

  const isLoading = !formModel || tipoDestinatario.loading;

  const handleSubmit = (data) => {
    const model = {
      ...data,
    };
    dispatch(saveBeneficiario(model));
    nextForm("DatosComprobante");
  };
  useEffect(() => {
    setFormModel(() => ({
      ...initialValues,
      empleadores: postReintegro.beneficiario
        ? postReintegro.beneficiario.empleadores
        : initialValues.empleadores,
      trabajador: postReintegro.beneficiario
        ? postReintegro.beneficiario.trabajador
        : initialValues.trabajador,
      cbu: postReintegro.beneficiario
        ? postReintegro.beneficiario.cbu
        : initialValues.cbu,
      nombre_trabajador: postReintegro.beneficiario
        ? postReintegro.beneficiario.nombre_trabajador
        : initialValues.nombre_trabajador,
      nombre_empleadores: postReintegro.beneficiario
        ? postReintegro.beneficiario.nombre_empleadores
        : initialValues.nombre_empleadores,
      idSeleccionado: postReintegro.beneficiario
        ? postReintegro.beneficiario.idSeleccionado
        : initialValues.idSeleccionado,
    }));
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.titleFormContainer}>
        <Typography variant="h6">Selección del Beneficiario</Typography>
      </div>
      <DataLabel
        firstText={"Siniestro: " + siniestro.numeroSiniestroART}
        secondText={"DNI: " + siniestro.numeroDocumento}
        thirdText={siniestro.apellidoNombres}
      />
      <div className={classes.formContainer}>
        <BeneficiariosForm
          handleSubmit={handleSubmit}
          model={formModel}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default BeneficiarioContainer;
