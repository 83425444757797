import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, lighten } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  circularProgress: {
    marginBottom: "20px",
    color: lighten(theme.palette.tertiary.main, 0.3),
  },
  title: {
    color: lighten(theme.palette.tertiary.main, 0.3),
  },
}));

export default function LoadingComponent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.circularProgress} />
      <Typography variant="h6" className={classes.title}>
        Cargando...
      </Typography>
    </div>
  );
}
