import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  makeStyles,
  DialogActions,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  containerBody: {
    width: 550,
    height: 200,
    display: "flex",
  },
  containerButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));

const ErrorDialog = ({ open, handleClose, errors }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>El archivo contiene los siguientes errores</DialogTitle>
      <DialogContent className={classes.containerBody}>
        <ul>
          {errors.map((err) => (
            <li>
              <Typography key={err.key}>{err.error}</Typography>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions className={classes.containerButton}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
