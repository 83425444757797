import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNominasLotes } from "../../../../../redux/actions/examenesPeriodicos";
import TrabajadoresTable from "./TrabajadoresTable";

const TrabajadoresContainer = ({ handleGoBack, selectedLote }) => {
  const dispatch = useDispatch();

  const nominas = useSelector((state) => state.examenesPeriodicos.nominasLotes);
  const idDependencia = useSelector(
    (state) => state.examenesPeriodicos.searchParams?.idEstablecimiento
  );

  useEffect(() => {
    dispatch(getNominasLotes(selectedLote, idDependencia));
  }, []);

  return (
    <TrabajadoresTable
      data={nominas.data}
      loading={nominas.loading}
      handleGoBack={handleGoBack}
    />
  );
};

export default TrabajadoresContainer;
