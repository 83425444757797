import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconoStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    color: theme.palette.secondary.light,
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DinerariasHelperLabel = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={4} className={classes.gridItem}>
        <Typography variant="h6" className={classes.iconoStyle}>
          ILT
        </Typography>
        <Typography style={{ color: "grey" }}>
          Incapacidad Laboral Temporal
        </Typography>
      </Grid>

      <Grid item xs={4} className={classes.gridItem}>
        <Typography variant="h6" className={classes.iconoStyle}>
          ILP
        </Typography>
        <Typography style={{ color: "grey" }}>
          Incapacidad Laboral Permanente
        </Typography>
      </Grid>

      <Grid item xs={4} className={classes.gridItem}>
        <Typography variant="h6" className={classes.iconoStyle}>
          CGI
        </Typography>
        <Typography style={{ color: "grey" }}>
          Compensación por Gran Invalidez
        </Typography>
      </Grid>
    </>
  );
};

export default DinerariasHelperLabel;
