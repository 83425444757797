import {
  GET_NOTIFICACIONES_Y_CITAS,
  GET_NOTIFICACION,
  CLEAR_NOTIFICACION,
  MARCAR_NOTIFICACION,
  GET_CITACIONES,
} from "../actions/notificacion";

const INITIAL_STATE = {
  notificaciones: {
    loading: false,
    error: false,
    data: {},
  },
  juntaMedica: {
    loading: false,
    error: false,
    data: {},
  },
  notificacion: {
    loading: false,
    error: false,
    data: {},
  },
  marcarNotificacion: {
    loading: false,
    error: false,
    data: {},
  },
};

const notificacion = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_NOTIFICACIONES_Y_CITAS}_PENDING`:
      return {
        ...state,
        notificaciones: {
          ...state.notificaciones,
          error: false,
          loading: true,
        },
      };
    case `${GET_NOTIFICACIONES_Y_CITAS}_REJECTED`:
      return {
        ...state,
        notificaciones: {
          ...state.notificaciones,
          error: true,
          loading: false,
        },
      };
    case `${GET_NOTIFICACIONES_Y_CITAS}_FULFILLED`:
      return {
        ...state,
        notificaciones: {
          ...state.notificaciones,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_NOTIFICACION}_PENDING`:
      return {
        ...state,
        notificacion: {
          ...state.notificacion,
          error: false,
          loading: true,
        },
      };
    case `${GET_NOTIFICACION}_REJECTED`:
      return {
        ...state,
        notificacion: {
          ...state.notificacion,
          error: true,
          loading: false,
        },
      };
    case `${GET_NOTIFICACION}_FULFILLED`:
      return {
        ...state,
        notificacion: {
          ...state.notificacion,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${MARCAR_NOTIFICACION}_PENDING`:
      return {
        ...state,
        marcarNotificacion: {
          ...state.marcarNotificacion,
          error: false,
          loading: true,
        },
      };
    case `${MARCAR_NOTIFICACION}_REJECTED`:
      return {
        ...state,
        marcarNotificacion: {
          ...state.marcarNotificacion,
          error: true,
          loading: false,
        },
      };
    case `${MARCAR_NOTIFICACION}_FULFILLED`:
      return {
        ...state,
        marcarNotificacion: {
          ...state.marcarNotificacion,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case CLEAR_NOTIFICACION:
      return {
        ...state,
        notificacion: {
          ...INITIAL_STATE.notificacion,
        },
      };
    case `${GET_CITACIONES}_PENDING`:
      return {
        ...state,
        juntaMedica: {
          ...state.juntaMedica,
          error: false,
          loading: true,
        },
      };
    case `${GET_CITACIONES}_REJECTED`:
      return {
        ...state,
        juntaMedica: {
          ...state.juntaMedica,
          error: true,
          loading: false,
        },
      };
    case `${GET_CITACIONES}_FULFILLED`:
      return {
        ...state,
        juntaMedica: {
          ...state.juntaMedica,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default notificacion;
