import { Button, DialogActions, makeStyles } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikTextField from "../../../commons/formik/FormikTextField";
const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.up(600)]: {
      width: "30vw",
      height: "30vh",
      alignItems: "center",
    },
  },
  form: {
    display: "flex",
    width: "100%",
    flexFlow: "column wrap",
    rowGap: "5vh",
  },
  email: {
    display: "flex",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Debe escribir un formato de email válido")
    .required("Campo obligatorio"),
});

export default function NominaEmailDialog({ handleSubmit, open, handleClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enviar certificado por Email</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values.email);
            handleClose();
          }}
        >
          <Form className={classes.form}>
            <div className={classes.email}>
              <FormikTextField
                fullWidth={true}
                id="email"
                name="email"
                type="text"
                label="Email"
              />
            </div>
            <DialogActions className={classes.buttons}>
              <Button type="button" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
