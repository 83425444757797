import { Grid, MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalle,
  getCodigoPostal,
  getLocalidades,
  getProvincias,
} from "../../../../../redux/actions/direccion";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import { useStyles } from "../styles/formStyle";
import {
  direccionTrabajadorValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const listaPais = [{ id: "ARGENTINA", nombre: "Argentina" }];

const DireccionTrabajadorForm = ({ handleSubmit, fields, model }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const calles = useSelector((state) => state.direccion.calle);
  const provincias = useSelector((state) => state.direccion.provincias);
  const localidades = useSelector((state) => state.direccion.localidades);
  const codigoPostalRedux = useSelector(
    (state) => state.direccion.codigoPostal
  );

  const [provinciasList, setProvinciasList] = useState([]);
  const [provinciaIsCapital, setProvinciaIsCapital] = useState(false);
  const [localidadesList, setLocalidadesList] = useState([
    { id: model.localidad, nombre: model.localidad },
  ]);
  const [callesList, setCallesList] = useState([]);

  useEffect(() => {
    dispatch(getProvincias());
    if (model.provincia) {
      dispatch(getLocalidades(model.provincia));
    }
  }, []);

  useEffect(() => {
    if (provincias.data) {
      setProvinciasList(provincias.data);
    }
  }, [provincias.data]);

  useEffect(() => {
    if (calles.data) {
      setCallesList(calles.data);
    }
  }, [calles.data]);

  useEffect(() => {
    if (localidades.data) {
      setLocalidadesList(localidades.data);
    }
  }, [localidades.data]);

  const handlePaisChange = () => {
    dispatch(getProvincias());
  };

  const handleProvinciaChange = (value) => {
    dispatch(getLocalidades(value));
  };

  const handleLocalidadesChange = (value) => {
    dispatch(getCalle(value, value));
  };

  const handleGetCodigoPostal = (
    provincia,
    localidad,
    numero,
    calle,
    setFieldValue
  ) => {
    dispatch(getCodigoPostal(provincia, localidad, numero, calle)).then(
      (res) => {
        if (res.value.data.codigoPostal) {
          setFieldValue("codigoPostal", res.value.data.codigoPostal);
        }
      }
    );
  };

  return (
    <Formik
      initialValues={model}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={direccionTrabajadorValidation}
      validate={(values) => validateRequiredFields(values, fields)}
    >
      {({ values, setFieldValue }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={9} className={classes.gridContainer}>
            <Grid item xs={4}>
              <FormikSelect
                name="pais"
                label={`País ${fields.pais.obligatorio ? "*" : ""}`}
                variant="standard"
                fullWidth
                onChange={handlePaisChange}
              >
                {listaPais.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={4}>
              <FormikAutocomplete
                fullWidth
                name="provincia"
                label={`Provincia ${fields.provincia.obligatorio ? "*" : ""}`}
                variant="standard"
                callBack={(value) => {
                  if (value) {
                    handleProvinciaChange(value);
                    if (value.id === "Capital Federal") {
                      handleLocalidadesChange(value.id);
                      setProvinciaIsCapital(true);
                    } else {
                      setProvinciaIsCapital(false);
                    }
                  } else {
                    setFieldValue("localidad", "");
                    setFieldValue("calle", "");
                    setFieldValue("nro", "");
                    setFieldValue("codigoPostal", "");
                  }
                }}
                options={fields.provincia.opciones}
                getOptions={() => dispatch(getProvincias())}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikAutocomplete
                fullWidth
                name="localidad"
                label={`Localidad ${fields.localidad.obligatorio ? "*" : ""}`}
                variant="standard"
                disabled={!values.provincia}
                options={localidadesList}
                callBack={(value) => {
                  if (!value) {
                    setFieldValue("calle", "");
                    setFieldValue("nro", "");
                    setFieldValue("codigoPostal", "");
                  }
                }}
                getOptions={() => dispatch(getLocalidades(values.provincia))}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              {!provinciaIsCapital ? (
                <FormikTextField
                  fullWidth
                  id="calle"
                  name="calle"
                  type="text"
                  label={`Calle ${fields.calle.obligatorio ? "*" : ""}`}
                  disabled={!values.localidad}
                  onBlur={(value) => {
                    if (!value.target.value) {
                      setFieldValue("nro", "");
                      setFieldValue("codigoPostal", "");
                    }
                  }}
                />
              ) : (
                <FormikAutocomplete
                  fullWidth
                  name="calle"
                  label={`Calle ${fields.calle.obligatorio ? "*" : ""}`}
                  options={callesList}
                  disabled={!values.localidad}
                  callBack={(value) => {
                    if (!value) {
                      setFieldValue("nro", "");
                      setFieldValue("codigoPostal", "");
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="nro"
                name="nro"
                type="text"
                label={`Número ${fields.nro.obligatorio ? "*" : ""}`}
                disabled={!values.calle}
                onBlur={(value) => {
                  if (value.target.value) {
                    handleGetCodigoPostal(
                      values.provincia,
                      values.localidad,
                      values.nro,
                      values.calle,
                      setFieldValue
                    );
                  } else {
                    setFieldValue("codigoPostal", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormikTextField
                    id="piso"
                    name="piso"
                    type="text"
                    label={`Piso ${fields.piso.obligatorio ? "*" : ""}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField
                    id="departamento"
                    name="departamento"
                    type="text"
                    label={`Departamento ${
                      fields.departamento.obligatorio ? "*" : ""
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="codigoPostal"
                name="codigoPostal"
                type="text"
                label={`Código postal ${
                  fields.codigoPostal.obligatorio ? "*" : ""
                }`}
                disabled={!values.nro}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="entreCalle"
                name="entreCalle"
                type="text"
                label={`Entre calle ${
                  fields.entreCalle.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="entreCalle1"
                name="entreCalle1"
                type="text"
                label={`Y calle ${fields.entreCalle1.obligatorio ? "*" : ""}`}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Siguiente" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DireccionTrabajadorForm;
