import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../redux/actions/auth";
import Login from "./Login";

const LoginContainer = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.loading && !auth.data.token) {
      dispatch(logout());
    }
  }, []);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  return <Login handleSubmit={handleSubmit} />;
};

export default LoginContainer;
