import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContratos } from "../../../redux/actions/contrato";
import CircularLoading from "../../commons/loading/CircularLoading";
import ContratoCard from "./ContratoCard";
import ModalSendEmail from "./ModalSendEmail";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const ContratoContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const contratos = useSelector((state) => state.contrato.contratos);
  const contratoPDF = useSelector((state) => state.contrato.contratoPDF);

  const [open, setOpen] = useState(false);
  const [codigoContrato, setCodigoContrato] = useState("");

  useEffect(() => {
    if (!contratos.data.length) dispatch(getAllContratos());
  }, []);

  const handleOpenModal = (codigo) => {
    setCodigoContrato(codigo);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      {!contratos.loading && !contratoPDF.loading ? (
        contratos.data.map((contrato) => (
          <ContratoCard
            key={contrato.codigoUnificado}
            nombreContrato={contrato.nombre}
            openModal={handleOpenModal}
            codigoContrato={contrato.codigoUnificado}
          />
        ))
      ) : (
        <CircularLoading />
      )}
      <ModalSendEmail
        open={open}
        handleClose={handleCloseModal}
        codigoContrato={codigoContrato}
      />
    </div>
  );
};

export default ContratoContainer;
