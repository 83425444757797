import { Button, makeStyles } from "@material-ui/core";
import { FormGroup } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatCUIT } from "../../../util/cuilcuit";
import FormikCheckbox from "../../commons/formik/FormikCheckbox";
import FormikTextField from "../../commons/formik/FormikTextField";
import CircularLoading from "../../commons/loading/CircularLoading";
import { beneficiarioFormValidation } from "../validation/formValidation";

const useStyles = makeStyles({
  textFieldContainer: {
    marginTop: 35,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  checkboxContainer: {
    marginTop: 35,
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 35,
  },
  formGroup: { padding: "0px 0px 0px 20px" },
});

const BeneficiariosForm = ({ handleSubmit, model, isLoading }) => {
  const classes = useStyles();

  const siniestro = useSelector((state) => state.reintegros.localSiniestro);
  const tipoDestinatario = useSelector(
    (state) => state.reintegros.tipoDestinatario
  );
  const postReintegro = useSelector((state) => state.reintegros.postReintegro);

  const [seleccionado, setSeleccionado] = useState(null);

  useEffect(() => {
    if (postReintegro.beneficiario) {
      setSeleccionado(postReintegro.beneficiario.idSeleccionado);
    }
  }, []);

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={model}
          onSubmit={handleSubmit}
          validationSchema={beneficiarioFormValidation}
        >
          {({ setFieldValue }) => (
            <Form>
              {tipoDestinatario.data.map((e) => {
                var label;
                var value;
                var id;
                if (e.id === 1) {
                  label = `${siniestro.numeroDocumento} / ${siniestro.apellidoNombres}`;
                  id = e.nombre.toLowerCase();
                  value = "1";
                }
                if (e.id === 2) {
                  label = `${formatCUIT(siniestro.cuit)} / ${
                    siniestro.razonSocial
                  }`;
                  id = e.nombre.toLowerCase();
                  value = "2";
                }
                return (
                  <div key={id} className={classes.checkboxContainer}>
                    <FormGroup className={classes.formGroup}>
                      <FormikCheckbox
                        label={label}
                        checked={seleccionado === e.id}
                        onChange={() => {
                          if (seleccionado !== e.id) {
                            setFieldValue(
                              `nombre_${e.nombre.toLowerCase()}`,
                              label
                            );
                            setFieldValue(e.nombre.toLowerCase(), true); // Actualiza el valor de nombre
                            setSeleccionado(e.id);
                            setFieldValue("idSeleccionado", e.id);
                            tipoDestinatario.data.forEach((item) => {
                              if (item.id !== e.id) {
                                setFieldValue(
                                  `nombre_${item.nombre.toLowerCase()}`,
                                  ""
                                );
                                setFieldValue(item.nombre.toLowerCase(), false);
                              }
                            });
                          }
                        }}
                        name={id}
                        value={value}
                      />
                    </FormGroup>
                  </div>
                );
              })}
              <div className={classes.textFieldContainer}>
                <FormikTextField
                  variant="outlined"
                  name="cbu"
                  label="CBU - CVU"
                  fullWidth
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disabled={!seleccionado}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Siguiente
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default BeneficiariosForm;
