import axios from "axios";

export const GET_LIQUIDACIONES_DINERARIAS = "GET_LIQUIDACIONES_DINERARIAS";

export const getLiquidacionesDinerarias = (id) => (dispatch) => {
  return dispatch({
    type: GET_LIQUIDACIONES_DINERARIAS,
    payload: axios
      .get(`dinerarias/liquidaciones?idSiniestroCabecera=${id}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
