import { Button, Grid, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getEstablecimientos } from "../../../redux/actions/examenesPeriodicos";
import FormikAutocomplete from "../../commons/formik/FormikAutocomplete";
import FormikDatePicker from "../../commons/formik/FormikDatePicker";
import InputLabel from "../commons/InputLabel";

const initialValues = {
  idEstablecimiento: "",
  fechaDesde: null,
  fechaHasta: null,
};

const validationSchema = Yup.object().shape({
  idEstablecimiento: Yup.string().required("Campo Obligatorio").nullable(),
  fechaDesde: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .required("Campo obligatorio")
    .test({
      name: "fechaDesde",
      message: "La fecha desde debe ser menor o igual a la fecha hasta",
      test: function (value) {
        if (value <= this.parent.fechaHasta) {
          return true;
        }
        return false;
      },
    }),

  fechaHasta: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .required("Campo obligatorio")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor o igual a la fecha desde",
      test: function (value) {
        if (value >= this.parent.fechaDesde) {
          return true;
        }
        return false;
      },
    }),
});

const useStyles = makeStyles({
  form: {
    display: "flex",
    padding: 20,
  },
  grid: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  establecimiento: {
    marginTop: 30,
  },
  datesContainer: {
    display: "flex",
    marginTop: 30,
  },
  dateInput: {
    marginRight: 20,
  },
  buttonContainer: {
    marginTop: 80,
  },
});

const SearchLoteForm = ({ handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const previousSearch = useSelector(
    (state) => state.examenesPeriodicos.searchParams
  );
  const establecimientos = useSelector(
    (state) => state.examenesPeriodicos.establecimientos.data
  );
  const isLoadingDatosEstablecimiento = useSelector(
    (state) => state.examenesPeriodicos.datosEstablecimiento.loading
  );
  const isLoadingLotes = useSelector(
    (state) => state.examenesPeriodicos.lotesExamenesPeriodicos.loading
  );

  // Cuando el usuario apreta el botón de "Buscar", se disparan
  // dos búsquedas. Por especificación del analista, es necesario
  //  deshabilitar el botón a menos que estas se completen (o fallen).
  const isBuscando = isLoadingDatosEstablecimiento || isLoadingLotes;

  return (
    <Formik
      initialValues={previousSearch ?? initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className={classes.form}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={6}>
              <InputLabel title="Establecimiento" />
              <FormikAutocomplete
                className={classes.establecimiento}
                name="idEstablecimiento"
                label="Establecimiento *"
                variant="standard"
                options={establecimientos}
                getOptions={() => dispatch(getEstablecimientos())}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel title="Período" />
              <div className={classes.datesContainer}>
                <FormikDatePicker
                  className={classes.dateInput}
                  name="fechaDesde"
                  label="Desde *"
                />
                <FormikDatePicker
                  className={classes.dateInput}
                  name="fechaHasta"
                  label="Hasta *"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    values.fechaDesde == null ||
                    values.fechaHasta == null ||
                    isBuscando
                  }
                >
                  {!isBuscando ? "Buscar" : "Cargando..."}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SearchLoteForm;
