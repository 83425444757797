import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSiniestrosExamenesPeriodicos } from "../../../../redux/actions/examenesPeriodicos";
import { convertDateTimeToNet } from "../../../../util/datetime";
import SiniestrosTable from "./SiniestrosTable";

const SiniestrosContainer = () => {
  const dispatch = useDispatch();

  const searchParams = useSelector(
    (state) => state.examenesPeriodicos.searchParams
  );
  const siniestros = useSelector(
    (state) => state.examenesPeriodicos.siniestros
  );

  useEffect(() => {
    dispatch(
      getSiniestrosExamenesPeriodicos(
        searchParams.idEstablecimiento,
        convertDateTimeToNet(searchParams.fechaDesde),
        convertDateTimeToNet(searchParams.fechaHasta)
      )
    );
  }, []);

  return (
    <SiniestrosTable data={siniestros.data} loading={siniestros.loading} />
  );
};

export default SiniestrosContainer;
