import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add,
  Assignment,
  Create,
  ExpandLess,
  ExpandMore,
  Gavel,
  Group,
  Publish,
} from "@material-ui/icons";
import { TableRows, UploadFile } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FeedIcon from "@mui/icons-material/Feed";
import LinkIcon from "@mui/icons-material/Link";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFuncionalidadesParaPerfilUsuario } from "../perfilUsuario/PerfilUsuarioEnum";
import ListItemComponent from "./MenuItem";
import ListSubItemComponent from "./MenuSubItem";

const useStyles = makeStyles((theme) => ({
  itemComponent: {
    backgroundColor: theme.palette.primary.dark,
  },
  navBar: {
    [theme.breakpoints.down(600)]: {
      width: 10,
    },
  },
  expandArrow: {
    color: theme.palette.primary.menuItem,
  },
  nodeParentText: {
    color: theme.palette.primary.menuItem,
    fontFamily: "Roboto, sans-serif",
    margin: "0 0 0 0",
    marginLeft: 8,
    fontWeight: "bold",
    flex: 1,
  },
  nodeParent: {
    borderRadius: "7px",
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      marginLeft: 8,
    },
  },
  icon: {
    color: theme.palette.primary.menuItem,
  },
}));

const MenuAppBar = () => {
  const classes = useStyles();
  const location = useLocation();

  const drawerOpen = useSelector((state) => state.userPreferences.drawer);
  const menusVisibles = useSelector((state) =>
    getFuncionalidadesParaPerfilUsuario(state.auth.data?.menuVisible)
  );

  const [openSiniestros, setOpenSiniestros] = useState(false);
  const [openPersonal, setOpenPersonal] = useState(false);
  const [openPrevencion, setOpenPrevencion] = useState(false);

  const showMenuDesplegablePersonal =
    menusVisibles.ABMNominas ||
    menusVisibles.NominaMasiva ||
    menusVisibles.CertificadoDeCobertura ||
    menusVisibles.CoberturaViajeAlExterior;

  const showMenuDesplegablePrevencion =
    menusVisibles.Establecimientos ||
    menusVisibles.RGRL ||
    menusVisibles.ImportarNTE ||
    menusVisibles.Resolucion81;

  const showMenuDesplegableSiniestralidad =
    menusVisibles.Siniestralidad || menusVisibles.NuevaDenuncia;

  // Por pedido del analista, cuando el trabajador ingresa
  // al portal, deberia ver el menú "Personal" (el unico menu
  // al que tiene acceso) desplegado por defecto. Como, a partir
  // de PDCA-401, se perdió el campo "tipoUsuario", ahora es necesario
  // hardcodearlo con los menús que tiene disponible.
  const esTrabajador =
    !menusVisibles.Empresa &&
    !menusVisibles.CuentaCorriente &&
    !menusVisibles.Reintegros &&
    !showMenuDesplegableSiniestralidad &&
    !showMenuDesplegablePrevencion &&
    !menusVisibles.ABMNominas &&
    !menusVisibles.NominaMasiva &&
    menusVisibles.CertificadoDeCobertura &&
    menusVisibles.CoberturaViajeAlExterior;

  useEffect(() => {
    if (esTrabajador) {
      setOpenPersonal(true);
    }
  }, []);

  const handleClickSiniestros = () => {
    setOpenSiniestros(!openSiniestros);
  };

  const handleClickPersonal = () => {
    setOpenPersonal(!openPersonal);
  };

  const handleClickPrevencion = () => {
    setOpenPrevencion(!openPrevencion);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.navBar}
    >
      <div>
        {menusVisibles.Empresa && (
          <ListItemComponent
            path="/"
            title="Empresa"
            id="empresa"
            className={classes.itemComponent}
            icon={<AccountBalanceIcon className={classes.icon} />}
            tooltip={!drawerOpen}
            tooltipText="Empresa"
          />
        )}

        {menusVisibles.CuentaCorriente && (
          <ListItemComponent
            path="/cuentaCorriente"
            title="Cuenta Corriente"
            id="cuentaCorriente"
            className={classes.itemComponent}
            icon={<AccountBalanceWalletIcon className={classes.icon} />}
            tooltip={!drawerOpen}
            tooltipText="Cuenta Corriente"
          />
        )}

        {showMenuDesplegablePersonal && (
          <>
            {!drawerOpen ? (
              <Tooltip title="Personal" placement="bottom" arrow>
                <ListItem
                  button
                  onClick={handleClickPersonal}
                  className={classes.nodeParent}
                  id="personal"
                >
                  <ListItemIcon>
                    <Group className={classes.icon} />
                  </ListItemIcon>
                  <p className={classes.nodeParentText}>Personal</p>
                  {openPersonal ? (
                    <ExpandLess className={classes.expandArrow} />
                  ) : (
                    <ExpandMore className={classes.expandArrow} />
                  )}
                </ListItem>
              </Tooltip>
            ) : (
              <ListItem
                button
                onClick={handleClickPersonal}
                className={classes.nodeParent}
                id="personal"
              >
                <ListItemIcon>
                  <Group className={classes.icon} />
                </ListItemIcon>
                <p className={classes.nodeParentText}>Personal</p>
                {openPersonal ? (
                  <ExpandLess className={classes.expandArrow} />
                ) : (
                  <ExpandMore className={classes.expandArrow} />
                )}
              </ListItem>
            )}
          </>
        )}
        <Collapse in={openPersonal} timeout="auto" unmountOnExit>
          {menusVisibles.ABMNominas && (
            <ListSubItemComponent
              path="/personal/ABMnominas"
              selected={window.location.pathname.includes(
                "/personal/ABMnominas"
              )}
              title="ABM Nóminas"
              id="personal_ABMnominas"
              icon={<Create className={classes.icon} />}
              open={drawerOpen}
              tooltipText="ABM Nóminas"
            />
          )}

          {menusVisibles.NominaMasiva && (
            <ListSubItemComponent
              path="/personal/nominaMasiva"
              selected={window.location.pathname.includes(
                "/personal/nominaMasiva"
              )}
              title="Nómina Masiva"
              id="personal_nominaMasiva"
              icon={<UploadFile className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Nómina Masiva"
            />
          )}

          {menusVisibles.CertificadoDeCobertura && (
            <ListSubItemComponent
              path="/personal/certificadoDeCobertura"
              selected={window.location.pathname.includes(
                "/personal/certificadoDeCobertura"
              )}
              title="Certificado de Cobertura"
              id="personal_certificadoDeCobertura"
              icon={<Gavel className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Certificado de Cobertura"
            />
          )}

          {menusVisibles.CoberturaViajeAlExterior && (
            <ListSubItemComponent
              path="/personal/coberturaViajeAlExterior"
              selected={window.location.pathname.includes(
                "/personal/coberturaViajeAlExterior"
              )}
              title="Cobertura Viaje al Exterior"
              id="personal_coberturaViajeAlExterior"
              icon={<UploadFile className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Cobertura Viaje al Exterior"
            />
          )}
        </Collapse>

        {showMenuDesplegablePrevencion && (
          <>
            {!drawerOpen ? (
              <Tooltip title="Prevención" placement="bottom" arrow>
                <ListItem
                  button
                  onClick={handleClickPrevencion}
                  className={classes.nodeParent}
                  id="prevencion"
                >
                  <ListItemIcon>
                    <ChromeReaderModeIcon className={classes.icon} />
                  </ListItemIcon>
                  <p className={classes.nodeParentText}>Prevención</p>
                  {openPrevencion ? (
                    <ExpandLess className={classes.expandArrow} />
                  ) : (
                    <ExpandMore className={classes.expandArrow} />
                  )}
                </ListItem>
              </Tooltip>
            ) : (
              <ListItem
                button
                onClick={handleClickPrevencion}
                className={classes.nodeParent}
                id="prevencion"
              >
                <ListItemIcon>
                  <ChromeReaderModeIcon className={classes.icon} />
                </ListItemIcon>
                <p className={classes.nodeParentText}>Prevención</p>
                {openPrevencion ? (
                  <ExpandLess className={classes.expandArrow} />
                ) : (
                  <ExpandMore className={classes.expandArrow} />
                )}
              </ListItem>
            )}
          </>
        )}
        <Collapse in={openPrevencion} timeout="auto" unmountOnExit>
          {menusVisibles.Establecimientos && (
            <ListSubItemComponent
              path="/prevencion/establecimientos"
              selected={window.location.pathname.includes(
                "/prevencion/establecimientos"
              )}
              title="Establecimientos"
              id="prevencion_establecimientos"
              icon={<ApartmentIcon className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Establecimientos"
            />
          )}

          {menusVisibles.RGRL && (
            <ListSubItemComponent
              path="/prevencion/RGRL"
              selected={window.location.pathname.includes("/prevencion/RGRL")}
              title="RGRL"
              id="prevencion_establecimientos"
              icon={<FeedIcon className={classes.icon} />}
              open={drawerOpen}
              tooltipText="RGRL"
            />
          )}

          {menusVisibles.ImportarNTE && (
            <ListSubItemComponent
              path="/prevencion/NTE"
              selected={window.location.pathname.includes("/prevencion/NTE")}
              title="Importar NTE"
              id="importar_NTE"
              icon={<Publish className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Importar NTE"
            />
          )}

          {menusVisibles.Resolucion81 && (
            <ListSubItemComponent
              path="/prevencion/resolucion-81"
              selected={window.location.pathname.includes(
                "/prevencion/resolucion-81"
              )}
              title="Resolución 81"
              id="prevencion_resolucion81"
              icon={<LinkIcon className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Resolución 81"
            />
          )}
        </Collapse>

        {showMenuDesplegableSiniestralidad && (
          <>
            {!drawerOpen ? (
              <Tooltip title="Siniestros" placement="bottom" arrow>
                <ListItem
                  button
                  onClick={handleClickSiniestros}
                  className={classes.nodeParent}
                  id="accidents"
                >
                  <ListItemIcon>
                    <Assignment className={classes.icon} />
                  </ListItemIcon>
                  <p className={classes.nodeParentText}>Siniestros</p>
                  {openSiniestros ? (
                    <ExpandLess className={classes.expandArrow} />
                  ) : (
                    <ExpandMore className={classes.expandArrow} />
                  )}
                </ListItem>
              </Tooltip>
            ) : (
              <ListItem
                button
                onClick={handleClickSiniestros}
                className={classes.nodeParent}
                id="accidents"
              >
                <ListItemIcon>
                  <Assignment className={classes.icon} />
                </ListItemIcon>
                <p className={classes.nodeParentText}>Siniestros</p>
                {openSiniestros ? (
                  <ExpandLess className={classes.expandArrow} />
                ) : (
                  <ExpandMore className={classes.expandArrow} />
                )}
              </ListItem>
            )}
          </>
        )}
        <Collapse in={openSiniestros} timeout="auto" unmountOnExit>
          {menusVisibles.Siniestralidad && (
            <ListSubItemComponent
              path="/siniestros/siniestralidad"
              selected={location.pathname === "/siniestros/siniestralidad"}
              title="Siniestralidad"
              id="siniestros_siniestralidad"
              icon={<TableRows className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Siniestralidad"
            />
          )}

          {menusVisibles.NuevaDenuncia && (
            <ListSubItemComponent
              path="/siniestros/nuevaDenuncia"
              selected={location.pathname === "/siniestros/nuevaDenuncia"}
              title="Nueva Denuncia"
              id="siniestros_nuevaDenuncia"
              icon={<Add className={classes.icon} />}
              open={drawerOpen}
              tooltipText="Nueva Denuncia"
            />
          )}
        </Collapse>

        {menusVisibles.ExamenesMedicosPeriodicos && (
          <ListItemComponent
            path="/examenesPeriodicos"
            title="Exámenes Periódicos"
            id="examenesPeriodicos"
            className={classes.itemComponent}
            icon={<ContactPageIcon className={classes.icon} />}
            tooltip={!drawerOpen}
            tooltipText="Examenés Periódicos"
          />
        )}

        {menusVisibles.Reintegros && (
          <ListItemComponent
            path="/reintegros"
            title="Reintegros"
            id="reintegros"
            className={classes.itemComponent}
            icon={<CurrencyExchangeIcon className={classes.icon} />}
            tooltip={!drawerOpen}
            tooltipText="Reintegros"
          />
        )}
      </div>
    </List>
  );
};

export default MenuAppBar;
