import { TablePagination, useTheme } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import { format, parseISO } from "date-fns";
import MaterialTable from "material-table";
import { localization, tableStyles } from "../../commons/table/tableConfig";

const EstablecimientosTable = ({
  data,
  loading,
  handleVerEstadoEstablecimiento,
}) => {
  const theme = useTheme();

  const columns = [
    {
      title: "Nombre",
      field: "nombre",
      minWidth: 250,
      align: "center",
      customFilterAndSearch: (term, rowData) => {
        if (rowData.nombre.toLowerCase().includes(term.toLowerCase())) {
          return true;
        }
        return false;
      },
    },
    {
      title: "Domicilio",
      field: "domicilio",
      minWidth: 300,
      align: "center",
      customFilterAndSearch: (term, rowData) => {
        if (rowData.domicilio.toLowerCase().includes(term.toLowerCase())) {
          return true;
        }
        return false;
      },
    },
    {
      title: "Cód. Establecimiento",
      field: "codigoEstablecimiento",
      width: "10%",
      align: "center",
      type: "numeric",
      sorting: true,
      filtering: false,
    },
    {
      title: "Cód. Intercambio",
      field: "codigoIntercambio",
      width: "10%",
      align: "center",
      type: "numeric",
      sorting: true,
      filtering: false,
    },
    {
      title: "Establecimiento Empresa (MU)",
      field: "matrizUnica",
      width: "10%",
      align: "center",
      type: "numeric",
      sorting: true,
      filtering: false,
    },
    {
      title: "Es de Terceros",
      field: "esDeTerceros",
      width: "10%",
      align: "center",
      sorting: true,
      filtering: false,
    },
    {
      title: "Fecha Baja",
      field: "fechaBaja",
      width: "10%",
      align: "center",
      sorting: true,
      filtering: false,
      emptyValue: "",
      render: (rowData) => format(parseISO(rowData.fechaBaja), "dd/MM/yyyy"),
    },
  ];

  return (
    <>
      <div>
        <MaterialTable
          columns={columns}
          data={data}
          isLoading={loading}
          style={{ ...tableStyles.container }}
          title=""
          actions={[
            (rowData) => ({
              icon: () => (
                <Visibility
                  style={{ color: `${theme.palette.primary.main}` }}
                />
              ),
              tooltip: "Ver detalle",
              onClick: (event, rowData) => {
                handleVerEstadoEstablecimiento(rowData);
              },
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            headerStyle: tableStyles.headerStyle,
            rowStyle: tableStyles.rowStyle,
            pageSize: 10,
            search: false,
            filtering: true,
            exportButton: false,
          }}
          components={{
            Toolbar: () => {
              return null;
            },
            Pagination: (props) => {
              const {
                onChangePage,
                onChangeRowsPerPage,
                ...tablePaginationProps
              } = props;
              return (
                <TablePagination
                  {...tablePaginationProps}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  ActionsComponent={(subprops) => {
                    const { onPageChange, ...actionsComponentProps } = subprops;
                    return (
                      <props.ActionsComponent
                        {...actionsComponentProps}
                        onChangePage={onPageChange}
                      />
                    );
                  }}
                />
              );
            },
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: "No hay datos que mostrar",
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default EstablecimientosTable;
