import {
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { downloadEstadoNomina } from "../../../../../redux/actions/nte";
import { downloadFile } from "../../../../../util/files";
import RequeridoLabel from "../../../../commons/labels/RequeridoLabel";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import FeatureLabel from "../../commons/labels/FeatureLabel";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 5,
    display: "flex",
    wordWrap: "break-word",
  },
  label: {
    marginRight: 15,
  },
}));

const DatosDelEstablecimientoCard = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const searchedNte = useSelector((state) => state.nte.searchedNte);

  const [isDownloadingNominas, setIsDownloadingNominas] = useState(false);

  const handleDescargarNominas = () => {
    setIsDownloadingNominas(true);
    dispatch(downloadEstadoNomina(searchedNte))
      .then((res) => {
        downloadFile(res?.value?.data, "Estado de nómina.csv").then((_) => {
          setIsDownloadingNominas(false);
        });
      })
      .catch((err) => {
        toast.error(err);
        setIsDownloadingNominas(false);
      });
  };

  return (
    <>
      <List className={classes.list}>
        <ListItem key="nombre" className={classes.listItem}>
          <Typography className={classes.label}>
            Nombre Establecimiento
          </Typography>
          <ListItemText
            secondary={
              data?.nombre ? data.nombre.split("-")[0] : "Sin Información"
            }
          />
        </ListItem>
        <ListItem key="domicilio" className={classes.listItem}>
          <Typography className={classes.label}>Domicilio</Typography>
          <ListItemText
            secondary={data?.domicilio ? data?.domicilio : "Sin Información"}
          />
        </ListItem>
        <ListItem key="matrizUnica" className={classes.listItem}>
          <Typography className={classes.label}>Matriz Única</Typography>
          <ListItemText
            secondary={data?.matrizUnica ? data.matrizUnica : "Sin Información"}
          />
        </ListItem>
        <ListItem key="codigoIntercambio" className={classes.listItem}>
          <Typography className={classes.label}>Cód. Intercambio</Typography>
          <ListItemText
            secondary={
              data?.codigoIntercambio
                ? data?.codigoIntercambio
                : "Sin Información"
            }
          />
        </ListItem>
        <ListItem key="actividad" className={classes.listItem}>
          <Typography className={classes.label}>
            Actividad Establecimiento
          </Typography>
          <ListItemText
            secondary={data?.actividad ? data.actividad : "Sin Información"}
          />
        </ListItem>
      </List>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 100,
        }}
      >
        {!isDownloadingNominas ? (
          <FeatureLabel
            labelText="DESCARGAR ÚLTIMO ESTADO DE NÓMINAS"
            icon={<FileDownloadIcon />}
            onClick={handleDescargarNominas}
            tooltipText="Descargar"
          />
        ) : (
          <>
            <RequeridoLabel text="Descargando nóminas..." />
            <CircularLoading
              style={{
                color: `${theme.palette.tertiary.main}`,
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DatosDelEstablecimientoCard;
