import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  getDatosDeContacto,
  postDatosDeContacto,
} from "../../../../../../redux/actions/informacion";
import FormikTextField from "../../../../../commons/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  formikInput: {
    display: "flex",
    flexDirection: "column",
  },
  errorDiv: {
    height: "20px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 10,
    "& button": {
      color: `${theme.palette.primary.main}`,
      marginTop: 5,
      borderRadius: "10px",
      "& :hover": {
        borderBottom: `${theme.palette.primary.main} 1px solid`,
      },
    },
  },
}));

const validationSchema = Yup.object().shape({
  celular: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9()+-\s]*$/,
      "Solo se permiten números y los caracteres ( ) + - y espacio"
    )
    .max(30, "El número de celular debe tener como máximo 30 caracteres"),
  telefono: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9()+-\s]*$/,
      "Solo se permiten números y los caracteres ( ) + - y espacio"
    )
    .max(20, "El número de teléfono debe tener como máximo 20 caracteres"),
  eMail: Yup.string()
    .required("Este campo es obligatorio")
    .email("Mail inválido")
    .max(250, "El e-mail debe tener como máximo 250 caracteres"),
});

const datosContactoinitialValues = {
  telefono: "Sin Información",
  celular: "Sin Información",
  eMail: "Sin Información",
};

const DatosDeContacto = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSumbit = (data) => {
    dispatch(postDatosDeContacto(data)).then(() => {
      dispatch(getDatosDeContacto());
      toast.success("Los datos han sido actualizados correctamente");
    });
  };

  return (
    <Formik
      initialValues={{
        telefono: data.telefono || datosContactoinitialValues.telefono,
        celular: data.celular || datosContactoinitialValues.celular,
        eMail: data.mail || datosContactoinitialValues.eMail,
      }}
      onSubmit={handleSumbit}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 35,
              paddingLeft: 35,
              paddingTop: 15,
            }}
          >
            <div className={classes.formikInput}>
              <Typography>Teléfono</Typography>
              <FormikTextField
                id="telefono"
                name="telefono"
                type="text"
                FormHelperTextProps={{
                  style: {
                    display: "none",
                  },
                }}
              />
              <div className={classes.errorDiv}>
                {touched.telefono && errors.telefono && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors.telefono}
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.formikInput}>
              <Typography>Celular</Typography>
              <FormikTextField
                id="celular"
                name="celular"
                type="text"
                FormHelperTextProps={{
                  style: {
                    display: "none",
                  },
                }}
              />

              <div className={classes.errorDiv}>
                {touched.celular && errors.celular && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors.celular}
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.formikInput}>
              <Typography>Mail</Typography>
              <FormikTextField
                id="eMail"
                name="eMail"
                type="text"
                FormHelperTextProps={{
                  style: {
                    display: "none",
                  },
                }}
              />
              <div className={classes.errorDiv}>
                {touched.eMail && errors.eMail && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors.eMail}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button type="submit">Guardar</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DatosDeContacto;
