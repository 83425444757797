import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  gridContainer: {
    paddingRight: 35,
    paddingLeft: 35,
    paddingTop: 15,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 25,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    marginTop: 50,
  },
});
