import { Paper, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveImportacionIdRar } from "../../../redux/actions/nte";
import { formatFormTitle } from "../../../util/breadcrumb";
import Breadcrumb from "../../commons/breadcrumb/Breadcrumb";
import HeaderForm from "../../commons/labels/HeaderForm";
import EstablecimientoContainer from "./establecimientos/EstablecimientoContainer";
import ImportarNominasContainer from "./importarNominas/ImportarNominasContainer";
import PrePresentacionContainer from "./prePresentacion/PrePresentacionContainer";
import ResponsablesContainer from "./responsables/ResponsablesContainer";

const useStyles = makeStyles({
  breadcrumbContainer: { display: "flex", margin: 15 },
});

const ImportarNTEContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const nominaAdjuntadaExitosamente = useSelector(
    (state) => state.nte.importacionIdRar != null
  );

  const [form, setForm] = useState("Establecimiento");
  const [fromBread, setFromBread] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);

  // por especificacion del analista, el primer formulario "establecimiento" y
  // el tercer formulario "importar nominas", llevan como titulo algo
  // distinto a "establecimiento" o "importar nominas".
  const tituloEstablecimientoForm =
    "Presentación de Nóminas de Trabajadores Expuestos a Riesgos - NTE";
  const tituloImportarNominasForm =
    "Importar Nóminas de Trabajadores Expuestos - NTE";

  const handleSetForm = (form) => {
    if (!breadcrumbFields.includes(form)) {
      setBreadcrumbFields([...breadcrumbFields, form]);
    }
    setForm(form);
  };

  useEffect(() => {
    return () => {
      dispatch(saveImportacionIdRar(null));
    };
  }, []);

  return (
    <Paper
      style={{ overflow: "hidden", height: "fit-content", minHeight: 650 }}
    >
      <HeaderForm
        title={
          form === "Establecimiento"
            ? tituloEstablecimientoForm
            : form === "ImportarNóminas"
            ? tituloImportarNominasForm
            : formatFormTitle(form)
        }
      />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
          disableNavigationBackwards={
            nominaAdjuntadaExitosamente ? true : false
          }
        />
      </div>
      <div style={{ margin: 20 }}>
        {
          {
            Establecimiento: (
              <EstablecimientoContainer
                nextForm={handleSetForm}
                fromBread={fromBread}
              />
            ),
            Responsables: <ResponsablesContainer nextForm={handleSetForm} />,
            ImportarNóminas: (
              <ImportarNominasContainer nextForm={handleSetForm} />
            ),
            PrePresentación: <PrePresentacionContainer />,
          }[form]
        }
      </div>
    </Paper>
  );
};

export default ImportarNTEContainer;
