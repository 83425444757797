import React from "react";
import { Formik, Form } from "formik";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import { useStyles } from "../styles/formStyle";
import FormikActions from "../../../../commons/formik/FormikActions";
import {
  datosParticularesValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const DatosParticularesForm = ({ handleSubmit, model, fields }) => {
  const classes = useStyles();

  const disabled = !fields;

  return (
    <>
      <Formik
        initialValues={model}
        onSubmit={handleSubmit}
        validationSchema={() => datosParticularesValidation()}
        validate={(values) => validateRequiredFields(values, fields)}
      >
        {({ errors, touched, values }) => (
          <Form className={classes.formContainer}>
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="apellido"
                  name="apellido"
                  type="text"
                  label={`Apellido ${fields.apellido.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="nombres"
                  name="nombres"
                  type="text"
                  label={`Nombres ${fields.nombres.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikSelect
                  fullWidth
                  name="tipoDocumento"
                  label={`Tipo documento ${
                    fields.tipoDocumento.obligatorio ? "*" : ""
                  }`}
                  variant="standard"
                  disabled={disabled}
                >
                  {fields?.tipoDocumento.opciones.map(({ id, nombre }) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="nroDocumento"
                  name="nroDocumento"
                  type="text"
                  label={`Nro documento ${
                    fields.nroDocumento.obligatorio ? "*" : ""
                  }`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="nacionalidad"
                  name="nacionalidad"
                  label={`Nacionalidad ${
                    fields.nacionalidad.obligatorio ? "*" : ""
                  }`}
                  type="text"
                  disabled={disabled}
                />
              </Grid>

              <Grid item xs={4} className={classes.inputContainer}>
                <FormikDatePicker
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  type="text"
                  label={`Fecha de nacimiento ${
                    fields.fechaNacimiento.obligatorio ? "*" : ""
                  }`}
                  disableFuture
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikSelect
                  fullWidth
                  name="genero"
                  label={`Género ${fields.genero.obligatorio ? "*" : ""}`}
                  variant="standard"
                  disabled={disabled}
                >
                  {fields?.genero.opciones.map(({ id, nombre }) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikSelect
                  fullWidth
                  name="estadoCivil"
                  label={`Estado civil ${
                    fields.estadoCivil.obligatorio ? "*" : ""
                  }`}
                  variant="standard"
                  disabled={disabled}
                >
                  {fields?.estadoCivil.opciones.map(({ id, nombre }) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="telefono"
                  name="telefono"
                  type="text"
                  label={`Celular ${fields.telefono.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="mail"
                  name="mail"
                  type="text"
                  label={`Mail ${fields.mail.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <FormikActions submit="Siguiente" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DatosParticularesForm;
