import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveLugarOcurrencia } from "../../../../../redux/actions/denuncia";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import LugarOcurrenciaForm from "./LugarOcurrenciaForm";

const initialValues = {
  paisOcurrencia: "",
  provinciaOcurrencia: "",
  localidadOcurrencia: "",
  calleOcurrencia: "",
  kmOcurrencia: false,
  numeroOcurrencia: "",
  pisoOcurrencia: "",
  departamentoOcurrencia: "",
  codigoPostalOcurrencia: "",
  entreCalleOcurrencia: "",
  entreCalle1Ocurrencia: "",
};

const LugarOcurrenciaContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.denuncia.nomina);

  const [formModel, setFormModel] = useState(null);

  useEffect(() => {
    if (nomina.data) {
      setFormModel(() => ({
        ...initialValues,
        kmOcurrencia: nomina.data.km?.valor || initialValues.kmOcurrencia,
        paisOcurrencia: nomina.data.pais?.valor || initialValues.paisOcurrencia,
        provinciaOcurrencia:
          nomina.data.provinciaOcurrencia?.valor ||
          initialValues.provinciaOcurrencia,
        localidadOcurrencia:
          nomina.data.localidadOcurrencia?.valor ||
          initialValues.localidadOcurrencia,
        calleOcurrencia:
          nomina.data.calleOcurrencia?.valor || initialValues.calleOcurrencia,
        numeroOcurrencia:
          nomina.data.numeroOcurrencia?.valor || initialValues.numeroOcurrencia,
        pisoOcurrencia: nomina.data.piso?.valor || initialValues.pisoOcurrencia,
        departamentoOcurrencia:
          nomina.data.departamentoOcurrencia?.valor ||
          initialValues.departamentoOcurrencia,
        codigoPostalOcurrencia:
          nomina.data.codigoPostalOcurrencia?.valor ||
          initialValues.codigoPostalOcurrencia,
        entreCalleOcurrencia:
          nomina.data.entreCalleOcurrencia?.valor ||
          initialValues.entreCalleOcurrencia,
        entreCalle1Ocurrencia:
          nomina.data.entreCalle1Ocurrencia?.valor ||
          initialValues.entreCalle1Ocurrencia,
      }));
    }
  }, [nomina.data]);

  const handleSubmit = (data) => {
    dispatch(saveLugarOcurrencia(data));
    nextForm("AccidenteOEnfermedadForm");
  };

  return (
    <>
      <HeaderForm title="Lugar de Ocurrencia" />
      {formModel != null ? (
        <LugarOcurrenciaForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
        />
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default LugarOcurrenciaContainer;
