import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveDatosDomiciliarios } from "../../../../../redux/actions/nomina";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DatosDomiciliarosForm from "./DatosDomiciliarosForm";

const initialValues = {
  pais: "",
  provincia: "",
  localidad: "",
  calle: "",
  nro: "",
  piso: "",
  departamento: "",
  codigoPostal: "",
  entreCalle: "",
  entreCalle1: "",
};

const DatosDomiciliariosContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.nomina.existeNomina);
  const postNomina = useSelector((state) => state.nomina.postNomina);

  const [formModel, setFormModel] = useState(null);

  const handleSubmit = (data) => {
    dispatch(saveDatosDomiciliarios(data));
    nextForm("DatosNominales");
  };

  useEffect(() => {
    if (nomina.data) {
      setFormModel(() => ({
        ...initialValues,
        pais:
          postNomina.datosDomiciliarios?.pais ||
          nomina.data.pais?.valor ||
          initialValues.pais,
        provincia:
          postNomina.datosDomiciliarios?.provincia ||
          nomina.data.provincia?.valor ||
          initialValues.provincia,
        localidad:
          postNomina.datosDomiciliarios?.localidad ||
          nomina.data.localidad?.valor ||
          initialValues.localidad,
        calle:
          postNomina.datosDomiciliarios?.calle ||
          nomina.data.calle?.valor ||
          initialValues.calle,
        nro:
          postNomina.datosDomiciliarios?.nro ||
          nomina.data.nro?.valor ||
          initialValues.nro,
        piso:
          postNomina.datosDomiciliarios?.piso ||
          nomina.data.piso?.valor ||
          initialValues.piso,
        departamento:
          postNomina.datosDomiciliarios?.departamento ||
          nomina.data.departamento?.valor ||
          initialValues.departamento,
        codigoPostal:
          postNomina.datosDomiciliarios?.codigoPostal ||
          nomina.data.codigoPostal?.valor ||
          initialValues.codigoPostal,
        entreCalle:
          postNomina.datosDomiciliarios?.entreCalle ||
          nomina.data.entreCalle?.valor ||
          initialValues.entreCalle,
        entreCalle1:
          postNomina.datosDomiciliarios?.entreCalle1 ||
          nomina.data.entreCalle1?.valor ||
          initialValues.entreCalle1,
      }));
    }
  }, [nomina.data]);

  return (
    <>
      {formModel != null ? (
        <DatosDomiciliarosForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
        />
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default DatosDomiciliariosContainer;
