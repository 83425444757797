import { CLEAR_ANALISTA_CUENTA, DOWNLOAD_ESTADO_CUENTA_CORRIENTE, GET_ANALISTA_CUENTA, GET_CUENTA_CORRIENTE } from "../actions/cuentaCorriente";

const INITIAL_STATE = {
  cuentaCorriente: {
    loading: false,
    error: false,
    data: [],
  },
  analistaDeCuenta: {
    loading: false,
    error: false,
    data: null,
  },
  downloadCuentaCorriente: {
    loading: false,
    error: false,
    data: null,
  }
};

const cuentaCorriente = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_CUENTA_CORRIENTE}_PENDING`:
      return {
        ...state,
        cuentaCorriente: {
          loading: true,
          error: false,
        },
      };
    case `${GET_CUENTA_CORRIENTE}_REJECTED`:
      return {
        ...state,
        cuentaCorriente: {
          loading: false,
          error: true,
        },
      };
    case `${GET_CUENTA_CORRIENTE}_FULFILLED`:
      return {
        ...state,
        cuentaCorriente: {
          loading: false,
          error: false,
          data: action.payload.data,
        },
      };
    case `${GET_ANALISTA_CUENTA}_PENDING`:
      return {
        ...state,
        analistaDeCuenta: {
          ...state.analistaDeCuenta,
          loading: true,
          error: false,

        },
      };
    case `${GET_ANALISTA_CUENTA}_REJECTED`:
      return {
        ...state,
        analistaDeCuenta: {
          ...state.analistaDeCuenta,
          loading: false,
          error: true,
        },
      };
    case `${GET_ANALISTA_CUENTA}_FULFILLED`:
      return {
        ...state,
        analistaDeCuenta: {
          loading: false,
          error: false,
          data: action.payload.data,
        },
      };
    case `${DOWNLOAD_ESTADO_CUENTA_CORRIENTE}_PENDING`:
      return {
        ...state,
        downloadCuentaCorriente: {
          ...state.downloadCuentaCorriente,
          loading: true,
          error: false,

        },
      };
    case `${DOWNLOAD_ESTADO_CUENTA_CORRIENTE}_REJECTED`:
      return {
        ...state,
        analistaDeCuenta: {
          ...state.downloadCuentaCorriente,
          loading: false,
          error: true,
        },
      };
    case `${DOWNLOAD_ESTADO_CUENTA_CORRIENTE}_FULFILLED`:
      return {
        ...state,
        downloadCuentaCorriente: {
          loading: false,
          error: false,
          data: action.payload.data,
        },
      };
    case CLEAR_ANALISTA_CUENTA:
      return {
        ...state,
        analistaDeCuenta: INITIAL_STATE.analistaDeCuenta
      }
    default:
      return state;
  }
};

export default cuentaCorriente;
