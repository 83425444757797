import {
  Button,
  TablePagination,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import RequeridoLabel from "../../../commons/labels/RequeridoLabel";

const columns = [
  { id: "cuit", label: "CUIT" },
  { id: "cuil", label: "CUIL" },
  { id: "codEstablecimiento", label: "Cód Estab." },
  { id: "apellidoNombre", label: "Apellido y Nombre" },
  { id: "tarea", label: "Tarea" },
  { id: "ciuo", label: "CIUO" },
  { id: "sector", label: "Sector" },
  { id: "codRiesgos", label: "Cód. Riesgos" },
  { id: "fechaExposicion", label: "Fecha Exposición" },
  { id: "mensaje", label: "Mensaje" },
];

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 10,
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    margin: "30px 25px 0px 0px",
    "& button": {
      marginLeft: 10,
    },
  },
}));

const ImportarNominaErrorTable = ({
  handleGoHome,
  handleReintentarImportacionNomina,
  data,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6">Errores de Importación</Typography>
      </div>
      <RequeridoLabel text="La importación arrojó los siguientes errores, subsane los mismos y vuelva a intentar" />
      <TableContainer
        style={{ width: "100%", marginTop: 10 }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell align="center" key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.cuilTrabajador}>
                <TableCell width="10%" align="center">
                  {row.cuitEmpleador}
                </TableCell>
                <TableCell width="10%" align="center">
                  {row.cuilTrabajador}
                </TableCell>
                <TableCell width="5%" align="center">
                  {row.codigoSRTEstablecimiento}
                </TableCell>
                <TableCell width="30%" align="center">
                  {row.apellidoNombre}
                </TableCell>
                <TableCell width="5%" align="center">
                  {row.tarea}
                </TableCell>
                <TableCell width="5%" align="center">
                  {row.ciuo}
                </TableCell>
                <TableCell width="5%" align="center">
                  {row.sector}
                </TableCell>
                <TableCell width="5%" align="center">
                  {row.codigosRiesgo}
                </TableCell>
                <TableCell width="10%" align="center">
                  {row.fechaExposicion}
                </TableCell>
                <TableCell width="20%" align="center">
                  {row.mensaje}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.tableFooter}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
          <div
            style={{ display: "flex", justifyContent: "center", margin: 20 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleReintentarImportacionNomina}
            >
              Reintentar
            </Button>
            <Button
              style={{ color: "red", border: "1px solid red", marginLeft: 10 }}
              variant="outlined"
              onClick={handleGoHome}
            >
              Salir
            </Button>
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default ImportarNominaErrorTable;
