import axios from "axios";
export const IMPORTAR_NOMINA = "IMPORTAR_NOMINA";
export const EXISTE_NOMINA = "EXISTE_NOMINA";
export const POST_DATOS_PARTICULARES = "POST_DATOS_PARTICULARES";
export const CLEAR_EXISTE_NOMINA = "CLEAR_EXISTE_NOMINA";
export const POST_LAST_FORM = "POST_LAST_FORM";
export const POST_DATOS_DOMICILIARIOS = "POST_DATOS_DOMICILIARIOS";
export const POST_DATOS_NOMINALES = "POST_DATOS_NOMINALES";
export const CLEAR_DATOS_POST_NOMINA = "CLEAR_DATOS_POST_NOMINA";
export const CLEAR_NOMINA = "CLEAR_NOMINA";
export const SAVE_CUIL = "SAVE_CUIL";

export const importarNomina = (body) => (dispatch) => {
  return dispatch({
    type: IMPORTAR_NOMINA,
    payload: axios
      .post("nomina/importar", body)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const existeNomina = (cuil) => (dispatch) => {
  return dispatch({
    type: EXISTE_NOMINA,
    payload: axios
      .get(`nomina/existeNomina?cuil=${cuil}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const sendAllNominaForm = (data) => (dispatch) => {
  return dispatch({
    type: POST_LAST_FORM,
    payload: axios
      .post(`nomina/cargarNomina`, data)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const saveDatosDomiciliarios = (data) => (dispatch) => {
  return dispatch({
    type: POST_DATOS_DOMICILIARIOS,
    payload: data,
  });
};
export const saveDatosParticulares = (data) => (dispatch) => {
  return dispatch({
    type: POST_DATOS_PARTICULARES,
    payload: data,
  });
};
export const saveDatosNominales = (data) => (dispatch) => {
  return dispatch({
    type: POST_DATOS_NOMINALES,
    payload: data,
  });
};
export const clearPostNomina = () => (dispatch) => {
  return dispatch({
    type: CLEAR_DATOS_POST_NOMINA,
  });
};
export const clearExisteNomina = () => (dispatch) => {
  return dispatch({
    type: CLEAR_EXISTE_NOMINA,
  });
};
export const setSearchedCuil = (cuil) => (dispatch) => {
  return dispatch({
    type: SAVE_CUIL,
    payload: cuil,
  });
};
export const clearNomina = () => (dispatch) => {
  return dispatch({
    type: CLEAR_NOMINA,
  });
};
