import { combineReducers } from "redux";
import auth from "./auth";
import certificadoCobertura from "./certificadoCobertura";
import contrato from "./contrato";
import cuentaCorriente from "./cuentaCorriente";
import denuncia from "./denuncia";
import dinerarias from "./dinerarias";
import direccion from "./direccion";
import error from "./error";
import establecimiento from "./establecimiento";
import examenesPeriodicos from "./examenesPeriodicos";
import informacion from "./informacion";
import logo from "./logo";
import nomina from "./nomina";
import notificacion from "./notificacion";
import nte from "./nte";
import reintegros from "./reintegros";
import rgrl from "./rgrl";
import siniestralidad from "./siniestralidad";
import userPreferences from "./userPreferences";
const allReducers = combineReducers({
  auth,
  error,
  userPreferences,
  informacion,
  contrato,
  cuentaCorriente,
  denuncia,
  dinerarias,
  direccion,
  nomina,
  certificadoCobertura,
  siniestralidad,
  notificacion,
  reintegros,
  rgrl,
  establecimiento,
  nte,
  logo,
  examenesPeriodicos,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

export default rootReducer;
