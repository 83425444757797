import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Button, DialogActions, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../../../commons/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  containerBody: {
    width: 550,
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formikInput: {
    width: 500,
    marginTop: -50,
  },
  containerButtons: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Debe escribir un formato de email válido")
    .required("Este campo es obligatorio"),
});

export default function NominaEmailDialog({ handleSubmit, open, handleClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enviar certificado por Email</DialogTitle>
      <DialogContent className={classes.containerBody}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values.email);
            handleClose();
          }}
        >
          <Form>
            <div className={classes.formikInput}>
              <FormikTextField
                fullWidth={true}
                id="email"
                name="email"
                type="text"
                label="Email"
              />
            </div>
            <DialogActions className={classes.containerButtons}>
              <Button type="button" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
