import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  buscarSiniestros,
  cargarDenunciaFirmada,
  cargarFI,
  cargarReciboSueldo,
  descargarReportePredenuncia,
} from "../../../redux/actions/siniestralidad";
import { convertDateTimeToNet } from "../../../util/datetime";
import { downloadFile } from "../../../util/files";
import HeaderForm from "../../commons/labels/HeaderForm";
import SiniestroTable from "./SiniestroTable";
import DatosSiniestroContainer from "./datosSiniestro/DatosSiniestroContainer";
import DialogDescargarReporte from "./dialogs/DialogDescargarReporte";
import SiniestroAdjuntarDenunciaForm from "./forms/SiniestroAdjuntarDenunciaForm";
import SiniestroAgregarDocumentacionForm from "./forms/SiniestroAgregarDocumentacionForm";
import SiniestroEnviarReciboSueldoForm from "./forms/SiniestroEnviarReciboSueldoForm";

const initialValues = {
  nroSiniestroART: null,
  idModalidadDenuncia: null,
  idTipoSiniestro: null,
  idDependenciaEmpleador: null,
  nroDocumento: null,
  apellido: "",
  nombres: "",
  fechaDesde: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  fechaHasta: null,
};

const SiniestroContainer = () => {
  const dispatch = useDispatch();

  const siniestros = useSelector((state) => state.siniestralidad.siniestros);

  const [openAgregarDocumentacion, setOpenAgregarDocumentacion] =
    useState(false);
  const [openEnviarReciboSueldo, setOpenEnviarReciboSueldo] = useState(false);
  const [openAdjuntarDenuncia, setOpenAdjuntarDenuncia] = useState(false);
  const [siniestroSelected, setSiniestroSelected] = useState({});
  const [displayAccidents, setDisplayAccidents] = useState("block");
  const [selectedAccident, setSelectedAccident] = useState(null);
  const [filterBody, setFilterBody] = useState(initialValues);
  const [archivosDocumentacion, setArchivosDocumentacion] = useState(null);
  const [descargandoReportePredenuncia, setDescargandoReportePredenuncia] =
    useState(false);

  useEffect(() => {
    if (siniestros.data.length === 0) {
      dispatch(buscarSiniestros(filterBody));
    }
  }, []);

  const handleCloseAllForms = () => {
    setOpenAgregarDocumentacion(false);
    setOpenEnviarReciboSueldo(false);
    setOpenAdjuntarDenuncia(false);
    setSiniestroSelected({});
    setArchivosDocumentacion(null);
  };

  const handleAccidentDetail = (siniestro) => {
    setSelectedAccident(siniestro);
    setDisplayAccidents("none");
  };

  const handleOpenAgregarDocumentacion = (siniestro) => {
    setSiniestroSelected(siniestro);
    setOpenAgregarDocumentacion(true);
  };

  const handleOpenEnviarReciboSueldo = (siniestro) => {
    setSiniestroSelected(siniestro);
    setOpenEnviarReciboSueldo(true);
  };

  const handleOpenAdjuntarDenuncia = (siniestro) => {
    setSiniestroSelected(siniestro);
    setOpenAdjuntarDenuncia(true);
  };

  const handleSubmitAdjuntarDenuncia = (values) => {
    const archivosAsociados = [];
    if (archivosDocumentacion) {
      archivosDocumentacion.forEach((f) => {
        archivosAsociados.push({
          base64: f.value,
          extension: f.filename.split(".").pop(),
          nombre: f.filename.split(".")[0],
        });
      });
    }

    const body = {
      idSiniestroCabecera: values.idSiniestroCabecera,
      numeroSiniestroArt: values.siniestroArt,
      fecha: convertDateTimeToNet(new Date()),
      observaciones: values.observaciones,
      archivosAsociados,
    };

    dispatch(cargarDenunciaFirmada(body)).then(() => {
      toast.success("Denuncia adjuntada correctamente");
      handleCloseAllForms();
    });
  };

  const handleDescargarReportePredenuncia = (numeroPredenuncia) => {
    setDescargandoReportePredenuncia(true);
    dispatch(descargarReportePredenuncia(numeroPredenuncia))
      .then((res) => {
        const reportePredenuncia = res?.value?.data;
        const fechaHoy = moment(new Date()).format("DD/MM/YYYY");

        downloadFile(reportePredenuncia, `ReportePredenuncia-${fechaHoy}.pdf`);
        setDescargandoReportePredenuncia(false);
      })
      .catch(() => setDescargandoReportePredenuncia(false));
  };

  const handleSubmitReciboSueldo = (values) => {
    const archivosAsociados = [];
    if (archivosDocumentacion) {
      archivosDocumentacion.forEach((f) => {
        archivosAsociados.push({
          base64: f.value,
          extension: f.filename.split(".").pop(),
          nombre: f.filename.split(".")[0],
        });
      });
    }
    const body = {
      idSiniestroCabecera: values.idSiniestroCabecera,
      fechaHoraEvento: convertDateTimeToNet(values.fechaHoraEvento),
      observaciones: values.observaciones,
      archivosAsociados,
    };

    dispatch(cargarReciboSueldo(body)).then(() => {
      toast.success("Recibo de sueldo enviado correctamente");
      handleCloseAllForms();
    });
  };

  const handleSubmitAgregarDocumentacion = (values) => {
    const archivosAsociados = [];
    if (archivosDocumentacion) {
      archivosDocumentacion.forEach((f) => {
        archivosAsociados.push({
          base64: f.value,
          extension: f.filename.split(".").pop(),
          nombre: f.filename.split(".")[0],
        });
      });
    }
    const body = {
      idSiniestroCabecera: values.idSiniestroCabecera,
      fechaHoraEvento: convertDateTimeToNet(values.fechaHoraEvento),
      observaciones: values.observaciones,
      archivosAsociados,
    };

    dispatch(cargarFI(body)).then(() => {
      toast.success("Documentación cargada correctamente");
      handleCloseAllForms();
    });
  };

  const handleSubmitFilter = (values) => {
    setFilterBody(values);
    dispatch(buscarSiniestros(values));
  };

  return (
    <div>
      <HeaderForm title="Consulta de Siniestalidad" />
      {displayAccidents === "none" && (
        <DatosSiniestroContainer
          setDisplayAccidents={setDisplayAccidents}
          selectedAccident={selectedAccident}
        />
      )}
      <SiniestroTable
        data={siniestros.data}
        handleSubmitFilter={handleSubmitFilter}
        loading={siniestros.loading}
        handleAccidentDetail={handleAccidentDetail}
        handleAgregarDocumentacion={handleOpenAgregarDocumentacion}
        handleEnviarReciboSueldo={handleOpenEnviarReciboSueldo}
        handleDescargarReportePredenuncia={handleDescargarReportePredenuncia}
        handleAdjuntarDenuncia={handleOpenAdjuntarDenuncia}
        displayAccidents={displayAccidents}
        initialValues={filterBody}
      />
      <SiniestroAgregarDocumentacionForm
        open={openAgregarDocumentacion}
        handleClose={handleCloseAllForms}
        handleSubmit={handleSubmitAgregarDocumentacion}
        siniestro={siniestroSelected}
        file={archivosDocumentacion}
        setFile={setArchivosDocumentacion}
      />
      <SiniestroEnviarReciboSueldoForm
        open={openEnviarReciboSueldo}
        handleClose={handleCloseAllForms}
        handleSubmit={handleSubmitReciboSueldo}
        siniestro={siniestroSelected}
        file={archivosDocumentacion}
        setFile={setArchivosDocumentacion}
      />
      <SiniestroAdjuntarDenunciaForm
        open={openAdjuntarDenuncia}
        handleClose={handleCloseAllForms}
        handleSubmit={handleSubmitAdjuntarDenuncia}
        siniestro={siniestroSelected}
        file={archivosDocumentacion}
        setFile={setArchivosDocumentacion}
      />

      {/* Necesitamos un dialog ya que no queremos dejar de renderizar la tabla.
      Además, no podemos usar el prop de loading de SiniestralidadTable porque
      queremos mostrar un mensaje sobre la descarga del reporte de predenuncia */}
      <DialogDescargarReporte open={descargandoReportePredenuncia} />
    </div>
  );
};

export default SiniestroContainer;
