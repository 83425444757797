import { Button, Grid, Tooltip } from "@material-ui/core";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Form, Formik } from "formik";
import FormikTextField from "../../commons/formik/FormikTextField";
import CircularLoading from "../../commons/loading/CircularLoading";
import { useStyles } from "../../personal/ABMnomina/forms/styles/formStyle";
import { siniestroFormValidation } from "../validation/formValidation";

const SiniestroForm = ({ model, handleSubmit, isLoading }) => {
  const classes = useStyles();

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={model}
          onSubmit={handleSubmit}
          validationSchema={siniestroFormValidation}
        >
          <Form className={classes.formContainer}>
            <Grid container spacing={10} className={classes.gridContainer}>
              <Grid item xs={5} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="nroSiniestro"
                  name="nroSiniestro"
                  type="text"
                  label="Nro. Siniestro"
                />
              </Grid>
              <Grid item xs={5} className={classes.inputContainer}>
                <FormikTextField
                  fullWidth
                  id="nroDocumento"
                  name="nroDocumento"
                  type="text"
                  label="Nro. Documento"
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: 20 }}>
                <Tooltip title="Buscar Siniestros" arrow>
                  <Button type="submit">
                    <PersonSearchIcon fontSize="large" />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default SiniestroForm;
