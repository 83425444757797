import React from "react";
import CardContainer from "../../../../../commons/cards/CardContainer";
import NotificacionesCard from "./cards/notificacionesCard/NotificacionesCard";
import JuntaMedicaCard from "./cards/juntaMedicaCard/JuntaMedicaCard";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import {
  getCitaciones,
  getNotificacionesYCitas,
} from "../../../../../../redux/actions/notificacion";

const Notificaciones = ({ selectedAccident }) => {
  const dispatch = useDispatch();
  const notificacionesRedux = useSelector((state) => state.notificacion);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Grid item xs={6}>
            <CardContainer
              title="Notificaciones"
              loading={notificacionesRedux.notificaciones.loading}
              getData={() =>
                dispatch(
                  getNotificacionesYCitas(selectedAccident.idSiniestroCabecera)
                )
              }
              componente={
                <NotificacionesCard
                  data={notificacionesRedux.notificaciones.data}
                  idSiniestroCabecera={selectedAccident.idSiniestroCabecera}
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CardContainer
              title="Junta Médica"
              loading={notificacionesRedux.juntaMedica.loading}
              getData={() =>
                dispatch(getCitaciones(selectedAccident.idSiniestroCabecera))
              }
              componente={
                <JuntaMedicaCard data={notificacionesRedux.juntaMedica.data} />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Notificaciones;
