import { GET_LOGO } from "../actions/logo";

const INITIAL_STATE = {
  getLogo: {
    loading: false,
    error: false,
    data: null,
  },
};

const logo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_LOGO}_PENDING`:
      return {
        ...state,
        getLogo: {
          loading: true,
          error: false,
        },
      };
    case `${GET_LOGO}_REJECTED`:
      return {
        ...state,
        getLogo: {
          loading: false,
          error: true,
        },
      };
    case `${GET_LOGO}_FULFILLED`:
      return {
        ...state,
        getLogo: {
          loading: false,
          error: false,
          data: `data:image/png;base64,${action.payload.data.base64}`,
        },
      };
    default:
      return state;
  }
};

export default logo;
