import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import FormikActions from "../../../commons/formik/FormikActions";
import RequeridoLabel from "../../../commons/labels/RequeridoLabel";
import CustomStepper from "../../../commons/stepper/CustomStepper";
import { useStyles } from "../styles/formStyles";
import { validatePlanillaTable } from "../validation/formValidation";
import TableTitle from "./TableTitle";

const columns = [
  { id: "sustancia", label: "Sustancia" },
  { id: "si", label: "Sí" },
  { id: "no", label: "No" },
];

const initialValues = {
  idItemFormularioArt: "",
  secuencia: "",
  pregunta: "",
  resultado: "0",
};

const PlanillaTable = ({
  handleSubmit,
  data,
  tituloGrupo,
  contador,
  totalGrupos,
  handleGoBack,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableTitle
        title={`Sustancias: ${tituloGrupo.replace(
          /(^\w{1})|(\s+\w{1})/g,
          (letter) => letter.toUpperCase()
        )}`}
      />
      <Formik
        initialValues={{
          opciones: data.reduce((opciones, sustancia, index) => {
            opciones[index] = { ...initialValues, ...sustancia };
            return opciones;
          }, []),
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validatePlanillaTable}
      >
        {({ values, handleChange }) => (
          <Form>
            <FieldArray name="opciones" validateOnChange={false}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <>
                        {columns.map((column) => (
                          <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.opciones.map((sustancia, sustanciaIndex) => (
                      <TableRow key={sustanciaIndex}>
                        <TableCell>{sustancia.pregunta}</TableCell>
                        <TableCell>
                          <RadioGroup
                            name={`opciones.${sustanciaIndex}.resultado`}
                            value="1"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="1"
                              control={
                                <Radio
                                  checked={
                                    values.opciones[sustanciaIndex]
                                      ?.resultado === "1"
                                  }
                                />
                              }
                            />
                          </RadioGroup>
                        </TableCell>
                        <TableCell>
                          <RadioGroup
                            name={`opciones.${sustanciaIndex}.resultado`}
                            value="0"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="0"
                              control={
                                <Radio
                                  checked={
                                    values.opciones[sustanciaIndex]
                                      ?.resultado === "0"
                                  }
                                />
                              }
                            />
                          </RadioGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <CustomStepper contador={contador} maxGrupos={totalGrupos} />
                <div className={classes.planillaTableButtonContainer}>
                  {contador === totalGrupos - 1 && (
                    <RequeridoLabel text="Al enviar el formulario no podrá modificar sus respuestas" />
                  )}
                  <FormikActions
                    submit={
                      contador === totalGrupos - 1
                        ? "Enviar formulario"
                        : "Grupo siguiente"
                    }
                    handleCancel={
                      contador !== 0 ? () => handleGoBack(values) : null
                    }
                    cancel={contador !== 0 ? "Grupo anterior" : null}
                  />
                </div>
              </TableContainer>
            </FieldArray>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PlanillaTable;
