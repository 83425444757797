import { forwardRef, useImperativeHandle, useRef } from "react";
import { useReactToPrint } from "react-to-print";


// Wrapper cuya referencia tiene el metodo print que imprime cualquier componente react adentro del mismo

const PrintWrapper = forwardRef(({
    reactToPrintProps = {},
    children,
    hidden,
}, ref) => {

    const printFileRef = useRef();
    const pageStyle = `
    @media print {
        html, body {
            height: 100%;
            margin: 0 !important;
            padding: 0 !important;
        }
    }`
    const handlePrint = useReactToPrint({
        ...reactToPrintProps,
        content: () => printFileRef.current,
        pageStyle: pageStyle,
    });

    useImperativeHandle(ref, () => ({
        print() {
            handlePrint();
        }
    }), [handlePrint]);


    return (
        <div style={{ display: hidden ? "none" : "" }}>
            <div ref={printFileRef}  >
                {children}
            </div>
        </div>
    )
});

export default PrintWrapper;