import { Grid, MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useState } from "react";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTimePicker from "../../../../commons/formik/FormikTimePicker";
import { useStyles } from "../styles/formStyle";
import {
  datosNominaValidation,
  datosNominaValidationWithDomestica,
  validateRequiredFields,
} from "../validation/formValidation";

const DatosNominaForm = ({ handleSubmit, model, fields }) => {
  const classes = useStyles();

  const [isDomestica, setDomestica] = useState(false);

  return (
    <Formik
      initialValues={model}
      onSubmit={handleSubmit}
      validationSchema={
        !isDomestica
          ? datosNominaValidation
          : datosNominaValidationWithDomestica
      }
      validate={(values) => validateRequiredFields(values, fields)}
    >
      {({ setFieldValue, values }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={10} className={classes.gridContainer}>
            <Grid item xs={4}>
              <FormikDatePicker
                id="fechaIngresoEmpleador"
                name="fechaIngresoEmpleador"
                label={`Fecha ingreso de la nómina ${
                  fields.fechaIngresoEmpleador.obligatorio ? "*" : ""
                }`}
                fullWidth
                disableFuture
              />
            </Grid>
            <Grid item xs={2}>
              <FormikCheckbox
                id="agropecuario"
                name="agropecuario"
                label={`Agropecuario ${
                  fields.agropecuario.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={2}>
              <FormikCheckbox
                id="domestica"
                name="domestica"
                label={`Doméstica ${fields.domestica.obligatorio ? "*" : ""}`}
                onBlur={(value) => {
                  if (value.target.checked) {
                    setDomestica(true);
                  } else {
                    setDomestica(false);
                    setFieldValue("categoriaDomestica", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikSelect
                fullWidth
                name="categoriaDomestica"
                label={`Categoría doméstica ${
                  fields.categoriaDomestica.obligatorio || values.domestica
                    ? "*"
                    : ""
                }`}
                variant="standard"
                disabled={!values.domestica}
              >
                {fields.categoriaDomestica.opciones.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={4}>
              <FormikDatePicker
                id="fechaIngresoDependencia"
                name="fechaIngresoDependencia"
                label={`Fecha ingreso de la dependencia ${
                  fields.fechaIngresoDependencia.obligatorio ? "*" : ""
                }`}
                fullWidth
                disableFuture
              />
            </Grid>
            <Grid item xs={8}>
              <FormikAutocomplete
                fullWidth
                name="establecimiento"
                label={`Establecimiento ${
                  fields.establecimiento.obligatorio ? "*" : ""
                }`}
                variant="standard"
                options={fields.establecimiento.opciones}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikAutocomplete
                fullWidth
                name="puestoTrabajo"
                label={`Puesto laboral ${
                  fields.puestoTrabajo.obligatorio ? "*" : ""
                }`}
                variant="standard"
                options={fields.puestoTrabajo.opciones}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikSelect
                fullWidth
                name="turno"
                label={`Turno ${fields.turno.obligatorio ? "*" : ""}`}
                variant="standard"
              >
                {fields.turno.opciones.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormikTimePicker
                    name="horaDesde"
                    label={`Hora desde ${
                      fields.horaDesde.obligatorio ? "*" : ""
                    }`}
                    disablePast
                    autoComplete="off"
                    // necesitamos sobreescribir el estilo de FormikTimePicker
                    // porque sino, quedan desalineados en el grid
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikTimePicker
                    name="horaHasta"
                    label={`Hora hasta ${
                      fields.horaHasta.obligatorio ? "*" : ""
                    }`}
                    disablePast
                    autoComplete="off"
                    // necesitamos sobreescribir el estilo de FormikTimePicker
                    // porque sino, quedan desalineados en el grid
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Siguiente" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DatosNominaForm;
