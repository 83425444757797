import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  errorDatosComprobanteForm,
  saveDatosComprobanteForm,
} from "../../../redux/actions/reintegros";
import FormikAutoComplete from "../../commons/formik/FormikAutocomplete";
import FormikDatePicker from "../../commons/formik/FormikDatePicker";
import FormikTextField from "../../commons/formik/FormikTextField";
import CircularLoading from "../../commons/loading/CircularLoading";
import { useStyles } from "../../personal/ABMnomina/forms/styles/formStyle";
import { datosComprobanteValidation } from "../validation/formValidation";

const DatosComprobanteForm = ({ model, isLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tipoComprobante = useSelector(
    (state) => state.reintegros.tipoComprobante
  );

  const [errorMsg, setErrorMsg] = useState(null);

  const handleSubmit = async (values) => {
    await datosComprobanteValidation
      .validate(values)
      .then((res) => {
        dispatch(saveDatosComprobanteForm(values));
        setErrorMsg(null);
      })
      .catch((err) => {
        setErrorMsg(err);
        dispatch(errorDatosComprobanteForm());
      });
  };

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={model}
          validationSchema={datosComprobanteValidation}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={3} className={classes.inputContainer}>
                  <FormikDatePicker
                    fullWidth
                    id="fechaDelComprobante"
                    name="fechaDelComprobante"
                    type="text"
                    label="Fecha del Comprobante"
                    callback={(value) => {
                      handleSubmit({
                        ...values,
                        fechaDelComprobante: moment(value, {
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                          milliseconds: 0,
                        }),
                      });
                    }}
                    disableFuture
                  />
                </Grid>
                <Grid item xs={3} className={classes.inputContainer}>
                  <FormikAutoComplete
                    fullWidth
                    id="tipoComprobante"
                    name="tipoComprobante"
                    type="text"
                    label="Tipo Comprobante"
                    options={tipoComprobante.data}
                    renderOption={(option) => option.nombre}
                    filterOptions={(option) => option.nombre}
                    callBack={(value) => {
                      if (value) {
                        const arr = value.nombre.split(" - ");
                        setFieldValue("letra", arr[1]);
                        setFieldValue("tipo", arr[0]);
                        handleSubmit({ ...values, tipoComprobante: value.id });
                      } else {
                        handleSubmit({ ...values, tipoComprobante: null });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2} className={classes.inputContainer}>
                  <FormikTextField
                    fullWidth
                    id="letra"
                    name="letra"
                    type="text"
                    label="Letra"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2} className={classes.inputContainer}>
                  <FormikTextField
                    fullWidth
                    variant="outlined"
                    id="ptoVenta"
                    name="ptoVenta"
                    type="text"
                    label="Pto Venta"
                    onBlur={(value) => {
                      setFieldValue("ptoVenta", value.target.value);
                      handleSubmit({ ...values, ptoVenta: value.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={2} className={classes.inputContainer}>
                  <FormikTextField
                    fullWidth
                    variant="outlined"
                    id="nro"
                    name="nro"
                    type="text"
                    label="Nro"
                    onBlur={(value) => {
                      setFieldValue("nro", value.target.value);
                      handleSubmit({ ...values, nro: value.target.value });
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default DatosComprobanteForm;
