import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    border: `3px solid ${theme.palette.primary.main}`,
    boxShadow: "10px 10px 20px -15px",
    borderRadius: 10,
    margin: 15,
  },
  button: {
    width: "fit-content",
    margin: 0,
    border: "none",
    backgroundColor: "tran",
  },
  lastTypo: {
    display: "flex",
  },
}));

const DataLabel = ({ firstText, secondText, thirdText, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.dataContainer}>
      <Typography style={{ fontSize: 13 }}>{firstText}</Typography>
      <Typography style={{ fontSize: 13 }}>{secondText}</Typography>
      <div className={classes.lastTypo}>
        <Typography style={{ fontSize: 13 }}>{thirdText}</Typography>
        {icon && icon}
      </div>
    </div>
  );
};

export default DataLabel;
