import { Grid, MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import { useStyles } from "../styles/formStyle";
import {
  datosTrabajadorValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const DatosTrabajadorForm = ({ handleSubmit, model, fields }) => {
  const classes = useStyles();

  const disabled = !fields;

  return (
    <>
      {model != null && fields?.datosNominaDenunciaPortal && (
        <Formik
          initialValues={model}
          onSubmit={handleSubmit}
          validationSchema={datosTrabajadorValidation}
          validate={(values) => validateRequiredFields(values, fields)}
          enableReinitialize={true}
        >
          <Form className={classes.formContainer}>
            <Grid container className={classes.gridContainer} spacing={6}>
              <Grid item xs={4}>
                <FormikTextField
                  fullWidth
                  id="nombres"
                  name="nombres"
                  type="text"
                  label={`Nombres ${fields.nombres.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  fullWidth
                  id="apellido"
                  name="apellido"
                  type="text"
                  label={`Apellido ${fields.apellido.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  fullWidth
                  id="nacionalidad"
                  name="nacionalidad"
                  label={`Nacionalidad ${
                    fields.nacionalidad.obligatorio ? "*" : ""
                  }`}
                  type="text"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikSelect
                  fullWidth
                  name="tipoDocumento"
                  label={`Tipo documento ${
                    fields.tipoDocumento.obligatorio ? "*" : ""
                  }`}
                  variant="standard"
                  disabled={disabled}
                >
                  {fields?.tipoDocumento.opciones.map(({ id, nombre }) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  fullWidth
                  id="nroDocumento"
                  name="nroDocumento"
                  type="text"
                  label={`Nro documento ${
                    fields.nroDocumento.obligatorio ? "*" : ""
                  }`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikDatePicker
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  type="text"
                  label={`Fecha de nacimiento ${
                    fields.fechaNacimiento.obligatorio ? "*" : ""
                  }`}
                  disableFuture
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikSelect
                  fullWidth
                  name="genero"
                  label={`Género ${fields.genero.obligatorio ? "*" : ""}`}
                  variant="standard"
                  disabled={disabled}
                >
                  {fields?.genero.opciones.map(({ id, nombre }) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </Grid>
              <Grid item xs={4}>
                <FormikSelect
                  fullWidth
                  variant="standard"
                  name="estadoCivil"
                  label={`Estado civil ${
                    fields.estadoCivil.obligatorio ? "*" : ""
                  }`}
                  disabled={disabled}
                >
                  {fields?.estadoCivil.opciones.map(({ id, nombre }) => {
                    return (
                      <MenuItem value={id} key={id}>
                        {nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  fullWidth
                  id="telefono"
                  name="telefono"
                  type="text"
                  label={`Teléfono ${fields.telefono.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  fullWidth
                  id="mail"
                  name="mail"
                  type="text"
                  label={`Mail ${fields.mail.obligatorio ? "*" : ""}`}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <FormikActions submit="Siguiente" />
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default DatosTrabajadorForm;
