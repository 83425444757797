import React from "react";
import CardContainer from "../../../../../commons/cards/CardContainer";
import DinerariasCard from "./cards/DinerariasCard";
import { useDispatch, useSelector } from "react-redux";
import { getLiquidacionesDinerarias } from "../../../../../../redux/actions/dinerarias";
import DinerariasHelperLabel from "./labels/DinerariasHelperLabel";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Dinerarias = ({ selectedAccident }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const liquidacionesDinerarias = useSelector(
    (state) => state.dinerarias.liquidaciones
  );

  return (
    <>
      <Grid container className={classes.contentContainer}>
        <Grid container item xs={12}>
          <DinerariasHelperLabel />
        </Grid>
        <Grid item xs={10}>
          <CardContainer
            title="Estado de las Liquidaciones"
            loading={liquidacionesDinerarias.loading}
            getData={() =>
              dispatch(
                getLiquidacionesDinerarias(
                  selectedAccident?.idSiniestroCabecera
                )
              )
            }
            componente={<DinerariasCard data={liquidacionesDinerarias.data} />}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Dinerarias;
