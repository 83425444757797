import { makeStyles, useTheme } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearDenuncia,
  descargarReportePredenuncia,
  postDenuncia,
} from "../../../../redux/actions/denuncia";
import { downloadFile } from "../../../../util/files";
import RequeridoLabel from "../../../commons/labels/RequeridoLabel";
import CircularLoading from "../../../commons/loading/CircularLoading";
import AccidenteTrabajoContainer from "./accidenteTrabajoForm/AccidenteTrabajoContainer";
import EnfermedadProfesionalContainer from "./enfermedadProfesionalForm/EnfermedadProfesionalContainer";

const useStyles = makeStyles({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
});

const AccidenteOEnfermedadContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const nomina = useSelector((state) => state.denuncia.nomina);
  const datosDenuncia = useSelector((state) => state.denuncia.postDenuncia);

  const [obteniendoNumeroPredenuncia, setObteniendoNumeroPredenuncia] =
    useState(false);
  const [descargandoReporteDenuncia, setDescargandoReporteDenuncia] =
    useState(false);

  const isLoading = obteniendoNumeroPredenuncia || descargandoReporteDenuncia;

  const handleSubmit = (fromAccidente) => {
    let modeloDenuncia = {
      nominaDenunciaPortal: {
        ...datosDenuncia.datosTrabajador,
        ...datosDenuncia.direccionTrabajador,
        ...datosDenuncia.datosNomina,
        idDependenciaNomina: nomina.data.idDependenciaNomina,
        idTrabajador: nomina.data.idTrabajador,
        sinNro: false,
        pais: 1,
        horaDesde: datosDenuncia.datosNomina.horaDesde,
        horaHasta: datosDenuncia.datosNomina.horaHasta,
        categoriaDomestica: datosDenuncia.datosNomina.categoriaDomestica,
      },
      ...datosDenuncia.datosDenuncia,
      ...datosDenuncia.lugarOcurrencia,
      paisOcurrencia: 1,
      codigoPostalOcurrencia: parseInt(
        datosDenuncia.lugarOcurrencia.codigoPostalOcurrencia
      ),
      subtipoSiniestro: datosDenuncia.accidenteTrabajo.subTipoAccidente
        ? datosDenuncia.accidenteTrabajo.subTipoAccidente
        : 0,
      numeroOcurrencia: datosDenuncia.lugarOcurrencia.kmOcurrencia
        ? 0
        : parseInt(datosDenuncia.lugarOcurrencia.numeroOcurrencia),
      alturaOcurrencia: datosDenuncia.lugarOcurrencia.kmOcurrencia
        ? parseFloat(
            datosDenuncia.lugarOcurrencia.numeroOcurrencia.replace(",", ".")
          )
        : 0,
      fechaTomaConocimiento: null,
    };

    if (fromAccidente) {
      modeloDenuncia = {
        ...modeloDenuncia,
        denunciaPortalAccidente: {
          ocurrenciaViaPublica:
            datosDenuncia.accidenteTrabajo.ocurrenciaViaPublica,
          teletrabajo: datosDenuncia.accidenteTrabajo.teletrabajo,
          agenteCausante: datosDenuncia.accidenteTrabajo.agenteCausante,
          diagnosticoDenunciaPortalAccidente:
            datosDenuncia.diagnosticoAccidente,
          formaAccidente: datosDenuncia.accidenteTrabajo.formaAccidente
            ? datosDenuncia.accidenteTrabajo.formaAccidente
            : 0,
        },
      };
    } else {
      var examenPeriodico = false;
      const fechaExamenPeriodico = datosDenuncia.diagnosticoEnfermedad.find(
        (e) => e.principal === true
      );

      if (fechaExamenPeriodico !== undefined) {
        examenPeriodico = fechaExamenPeriodico.fechaExamen ? true : false;
      }

      modeloDenuncia = {
        ...modeloDenuncia,
        denunciaPortalEnfermedadProfesional: {
          ...datosDenuncia.enfermedadProfesional,
          examenPeriodico: examenPeriodico,
          fechaExamenPeriodico: fechaExamenPeriodico?.fechaExamen
            ? fechaExamenPeriodico.fechaExamen
            : null,
          mesesExposicionAgenteCausante:
            datosDenuncia.enfermedadProfesional.tiempoExposicionAgenteCausante,
          diagnosticoDenunciaPortalEnfermedadProfesional:
            datosDenuncia.diagnosticoEnfermedad,
          tiempoExposicionAgenteCausante:
            datosDenuncia.enfermedadProfesional.tiempoExposicionAgenteCausante,
        },
      };
    }

    setObteniendoNumeroPredenuncia(true);
    dispatch(postDenuncia(modeloDenuncia))
      .then((res) => {
        const { nroDenunciaWeb, numeroSiniestroArt } = res?.value?.data;

        const isVinculadoSiniestro = numeroSiniestroArt !== "";
        if (isVinculadoSiniestro) {
          toast.info(
            `Se creó la Predenuncia: ${nroDenunciaWeb}, y se asoció al Siniestro: ${numeroSiniestroArt}.`
          );
        } else {
          toast.info(`Se creó la Predenuncia: ${nroDenunciaWeb}.`);
        }

        setObteniendoNumeroPredenuncia(false);

        setDescargandoReporteDenuncia(true);
        dispatch(descargarReportePredenuncia(nroDenunciaWeb))
          .then((res) => {
            const reportePredenuncia = res?.value?.data;
            const fechaHoy = moment(new Date()).format("DD-MM-YYYY");

            downloadFile(
              reportePredenuncia,
              `ReportePredenuncia-${fechaHoy}.pdf`
            );
            toast.success(
              `El reporte de predenuncia ha sido descargado con éxito. Número de predenuncia: ${nroDenunciaWeb}`
            );
            setDescargandoReporteDenuncia(false);
            dispatch(clearDenuncia());
            navigate("/");
          })
          .catch(() => setDescargandoReporteDenuncia(false));
      })
      .catch(() => setObteniendoNumeroPredenuncia(false));
  };

  const labelCarga =
    (obteniendoNumeroPredenuncia && "Obteniendo el número de predenuncia...") ||
    (descargandoReporteDenuncia && "Descargando reporte de predenuncia...");

  return (
    <>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularLoading style={{ color: theme.palette.tertiary.main }} />
          <RequeridoLabel text={labelCarga} />
        </div>
      ) : (
        <>
          {
            {
              1: (
                <AccidenteTrabajoContainer
                  idTipoSiniestro={datosDenuncia.datosDenuncia.tipoSiniestro}
                  handleSubmitAllForm={handleSubmit}
                />
              ),
              2: (
                <EnfermedadProfesionalContainer
                  idTipoSiniestro={datosDenuncia.datosDenuncia.tipoSiniestro}
                  handleSubmitAllForm={handleSubmit}
                />
              ),
            }[datosDenuncia.datosDenuncia.tipoSiniestro]
          }
        </>
      )}
    </>
  );
};

export default AccidenteOEnfermedadContainer;
