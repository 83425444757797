import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";

const Title = styled.div`
  font-size: 22px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #484848;
  margin-bottom: 8px;
`;
const Subtitle = styled.div`
  font-size: 17px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: grey;
  margin-bottom: 8px;
  margin-left: 15px;
`;
const Error = styled.div`
  font-size: 15px;
  font-family: Roboto, sans-serif;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: grey;
  margin-bottom: 8px;
  margin-left: 15px;

`;

const SubtitleMail = styled.div`
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: grey;
  margin-bottom: 8px;
  margin-left: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

const CuentaCorrienteAccountData = () => {

  const { error: errorAnalistaDeCuenta, loading: loadingAnalistaDeCuenta, data } = useSelector((state) => state.cuentaCorriente.analistaDeCuenta);

  const { nombreCompleto, telefono, email } = data || {};
  const accountDataExists = nombreCompleto || telefono || email;



  useEffect(() => {
    if (data && !loadingAnalistaDeCuenta && !errorAnalistaDeCuenta && !accountDataExists) {
      toast.warn(
        "La ART no tiene configurado los datos del Analista de Cuenta, ante cualquier consulta comunicarse directamente con la ART",
        { toastId: "analistaDeCuentaCorrienteVacio" }
      )
    }

  }, [data, loadingAnalistaDeCuenta, errorAnalistaDeCuenta, accountDataExists]);


  return (

    <Container>
      <Title>Analista de Cuenta</Title>
      {data && !accountDataExists ?
        <Error> La ART no tiene configurado los datos </Error>
        :
        <>
          <Subtitle>{nombreCompleto}</Subtitle>
          {telefono && <Subtitle>Teléfono: {telefono}</Subtitle>}
          <SubtitleMail>{email}</SubtitleMail>
        </>
      }

    </Container>

  )


};

export default CuentaCorrienteAccountData;
