import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 5,
    display: "flex",
    wordWrap: "break-word",
  },
  label: {
    marginRight: 15,
  },
}));

const DatosEstablecimientoPresentacion = ({ data }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem key="nombre" className={classes.listItem}>
        <Typography className={classes.label}>
          Nombre Establecimiento
        </Typography>
        <ListItemText
          secondary={
            data?.nombre ? data.nombre.split("-")[0] : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="domicilio" className={classes.listItem}>
        <Typography className={classes.label}>Domicilio</Typography>
        <ListItemText
          secondary={data?.domicilio ? data?.domicilio : "Sin Información"}
        />
      </ListItem>
      <ListItem key="matrizUnica" className={classes.listItem}>
        <Typography className={classes.label}>Matriz Única</Typography>
        <ListItemText
          secondary={data?.matrizUnica ? data.matrizUnica : "Sin Información"}
        />
      </ListItem>
      <ListItem key="codigoIntercambio" className={classes.listItem}>
        <Typography className={classes.label}>Cod. Intercambio</Typography>
        <ListItemText
          secondary={
            data?.codigoIntercambio
              ? data?.codigoIntercambio
              : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="actividad" className={classes.listItem}>
        <Typography className={classes.label}>
          Actividad Establecimiento
        </Typography>
        <ListItemText
          secondary={data?.actividad ? data.actividad : "Sin Información"}
        />
      </ListItem>
    </List>
  );
};

export default DatosEstablecimientoPresentacion;
