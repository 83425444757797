import React from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import clsx from "clsx";
import { Menu } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppBar, IconButton, Toolbar, Tooltip } from "@material-ui/core";
import { logout } from "../../../redux/actions/auth";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import CloseSessionDialog from "./CloseSessionDialog";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0 0 0 0",
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.primary.menuItem,
  },
  hide: {
    display: "none",
  },
  logout: {
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",
    color: theme.palette.primary.menuItem,
  },
}));

const HeaderAppBar = ({ open, handleDrawerToggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cerrarSesion, setCerrarSesion] = useState(false);
  const handleLogout = () => {
    dispatch(logout());
    location.pathname = "/";
  };
  const handleOpenDialog = () => {
    setCerrarSesion(true);
  };
  const handleCloseDialog = () => {
    setCerrarSesion(false);
  };
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>
          <div className={classes.logout}>
            <Tooltip title="Cerrar Sesión" aria-label="cerrar sesion">
              <RadioButtonCheckedIcon
                aria-label="cerrar sesion"
                aria-controls="menu-appbar-options"
                aria-haspopup="true"
                onClick={handleOpenDialog}
                color="inherit"
              >
                <LogoutIcon />
              </RadioButtonCheckedIcon>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <CloseSessionDialog
        title="Cerrar sesión"
        text="¿Desea cerrar sesión?"
        open={cerrarSesion}
        handleClose={handleCloseDialog}
        handleConfirm={handleLogout}
      />
    </>
  );
};

export default HeaderAppBar;
