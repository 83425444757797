import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@mui/material";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 5,
    display: "flex",
    wordWrap: "break-word",
  },
  label: {
    marginRight: 15,
  },
}));

const DatosDecretoPresentacion = ({ data }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem key="fechaPresentacion" className={classes.listItem}>
        <Typography className={classes.label}>Fecha Presentación</Typography>
        <ListItemText secondary={format(new Date(), "dd/MM/yyyy")} />
      </ListItem>
      <ListItem key="decreto" className={classes.listItem}>
        <Typography className={classes.label}>Decreto</Typography>
        <ListItemText
          secondary={data?.nombre ? data?.nombre : "Sin Información"}
        />
      </ListItem>
      <ListItem key="totalTrabajadores" className={classes.listItem}>
        <Typography className={classes.label}>Total Trabajadores</Typography>
        <ListItemText
          secondary={
            data?.totalTrabajadoresEstablecimientos
              ? data.totalTrabajadoresEstablecimientos
              : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="superficie" className={classes.listItem}>
        <Typography className={classes.label}>Superficie</Typography>
        <ListItemText
          secondary={data?.superficie ? data?.superficie : "Sin Información"}
        />
      </ListItem>
      <ListItem
        key="observaciones"
        className={classes.listItem}
        sx={{ display: "flex", alignItems: "flex-start" }}
      >
        <Typography className={classes.label}>Observaciones</Typography>
        <ListItemText
          sx={{ display: "block", whiteSpace: "pre-line" }}
          secondary={
            data?.observaciones ? data.observaciones : "Sin Información"
          }
        />
      </ListItem>
    </List>
  );
};

export default DatosDecretoPresentacion;
