import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  formularioCompletado,
  getPreguntasDecreto,
  postGrupoPreguntas,
} from "../../../../redux/actions/rgrl";
import { convertDateTimeToNet } from "../../../../util/datetime";
import CircularLoading from "../../../commons/loading/CircularLoading";
import FechaPresentacionLabel from "../commons/FechaPresentacionLabel";
import { useStyles } from "../styles/formStyles";
import PreguntaTable from "./PreguntaTable";

const PreguntaContainer = ({ nextForm }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [contador, setContador] = useState(0);
  const [grupoActual, setGrupoActual] = useState(null);
  const [totalGrupos, setTotalGrupos] = useState(0);
  const rgrl = useSelector((state) => state.rgrl.preguntas);
  const postRgrl = useSelector((state) => state.rgrl.postRgrl);
  const decretoForm = useSelector((state) => state.rgrl.postRgrl.decretoForm);

  const isLoading = !grupoActual || rgrl.loading;

  useEffect(() => {
    dispatch(getPreguntasDecreto(decretoForm.id));
  }, [decretoForm.id]);

  useEffect(() => {
    if (rgrl.data && !postRgrl.preguntasDecreto.preguntas.length) {
      setTotalGrupos(rgrl.data.length);
      setGrupoActual(rgrl.data[contador]);
    }
  }, [rgrl.data]);

  useEffect(() => {
    if (postRgrl.preguntasDecreto.preguntas.length) {
      if (postRgrl.formulariosCompletados.preguntasDecreto) {
        toast.warn("El formulario de preguntas ya ha sido completado.");
        nextForm("PlanillaA");
      } else {
        setTotalGrupos(rgrl.data.length);

        const indiceUltimoGrupo = Math.min(postRgrl.preguntasDecreto.preguntas.length, rgrl.data.length - 1);
        setContador(indiceUltimoGrupo);
        setGrupoActual(postRgrl?.preguntasDecreto.preguntas?.[indiceUltimoGrupo] || rgrl.data[indiceUltimoGrupo]);

      }
    }
  }, []);

  const handleGoBack = (values) => {
    setContador(contador - 1);
    postRgrl.preguntasDecreto.preguntas[
      contador - 1
    ].itemsFormularioArt.forEach((e) => {
      e.resultado = e.resultado.toString();
    });
    setGrupoActual(postRgrl.preguntasDecreto.preguntas[contador - 1]);
    dispatch(
      postGrupoPreguntas({
        ...grupoActual,
        itemsFormularioArt: values.itemsFormularioArt,
      })
    );

  };

  const handleSubmit = (datos) => {

    setContador(contador + 1);
    datos.itemsFormularioArt.forEach((p) => {
      p.fechaLimiteVerificacion = convertDateTimeToNet(
        p.fechaLimiteVerificacion
      );
      p.fechaRegulacion = convertDateTimeToNet(p.fechaRegulacion);
      p.resultado = parseInt(p.resultado);
    });
    const indexToUpdate = postRgrl.preguntasDecreto.preguntas.findIndex(
      (element) =>
        element.idGrupoItemFormularioArt === datos.idGrupoItemFormularioArt
    );
    if (indexToUpdate !== -1 && indexToUpdate === contador) {
      setGrupoActual(
        postRgrl.preguntasDecreto.preguntas[indexToUpdate + 1]
          ? postRgrl.preguntasDecreto.preguntas[indexToUpdate + 1]
          : rgrl.data[indexToUpdate + 1]
      );
    } else {
      setGrupoActual(rgrl.data[contador + 1]);
    }
    dispatch(
      postGrupoPreguntas({
        ...grupoActual,
        itemsFormularioArt: datos.itemsFormularioArt,
      },)
    );
    if (contador >= rgrl.data.length - 1) {
      dispatch(formularioCompletado("preguntasDecreto"));
      nextForm("PlanillaA");
    }
  };



  return (
    <>
      <FechaPresentacionLabel />
      <Paper>
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularLoading />
          </div>
        ) : (
          <PreguntaTable
            handleSubmit={handleSubmit}
            data={grupoActual}
            contador={contador}
            totalGrupos={totalGrupos}
            handleGoBack={handleGoBack}
          />
        )}
      </Paper>
    </>
  );
};

export default PreguntaContainer;
