/**
 * @param {string} title - El título del menú breadcrumb en formato
 * CamelCase
 *
 * @example
 * // Enviamos "ImportarNóminas" y recibimos "Importar Nóminas"
 * formatFormTitle("ImportarNóminas")
 *
 * @returns {string} El título con espacios, manteniendo las
 * mayúsculas y minúsculas
 */
export const formatFormTitle = (title) => {
  let splitOnCamelCase = title.split(/(?=[A-Z])/);
  return splitOnCamelCase.join(" ");
};

export const deleteRight = (arr, elemento) => {
  const indice = arr.indexOf(elemento); // Obtener el índice del elemento en el array

  if (indice !== -1) {
    arr.splice(indice + 1); // Eliminar los elementos a la derecha del elemento
  }

  return arr;
};
