import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Button, makeStyles, DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { sendContratoByEmail } from "../../../redux/actions/contrato";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../commons/formik/FormikTextField";
import CircularLoading from "../../commons/loading/CircularLoading";
const useStyles = makeStyles((theme) => ({
  containerBody: {
    width: 550,
    height: 180,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formikInput: {
    width: 500,
    marginTop: -50,
  },
  containerButtons: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Debe escribir un formato de email valido")
    .required("Este campo es obligatorio"),
});

export default function ModalSendEmail({ open, handleClose, codigoContrato }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSumbit = ({ email }) => {
    dispatch(sendContratoByEmail(codigoContrato, email)).then((response) => {
      toast.success("Contrato enviado correctamente");
      handleClose();
    });
  };
  const contratoEmail = useSelector((state) => state.contrato.contratoEmail);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {!contratoEmail.loading ? "Enviar contrato por Email" : "Enviando..."}
      </DialogTitle>

      {!contratoEmail.loading ? (
        <DialogContent className={classes.containerBody}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSumbit}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div className={classes.formikInput}>
                  <FormikTextField
                    fullWidth={true}
                    id="email"
                    name="email"
                    type="text"
                    label="Email"
                  />
                </div>
                <DialogActions className={classes.containerButtons}>
                  <Button type="button" onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Enviar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      ) : (
        <DialogContent className={classes.containerBody}>
          <CircularLoading />
        </DialogContent>
      )}
    </Dialog>
  );
}
