import { RemoveRedEye } from "@mui/icons-material";
import DropZone from "../../../commons/dropZone/DropZone";
import FeatureLabel from "../commons/labels/FeatureLabel";

const ImportarNominaDropZone = ({
  handleViewInstruccionesImportar,
  setNominasCsv,
  nominasCsv,
}) => {
  return (
    <div>
      <FeatureLabel
        labelText="INFORMACIÓN DEL ARCHIVO A IMPORTAR"
        icon={<RemoveRedEye />}
        onClick={handleViewInstruccionesImportar}
        tooltipText="Ver Información"
      />
      <DropZone
        file={nominasCsv}
        setFile={setNominasCsv}
        loading={false}
        maxFiles={1}
        colorearArchivosAdjuntos={true}
        extension=".csv"
        accept={{
          "text/csv": [".csv"],
        }}
        dropZoneText="Arrastre la nómina en formato .csv aquí o haga click en el recuadro para
    adjuntarla."
        dropZoneEditText="Si desea cambiar el archivo arrastrelo nuevamente o haga click en
    el recuadro."
      />
    </div>
  );
};

export default ImportarNominaDropZone;
