import { TablePagination } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React from "react";
import ExportButton from "../../../commons/export/ExportButton";
import { localization, tableStyles } from "../../../commons/table/tableConfig";

const SiniestrosTable = ({ data, loading }) => {
  const columns = [
    {
      title: "Período",
      field: "periodo",
      align: "center",
    },
    {
      title: "Apellido y Nombre",
      field: "nombreCompletoTrabajador",
      align: "center",
    },
    {
      title: "DNI",
      field: "numeroDocumento",
      align: "center",
      render: (rowData) =>
        // que el numero de documento tenga formato de miles
        Intl.NumberFormat("es-AR").format(rowData.numeroDocumento),
    },
    {
      title: "Agente de Riesgo",
      field: "riesgos",
      width: "30%",
      align: "center",
      // por especificacion del analista, como el campo de riesgos
      // es muy largo, se habló sobre splitear el campo sobre el
      // pipe "|" que trae el back. Entonces, usamos ese caracter
      // "|" para separarlo esteticamente en una lista itemizada.
      render: (rowData) => {
        return (
          <ul style={{ paddingLeft: 20 }}>
            {rowData.riesgos?.split("|").map((r) => (
              <li key={r.slice(0, 10)}>{r}</li>
            ))}
          </ul>
        );
      },
    },
    {
      title: "CIE-10",
      field: "diagnostico",
      width: "30%",
      align: "center",
    },
    {
      title: "Siniestro",
      field: "numeroSiniestroART",
      align: "center",
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={{ ...tableStyles.container }}
      title=""
      options={{
        actionsColumnIndex: -1,
        headerStyle: tableStyles.headerStyle,
        rowStyle: tableStyles.rowStyle,
        pageSize: 10,
        search: false,
        filtering: true,
      }}
      components={{
        Toolbar: (props) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MTableToolbar {...props} />
            <ExportButton
              data={props.data}
              columns={props.columns}
              disabled={data.length === 0}
              title="Siniestros por Exámenes Periódicos"
            />
          </div>
        ),
        Pagination: (props) => {
          const { onChangePage, onChangeRowsPerPage, ...tablePaginationProps } =
            props;
          return (
            <TablePagination
              {...tablePaginationProps}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                  <props.ActionsComponent
                    {...actionsComponentProps}
                    onChangePage={onPageChange}
                  />
                );
              }}
            />
          );
        },
      }}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay datos disponibles para mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};

export default SiniestrosTable;
