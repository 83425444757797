import {
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { formatForNetDates } from "../../../../../../util/datetime";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 5,
    wordWrap: "break-word",
  },
  listItemText: {
    display: "flex",
    flex: " 0 0 auto",
    margin: 5,
    justifyContent: "space-evenly",
    width: "20%",
  },
}));

const FormulariosPresentadosCard = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      {data?.length ? (
        <List className={classes.list}>
          {data.map((formulario, index) => (
            <ListItem key={index} className={classes.listItem}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  formulario.codigo ? formulario.codigo : "Sin Información"
                }
              />
              <ListItemText
                className={classes.listItemText}
                primary={
                  formulario.fecha
                    ? formatForNetDates(formulario.fecha).split(" ")[0]
                    : "Sin Información"
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "100px 20px 20px 50px",
            color: "grey",
            textAlign: "center",
          }}
        >
          Sin formularios
        </Typography>
      )}
    </>
  );
};

export default FormulariosPresentadosCard;
