import React, { useState } from "react";
import EmpresaCargaPorTextoForm from "./EmpresaCargaPorTextoForm";
import EmpresaTable from "./EmpresaTable";

const EmpresaContainer = ({
  empresaList,
  setEmpresaList,
  mostrarCargaPorTexto,
  handleCargarEmpresasPorTexto,
}) => {
  const [openAddEmpresa, setOpenAddEmpresa] = useState(false);
  const [edit, setEdit] = useState(false);
  const [empresa, setEmpresa] = useState(null);

  const deleteEmpresa = (values) => {
    const empresaListAux = [...empresaList];
    const index = empresaListAux.indexOf(values);
    if (index > -1) {
      empresaListAux.splice(index, 1);
    }
    setEmpresaList(empresaListAux);
  };

  const addEmpresa = () => {
    setOpenAddEmpresa(true);
  };

  const editEmpresa = (empresa) => {
    setEmpresa(empresa);
    setOpenAddEmpresa(true);
    setEdit(true);
  };

  const handleAddEmpresa = (values) => {
    const empresaListAux = [...empresaList];
    if (!edit && !empresaList.find((e) => e.cuit === values.cuit)) {
      empresaListAux.push(values);
    } else {
      empresaListAux.forEach((empresa) => {
        if (empresa.cuit === values.cuit) {
          empresa.razonsocial = values.razonsocial;
        }
      });
    }
    setEmpresaList(empresaListAux);
    handleCloseEmpresa();
  };

  const handleCloseEmpresa = () => {
    setEmpresa(null);
    setEdit(false);
    setOpenAddEmpresa(false);
  };

  return (
    <>
      {mostrarCargaPorTexto ? (
        <EmpresaCargaPorTextoForm handleSubmit={handleCargarEmpresasPorTexto} />
      ) : (
        <EmpresaTable
          empresaList={empresaList}
          openAddEmpresa={openAddEmpresa}
          edit={edit}
          empresa={empresa}
          handleAddEmpresa={handleAddEmpresa}
          handleCloseEmpresa={handleCloseEmpresa}
          addEmpresa={addEmpresa}
          deleteEmpresa={deleteEmpresa}
          editEmpresa={editEmpresa}
        />
      )}
    </>
  );
};

export default EmpresaContainer;
