import { Button, DialogActions, Typography } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import DropZone from "../../../commons/dropZone/DropZone";
import FormikTextField from "../../../commons/formik/FormikTextField";
import CircularLoading from "../../../commons/loading/CircularLoading";
import { useStyles } from "../styles/formStyle";

const validationSchema = Yup.object().shape({
  observaciones: Yup.string()
    .max(5000, "El campo no puede superar los 5000 caracteres")
    .required("Campo obligatorio"),
});

const SiniestroAgregarDocumentacionForm = ({
  open,
  handleClose,
  handleSubmit,
  siniestro,
  file,
  setFile,
}) => {
  const classes = useStyles();

  const isCargandoFI = useSelector(
    (state) => state.siniestralidad.cargarFi.loading
  );

  const initialValues = {
    siniestroArt: siniestro.siniestroART,
    tipoSiniestro: siniestro.tipoSiniestro,
    nroDocumento: siniestro.nroDocumento,
    apellidoNombre: siniestro.apellidoNombre,
    fechaHoraEvento: new Date(),
    observaciones: "",
    idSiniestroCabecera: siniestro.idSiniestroCabecera,
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Ingresar Documentación</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit({ ...values, file })}
      >
        {({ values }) => (
          <Form>
            {isCargandoFI ? (
              <div className={classes.loading}>
                <CircularLoading />
              </div>
            ) : (
              <DialogContent className={classes.content}>
                <div className={classes.row}>
                  <FormikTextField
                    fullWidth={true}
                    name="siniestroArt"
                    label="Nro Siniestro"
                    type="text"
                    disabled
                  />
                  <FormikTextField
                    fullWidth={true}
                    name="tipoSiniestro"
                    label="Tipo Siniestro"
                    type="text"
                    disabled
                  />
                </div>
                <div className={classes.row}>
                  <FormikTextField
                    fullWidth={true}
                    name="nroDocumento"
                    label="Número Documento"
                    type="text"
                    disabled
                  />
                  <FormikTextField
                    fullWidth={true}
                    name="apellidoNombre"
                    label="Apellido y Nombre"
                    type="text"
                    disabled
                  />
                </div>
                <div className={classes.observaciones}>
                  <FormikTextField
                    fullWidth={true}
                    name="observaciones"
                    label="Observaciones"
                    type="text"
                    variant="outlined"
                    multiline
                    maxRows={15}
                    minRows={2}
                  />
                  {values.observaciones.length > 0 && (
                    <Typography variant="caption">
                      {values.observaciones.length} caracteres
                    </Typography>
                  )}
                </div>
                <DropZone
                  file={file}
                  setFile={setFile}
                  loading={false}
                  maxFiles={5}
                  extension=".TXT, .PDF, .JPG, .PNG, .XLS o .DOCX."
                  accept={{
                    "application/vnd.ms-excel": [".xls"],
                    "text/plain": [".txt"],
                    "application/pdf": [".pdf"],
                    "image/jpeg": [".jpg"],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [".docx"],
                    "image/png": [".png"],
                  }}
                  dropZoneText="Arrastre aquí o haga click en el recuadro para importar información en formato TXT, PDF, JPG, PNG, XLS, DOCX."
                  dropZoneEditText="Si desea subir mas archivos arrastrelos nuevamente o haga click en el recuadro."
                />
              </DialogContent>
            )}
            <DialogActions className={classes.buttonsContainer}>
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SiniestroAgregarDocumentacionForm;
