import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0.5,
    display: "block",
    wordWrap: "break-word",
  },
});

const DatosSiniestroCard = ({ data }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem key="numeroSiniestro" className={classes.listItem}>
        <ListItemText
          primary="Número Siniestro ART"
          secondary={
            data?.nroSiniestroART ? data.nroSiniestroART : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="tipoAccidente" className={classes.listItem}>
        <ListItemText
          primary="Tipo Accidente Laboral"
          secondary={
            data?.tipoSiniestro ? data.tipoSiniestro : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="dni" className={classes.listItem}>
        <ListItemText
          primary="DNI"
          secondary={data?.nroDocumento ? data.nroDocumento : "Sin Información"}
        />
      </ListItem>
      <ListItem key="nombre" className={classes.listItem}>
        <ListItemText
          primary="Nombre"
          secondary={
            data?.apellidoNombre ? data.apellidoNombre : "Sin Información"
          }
        />
      </ListItem>
    </List>
  );
};

export default DatosSiniestroCard;
