import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNte,
  clearPostNte,
  clearSearchedNte,
  getDatosEstablecimiento,
  getDependenciasEmpleador,
  postSearchedNte,
  validarDisponibilidadDeCarga,
} from "../../../../redux/actions/nte";
import ConfirmDialog from "../../../commons/dialog/ConfirmDialog";
import SearchEstablecimiento from "./SearchEstablecimiento";
import EstablecimientoCard from "./cards/EstablecimientoCard";

const EstablecimientoContainer = ({ nextForm, fromBread }) => {
  const dispatch = useDispatch();

  const searchedNte = useSelector((state) => state.nte.searchedNte);
  const dependenciasEmpleador = useSelector(
    (state) => state.nte.dependenciasEmpleador
  );
  const validatedNte = useSelector((state) => state.nte.validarCarga.validated);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [searchModel, setSearchModel] = useState(null);
  const [selectedValue, setSelectedValue] = useState(searchedNte ?? null);

  const isLoading = dependenciasEmpleador.loading;

  useEffect(() => {
    setSearchModel({ establecimiento: selectedValue });
  }, [selectedValue]);

  useEffect(() => {
    dispatch(getDependenciasEmpleador());
  }, []);

  useEffect(() => {
    if (!fromBread) {
      dispatch(clearNte());
    }
  }, []);

  const getCards = (values) => {
    dispatch(getDatosEstablecimiento(values.establecimiento));
  };

  const handleSearch = (values) => {
    dispatch(clearPostNte());
    if (values?.establecimiento) {
      dispatch(postSearchedNte(values.establecimiento));
      dispatch(validarDisponibilidadDeCarga(values.establecimiento));
      getCards(values);
    } else {
      dispatch(clearSearchedNte());
    }
  };

  return (
    <>
      <SearchEstablecimiento
        options={dependenciasEmpleador.data}
        loading={isLoading}
        handleSearch={handleSearch}
        searchModel={searchModel}
        searchedNte={searchedNte}
        setOpenConfirmDialog={() => setOpenConfirmDialog(true)}
        setSelectedValue={setSelectedValue}
      />
      <EstablecimientoCard
        searchedNte={searchedNte}
        nextForm={nextForm}
        validatedNte={validatedNte}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={() => {
          setOpenConfirmDialog(false);
          setSelectedValue(searchedNte);
          setSearchModel({ establecimiento: searchedNte });
        }}
        handleConfirm={() => {
          handleSearch({ establecimiento: selectedValue });
          setOpenConfirmDialog(false);
        }}
        title="Perderá todos los datos completados"
        warningText={
          "Al buscar otro establecimiento los datos cargados anteriormente se perderán."
        }
        loading={false}
      />
    </>
  );
};

export default EstablecimientoContainer;
