import {
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 5,
    display: "flex",
    wordWrap: "break-word",
  },
  label: {
    marginRight: 15,
  },
}));

const DatosEmpresaContractual = ({ data }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem key="polizaDigital" className={classes.listItem}>
        <Typography className={classes.label}>Póliza Digital</Typography>
        <ListItemText
          secondary={
            data?.polizaDigital ? data.polizaDigital : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="vigenciaDesde" className={classes.listItem}>
        <Typography className={classes.label}>Vigencia</Typography>
        <ListItemText
          secondary={
            data?.vigenciaDesde && data?.vigenciaHasta
              ? `${data?.vigenciaDesde} al ${data?.vigenciaHasta}`
              : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="tipoSolicitud" className={classes.listItem}>
        <Typography className={classes.label}>Tipo de Solicitud</Typography>
        <ListItemText
          secondary={
            data?.tipoSolicitud ? data.tipoSolicitud : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="contratoMixto" className={classes.listItem}>
        <Typography className={classes.label}>Contrato Mixto</Typography>
        <ListItemText secondary={data?.contratoMixto ? "Si" : "No"} />
      </ListItem>
      <ListItem key="nivelSeguridad" className={classes.listItem}>
        <Typography className={classes.label}>Nivel de Seguridad</Typography>
        <ListItemText
          secondary={
            data?.nivelSeguridad ? data.nivelSeguridad : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="cuss" className={classes.listItem}>
        <Typography className={classes.label}>CUSS</Typography>
        <ListItemText secondary={data?.cuss ? "Si" : "No"} />
      </ListItem>
      <ListItem key="suss" className={classes.listItem}>
        <Typography className={classes.label}>SUSS</Typography>
        <ListItemText secondary={data?.cuss ? "Si" : "No"} />
      </ListItem>
      <ListItem key="capitas" className={classes.listItem}>
        <Typography className={classes.label}>Cápitas</Typography>
        <ListItemText
          secondary={data?.capitas ? data.capitas : "Sin Información"}
        />
      </ListItem>
    </List>
  );
};

export default DatosEmpresaContractual;
