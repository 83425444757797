import React from "react";
import { Grid } from "@material-ui/core";
import DatosGeneralesEnAfip from "./cards/IndividualCards/listCards/DatosGeneralesEnAfip";
import CardContainer from "../../commons/cards/CardContainer";
import DatosEmpresaContractual from "./cards/IndividualCards/listCards/DatosEmpresaContractual";
import Domicilio from "./cards/IndividualCards/formCards/Domicilio";
import DatosDeContacto from "./cards/IndividualCards/formCards/DatosDeContacto";
import {
  getDatosContractuales,
  getDatosDeContacto,
  getDatosGeneralesAfip,
  getDomicilioLegal,
  getDomicilioPostal,
} from "../../../redux/actions/informacion";
import { useSelector, useDispatch } from "react-redux";

const InformacionEmpresaContainer = () => {
  const dispatch = useDispatch();
  const informacionReducer = useSelector((state) => state.informacion);

  return (
    <Grid container spacing={2}>
      {/* Contenedor de la izquierda */}
      <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
        <CardContainer
          title="Datos Generales en AFIP"
          getData={() => dispatch(getDatosGeneralesAfip())}
          loading={informacionReducer.datosGeneralesAfip.loading}
          componente={
            <DatosGeneralesEnAfip
              data={informacionReducer.datosGeneralesAfip.data}
            />
          }
        />
        <CardContainer
          title="Datos Contractuales"
          getData={() => dispatch(getDatosContractuales())}
          loading={informacionReducer.datosContractuales.loading}
          componente={
            <DatosEmpresaContractual
              data={informacionReducer.datosContractuales.data}
            />
          }
        />
      </Grid>
      {/* Contenedor de la derecha */}
      <Grid item xs={6} style={{ display: "flex", flexDirection: "column" }}>
        <CardContainer
          title="Domicilio Legal"
          getData={() => dispatch(getDomicilioLegal())}
          loading={informacionReducer.domicilioLegal.loading}
          componente={
            <Domicilio data={informacionReducer.domicilioLegal.data} />
          }
        />
        <CardContainer
          title="Domicilio Postal"
          getData={() => dispatch(getDomicilioPostal())}
          loading={informacionReducer.domicilioPostal.loading}
          componente={
            <Domicilio data={informacionReducer.domicilioPostal.data} />
          }
        />
        <CardContainer
          title="Datos de Contacto"
          getData={() => dispatch(getDatosDeContacto())}
          loading={
            informacionReducer.datosDeContacto.loading ||
            informacionReducer.postDatosContacto.loading
          }
          componente={
            <DatosDeContacto data={informacionReducer.datosDeContacto.data} />
          }
        />
      </Grid>
    </Grid>
  );
};

export default InformacionEmpresaContainer;
