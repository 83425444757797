import axios from "axios";
export const GET_CUENTA_CORRIENTE = "GET_CUENTA_CORRIENTE";
export const GET_ANALISTA_CUENTA = "GET_ANALISTA_CUENTA";
export const CLEAR_ANALISTA_CUENTA = "CLEAR_ANALISTA_CUENTA";
export const DOWNLOAD_ESTADO_CUENTA_CORRIENTE = "DOWNLOAD_ESTADO_CUENTA_CORRIENTE";

export const getCuentaCorriente = () => (dispatch) => {
  return dispatch({
    type: GET_CUENTA_CORRIENTE,
    payload: axios
      .get(`Empleador/getCuentaCorriente?fechaDesde=${null}&fechaHasta=${null}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getCuentaCorrienteWithFilter =
  (fechaDesde, fechaHasta) => (dispatch) => {
    return dispatch({
      type: GET_CUENTA_CORRIENTE,
      payload: axios
        .get(
          `Empleador/getCuentaCorriente?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAnalistaCuenta = () => (dispatch) => {
  return dispatch({
    type: GET_ANALISTA_CUENTA,
    payload: axios
      .get(`contratos/analistaCuentaAfiliaciones`)
      .then((response) => {
        return { data: response.data }
      })
      .catch((error) => Promise.reject(error)),
  });
};

export const clearAnalistaCuenta = () => (dispatch) => {
  return dispatch({ type: CLEAR_ANALISTA_CUENTA })
}

export const downloadEstadoCuentaCorriente = () => (dispatch) => {
  return dispatch({
    type: DOWNLOAD_ESTADO_CUENTA_CORRIENTE,
    payload: axios
      .get("Empleador/descargarEstadoCuentaCorriente",
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        let fileName = response?.headers['content-disposition']?.split("filename=")[1];
        return { data: response.data, fileName }
      })
      .catch((error) => Promise.reject(error)),
  })
}