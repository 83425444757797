import {
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import FileOpenIcon from "@mui/icons-material/FileOpen";

import PrintIcon from "@mui/icons-material/Print";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles({
  card: {
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  emptyState: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 20px 20px 50px",
    color: "grey",
    textAlign: "center",
  },
  estadoPrePresentado: {
    color: "#d52b34",
  },
  estadoRevision: {
    color: "#424db8",
  },
  estadoFinalizado: {
    color: "black",
  },
});

const FormulariosPrevencionCard = ({
  data,
  formularioSeleccionadoAdjuntar,
  setIsModalAdjuntarFormOpen,
  handleImprimirFormulario,
  handleVisualizarFormulario,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  // vienen como int desde el back
  const estadosFormulario = {
    0: "Sin Estado",
    1: "Pre-Presentado",
    2: "Revisión",
  };

  const estilosSegunEstado = {
    0: classes.estadoFinalizado,
    1: classes.estadoPrePresentado,
    2: classes.estadoRevision,
  };

  const getStyleParaFormulario = (formulario) => {
    return estilosSegunEstado[formulario?.estado] ?? classes.estadoFinalizado;
  };

  const getIconosSegunEstado = (formulario) => {
    if (
      formulario?.codigoArtFormulario === 3 ||
      formulario?.codigoArtFormulario === 11
    ) {
      if (formulario?.estado === 1) {
        return (
          <>
            <Tooltip title="Adjuntar formulario firmado" arrow>
              <UploadFileIcon
                onClick={() => {
                  formularioSeleccionadoAdjuntar.current = formulario;
                  setIsModalAdjuntarFormOpen(true);
                }}
                style={{ color: "#d52b34", marginRight: 5 }}
              />
            </Tooltip>

            <Tooltip title="Imprimir formulario" arrow>
              <PrintIcon
                onClick={() => handleImprimirFormulario(formulario)}
                style={{
                  color: `${theme.palette.secondary.light}`,
                }}
              />
            </Tooltip>
          </>
        );
      } else if (formulario?.estado === 2) {
        return (
          <>
            <Tooltip title="Ver formulario cargado" arrow>
              <FileOpenIcon
                onClick={() => handleVisualizarFormulario(formulario)}
                style={{ color: `${theme.palette.secondary.light}` }}
              />
            </Tooltip>
          </>
        );
      } else if (formulario?.estado === 0) {
        return (
          <>
            <Tooltip title="Imprimir formulario finalizado" arrow>
              <PrintIcon
                onClick={() => handleImprimirFormulario(formulario)}
                style={{
                  color: `${theme.palette.secondary.light}`,
                }}
              />
            </Tooltip>
          </>
        );
      }
      return null;
    }
  };

  return (
    <div>
      {data?.length > 0 ? (
        data.map((formulario) => {
          const estiloFormulario = getStyleParaFormulario(formulario);
          const iconosSegunEstado = getIconosSegunEstado(formulario);

          return (
            <Paper
              elevation={2}
              className={classes.card}
              key={formulario?.idFormularioProgramaPrevencionEmpleador}
            >
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="body1" className={estiloFormulario}>
                    {format(
                      parseISO(formulario?.fechaHoraFormulario),
                      "dd/MM/yyyy"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" className={estiloFormulario}>
                    {formulario?.nombreFormulario}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography className={estiloFormulario}>
                    {formulario?.estado !== 0
                      ? estadosFormulario[formulario?.estado]
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {iconosSegunEstado}
                </Grid>
              </Grid>
            </Paper>
          );
        })
      ) : (
        <Typography className={classes.emptyState} variant="body2">
          No hay formularios para este establecimiento
        </Typography>
      )}
    </div>
  );
};

export default FormulariosPrevencionCard;
