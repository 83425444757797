import { Grid, MenuItem } from "@material-ui/core";
import { addDays } from "date-fns";
import { Form, Formik } from "formik";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import FormikDateTimePicker from "../../../../commons/formik/FormikDateTimePicker";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import { useStyles } from "../styles/formStyle";
import {
  datosDenunciaValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const DatosDenunciaForm = ({ handleSubmit, model, fields }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={model}
      onSubmit={handleSubmit}
      validationSchema={datosDenunciaValidation}
      validate={(values) => validateRequiredFields(values, fields)}
      // necesitamos el reinicializar porque el GET del cuitOcurrencia
      // puede tardar, y necesitamos autorellenar ese valor
      enableReinitialize={true}
    >
      {({ setFieldValue }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={9} className={classes.gridContainer}>
            <Grid item xs={4}>
              <FormikSelect
                fullWidth
                name="tipoSiniestro"
                label={`Tipo de siniestro ${
                  fields.tipoSiniestro.obligatorio ? "*" : ""
                }`}
                variant="standard"
                disabled={!fields.tipoSiniestro.editable}
              >
                {fields.tipoSiniestro.opciones.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={4}>
              <FormikDateTimePicker
                id="fechaHoraAccidente"
                name="fechaHoraAccidente"
                label={`Fecha hora ocurrencia ${
                  fields.fechaHoraAccidente.obligatorio ? "*" : ""
                }`}
                callBack={(value) => {
                  const fechaHoraAccidenteValue = value;
                  if (fechaHoraAccidenteValue) {
                    setFieldValue("fechaAbandonoTrabajo", addDays(value, 1));
                  }
                }}
                fullWidth
                disableFuture
              />
            </Grid>
            <Grid item xs={4}>
              <FormikDatePicker
                id="fechaAbandonoTrabajo"
                name="fechaAbandonoTrabajo"
                label={`Fecha abandono del puesto ${
                  fields.fechaAbandonoTrabajo.obligatorio ? "*" : ""
                }`}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="cuitOcurrencia"
                name="cuitOcurrencia"
                type="text"
                label={`CUIT ocurrencia ${
                  fields.cuitOcurrencia.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="centroAsistencial"
                name="centroAsistencial"
                type="text"
                label={`Centro asistencial ${
                  fields.centroAsistencial.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="obraSocial"
                name="obraSocial"
                type="text"
                label={`Obra social ${
                  fields.obraSocial.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="denunciaPolicial"
                name="denunciaPolicial"
                type="text"
                label={`Denuncia Policial ${
                  fields.denunciaPolicial.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="comisaria"
                name="comisaria"
                type="text"
                label={`Comisaría ${fields.comisaria.obligatorio ? "*" : ""}`}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                id="comoOcurrioAccidenteo"
                name="comoOcurrioAccidente"
                type="text"
                variant="outlined"
                label={`Descripción del siniestro ${
                  fields.comoOcurrioAccidente.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Siguiente" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DatosDenunciaForm;
