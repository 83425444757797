import { Button, Typography } from "@material-ui/core";
import DropZone from "../../../commons/dropZone/DropZone";

const AdjuntarNteFirmadoContainer = ({
  file,
  setFile,
  isLoading,
  handleClickEnviar,
}) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        <Typography variant="h6">
          Adjunte el NTE firmado para realizar la Presentación completa.
        </Typography>
      </div>
      <DropZone
        file={file}
        setFile={setFile}
        loading={isLoading}
        maxFiles={1}
        colorearArchivosAdjuntos={true}
        extension=".pdf"
        accept={{
          "application/pdf": [".pdf"],
        }}
        dropZoneText="Arrastre el NTE firmado en formato .pdf aquí o haga click en el recuadro para
    adjuntarlo."
        dropZoneEditText="Si desea cambiar el archivo arrastrelo nuevamente o haga click en
    el recuadro."
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickEnviar}
          disabled={!file || isLoading}
        >
          Enviar
        </Button>
      </div>
    </>
  );
};

export default AdjuntarNteFirmadoContainer;
