import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperOutlined: {
    flex: 1,
    borderRadius: 5,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
  },
}));

const HeaderLabelEstablecimientoDetalle = ({ establecimiento }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={true}>
        <Paper elevation={2} className={classes.paperOutlined}>
          <div className={classes.headerDiv}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Typography>
                <strong>{establecimiento?.matrizUnica}</strong>
              </Typography>
              <Typography style={{ paddingLeft: 30 }}>
                <strong>{establecimiento?.nombre}</strong>
              </Typography>
            </div>
            <Typography style={{ paddingLeft: 20 }}>
              {establecimiento?.domicilio}
            </Typography>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HeaderLabelEstablecimientoDetalle;
