import { TextField } from "@material-ui/core";
import React from "react";
import { useField } from "formik";

const FormikTextField = (props) => {
  const [field, meta] = useField(props);
  const handleBlur = (e) => {
    field.onBlur(e);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };
  return (
    <TextField
      error={meta.touched && meta.error ? true : false}
      helperText={
        meta.touched && meta.error
          ? meta.error
          : props.helperText
          ? props.helperText
          : null
      }
      {...props}
      {...field}
      onBlur={handleBlur}
    />
  );
};

export default FormikTextField;
