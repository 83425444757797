import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentesCausantes,
  getFormasAccidente,
  getSubtiposSiniestro,
  saveAccidenteTrabajo,
} from "../../../../../redux/actions/denuncia";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DiagnosticoAccidenteContainer from "../diagnosticoAccidenteForm/DiagnosticoAccidenteContainer";
import AccidenteTrabajoForm from "./AccidenteTrabajoForm";

const initialValues = {
  subTipoAccidente: "",
  ocurrenciaViaPublica: false,
  teletrabajo: false,
  agenteCausante: "",
  formaAccidente: "",
};

const AccidenteTrabajoContainer = ({
  idTipoSiniestro,
  handleSubmitAllForm,
}) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.denuncia.nomina);
  const listaSubtipoAccidente = useSelector(
    (state) => state.denuncia.subtiposSiniestro
  );
  const listaAgenteMaterial = useSelector(
    (state) => state.denuncia.agentesCausantes
  );
  const listaFormaAccidente = useSelector(
    (state) => state.denuncia.formasAccidente
  );

  const [formModel, setFormModel] = useState(null);
  const [openTable, setOpenTable] = useState(false);

  useEffect(() => {
    if (idTipoSiniestro) {
      dispatch(getSubtiposSiniestro(idTipoSiniestro));
    }
    dispatch(getAgentesCausantes());
    dispatch(getFormasAccidente());
  }, [idTipoSiniestro]);

  const handleSubmit = (data) => {
    dispatch(saveAccidenteTrabajo(data));
    setOpenTable(true);
  };

  useEffect(() => {
    if (nomina.data) {
      setFormModel(() => ({
        ...initialValues,
        subTipoAccidente:
          nomina.data.subtipoAccidente?.valor || initialValues.subTipoAccidente,
        ocurrenciaViaPublica:
          nomina.data.ocurrenciaViaPublica?.valor ||
          initialValues.ocurrenciaViaPublica,
        teletrabajo:
          nomina.data.teletrabajo?.valor || initialValues.teletrabajo,
        agenteCausante:
          nomina.data.agenteMaterial?.valor || initialValues.agenteCausante,
        formaAccidente:
          nomina.data.comoOcurrioAccidente?.valor ||
          initialValues.formaAccidente,
      }));
    }
  }, [nomina.data]);

  return (
    <>
      {!openTable ? (
        <>
          {formModel != null ? (
            <>
              <HeaderForm title="Accidente de Trabajo" />
              <AccidenteTrabajoForm
                handleSubmit={handleSubmit}
                model={formModel}
                fields={nomina.data}
                listaSubTipoAccidente={listaSubtipoAccidente.data}
                listaAgenteMaterial={listaAgenteMaterial.data}
                listaFormaAccidente={listaFormaAccidente.data}
              />
            </>
          ) : (
            <CircularLoading />
          )}
        </>
      ) : (
        <DiagnosticoAccidenteContainer
          handleSubmitAllForm={handleSubmitAllForm}
        />
      )}
    </>
  );
};

export default AccidenteTrabajoContainer;
