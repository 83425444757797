import React from "react";
import FormikAutocomplete from "../../../commons/formik/FormikAutocomplete";
import { Form, Formik } from "formik";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import CircularLoading from "../../../commons/loading/CircularLoading";
import { useSelector } from "react-redux";

const initialValues = {
  establecimiento: "",
};

const validationSchema = Yup.object().shape({
  establecimiento: Yup.string().required("Campo Obligatorio").nullable(),
});

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: 35,
    display: "flex",
  },
  button: {
    marginLeft: 20,
    width: 80,
  },
  input: {
    display: "flex",
    flexDirection: "column",
    width: 750,
  },
}));

const SearchEstablecimiento = ({
  options,
  loading,
  handleSearch,
  searchModel,
  setSelectedValue,
  searchedRgrl,
  setOpenConfirmDialog,
}) => {
  const classes = useStyles();

  const hasDatosPost = useSelector((state) =>
    state.rgrl.postRgrl.decretoForm?.id ? true : false
  );

  return (
    <div>
      {loading ? (
        <CircularLoading />
      ) : (
        <Formik
          initialValues={searchModel || initialValues}
          onSubmit={handleSearch}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          <Form className={classes.formContainer}>
            <div className={classes.inputContainer}>
              <FormikAutocomplete
                className={classes.input}
                name="establecimiento"
                variant="standard"
                options={options}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
                callBack={(values) => {
                  if (searchedRgrl && hasDatosPost) {
                    setOpenConfirmDialog(true);
                    setSelectedValue(values?.id);
                  } else if (!hasDatosPost && values) {
                    setSelectedValue(values?.id);
                    handleSearch({ establecimiento: values?.id });
                  } else {
                    setSelectedValue("");
                    handleSearch({ establecimiento: null });
                  }
                }}
              />
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default SearchEstablecimiento;
