import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormulariosPrevencionEstablecimiento } from "../../../../redux/actions/establecimiento";
import CardContainer from "../../../commons/cards/CardContainer";
import FormulariosPrevencionCard from "./cards/FormulariosPrevencionCard";
import AyudaLabelEstablecimientoDetalle from "./label/AyudaLabelEstablecimientoDetalle";
import HeaderLabelEstablecimientoDetalle from "./label/HeaderLabelEstablecimientoDetalle";

const EstadoEstablecimientoDetalle = ({
  establecimiento,
  formularioSeleccionadoAdjuntar,
  setIsModalAdjuntarFormOpen,
  handleImprimirFormulario,
  handleVisualizarFormulario,
}) => {
  const dispatch = useDispatch();

  const formulariosPrevencion = useSelector(
    (state) => state.establecimiento.formulariosPrevencion
  );

  useEffect(() => {
    dispatch(
      getFormulariosPrevencionEstablecimiento(
        establecimiento?.idDependenciaEmpleador
      )
    );
  }, [establecimiento, dispatch]);

  return (
    <>
      <HeaderLabelEstablecimientoDetalle establecimiento={establecimiento} />
      <AyudaLabelEstablecimientoDetalle />
      <CardContainer
        title="Formularios de Prevención"
        loading={formulariosPrevencion.loading}
        componente={
          <FormulariosPrevencionCard
            data={formulariosPrevencion.data}
            formularioSeleccionadoAdjuntar={formularioSeleccionadoAdjuntar}
            setIsModalAdjuntarFormOpen={setIsModalAdjuntarFormOpen}
            handleImprimirFormulario={handleImprimirFormulario}
            handleVisualizarFormulario={handleVisualizarFormulario}
          />
        }
      />
    </>
  );
};

export default EstadoEstablecimientoDetalle;
