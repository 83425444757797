import { Typography, makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { lighten } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    margin: 25,
    padding: 10,
    backgroundColor: lighten(theme.palette.tertiary.main, 0.8),
    width: "fit-content",
    borderRadius: 10,
    color: theme.palette.tertiary.main,
  },
  icon: {
    marginRight: 15,
  },
}));

const RequeridoLabel = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.labelContainer}>
      <ErrorOutlineIcon className={classes.icon} />
      <Typography>{text}</Typography>
    </div>
  );
};

export default RequeridoLabel;
