import React, { useState } from "react";
import { useSelector } from "react-redux";
import ResumenGestionTable from "./ResumenGestionTable";
import TrabajadoresContainer from "./trabajadores/TrabajadoresContainer";

const ResumenGestionContainer = () => {
  const lotes = useSelector(
    (state) => state.examenesPeriodicos.lotesExamenesPeriodicos
  );

  const [displayTrabajadores, setDisplayTrabajadores] = useState("block");
  const [selectedLote, setSelectedLote] = useState(null);

  const handleVerTrabajadores = (loteId) => {
    setSelectedLote(loteId);
    setDisplayTrabajadores("none");
  };

  return (
    <>
      {displayTrabajadores === "none" && (
        <TrabajadoresContainer
          handleGoBack={() => setDisplayTrabajadores("block")}
          selectedLote={selectedLote}
        />
      )}
      <ResumenGestionTable
        display={displayTrabajadores}
        data={lotes.data}
        loading={lotes.loading}
        handleViewDetails={handleVerTrabajadores}
      />
    </>
  );
};

export default ResumenGestionContainer;
