import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  grupoTitulo: {
    display: "flex",
    margin: 15,
    color: `${theme.palette.primary.main}`,
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
  },
}));
const TableTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.grupoTitulo}>
      {title}
    </Typography>
  );
};

export default TableTitle;
