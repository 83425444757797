import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SquareIcon from "@mui/icons-material/Square";

const useStyles = makeStyles((theme) => ({
  gridItemAligned: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  gridRowItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

const AyudaLabelEstablecimientoDetalle = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} direction="column" style={{ marginTop: 10 }}>
        <Grid container item spacing={2} xs={12} nowrap="true">
          <Grid item xs={4} className={classes.gridRowItem}>
            <SquareIcon
              className={classes.gridItemAligned}
              style={{ color: "#d52b34" }}
            />
            <Typography>
              El empleador debe adjuntar el formulario firmado
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridRowItem}>
            <SquareIcon
              className={classes.gridItemAligned}
              style={{ color: "#424db8" }}
            />
            <Typography>
              El área de prevención está verificando la presentación
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.gridRowItem}>
            <SquareIcon className={classes.gridItemAligned} />
            <Typography>Formulario con estado finalizado</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12} nowrap="true">
          <Grid item xs={4} className={classes.gridRowItem}>
            <PrintIcon
              className={classes.gridItemAligned}
              style={{
                color: `${theme.palette.secondary.light}`,
              }}
            />
            <Typography>Impresión del formulario para su firma</Typography>
          </Grid>
          <Grid item xs={5} className={classes.gridRowItem}>
            <UploadFileIcon
              className={classes.gridItemAligned}
              style={{ color: "#d52b34" }}
            />
            <Typography>
              Adjuntar el formulario firmado para finalizar la presentación
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.gridRowItem}>
            <FileOpenIcon
              className={classes.gridItemAligned}
              style={{ color: `${theme.palette.secondary.light}` }}
            />
            <Typography>Visualizar formulario cargado</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AyudaLabelEstablecimientoDetalle;
