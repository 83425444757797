import {
  CLEAR_EXAMENES_PERIODICOS,
  CLEAR_SEARCH_PARAMS,
  GET_DATOS_ESTABLECIMIENTO,
  GET_ESTABLECIMIENTOS,
  GET_LOTES_EXAMENES_PERIODICOS,
  GET_NOMINAS_LOTES,
  GET_NOTIFICACIONES_Y_CITAS,
  GET_SINIESTROS_EXAMENES_PERIODICOS,
  SAVE_SEARCH_PARAMS,
} from "../actions/examenesPeriodicos";

const INITIAL_STATE = {
  establecimientos: {
    loading: false,
    error: false,
    data: [],
  },
  datosEstablecimiento: {
    loading: false,
    error: false,
    data: [],
  },
  lotesExamenesPeriodicos: {
    loading: false,
    error: false,
    data: [],
  },
  nominasLotes: {
    loading: false,
    error: false,
    data: [],
  },
  siniestros: {
    loading: false,
    error: false,
    data: [],
  },
  notificaciones: {
    loading: false,
    error: false,
    data: [],
  },
  searchParams: null,
};

const examenesPeriodicos = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ESTABLECIMIENTOS}_PENDING`:
      return {
        ...state,
        establecimientos: {
          ...state.establecimientos,
          error: false,
          loading: true,
        },
      };
    case `${GET_ESTABLECIMIENTOS}_REJECTED`:
      return {
        ...state,
        establecimientos: {
          ...state.establecimientos,
          error: true,
          loading: false,
        },
      };
    case `${GET_ESTABLECIMIENTOS}_FULFILLED`:
      return {
        ...state,
        establecimientos: {
          ...state.establecimientos,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTO}_PENDING`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: true,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTO}_REJECTED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: true,
          loading: false,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTO}_FULFILLED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_LOTES_EXAMENES_PERIODICOS}_PENDING`:
      return {
        ...state,
        lotesExamenesPeriodicos: {
          ...state.lotesExamenesPeriodicos,
          error: false,
          loading: true,
        },
      };
    case `${GET_LOTES_EXAMENES_PERIODICOS}_REJECTED`:
      return {
        ...state,
        lotesExamenesPeriodicos: {
          ...state.lotesExamenesPeriodicos,
          error: true,
          loading: false,
        },
      };
    case `${GET_LOTES_EXAMENES_PERIODICOS}_FULFILLED`:
      return {
        ...state,
        lotesExamenesPeriodicos: {
          ...state.lotesExamenesPeriodicos,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_NOMINAS_LOTES}_PENDING`:
      return {
        ...state,
        nominasLotes: {
          ...state.nominasLotes,
          error: false,
          loading: true,
        },
      };
    case `${GET_NOMINAS_LOTES}_REJECTED`:
      return {
        ...state,
        nominasLotes: {
          ...state.nominasLotes,
          error: true,
          loading: false,
        },
      };
    case `${GET_NOMINAS_LOTES}_FULFILLED`:
      return {
        ...state,
        nominasLotes: {
          ...state.nominasLotes,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_SINIESTROS_EXAMENES_PERIODICOS}_PENDING`:
      return {
        ...state,
        siniestros: {
          ...state.siniestros,
          error: false,
          loading: true,
        },
      };
    case `${GET_SINIESTROS_EXAMENES_PERIODICOS}_REJECTED`:
      return {
        ...state,
        siniestros: {
          ...state.siniestros,
          error: true,
          loading: false,
        },
      };
    case `${GET_SINIESTROS_EXAMENES_PERIODICOS}_FULFILLED`:
      return {
        ...state,
        siniestros: {
          ...state.siniestros,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_NOTIFICACIONES_Y_CITAS}_PENDING`:
      return {
        ...state,
        notificaciones: {
          ...state.notificaciones,
          error: false,
          loading: true,
        },
      };
    case `${GET_NOTIFICACIONES_Y_CITAS}_REJECTED`:
      return {
        ...state,
        notificaciones: {
          ...state.notificaciones,
          error: true,
          loading: false,
        },
      };
    case `${GET_NOTIFICACIONES_Y_CITAS}_FULFILLED`:
      return {
        ...state,
        notificaciones: {
          ...state.notificaciones,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case SAVE_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload,
        },
      };
    case CLEAR_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: null,
      };
    case CLEAR_EXAMENES_PERIODICOS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default examenesPeriodicos;
