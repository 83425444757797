import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDiagnosticosOms } from "../../../../../redux/actions/denuncia";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import EnfermedadTable from "../../commons/EnfermedadTable";

const DiagnosticoEnfermedadContainer = ({ handleSubmitAllForm }) => {
  const dispatch = useDispatch();

  const datosDenuncia = useSelector((state) => state.denuncia.postDenuncia);

  useEffect(() => {
    if (
      datosDenuncia.datosDenuncia &&
      datosDenuncia.datosDenuncia.tipoSiniestro
    ) {
      dispatch(getDiagnosticosOms(datosDenuncia.datosDenuncia.tipoSiniestro));
    }
  }, []);

  return (
    <>
      <HeaderForm title="Diagnóstico de Enfermedad" />
      <EnfermedadTable
        handleSubmit={handleSubmitAllForm}
        data={datosDenuncia.diagnosticoEnfermedad}
      />
    </>
  );
};

export default DiagnosticoEnfermedadContainer;
