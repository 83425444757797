import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { addMonths } from "date-fns";
import { FieldArray, Form, Formik } from "formik";
import FormikActions from "../../../commons/formik/FormikActions";
import FormikDatePicker from "../../../commons/formik/FormikDatePicker";
import RequeridoLabel from "../../../commons/labels/RequeridoLabel";
import CustomStepper from "../../../commons/stepper/CustomStepper";
import TableTitle from "../commons/TableTitle";
import { validatePreguntaTable } from "../validation/formValidation";

const columns = [
  { id: "pregunta", label: "Pregunta" },
  { id: "si", label: "Sí" },
  { id: "no", label: "No" },
  { id: "noAplica", label: "N/A" },
  { id: "fechaRegulacion", label: "Fecha Regulación" },
  { id: "fechaLimiteVerificacion", label: "Fecha Lim. Verificación" },
];

const initialValues = {
  idItemFormularioArt: "",
  secuencia: "",
  pregunta: "",
  resultado: "2",
  fechaRegulacion: null,
  fechaLimiteVerificacion: null,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 10,
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 10,
  },
  tableContainer: {
    border: "2px solid primary",
  },
}));

const PreguntaTable = ({
  handleSubmit,
  data,
  contador,
  totalGrupos,
  handleGoBack,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableTitle title={data.nombre} />
      <Formik
        initialValues={{
          idGrupoItemFormularioArt: data.idGrupoItemFormularioArt,
          itemsFormularioArt: data.itemsFormularioArt.reduce(
            (itemsFormularioArt, pregunta, index) => {
              itemsFormularioArt[index] = { ...initialValues, ...pregunta };
              return itemsFormularioArt;
            },
            []
          ),
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validatePreguntaTable}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FieldArray name="itemsFormularioArt">
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.itemsFormularioArt.map((pregunta, preguntaIndex) => (
                      <TableRow key={preguntaIndex}>
                        <TableCell>{pregunta.pregunta}</TableCell>
                        <TableCell>
                          <RadioGroup
                            name={`itemsFormularioArt.${preguntaIndex}.resultado`}
                            value="1"
                            onChange={(props) => {
                              handleChange(props);
                              setFieldValue(
                                `itemsFormularioArt.${preguntaIndex}.fechaRegulacion`,
                                null
                              );
                              setFieldValue(
                                `itemsFormularioArt.${preguntaIndex}.fechaLimiteVerificacion`,
                                null
                              );
                            }}
                          >
                            <FormControlLabel
                              value="1"
                              control={
                                <Radio
                                  checked={
                                    values.itemsFormularioArt[preguntaIndex]
                                      ?.resultado === "1"
                                  }
                                />
                              }
                            />
                          </RadioGroup>
                        </TableCell>
                        <TableCell>
                          <RadioGroup
                            name={`itemsFormularioArt.${preguntaIndex}.resultado`}
                            value="0"
                            onChange={(props) => {
                              handleChange(props);
                              setFieldValue(
                                `itemsFormularioArt.${preguntaIndex}.fechaRegulacion`,
                                addMonths(new Date(), 11)
                              );
                              setFieldValue(
                                `itemsFormularioArt.${preguntaIndex}.fechaLimiteVerificacion`,
                                addMonths(new Date(), 13)
                              );
                            }}
                          >
                            <FormControlLabel
                              value="0"
                              control={
                                <Radio
                                  checked={
                                    values.itemsFormularioArt[preguntaIndex]
                                      ?.resultado === "0"
                                  }
                                />
                              }
                            />
                          </RadioGroup>
                        </TableCell>
                        <TableCell>
                          <RadioGroup
                            name={`itemsFormularioArt.${preguntaIndex}.resultado`}
                            value="2"
                            onChange={(props) => {
                              handleChange(props);
                              setFieldValue(
                                `itemsFormularioArt.${preguntaIndex}.fechaRegulacion`,
                                null
                              );
                              setFieldValue(
                                `itemsFormularioArt.${preguntaIndex}.fechaLimiteVerificacion`,
                                null
                              );
                            }}
                          >
                            <FormControlLabel
                              value="2"
                              control={
                                <Radio
                                  checked={
                                    values.itemsFormularioArt[preguntaIndex]
                                      ?.resultado === "2"
                                  }
                                />
                              }
                            />
                          </RadioGroup>
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          <FormikDatePicker
                            name={`itemsFormularioArt.${preguntaIndex}.fechaRegulacion`}
                            fullWidth
                            disablePast
                            disabled={
                              values.itemsFormularioArt[preguntaIndex]
                                ?.resultado !== "0"
                            }
                          />
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          <FormikDatePicker
                            name={`itemsFormularioArt.${preguntaIndex}.fechaLimiteVerificacion`}
                            fullWidth
                            disablePast
                            disabled={
                              values.itemsFormularioArt[preguntaIndex]
                                ?.resultado !== "0"
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <CustomStepper contador={contador} maxGrupos={totalGrupos} />
                <div className={classes.buttonContainer}>
                  {contador === totalGrupos - 1 && (
                    <RequeridoLabel text="Al enviar el formulario no podrá modificar sus respuestas" />
                  )}
                  <FormikActions
                    submit={
                      contador === totalGrupos - 1
                        ? "Enviar formulario"
                        : "Grupo siguiente"
                    }
                    handleCancel={
                      contador !== 0 ? () => handleGoBack(values) : null
                    }
                    cancel={contador !== 0 ? "Grupo anterior" : null}
                  />
                </div>
              </TableContainer>
            </FieldArray>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PreguntaTable;
