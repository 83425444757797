import { Typography } from "@material-ui/core";
import React from "react";

const ReintegrosSteps = ({ actualStep, lastStep }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography variant="h5">
        Paso {actualStep} de {lastStep}:
      </Typography>
    </div>
  );
};

export default ReintegrosSteps;
