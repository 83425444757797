import { Paper, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearReintegros,
  postReintegros,
} from "../../redux/actions/reintegros";
import { convertDateTimeToNet } from "../../util/datetime";
import Breadcrumb from "../commons/breadcrumb/Breadcrumb";
import HeaderForm from "../commons/labels/HeaderForm";
import BeneficiarioContainer from "./beneficiarios/BeneficiarioContainer";
import ComprobanteContainer from "./comprobante/ComprobanteContainer";
import DatosComprobanteContainer from "./datosComprobante/DatosComprobanteContainer";
import SiniestroContainer from "./siniestro/SiniestroContainer";
import ReintegrosSteps from "./steps/ReintegrosSteps";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: { display: "flex", margin: 15 },
}));

const ReintegrosContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postReintegro = useSelector((state) => state.reintegros.postReintegro);
  const localSiniestro = useSelector(
    (state) => state.reintegros.localSiniestro
  );

  const [form, setForm] = useState("Siniestro");
  const [fromBread, setFromBread] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);

  const pasoActualBreadcrumbs = breadcrumbFields.indexOf(form) + 1;

  useEffect(() => {
    dispatch(clearReintegros());
    return () => {
      dispatch(clearReintegros());
    };
  }, []);

  const handleSetForm = (form) => {
    if (!breadcrumbFields.find((e) => e === form)) {
      const auxArray = [...breadcrumbFields, form];
      setBreadcrumbFields(auxArray);
    }
    setForm(form);
  };

  const handleSumbitLastForm = (values) => {
    const auxArray = [];
    postReintegro.comprobantes.data.forEach((element) => {
      let numeroFloat = parseFloat(element.importe.replace(",", "."));
      let numeroConDecimales = numeroFloat.toString().split(".")[1];
      let numeroDeDecimales = numeroConDecimales
        ? numeroConDecimales.length
        : 0;
      let numeroCon4Decimales = numeroFloat.toFixed(
        Math.min(numeroDeDecimales, 4)
      );
      let newElement = {
        idRubroReintegro: element.rubro,
        importe: parseFloat(numeroCon4Decimales),
      };
      auxArray.push(newElement);
    });
    const aux = {
      idSiniestroCabecera: localSiniestro.idSiniestroCabecera,
      idTipoDestinatarioReintegro: postReintegro.beneficiario.idSeleccionado,
      idDestinatario:
        postReintegro.beneficiario.idSeleccionado === 2
          ? localSiniestro.idEmpleador
          : localSiniestro.idTrabajador,
      cbu: postReintegro.beneficiario.cbu,
      idTipoComprobante: postReintegro.datosComprobante.data.tipoComprobante,
      fechaComprobante: convertDateTimeToNet(
        postReintegro.datosComprobante.data.fechaDelComprobante
      ),
      puntoVenta: parseInt(postReintegro.datosComprobante.data.ptoVenta),
      numeroComprobante: parseInt(postReintegro.datosComprobante.data.nro),
      importeTotal: postReintegro.comprobantes.total,
      adjunto: {
        base64: values[0].value,
        extension: values[0].base64Header,
      },
      detallesReintegroSiniestro: [...auxArray],
    };
    dispatch(postReintegros(aux)).then((res) => {
      toast.success("Reintegro enviado con exito");
      navigate("/");
    });
  };

  return (
    <Paper style={{ overflowX: "hidden" }}>
      <HeaderForm title="Solicitud de Reintegro" />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
        />
      </div>
      <div>
        <ReintegrosSteps actualStep={pasoActualBreadcrumbs} lastStep={4} />
      </div>
      {
        {
          Siniestro: (
            <SiniestroContainer
              nextForm={handleSetForm}
              fromBread={fromBread}
            />
          ),
          Beneficiario: (
            <BeneficiarioContainer
              nextForm={handleSetForm}
              fromBread={fromBread}
            />
          ),
          DatosComprobante: (
            <DatosComprobanteContainer
              nextForm={handleSetForm}
              fromBread={fromBread}
            />
          ),
          Comprobante: (
            <ComprobanteContainer
              handleSubmit={handleSumbitLastForm}
              fromBread={fromBread}
            />
          ),
        }[form]
      }
    </Paper>
  );
};

export default ReintegrosContainer;
