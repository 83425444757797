import { Dialog, makeStyles, useTheme } from "@material-ui/core";
import RequeridoLabel from "../../../../commons/labels/RequeridoLabel";
import CircularLoading from "../../../../commons/loading/CircularLoading";

const useStyles = makeStyles((theme) => ({
  adjuntarFormularioDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loadingContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DialogDescargarFormulario = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.adjuntarFormularioDialog}
        fullWidth
        maxWidth="md"
      >
        <div className={classes.loadingContainer}>
          <CircularLoading
            style={{ color: `${theme.palette.tertiary.main}` }}
          />
          <RequeridoLabel text="Descargando el formulario..." />
        </div>
      </Dialog>
    </>
  );
};

export default DialogDescargarFormulario;
