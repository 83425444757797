import axios from "axios";

export const GET_ESTABLECIMIENTOS = "GET_ESTABLECIMIENTOS";
export const GET_DATOS_ESTABLECIMIENTO = "GET_DATOS_ESTABLECIMIENTO";
export const GET_NOMINAS_LOTES = "GET_NOMINAS_LOTES";
export const GET_NOTIFICACIONES_Y_CITAS = "GET_NOTIFICACIONES_Y_CITAS";
export const GET_LOTES_EXAMENES_PERIODICOS = "GET_LOTES_EXAMENES_PERIODICOS";
export const GET_SINIESTROS_EXAMENES_PERIODICOS =
  "GET_SINIESTROS_EXAMENES_PERIODICOS";
export const SAVE_SEARCH_PARAMS = "SAVE_SEARCH_PARAMS";
export const CLEAR_EXAMENES_PERIODICOS = "CLEAR_EXAMENES_PERIODICOS";
export const CLEAR_SEARCH_PARAMS = "CLEAR_EXAMENES_PERIODICOS";

export const getEstablecimientos = () => (dispatch) => {
  return dispatch({
    type: GET_ESTABLECIMIENTOS,
    payload: axios
      .get(`empleador/dependenciasEmpleador`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getDatosEstablecimiento = (idEstablecimiento) => (dispatch) => {
  return dispatch({
    type: GET_DATOS_ESTABLECIMIENTO,
    payload: axios
      .get(
        `dependenciaEmpleador/datosBasicos?idDependenciaEmpleador=${idEstablecimiento}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getNominasLotes = (idLote, idDependencia) => (dispatch) => {
  return dispatch({
    type: GET_NOMINAS_LOTES,
    payload: axios
      .get(
        `examen/nominasLote?idDependenciaEmpleador=${idDependencia}&idLote=${idLote}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

/**
 * @param {number} idDependencia - El ID del establecimiento
 * @param {string} fechaDesde - Formato YYYY-MM-DDTHH:mm
 * @param {string} fechaHasta - Formato YYYY-MM-DDTHH:mm
 */
export const getNotificacionesYCitas =
  (idDependencia, fechaDesde, fechaHasta) => (dispatch) => {
    return dispatch({
      type: GET_NOTIFICACIONES_Y_CITAS,
      payload: axios
        .get(
          `notificacionFehaciente/notificacionesExamen?idDependenciaEmpleador=${idDependencia}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

/**
 * @param {number} idDependencia - El ID del establecimiento
 * @param {string} fechaDesde - Formato YYYY-MM-DDTHH:mm
 * @param {string} fechaHasta - Formato YYYY-MM-DDTHH:mm
 */
export const getLotesExamenesPeriodicos =
  (idDependencia, fechaDesde, fechaHasta) => (dispatch) => {
    return dispatch({
      type: GET_LOTES_EXAMENES_PERIODICOS,
      payload: axios
        .get(
          `examen/resumenLotesExamen?idDependenciaEmpleador=${idDependencia}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

/**
 * @param {number} idDependencia - El ID del establecimiento
 * @param {string} fechaDesde - Formato YYYY-MM-DDTHH:mm
 * @param {string} fechaHasta - Formato YYYY-MM-DDTHH:mm
 */
export const getSiniestrosExamenesPeriodicos =
  (idDependencia, fechaDesde, fechaHasta) => (dispatch) => {
    return dispatch({
      type: GET_SINIESTROS_EXAMENES_PERIODICOS,
      payload: axios
        .get(
          `denuncia/siniestrosCabeceraEPExamen?idDependenciaEmpleador=${idDependencia}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const saveSearchParams = (values) => (dispatch) => {
  return dispatch({
    type: SAVE_SEARCH_PARAMS,
    payload: values,
  });
};

export const clearExamenesPeriodicos = () => (dispatch) => {
  return dispatch({
    type: CLEAR_EXAMENES_PERIODICOS,
  });
};

export const clearSearchParams = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SEARCH_PARAMS,
  });
};
