import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Header from "../components/commons/header/Header";
import LoadingComponent from "../components/commons/loading/LoadingComponent";
import ResponsiveDrawer from "../components/commons/menuAppBar/Drawer";
import config from "../config/config";
import { isActiveToken } from "../redux/actions/auth";
import { getAppLogo } from "../redux/actions/logo";
import { changeDrawerStatus } from "../redux/actions/userPreferences";
import AuthRoutes from "./AuthRoutes";
import PublicRoutes from "./PublicRoutes";

const useStyles = (isAuthenticated) =>
  makeStyles((theme) => ({
    content: {
      flexGrow: 1,
      padding: isAuthenticated && theme.spacing(2),
      width: theme.spacing(1),
      overflow: "auto",
    },
    root: {
      display: "flex",
      flex: 1,
      marginTop: isAuthenticated ? "58px" : 0,
      backgroundColor: "#f5f6fa",
    },
  }));

const Routing = () => {
  const dispatch = useDispatch();

  const logo = useSelector((state) => state.logo.getLogo);
  const auth = useSelector((state) => state.auth);
  const drawerStatus = useSelector((state) => state.userPreferences.drawer);

  const [activeToken, setActiveToken] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(drawerStatus);

  const isAuthenticated = auth.authenticated && activeToken;
  const classes = useStyles(isAuthenticated)();

  axios.defaults.baseURL = config.baseURL;

  if (auth.authenticated) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.data.token}`;
  }

  useEffect(() => {
    dispatch(getAppLogo());
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      dispatch(isActiveToken()).then(() => setActiveToken(true));
    }
  }, [auth.authenticated]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    dispatch(changeDrawerStatus(!mobileOpen));
  };

  if (logo.loading)
    return (
      <div className={classes.content}>
        <LoadingComponent />
      </div>
    );

  return (
    <div className={classes.root}>
      <BrowserRouter>
        {isAuthenticated && (
          <>
            <Header handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
            <ResponsiveDrawer
              open={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
          </>
        )}
        <main className={classes.content}>
          {isAuthenticated ? <AuthRoutes data={auth.data} /> : <PublicRoutes />}
        </main>
      </BrowserRouter>
    </div>
  );
};

export default Routing;
