import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addLocalContratista,
  deleteLocalContratista,
} from "../../../../redux/actions/rgrl";
import { validarCuilCuit } from "../../../../util/cuilcuit";
import ContratistasTable from "./ContratistasTable";
import AddContratistaDialog from "./dialog/AddContratistaDialog";

const ContratistasContainer = ({ nextForm }) => {
  const dispatch = useDispatch();
  const contratistasTable = useSelector(
    (state) => state.rgrl.postRgrl.contratistas
  );

  const [openAddContratista, setOpenAddContratista] = useState(false);

  const handleSubmit = () => {
    nextForm("Responsables");
  };

  const handleAddContratista = (values) => {
    if (validarCuilCuit(values.cuit)) {
      if (!contratistasTable.some((c) => c.cuit === values.cuit)) {
        dispatch(addLocalContratista(values));
        setOpenAddContratista(false);
      } else {
        toast.error("El contratista ya se encuentra agregado.");
      }
    } else {
      toast.error(
        "Por favor, ingrese un CUIL válido para continuar con la búsqueda"
      );
    }
  };

  const handleDeleteContratista = (values) => {
    dispatch(deleteLocalContratista(values.cuit));
  };

  const handleClose = () => {
    setOpenAddContratista(false);
  };
  const handleOpen = () => {
    setOpenAddContratista(true);
  };

  return (
    <>
      <ContratistasTable
        data={contratistasTable}
        handleSubmit={handleSubmit}
        handleDeleteContratista={handleDeleteContratista}
        handleOpen={handleOpen}
      />
      <AddContratistaDialog
        open={openAddContratista}
        handleClose={handleClose}
        handleSumbit={handleAddContratista}
      />
    </>
  );
};

export default ContratistasContainer;
