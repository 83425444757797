import React from "react";
import BackArrowHeader from "../../../commons/arrow/BackArrowHeader";
import CustomTab from "../../../commons/tab/CustomTab";
import DatosDelSiniestro from "./tabOptions/datosDelSiniestro/DatosDelSiniestro";
import Dinerarias from "./tabOptions/dinerarias/Dinerarias";
import DocumentacionMedica from "./tabOptions/documentacionMedica/DocumentacionMedica";
import Notificaciones from "./tabOptions/notificaciones/Notificaciones";

const optionsTabs = [
  {
    value: "1",
    label: "Datos del siniestro",
    component: (selectedAccident) => (
      <DatosDelSiniestro selectedAccident={selectedAccident} />
    ),
    wrapped: false,
    disabled: false,
  },
  {
    value: "2",
    label: "Documentación médica",
    component: (selectedAccident) => (
      <DocumentacionMedica selectedAccident={selectedAccident} />
    ),
    wrapped: false,
    disabled: false,
  },
  {
    value: "3",
    label: "Notificaciones Y Citas",
    component: (selectedAccident) => (
      <Notificaciones selectedAccident={selectedAccident} />
    ),
    wrapped: false,
    disabled: false,
  },
  {
    value: "4",
    label: "Dinerarias",
    component: (selectedAccident) => (
      <Dinerarias selectedAccident={selectedAccident} />
    ),
    wrapped: false,
    disabled: false,
  },
];

const configurationTabs = {
  textColor: "primary",
  indicatorColor: "primary",
  centered: true,
  variant: null,
  orientation: null,
};

const DatosSiniestroContainer = ({ setDisplayAccidents, selectedAccident }) => {
  const onBackClick = () => {
    setDisplayAccidents("block");
  };

  return (
    <>
      <CustomTab
        optionsTabs={optionsTabs}
        configurationTabs={configurationTabs}
        selectedAccident={selectedAccident}
        backArrow={<BackArrowHeader onClick={onBackClick} />}
        withLabelData={true}
      />
    </>
  );
};

export default DatosSiniestroContainer;
