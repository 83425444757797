import { Button, Typography, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import CircularLoading from "../../../../commons/loading/CircularLoading";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  title: {
    width: "95%",
    margin: "auto",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "3vw",
    padding: 10,
  },
  datesInputsContainer: {
    display: "flex",
    margin: "auto",
    gap: "5vw",

    // para que en la vista mobile, los inputs de fecha
    // esten uno arriba del otro. Son mas faciles de
    // clickear y se ven con mas espacio
    [theme.breakpoints.down(601)]: {
      flexFlow: "column wrap",
      gap: "1vw",
    },
  },
  datePicker: {
    display: "flex",
    flexDirection: "column",
  },
  textInputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  },
}));

const validationSchema = yup.object({
  fechaViajeDesde: yup
    .date()
    .required("Campo obligatorio")
    .nullable()
    .typeError("Fecha inválida"),
  fechaViajeHasta: yup
    .date()
    .required("Campo obligatorio")
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaViajeHasta",
      message: "La fecha hasta debe ser mayor a la fecha desde",
      test: function (value) {
        return value && this.parent.fechaViajeDesde
          ? value > this.parent.fechaViajeDesde
          : true;
      },
    }),
  destino: yup
    .string()
    .required("Campo obligatorio")
    .max(255, "El campo debe contener maxino 255 caracteres"),
  coberturaSaludExterior: yup
    .string()
    .required("Campo obligatorio")
    .max(1000, "El campo debe contener maxino 1000 caracteres"),
});

const DatosDelViaje = ({ handleSubmit, formModel }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        {formModel ? (
          <Formik
            initialValues={formModel}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className={classes.form}>
              <div>
                <Typography>Fecha del Viaje</Typography>
                <div className={classes.datesInputsContainer}>
                  <div className={classes.datePicker}>
                    <FormikDatePicker
                      name="fechaViajeDesde"
                      label="Fecha desde *"
                      ampm={false}
                      format="dd/MM/yyyy"
                      variant="inline"
                    />
                  </div>
                  <div className={classes.datePicker}>
                    <FormikDatePicker
                      name="fechaViajeHasta"
                      label="Fecha hasta *"
                      ampm={false}
                      format="dd/MM/yyyy"
                      variant="inline"
                    />
                  </div>
                </div>
              </div>
              <FormikTextField
                fullWidth={true}
                id="destino"
                name="destino"
                type="text"
                label="Destino *"
                variant="outlined"
              />
              <FormikTextField
                fullWidth={true}
                id="coberturaSaludExterior"
                name="coberturaSaludExterior"
                type="text"
                label="Cobertura médica en el exterior *"
                variant="outlined"
              />
              <div className={classes.button}>
                <Button type="submit" color="primary" variant="contained">
                  Siguiente
                </Button>
              </div>
            </Form>
          </Formik>
        ) : (
          <CircularLoading />
        )}
      </div>
    </div>
  );
};

export default DatosDelViaje;
