import React from "react";
import GremialistasTable from "./GremialistasTable";
import { useSelector } from "react-redux";

const GremialistasContainer = ({ nextForm }) => {
  const gremialistas = useSelector((state) => state.rgrl.postRgrl.gremialistas);

  const handleSubmit = () => {
    nextForm("Contratistas");
  };

  return <GremialistasTable data={gremialistas} handleSubmit={handleSubmit} />;
};
export default GremialistasContainer;
