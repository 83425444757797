import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearInformesMedicos, getInformesMedicos } from "../../../../../../redux/actions/siniestralidad";
import PdfDialog from "../../../../../commons/file/PdfDialog";

const InformesMedicos = ({
  openInformesMedicos,
  handleClose,
  selectedAccident,
}) => {
  const dispatch = useDispatch();

  const informesMedicosRedux = useSelector(
    (state) => state.siniestralidad.informesMedicos
  );
  const siniestralidad = useSelector((state) => state.siniestralidad.datosSiniestro.data)
  useEffect(() => {

    if (openInformesMedicos) {
      dispatch(getInformesMedicos(selectedAccident.idSiniestroCabecera));
    } else {
      dispatch(clearInformesMedicos());
    }
  }, [openInformesMedicos]);

  const printTitle = `${moment(new Date()).format("DD-MM-YYYY")}_Informes Médicos_${siniestralidad?.nroSiniestroART}`

  return (
    <>
      <PdfDialog
        open={
          openInformesMedicos && !informesMedicosRedux.error
        }
        title="Informes Médicos"
        printTitle={printTitle}
        handleClose={handleClose}
        controllers={true}
        file={informesMedicosRedux.data.base64}
        paginated={false}
        printable
        acceptButton={false}
        closeButton
      />
    </>
  );
};

export default InformesMedicos;
