import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Form, Formik } from "formik";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikActions from "../../../../commons/formik/FormikActions";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getGremialistas } from "../../../../../redux/actions/rgrl";
import * as Yup from "yup";

const initialValues = {
  id: "",
  nombre: "",
};

const validationSchema = Yup.object().shape({
  id: Yup.string().required("Campo obligatorio").nullable(),
});

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  input: {
    flex: 1,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
  rightInput: {
    marginLeft: 20,
  },
}));

const AddGremioDialog = ({ open, handleClose, handleSumbit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gremialistas = useSelector((state) => state.rgrl.gremialistas?.data);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Seleccionar Gremialista</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSumbit(values)}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <DialogContent>
              <div>
                <FormikAutocomplete
                  name="id"
                  label="Gremios"
                  className={classes.input}
                  options={gremialistas}
                  renderOption={(option) => option.nombre}
                  filterOptions={(option) => option.nombre}
                  getOptions={() => dispatch(getGremialistas())}
                  callBack={(value) => {
                    if (value) {
                      setFieldValue("nombre", value.nombre);
                    }
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <FormikActions handleCancel={handleClose}></FormikActions>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddGremioDialog;
