import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Form, Formik } from "formik";
import FormikActions from "../../../../commons/formik/FormikActions";
import { makeStyles } from "@material-ui/core";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import * as Yup from "yup";

const initialValues = {
  cuit: "",
  detalle: "",
};

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  input: {
    flex: 1,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
  rightInput: {
    marginLeft: 20,
  },
}));

const addContratistaValidation = Yup.object({
  cuit: Yup.string()
    .matches(/\d{11}/, "Ingrese solo números")
    .required("Campo obligatorio"),
  detalle: Yup.string()
    .max(200, "No se permiten más de 200 caracteres")
    .required("Campo obligatorio"),
});

const AddContratistaDialog = ({ open, handleClose, handleSumbit }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Seleccionar Contratista</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSumbit(values)}
        validationSchema={addContratistaValidation}
      >
        <Form>
          <DialogContent>
            <div className={classes.rowContainer}>
              <FormikTextField name="cuit" label="CUIT" fullWidth />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextField
                name="detalle"
                label="Nombre / Observación"
                fullWidth
                multiline
              />
            </div>
          </DialogContent>
          <DialogActions>
            <FormikActions handleCancel={handleClose}></FormikActions>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddContratistaDialog;
