import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentacionMedicaWithFilter } from "../../../../../../redux/actions/siniestralidad";
import DocumentacionMedicaTable from "./DocumentacionMedicaTable";
import InformesMedicos from "./InformesMedicos";

const initialValues = {
  fechaDesde: moment(new Date()).add(-30, "days"),
  fechaHasta: new Date(),
};

const DocumentacionMedica = ({ selectedAccident }) => {
  const dispatch = useDispatch();

  const documentacionMedicaRedux = useSelector(
    (state) => state.siniestralidad.documentacionMedica
  );

  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  const [openInformesMedicos, setOpenInformesMedicos] = useState(false);

  useEffect(() => {
    handleFilter({
      idSiniestroCabecera: selectedAccident.idSiniestroCabecera,
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
    });
  }, []);

  const handleFilter = (values) => {
    dispatch(
      getDocumentacionMedicaWithFilter({
        idSiniestroCabecera: selectedAccident.idSiniestroCabecera,
        fechaDesde: values.fechaDesde,
        fechaHasta: values.fechaHasta,
      })
    );

    setFechaDesde(values.fechaDesde);
    setFechaHasta(values.fechaHasta);
  };

  const handleGetInformesMedicos = () => {
    setOpenInformesMedicos(true);
  };

  const handleFetchData = () => {
    handleFilter({
      idSiniestroCabecera: selectedAccident.idSiniestroCabecera,
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
    });
  };

  const handleCloseInformesMedicos = () => {
    setOpenInformesMedicos(false);
  };

  return (
    <>
      <DocumentacionMedicaTable
        title="Documentacion Médica"
        data={documentacionMedicaRedux.data}
        fetchData={handleFetchData}
        secondaryTitle={true}
        handleFilter={handleFilter}
        fechaDesde={fechaDesde}
        fechaHasta={fechaHasta}
        loading={documentacionMedicaRedux.loading}
        getInformesMedicos={handleGetInformesMedicos}
        initialValues={initialValues}
      />
      <InformesMedicos
        openInformesMedicos={openInformesMedicos}
        handleClose={handleCloseInformesMedicos}
        selectedAccident={selectedAccident}
      />
    </>
  );
};

export default DocumentacionMedica;
