import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveDatosTrabajador } from "../../../../../redux/actions/denuncia";
import { convertDateTimeToNet } from "../../../../../util/datetime";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DatosTrabajadorForm from "./DatosTrabajadorForm";

const initialValues = {
  nombres: "",
  apellido: "",
  tipoDocumento: "",
  nroDocumento: "",
  nacionalidad: "",
  fechaNacimiento: null,
  genero: "",
  estadoCivil: "",
  telefono: "",
  mail: "",
};

const DatosTrabajadorContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.denuncia.nomina);

  const [formModel, setFormModel] = useState(null);

  const isLoading = !formModel || nomina.loading || nomina.data == null;

  const handleSubmit = (data) => {
    const model = {
      ...data,
      fechaNacimiento: convertDateTimeToNet(data.fechaNacimiento),
    };
    dispatch(saveDatosTrabajador(model));
    nextForm("DireccionTrabajadorForm");
  };

  useEffect(() => {
    if (nomina.data != null) {
      setFormModel(() => ({
        ...initialValues,
        nombres: nomina.data.nombres?.valor || initialValues.nombres,
        apellido: nomina.data.apellido?.valor || initialValues.apellido,
        tipoDocumento:
          nomina.data.tipoDocumento?.valor || initialValues.tipoDocumento,
        nroDocumento:
          nomina.data.nroDocumento?.valor || initialValues.nroDocumento,
        nacionalidad: nomina.data.pais?.valor || initialValues.nacionalidad,
        fechaNacimiento:
          nomina.data.fechaNacimiento?.valor || initialValues.fechaNacimiento,
        genero: nomina.data.genero?.valor || initialValues.genero,
        estadoCivil:
          nomina.data.estadoCivil?.valor || initialValues.estadoCivil,
        telefono: nomina.data.telefono?.valor || initialValues.telefono,
        mail: nomina.data.mail?.valor || initialValues.mail,
      }));
    }
  }, [nomina.data]);

  return (
    <>
      <HeaderForm title="Datos del Trabajador" />

      {!isLoading ? (
        <DatosTrabajadorForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
        />
      ) : (
        <div style={{ padding: "200px 30px 200px 30px" }}>
          <CircularLoading />
        </div>
      )}
    </>
  );
};

export default DatosTrabajadorContainer;
