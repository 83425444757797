import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { getIn, useField, useFormikContext } from "formik";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginLeft: -10,
  },
}));

const FormikCheckbox = (props) => {
  const [field] = useField(props);
  const classes = useStyles();
  const { values } = useFormikContext();
  const fieldValue = getIn(values, props.name);

  useEffect(() => {
    if (props.callBack) {
      props.callBack(fieldValue);
    }
  }, [fieldValue]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={props.name}
          {...field}
          checked={field.value}
          {...props}
        />
      }
      className={
        props.className
          ? `${classes.checkbox} ${props.className}`
          : classes.checkbox
      }
      {...props}
    />
  );
};

export default FormikCheckbox;
