import { Grid, MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import { useStyles } from "../styles/formStyle";
import {
  accidenteTrabajoFormValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const AccidenteTrabajoForm = ({
  handleSubmit,
  model,
  fields,
  listaFormaAccidente,
  listaAgenteMaterial,
  listaSubTipoAccidente,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={model}
      onSubmit={handleSubmit}
      validationSchema={accidenteTrabajoFormValidation}
      validate={(values) => validateRequiredFields(values, fields)}
    >
      <Form className={classes.formContainer}>
        <Grid container spacing={9} className={classes.gridContainer}>
          <Grid item xs={6}>
            <FormikSelect
              fullWidth
              name="subTipoAccidente"
              label={`Subtipo accidente ${
                fields.subTipoSiniestro?.obligatorio ? "*" : ""
              }`}
              variant="standard"
            >
              {listaSubTipoAccidente.map(({ id, nombre }) => {
                return (
                  <MenuItem value={id} key={id}>
                    {nombre}
                  </MenuItem>
                );
              })}
            </FormikSelect>
          </Grid>
          <Grid item xs={3}>
            <FormikCheckbox
              id="ocurrenciaViaPublica"
              name="ocurrenciaViaPublica"
              label={`Ocurrencia en la vía pública ${
                fields.ocurrenciaViaPublica?.obligatorio ? "*" : ""
              }`}
            />
          </Grid>
          <Grid item xs={3}>
            <FormikCheckbox
              id="teletrabajo"
              name="teletrabajo"
              label={`Teletrabajo ${
                fields.teletrabajo?.obligatorio ? "*" : ""
              }`}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikAutocomplete
              fullWidth
              name="agenteCausante"
              label={`Agente causante ${
                fields.agenteCausante?.obligatorio ? "*" : ""
              }`}
              variant="standard"
              options={listaAgenteMaterial}
              renderOption={(option) => option.nombre}
              filterOptions={(option) => option.nombre}
            />
          </Grid>
          <Grid item xs={3}>
            <FormikAutocomplete
              fullWidth
              name="formaAccidente"
              label={`Forma accidente ${
                fields.formaAccidente?.obligatorio ? "*" : ""
              }`}
              variant="standard"
              options={listaFormaAccidente}
              renderOption={(option) => option.nombre}
              filterOptions={(option) => option.nombre}
            />
          </Grid>
        </Grid>
        <div className={classes.buttonContainer}>
          <FormikActions submit="Siguiente" />
        </div>
      </Form>
    </Formik>
  );
};

export default AccidenteTrabajoForm;
