import { makeStyles } from "@material-ui/core";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNominasActivas } from "../../../../../redux/actions/certificadoCobertura";

// NOTA: es necesario que las columnas tengan un width
// y no flex, porque con flex desaparece la scrollbar horizontal
const columns = [
  {
    field: "cuil",
    headerName: "CUIL",
    headerAlign: "center",
    type: "number",
    width: 120,
  },
  {
    field: "nombreCompleto",
    headerName: "Apellido, Nombre",
    headerAlign: "left",
    width: 300,
  },
];

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
  },
});

const NominaTable = ({ nominasSelected, setNominasSelected }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const nominasActivas = useSelector(
    (state) => state.certificadoCobertura.nominaActiva
  );

  useEffect(() => {
    if (nominasActivas.data.length === 0) {
      dispatch(getNominasActivas());
    }
  }, []);
  return (
    <div className={classes.container}>
      <DataGrid
        rows={nominasActivas.data}
        columns={columns}
        loading={nominasActivas.loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        getRowId={(row) => row.idNomina}
        onRowSelectionModelChange={(selection) => {
          setNominasSelected(selection);
        }}
        selectionModel={nominasSelected}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};

export default NominaTable;
