import axios from "axios";
export const GET_LOGO = "GET_LOGO";

export const getAppLogo = () => (dispatch) => {
  return dispatch({
    type: GET_LOGO,
    payload: axios
      .get("empresa/obtenerLogoEmpresa")
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
