import { Grid, MenuItem, Typography } from "@material-ui/core";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Form, Formik } from "formik";
import { useState } from "react";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTimePicker from "../../../../commons/formik/FormikTimePicker";
import { useStyles } from "../styles/formStyle";
import {
  datosNominalesValidation,
  validateRequiredFields,
} from "../validation/formValidation";

const DatosNominalesForm = ({
  handleSubmit,
  model,
  fields,
  handleCancelNomina,
}) => {
  const classes = useStyles();

  const [warningBajaMsg, setWarningBajaMsg] = useState(false);
  const [warningMsg, setWarningMsg] = useState(false);

  return (
    <Formik
      initialValues={model}
      onSubmit={handleSubmit}
      validationSchema={() => datosNominalesValidation()}
      validate={(values) => validateRequiredFields(values, fields)}
    >
      {({ setFieldValue, values }) => (
        <Form className={(classes.formContainer, classes.formMariginTop)}>
          <Grid container spacing={9} className={classes.gridContainer}>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikDatePicker
                id="fechaIngresoEmpleador"
                name="fechaIngresoEmpleador"
                label={`Fecha ingreso de la nómina ${
                  fields?.fechaIngresoEmpleador.obligatorio ? "*" : ""
                }`}
                fullWidth
                disableFuture
              />
            </Grid>
            <Grid item xs={2}>
              <FormikCheckbox
                id="agropecuario"
                name="agropecuario"
                label={`Agropecuario ${
                  fields?.agropecuario.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={2}>
              <FormikCheckbox
                id="domestica"
                name="domestica"
                label="Doméstica"
                callBack={(value) => {
                  if (!value) {
                    setFieldValue("categoriaDomestica", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikSelect
                fullWidth
                name="categoriaDomestica"
                label={`Categoría doméstica ${
                  fields?.categoriaDomestica.obligatorio || values.domestica
                    ? "*"
                    : ""
                }`}
                variant="standard"
                disabled={!values.domestica}
              >
                {fields?.categoriaDomestica.opciones.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikCheckbox
                id="bajaNomina"
                name="bajaNomina"
                label="Baja nómina"
                callBack={(value) => {
                  if (!value) {
                    setFieldValue("fechaBaja", null);
                    setFieldValue("motivoBaja", "");
                  }
                  if (model.bajaNomina && !value) {
                    setWarningMsg(true);
                  } else if (!model.bajaNomina && value) {
                    setWarningBajaMsg(true);
                  } else {
                    setWarningBajaMsg(false);
                    setWarningMsg(false);
                  }
                }}
                disabled={fields.idTrabajador == 0}
              />
              {warningBajaMsg && (
                <div style={{ display: "flex" }}>
                  <WarningAmberIcon style={{ color: "#ebd80c" }} />
                  <Typography style={{ color: "#ebd80c" }}>
                    Está dando de baja la nómina
                  </Typography>
                </div>
              )}
              {warningMsg && (
                <div style={{ display: "flex" }}>
                  <WarningAmberIcon style={{ color: "#ebd80c" }} />
                  <Typography style={{ color: "#ebd80c" }}>
                    Está reactivando una nómina dada de baja
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikDatePicker
                id="fechaBaja"
                name="fechaBaja"
                label={`Fecha baja de la nómina `}
                fullWidth
                disableFuture
                disabled={!values.bajaNomina}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikAutocomplete
                fullWidth
                name="motivoBaja"
                label={`Motivo baja `}
                variant="standard"
                options={fields?.motivoBaja.opciones}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
                disabled={!values.bajaNomina}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikDatePicker
                id="fechaIngresoDependencia"
                name="fechaIngresoDependencia"
                label={`Fecha ingreso de la dependencia ${
                  fields?.fechaIngresoDependencia.obligatorio ? "*" : ""
                }`}
                fullWidth
                disableFuture
              />
            </Grid>
            <Grid item xs={8} className={classes.inputContainer}>
              <FormikAutocomplete
                fullWidth
                disabled={model.establecimiento != ""}
                name="establecimiento"
                label={`Establecimiento ${
                  fields?.establecimiento.obligatorio ? "*" : ""
                }`}
                variant="standard"
                options={fields?.establecimiento.opciones}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikAutocomplete
                fullWidth
                name="puestoTrabajo"
                label={`Puesto laboral ${
                  fields?.puestoTrabajo.obligatorio ? "*" : ""
                }`}
                variant="standard"
                options={fields?.puestoTrabajo.opciones}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputContainer}>
              <FormikSelect
                fullWidth
                name="turno"
                label={`Turno ${fields?.turno.obligatorio ? "*" : ""}`}
                variant="standard"
              >
                {fields?.turno.opciones.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.inputContainer}
              style={{ position: "relative" }}
            >
              <Typography
                style={{
                  position: "absolute",
                  top: "-10px",
                }}
              >
                Horario Laboral:
              </Typography>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: "25px",
                  right: "20px",
                }}
              >
                <FormikTimePicker
                  style={{ margin: "10px" }}
                  name="horaDesde"
                  label={`Hora desde ${
                    fields?.horaDesde.obligatorio ? "*" : ""
                  }`}
                  disablePast
                  autoComplete="off"
                />
                <FormikTimePicker
                  style={{ margin: "10px" }}
                  name="horaHasta"
                  label={`Hora hasta ${
                    fields?.horaHasta.obligatorio ? "*" : ""
                  }`}
                  disablePast
                  autoComplete="off"
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Guardar" handleCancel={handleCancelNomina} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DatosNominalesForm;
