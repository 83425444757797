import React from "react";
import InformacionContainer from "./informacion/InformacionContainer";
import ContratoContainer from "./contrato/ContratoContainer";
import CustomTab from "../commons/tab/CustomTab";

const optionsTabs = [
  {
    value: "1",
    label: "Datos empresa",
    component: () => <InformacionContainer />,
    wrapped: false,
    disabled: false,
  },
  {
    value: "2",
    label: "Contratos",
    component: () => <ContratoContainer />,
    wrapped: false,
    disabled: false,
  },
];

const configurationTabs = {
  textColor: "primary",
  indicatorColor: "primary",
  centered: true,
  variant: null,
  orientation: null,
};

const EmpresaContainer = () => {
  return (
    <CustomTab
      optionsTabs={optionsTabs}
      configurationTabs={configurationTabs}
    />
  );
};

export default EmpresaContainer;
