import { useTheme } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { enviarNteFirmado } from "../../../../redux/actions/nte";
import RequeridoLabel from "../../../commons/labels/RequeridoLabel";
import CircularLoading from "../../../commons/loading/CircularLoading";
import FechaPresentacionLabel from "../../rgrl/commons/FechaPresentacionLabel";
import DatosEstablecimientoHeaderLabel from "../commons/labels/DatosEstablecimientoHeaderLabel";
import EstadosNteLabel from "../commons/labels/EstadosNteLabel";
import { useStyles } from "../styles/formStyles";
import AdjuntarNteFirmadoContainer from "./AdjuntarNteFirmadoContainer";

const PrePresentacionContainer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idRarGuardado = useSelector((state) => state.nte.importacionIdRar);

  const [nteFirmadoFile, setNteFirmadoFile] = useState(null);
  const [isEnviandoNteFirmado, setIsEnviandoNteFirmado] = useState(false);

  const handleEnviarNteFirmado = () => {
    setIsEnviandoNteFirmado(true);

    const aux = {
      idRar: idRarGuardado,
      observaciones: "",
      archivosAsociados: [
        {
          base64: nteFirmadoFile[0]?.value,
          // estamos teniendo errores con enviar el file.base64header
          // que tiene como valor "data:application:pdf;base64"
          // mejor enviar la string "pdf" para la extensión
          extension: "pdf",
        },
      ],
    };

    dispatch(enviarNteFirmado(aux))
      .then((res) => {
        toast.success("Formulario NTE enviado correctamente");
        setIsEnviandoNteFirmado(false);
        navigate("/");
      })
      .catch((err) => {
        toast.error(err);
        setIsEnviandoNteFirmado(false);
      });
  };

  return (
    <>
      <DatosEstablecimientoHeaderLabel />
      <FechaPresentacionLabel />
      <EstadosNteLabel />
      {!isEnviandoNteFirmado ? (
        <AdjuntarNteFirmadoContainer
          file={nteFirmadoFile}
          setFile={setNteFirmadoFile}
          isLoading={isEnviandoNteFirmado}
          handleClickEnviar={handleEnviarNteFirmado}
        />
      ) : (
        <div className={classes.loadingContainer}>
          <CircularLoading
            style={{ color: `${theme.palette.tertiary.main}` }}
          />
          <RequeridoLabel text="Enviando el NTE firmado..." />
        </div>
      )}
    </>
  );
};

export default PrePresentacionContainer;
