import { Typography, lighten, makeStyles } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
    padding: 5,
    backgroundColor: lighten(theme.palette.tertiary.main, 0.8),
    width: "fit-content",
    borderRadius: 10,
    border: `2px solid ${lighten(theme.palette.tertiary.main, 0.5)}`,
    color: theme.palette.tertiary.main,
  },
  icon: {
    marginLeft: 10,
    transition: "transform 0.2s ease-in-out",
  },
  vibrando: {
    animation: "$vibrar 0.5s ease-in-out",
  },
  "@keyframes vibrar": {
    "0%": {
      transform: "rotate(-15deg)",
    },
    "50%": {
      transform: "rotate(15deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

const FeatureLabel = ({ labelText, icon, onClick, tooltipText }) => {
  const [vibrar, setVibrar] = useState(false);
  const timeoutID = useRef();
  const classes = useStyles();

  useEffect(() => {
    handleHover();
    return () => {
      clearTimeout(timeoutID.current);
    };
  }, []);

  const handleHover = () => {
    setVibrar(true);

    timeoutID.current = setTimeout(() => {
      setVibrar(false);
    }, 500);
  };

  return (
    <div className={classes.labelContainer}>
      <Typography>{labelText}</Typography>
      <div onMouseEnter={handleHover}>
        <Tooltip title={tooltipText} arrow>
          {icon &&
            React.cloneElement(icon, {
              fontSize: "large",
              className: `${classes.icon} ${vibrar ? classes.vibrando : ""}`,
              onClick: onClick,
            })}
        </Tooltip>
      </div>
    </div>
  );
};

export default FeatureLabel;
