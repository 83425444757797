/**
 * @file Administra el acceso a las rutas/menús según
 * las funciones habilitadas que devuelve el back para
 * cada usuario. Está bien utilizar el mismo objeto tanto
 * para los menús como para las rutas, ya que un usuario
 * no puede tener deshabilitado un menú y sin embargo navegar
 * hacia la página. Es correcto que ambas funcionalidades estén
 * ligadas.
 * Sigue la especificación de PDCA-401.
 */

/** @enum PerfilUsuarioEnum - Usar Object.freeze() hace que
 * el objeto sea `readonly`.
 */
const PerfilUsuarioEnum = Object.freeze({
  Empresa: 1,
  CuentaCorriente: 2,
  ABMNominas: 3,
  NominaMasiva: 4,
  CertificadoDeCobertura: 5,
  CoberturaViajeAlExterior: 6,
  Establecimientos: 7,
  RGRL: 8,
  ImportarNTE: 9,
  CargarNTE: 10,
  Resolucion81: 11,
  ExamenesMedicosPeriodicos: 12,
  Siniestralidad: 13,
  NuevaDenuncia: 14,
  Reintegros: 15,
});

/**
 * @param {Number[]} arrayFuncionalidades - La lista de enteros
 * (traida desde el back) que corresponden a los distintos menús
 * habilitados para ese usuario
 *
 * @example
 * // retorna solamente Empresa y CuentaCorriente en {true}
 * // y los demás menús en {false}:
 * getFuncionalidadesParaPerfilUsuario([1, 2])
 *
 * @returns {Object} Un objeto donde cada llave es un menú y su
 * valor es true o false. Por ejemplo:
 * ```{
    "Empresa": true,
    "CuentaCorriente": false,
    "ABMNominas": false,
    ...
    "NuevaDenuncia": true,
    "Reintegros": true
 * }```
 *
 */
export function getFuncionalidadesParaPerfilUsuario(arrayFuncionalidades) {
  let funcionalidadesParaUsuario = {};
  for (const [key, value] of Object.entries(PerfilUsuarioEnum)) {
    // poner tanto true como false, nos asegura que siempre
    // esten todas las keys del objeto, y no haga falta hacer,
    // por ejemplo, funcionalidades?.Empresa (con el ?)
    if (arrayFuncionalidades.includes(value)) {
      funcionalidadesParaUsuario[key] = true;
    } else {
      funcionalidadesParaUsuario[key] = false;
    }
  }

  return funcionalidadesParaUsuario;
}
