import { Button, Typography, makeStyles } from "@material-ui/core";
import { RemoveRedEye } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DropZone from "../../commons/dropZone/DropZone";
import DataLabel from "../../commons/labels/DataLabel";
import CircularLoading from "../../commons/loading/CircularLoading";
import ComprobanteDetail from "./ComprobanteDetail";

const useStyles = makeStyles((theme) => ({
  titleFormContainer: {},
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dataContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ComprobanteContainer = ({ fromBread, handleSubmit }) => {
  const classes = useStyles();

  const siniestro = useSelector((state) => state.reintegros.localSiniestro);
  const postReintegro = useSelector((state) => state.reintegros.postReintegro);
  const sendReintegros = useSelector(
    (state) => state.reintegros.sendReintegros
  );

  const [comprobanteFile, setComprobanteFile] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.titleFormContainer}>
        <Typography variant="h6">Adjuntar comprobante</Typography>
      </div>
      <div className={classes.dataContainer}>
        <DataLabel
          firstText={"Siniestro: " + siniestro.numeroSiniestroART}
          secondText={"DNI: " + siniestro.numeroDocumento}
          thirdText={siniestro.apellidoNombres}
        />
        <DataLabel
          firstText={
            postReintegro.beneficiario.nombre_trabajador.split("/")[0] ||
            postReintegro.beneficiario.nombre_empleadores.split("/")[0]
          }
          secondText={
            postReintegro.beneficiario.nombre_trabajador.split("/")[1] ||
            postReintegro.beneficiario.nombre_empleadores.split("/")[1]
          }
          thirdText={"CBU: " + postReintegro.beneficiario.cbu}
        />
        <DataLabel
          firstText={moment(
            postReintegro.datosComprobante.data.fechaDelComprobante
          ).format("DD/MM/YYYY")}
          secondText={
            postReintegro.datosComprobante.data.tipo +
            ": " +
            postReintegro.datosComprobante.data.letra +
            " " +
            postReintegro.datosComprobante.data.ptoVenta +
            " - " +
            postReintegro.datosComprobante.data.nro
          }
          thirdText={
            "$" +
            postReintegro.comprobantes.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }
          icon={
            <RemoveRedEye
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={() => setOpenDetail(true)}
            />
          }
        />
      </div>
      <div>
        <DropZone
          maxFiles={1}
          file={comprobanteFile}
          setFile={setComprobanteFile}
          loading={false}
          extension=".jpg, .png, .pdf"
          accept={{
            "image/jpeg": [".jpg"],
            "image/png": [".png"],
            "application/pdf": [".pdf"],
          }}
          dropZoneText="Arrastre aquí o haga click en el recuadro para importar los comprobantes en formato jpg, png o pdf."
          dropZoneEditText="Si desea cambiar el archivo arrástrelo nuevamente o haga click en el recuadro."
        />

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(comprobanteFile)}
          >
            {sendReintegros.loading ? (
              <CircularLoading style={{ color: "white" }} />
            ) : (
              "Enviar"
            )}
          </Button>
        </div>
      </div>
      <div>
        <ComprobanteDetail
          open={openDetail}
          handleCloseDetail={() => setOpenDetail(false)}
        />
      </div>
    </div>
  );
};

export default ComprobanteContainer;
