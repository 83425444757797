import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularLoading from "../loading/CircularLoading";
import { Typography } from "@material-ui/core";

const ConfirmDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  text,
  warningText,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
        <Typography>{warningText}</Typography>
      </DialogContent>
      {loading ? (
        <div style={{ marginBottom: "30px" }}>
          <CircularLoading />
        </div>
      ) : (
        <DialogActions style={{ margin: 10 }}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Aceptar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
