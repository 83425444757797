import PdfDialog from "../../../../commons/file/PdfDialog";

const DialogVisualizarFormulario = ({ open, title, file, onClose }) => {
  return (
    <>
      {open && (
        <div>
          <PdfDialog
            open={open}
            title={title}
            handleClose={onClose}
            controllers={true}
            file={file}
          />
        </div>
      )}
    </>
  );
};

export default DialogVisualizarFormulario;
