import { ListItem, ListItemIcon, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { memo } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  nested: {
    marginTop: "8px",
    borderRadius: "7px",
  },
  text: {
    color: theme.palette.primary.menuItem,
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    margin: 0,
  },
  icon: {
    color: theme.palette.primary.menuItem,
    marginLeft: 8,
  },
}));

const ListItemComponent = ({
  path,
  selected,
  title,
  icon: Icon,
  id,
  tooltip,
  tooltipText,
}) => {
  const classes = useStyles({ selected });

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltipText} placement="bottom" arrow>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={path}
            id={id}
          >
            <ListItemIcon className={classes.icon}>{Icon}</ListItemIcon>
            <p className={classes.text}>{title}</p>
          </ListItem>
        </Tooltip>
      ) : (
        <ListItem
          button
          className={classes.nested}
          component={Link}
          to={path}
          id={id}
        >
          <ListItemIcon className={classes.icon}>{Icon}</ListItemIcon>
          <p className={classes.text}>{title}</p>
        </ListItem>
      )}
    </>
  );
};

export default memo(ListItemComponent);
