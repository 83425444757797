import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAnalistaCuenta,
  getAnalistaCuenta,
  getCuentaCorriente,
  getCuentaCorrienteWithFilter,
} from "../../redux/actions/cuentaCorriente";
import { convertDateTimeToNet } from "../../util/datetime";
import CuentaCorrienteTable from "./CuentaCorrienteTable";

const CuentaCorrienteContainer = () => {
  const dispatch = useDispatch();
  const { cuentaCorriente, analistaDeCuenta } = useSelector(
    (state) => state.cuentaCorriente
  );

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  useEffect(() => {
    dispatch(getCuentaCorriente());
    dispatch(getAnalistaCuenta());
    return () => {
      dispatch(clearAnalistaCuenta());
    }
  }, [dispatch]);

  const handleFilter = (values) => {
    dispatch(
      getCuentaCorrienteWithFilter(
        convertDateTimeToNet(values.fechaDesde),
        convertDateTimeToNet(values.fechaHasta)
      )
    );

    setFechaDesde(values.fechaDesde);
    setFechaHasta(values.fechaHasta);
  };
  const refreshData = () => {
    dispatch(getCuentaCorriente());
    setFechaDesde(null);
    setFechaHasta(null);
  };

  return (
    <div>
      <CuentaCorrienteTable
        title="Cuenta Corriente"
        data={cuentaCorriente.data}
        fetchData={refreshData}
        noRefresh={false}
        secondaryTitle={true}
        loading={cuentaCorriente.loading || analistaDeCuenta.loading}
        handleFilter={handleFilter}
        fechaDesde={fechaDesde}
        fechaHasta={fechaHasta}
      />
    </div>
  );
};

export default CuentaCorrienteContainer;
