import {
  Button,
  TablePagination,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import EmpleadorForm from "./EmpresaForm";

const columns = [
  { id: "cuit", label: "CUIT" },
  { id: "razonsocial", label: "Razón Social" },
];

const useStyles = makeStyles((theme) => ({
  icons: {
    display: "flex",
    justifyContent: "center",
    // una separacion minima para que el usuario
    // pueda clickear bien los iconos en mobile
    gap: "1vw",
  },
  tableFooter: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",

    [theme.breakpoints.down(500)]: {
      padding: 5,
    },
  },
}));

const EmpresaTable = ({
  empresaList,
  openAddEmpresa,
  edit,
  empresa,
  handleAddEmpresa,
  handleCloseEmpresa,
  addEmpresa,
  deleteEmpresa,
  editEmpresa,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? empresaList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : empresaList
            ).map((row) => (
              <TableRow key={row.cuit}>
                <TableCell>{row.cuit}</TableCell>
                <TableCell>{row.razonsocial}</TableCell>
                <TableCell align="justify">
                  {/* el div es necesario para que los iconos queden
                  uno al lado del otro, ya que asi es mas facil
                  clickearlos, sobretodo en mobile */}
                  <div className={classes.icons}>
                    <Tooltip title="Editar" placement="bottom" arrow>
                      <EditIcon onClick={() => editEmpresa(row)} />
                    </Tooltip>
                    <Tooltip title="Eliminar" placement="bottom" arrow>
                      <DeleteIcon onClick={() => deleteEmpresa(row)} />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.tableFooter}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={empresaList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
          <div>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={addEmpresa}
            >
              Agregar empresa
            </Button>
          </div>
        </div>
      </TableContainer>
      <EmpleadorForm
        open={openAddEmpresa}
        edit={edit}
        empresa={empresa}
        handleSubmit={handleAddEmpresa}
        handleClose={handleCloseEmpresa}
      />
    </>
  );
};

export default EmpresaTable;
