import { makeStyles, Tooltip } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
    marginLeft: 10,
  },
  backArrow: {
    width: 40,
    height: 40,
  },
}));

const BackArrowHeader = ({ onClick, title }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={title ?? "Atrás"}
      onClick={onClick}
      className={classes.container}
    >
      <ArrowBack className={classes.backArrow} />
    </Tooltip>
  );
};

export default BackArrowHeader;
