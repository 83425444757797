import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  fechaPresentacionContainer: { display: "flex", marginLeft: 15 },
}));

const FechaPresentacionLabel = () => {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.fechaPresentacionContainer}>
      Fecha de Presentación: {format(new Date(), "dd/MM/yyyy")}
    </Typography>
  );
};

export default FechaPresentacionLabel;
