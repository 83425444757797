import { Grid, MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalle,
  getCodigoPostal,
  getLocalidades,
  getProvincias,
} from "../../../../../redux/actions/direccion";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import { useStyles } from "../../forms/styles/formStyle";
import {
  lugarOcurrenciaValidation,
  validateRequiredFields,
} from "../../forms/validation/formValidation";

const listaPais = [{ id: "ARGENTINA", nombre: "Argentina" }];

const LugarOcurrenciaForm = ({ handleSubmit, fields, model }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const calles = useSelector((state) => state.direccion.calle);
  const provincias = useSelector((state) => state.direccion.provincias);
  const localidades = useSelector((state) => state.direccion.localidades);
  const codigoPostalRedux = useSelector(
    (state) => state.direccion.codigoPostal
  );

  const [provinciasList, setProvinciasList] = useState([]);
  const [provinciaIsCapital, setProvinciaIsCapital] = useState(false);
  const [localidadesList, setLocalidadesList] = useState([
    { id: model.localidad, nombre: model.localidad },
  ]);
  const [callesList, setCallesList] = useState([]);

  useEffect(() => {
    dispatch(getProvincias());
    dispatch(getLocalidades(model.provincia));
  }, []);

  useEffect(() => {
    if (provincias.data) {
      setProvinciasList(provincias.data);
    }
  }, [provincias.data]);

  useEffect(() => {
    if (calles.data) {
      setCallesList(calles.data);
    }
  }, [calles.data]);

  useEffect(() => {
    if (localidades.data) {
      setLocalidadesList(localidades.data);
    }
  }, [localidades.data]);

  const handlePaisChange = () => {
    dispatch(getProvincias());
  };

  const handleProvinciaChange = (value) => {
    dispatch(getLocalidades(value));
  };

  const handleLocalidadesChange = (value) => {
    dispatch(getCalle(value, value));
  };

  const handleGetCodigoPostal = (
    provincia,
    localidad,
    numero,
    calle,
    setFieldValue
  ) => {
    dispatch(getCodigoPostal(provincia, localidad, numero, calle)).then(
      (res) => {
        if (res.value.data.codigoPostal) {
          setFieldValue("codigoPostalOcurrencia", res.value.data.codigoPostal);
        }
      }
    );
  };

  return (
    <Formik
      initialValues={model}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={lugarOcurrenciaValidation}
      validate={(values) => validateRequiredFields(values, fields)}
    >
      {({ values, setFieldValue }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={9} className={classes.gridContainer}>
            <Grid item xs={4}>
              <FormikSelect
                name="paisOcurrencia"
                label={`País ${fields.paisOcurrencia.obligatorio ? "*" : ""}`}
                variant="standard"
                fullWidth
                onChange={handlePaisChange}
              >
                {listaPais.map(({ id, nombre }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {nombre}
                    </MenuItem>
                  );
                })}
              </FormikSelect>
            </Grid>
            <Grid item xs={4}>
              <FormikAutocomplete
                fullWidth
                name="provinciaOcurrencia"
                label={`Provincia ${
                  fields.provinciaOcurrencia.obligatorio ? "*" : ""
                }`}
                variant="standard"
                callBack={(value) => {
                  if (value) {
                    handleProvinciaChange(value);
                    if (value.id === "Capital Federal") {
                      handleLocalidadesChange(value.id);
                      setProvinciaIsCapital(true);
                    } else {
                      setProvinciaIsCapital(false);
                    }
                  } else {
                    setFieldValue("localidadOcurrencia", "");
                    setFieldValue("calleOcurrencia", "");
                    setFieldValue("numeroOcurrencia", "");
                    setFieldValue("codigoPostalOcurrencia", "");
                  }
                }}
                options={provinciasList}
                getOptions={() => dispatch(getProvincias())}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikAutocomplete
                fullWidth
                name="localidadOcurrencia"
                label={`Localidad ${
                  fields.localidadOcurrencia.obligatorio ? "*" : ""
                }`}
                variant="standard"
                disabled={!values.provinciaOcurrencia}
                options={localidadesList}
                getOptions={() =>
                  dispatch(getLocalidades(values.provinciaOcurrencia))
                }
                callBack={(value) => {
                  if (!value) {
                    setFieldValue("calleOcurrencia", "");
                    setFieldValue("numeroOcurrencia", "");
                    setFieldValue("codigoPostalOcurrencia", "");
                  }
                }}
                renderOption={(option) => option.nombre}
                filterOptions={(option) => option.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  {!provinciaIsCapital ? (
                    <FormikTextField
                      fullWidth
                      id="calleOcurrencia"
                      name="calleOcurrencia"
                      type="text"
                      label={`Calle ${
                        fields.calleOcurrencia.obligatorio ? "*" : ""
                      }`}
                      disabled={!values.localidadOcurrencia}
                      onBlur={(value) => {
                        if (!value.target.value) {
                          setFieldValue("numeroOcurrencia", "");
                          setFieldValue("codigoPostalOcurrencia", "");
                        }
                      }}
                    />
                  ) : (
                    <FormikAutocomplete
                      fullWidth
                      name="calleOcurrencia"
                      label={`Calle ${
                        fields.calleOcurrencia.obligatorio ? "*" : ""
                      }`}
                      options={callesList}
                      disabled={!values.localidadOcurrencia}
                      callBack={(value) => {
                        if (!value) {
                          setFieldValue("numeroOcurrencia", "");
                          setFieldValue("codigoPostalOcurrencia", "");
                        }
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormikCheckbox
                    id="kmOcurrencia"
                    name="kmOcurrencia"
                    label={`KM ${fields.kmOcurrencia.obligatorio ? "*" : ""}`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="numeroOcurrencia"
                name="numeroOcurrencia"
                type="text"
                label={`Número ${
                  fields.numeroOcurrencia.obligatorio ? "*" : ""
                }`}
                disabled={!values.calleOcurrencia}
                onBlur={(value) => {
                  if (
                    value.target.value &&
                    !values.kmOcurrencia &&
                    values.numeroOcurrencia > 0
                  ) {
                    handleGetCodigoPostal(
                      values.provinciaOcurrencia,
                      values.localidadOcurrencia,
                      values.numeroOcurrencia,
                      values.calleOcurrencia,
                      setFieldValue
                    );
                  } else {
                    setFieldValue("codigoPostalOcurrencia", "");
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormikTextField
                    id="pisoOcurrencia"
                    name="pisoOcurrencia"
                    type="text"
                    label={`Piso ${
                      fields.pisoOcurrencia.obligatorio ? "*" : ""
                    }`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField
                    id="departamentoOcurrencia"
                    name="departamentoOcurrencia"
                    type="text"
                    label={`Departamento ${
                      fields.departamentoOcurrencia.obligatorio ? "*" : ""
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="codigoPostalOcurrencia"
                name="codigoPostalOcurrencia"
                type="text"
                label={`Código postal ${
                  fields.codigoPostalOcurrencia.obligatorio ? "*" : ""
                }`}
                disabled={!values.numeroOcurrencia}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="entreCalleOcurrencia"
                name="entreCalleOcurrencia"
                type="text"
                label={`Entre calle ${
                  fields.entreCalleOcurrencia.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikTextField
                fullWidth
                id="entreCalle1Ocurrencia"
                name="entreCalle1Ocurrencia"
                type="text"
                label={`Y calle ${
                  fields.entreCalle1Ocurrencia.obligatorio ? "*" : ""
                }`}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Siguiente" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LugarOcurrenciaForm;
