import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
  },
}));
