import {
  ADD_COMPROBANTE,
  BUSCAR_SINIESTRO,
  CLEAR_REINTEGROS,
  CLEAR_SINIESTRO,
  DELETE_COMPROBANTE,
  ERROR_DATOS_COMPROBANTE,
  GET_RUBROS_REINTEGRO,
  GET_TIPO_COMPROBANTE,
  GET_TIPO_DESTINATARIO,
  POST_BENEFICIARIO,
  POST_DATOS_COMPROBANTE,
  POST_REINTEGROS,
  SET_LOCAL_SINIESTRO,
} from "../actions/reintegros";

const INITIAL_STATE = {
  localSiniestro: null,
  siniestrosBuscados: {
    error: false,
    loading: false,
    data: [],
  },
  tipoDestinatario: {
    error: false,
    loading: false,
    data: [],
  },
  tipoComprobante: {
    error: false,
    loading: false,
    data: [],
  },
  rubrosReintegro: {
    error: false,
    loading: false,
    data: [],
  },
  sendReintegros: {
    error: false,
    loading: false,
    data: [],
  },
  postReintegro: {
    beneficiario: null,
    comprobantes: { data: [], total: 0 },
    datosComprobante: { data: null, done: false },
  },
};

const reintegros = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_DATOS_COMPROBANTE:
      return {
        ...state,
        postReintegro: {
          ...state.postReintegro,
          datosComprobante: { data: action.payload, done: true },
        },
      };
    case ERROR_DATOS_COMPROBANTE:
      return {
        ...state,
        postReintegro: {
          ...state.postReintegro,
          datosComprobante: {
            data: state.postReintegro.datosComprobante.data,
            done: false,
          },
        },
      };
    case DELETE_COMPROBANTE:
      let delArrayComprobantes = Array.from(
        state.postReintegro.comprobantes.data
      );

      // sabemos que nos despachan un comprobante, que ya existe
      const indexDeleteComprobante = delArrayComprobantes.findIndex(
        (item) => item?.id === action.payload?.id
      );

      if (indexDeleteComprobante !== -1) {
        delArrayComprobantes.splice(indexDeleteComprobante, 1);
      }

      const importeTotalAfterDelete = delArrayComprobantes.reduce(
        (total, detalle) =>
          total + parseFloat(detalle.importe.replace(",", ".")),
        0
      );

      return {
        ...state,
        postReintegro: {
          ...state.postReintegro,
          comprobantes: {
            data: delArrayComprobantes,
            total: importeTotalAfterDelete,
          },
        },
      };

    case ADD_COMPROBANTE:
      let newArrayComprobantes = Array.from(
        state.postReintegro.comprobantes.data
      );

      // sabemos que nos despachan un comprobante, que ya existe o
      // que es uno nuevo, por lo que hay que chequear si se encuentra
      // en el array de comprobantes
      const indexReplaceComprobante = newArrayComprobantes.findIndex(
        (item) => item?.id === action.payload?.id
      );

      if (indexReplaceComprobante === -1) {
        newArrayComprobantes.push({
          ...action.payload,
          // que el id del detalle del comprobante, tenga 3 digitos
          // y no sea dependiente de una propiedad volatil como array.length
          id: parseInt(Math.random() * 1000),
        });
      } else {
        newArrayComprobantes.splice(indexReplaceComprobante, 1, action.payload);
      }

      const importeTotalAfterAdd = newArrayComprobantes.reduce(
        (total, detalle) =>
          total + parseFloat(detalle.importe.replace(",", ".")),
        0
      );

      return {
        ...state,
        postReintegro: {
          ...state.postReintegro,
          comprobantes: {
            data: newArrayComprobantes,
            total: importeTotalAfterAdd,
          },
        },
      };

    case SET_LOCAL_SINIESTRO:
      return {
        ...state,
        localSiniestro: action.payload,
      };
    case CLEAR_SINIESTRO:
      return {
        ...state,
        localSiniestro: INITIAL_STATE.localSiniestro,
        siniestrosBuscados: INITIAL_STATE.siniestrosBuscados,
      };
    case POST_BENEFICIARIO:
      return {
        ...state,
        postReintegro: {
          ...state.postReintegro,
          beneficiario: action.payload,
        },
      };
    case `${BUSCAR_SINIESTRO}_PENDING`:
      return {
        ...state,
        siniestrosBuscados: {
          ...state.siniestrosBuscados,
          error: false,
          loading: true,
        },
      };
    case `${BUSCAR_SINIESTRO}_REJECTED`:
      return {
        ...state,
        siniestrosBuscados: {
          ...state.siniestrosBuscados,
          error: true,
          loading: false,
        },
      };
    case `${BUSCAR_SINIESTRO}_FULFILLED`:
      return {
        ...state,
        siniestrosBuscados: {
          ...state.siniestrosBuscados,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_TIPO_DESTINATARIO}_PENDING`:
      return {
        ...state,
        tipoDestinatario: {
          ...state.tipoDestinatario,
          error: false,
          loading: true,
        },
      };
    case `${GET_TIPO_DESTINATARIO}_REJECTED`:
      return {
        ...state,
        tipoDestinatario: {
          ...state.tipoDestinatario,
          error: true,
          loading: false,
        },
      };
    case `${GET_TIPO_DESTINATARIO}_FULFILLED`:
      return {
        ...state,
        tipoDestinatario: {
          ...state.tipoDestinatario,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_TIPO_COMPROBANTE}_PENDING`:
      return {
        ...state,
        tipoComprobante: {
          ...state.tipoComprobante,
          error: false,
          loading: true,
        },
      };
    case `${GET_TIPO_COMPROBANTE}_REJECTED`:
      return {
        ...state,
        tipoComprobante: {
          ...state.tipoComprobante,
          error: true,
          loading: false,
        },
      };
    case `${GET_TIPO_COMPROBANTE}_FULFILLED`:
      return {
        ...state,
        tipoComprobante: {
          ...state.tipoComprobante,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_RUBROS_REINTEGRO}_PENDING`:
      return {
        ...state,
        rubrosReintegro: {
          ...state.rubrosReintegro,
          error: false,
          loading: true,
        },
      };
    case `${GET_RUBROS_REINTEGRO}_REJECTED`:
      return {
        ...state,
        rubrosReintegro: {
          ...state.rubrosReintegro,
          error: true,
          loading: false,
        },
      };
    case `${GET_RUBROS_REINTEGRO}_FULFILLED`:
      return {
        ...state,
        rubrosReintegro: {
          ...state.rubrosReintegro,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };

    case `${POST_REINTEGROS}_PENDING`:
      return {
        ...state,
        sendReintegros: {
          ...state.sendReintegros,
          error: false,
          loading: true,
        },
      };
    case `${POST_REINTEGROS}_REJECTED`:
      return {
        ...state,
        sendReintegros: {
          ...state.sendReintegros,
          error: true,
          loading: false,
        },
      };
    case `${POST_REINTEGROS}_FULFILLED`:
      return {
        ...state,
        sendReintegros: {
          ...state.sendReintegros,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case CLEAR_REINTEGROS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default reintegros;
