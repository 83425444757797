import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { validarCuilCuit } from "../../../util/cuilcuit";

const useStyles = makeStyles({
  searchContainer: {
    padding: 35,
  },
  button: {
    margin: 10,
  },
});

const Search = ({ clearActions, reduxAction, callBack, onClear, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");

  const handleSearch = () => {
    if (inputValue && validarCuilCuit(inputValue)) {
      clearActions.forEach((action) => {
        dispatch(action());
      });
      dispatch(reduxAction(inputValue));
      if (callBack) {
        callBack(inputValue);
      }
    } else {
      toast.error(
        "Por favor, ingrese un CUIL válido para continuar con la búsqueda"
      );
    }
  };

  const handleClear = () => {
    setInputValue("");
    if (onClear) {
      onClear();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className={classes.searchContainer}>
      <TextField
        id="cuil"
        name="cuil"
        type="text"
        label="CUIL"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        FormHelperTextProps={{
          style: {
            display: "none",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ClearIcon onClick={handleClear} />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleSearch}
        className={classes.button}
      >
        {data.loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Buscar"
        )}
      </Button>
    </div>
  );
};

export default Search;
