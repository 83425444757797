import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { List, ListItem, ListItemText } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as React from "react";
import { formatForNetDates } from "../../../../../../../util/datetime";

const SecuenciaCard = ({ data }) => {
  return (
    <div>
      {data.map((secuencia) => (
        <Accordion key={secuencia.nroSecuencia}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Secuencia {secuencia.nroSecuencia}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "14px" }}>
                        Fecha ocurrencia
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ fontSize: "13px", color: "grey" }}>
                        {secuencia?.fechaHoraOcurrencia
                          ? moment(
                              formatForNetDates(secuencia.fechaHoraOcurrencia)
                            ).format("DD/MM/YYYY hh:mm")
                          : "Sin Información"}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "14px" }}>
                        Fecha cese
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ fontSize: "13px", color: "grey" }}>
                        {secuencia?.fechaCese
                          ? moment(
                              formatForNetDates(secuencia.fechaCese)
                            ).format("DD/MM/YYYY hh:mm")
                          : "Sin Información"}
                      </Typography>
                    }
                  />
                </ListItem>
              </div>
              <div>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "14px" }}>
                        Fecha conocimiento
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ fontSize: "13px", color: "grey" }}>
                        {secuencia?.fechaTomaConocimiento
                          ? moment(
                              formatForNetDates(secuencia.fechaTomaConocimiento)
                            ).format("DD/MM/YYYY hh:mm")
                          : "Sin Información"}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "14px" }}>
                        Fecha alta
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ fontSize: "13px", color: "grey" }}>
                        {secuencia?.fechaAlta
                          ? moment(
                              formatForNetDates(secuencia.fechaAlta)
                            ).format("DD/MM/YYYY hh:mm")
                          : "Sin Información"}
                      </Typography>
                    }
                  />
                </ListItem>
              </div>
              <div style={{ marginLeft: "30px" }}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "14px" }}>
                        Incapacidad
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ fontSize: "13px", color: "grey" }}>
                        {secuencia?.incapacidad
                          ? secuencia.incapacidad
                          : "Sin Información"}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "14px" }}>
                        Fecha rechazo
                      </Typography>
                    }
                    secondary={
                      <Typography sx={{ fontSize: "13px", color: "grey" }}>
                        {secuencia?.fechaRechazo
                          ? moment(
                              formatForNetDates(secuencia.fechaRechazo)
                            ).format("DD/MM/YYYY hh:mm")
                          : "Sin Información"}
                      </Typography>
                    }
                  />
                </ListItem>
              </div>
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SecuenciaCard;
