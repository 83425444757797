import axios from "axios";

export const GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS =
  "GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS";
export const GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO =
  "GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO";
export const DESCARGAR_FORMULARIO_RAR_PDF = "DESCARGAR_FORMULARIO_RAR_PDF";
export const DESCARGAR_FORMULARIO_RGRL_PDF = "DESCARGAR_FORMULARIO_RGRL_PDF";
export const GET_FORMULARIO_RAR_PDF = "GET_FORMULARIO_RAR_PDF";
export const GET_FORMULARIO_RGRL_PDF = "GET_FORMULARIO_RGRL_PDF";
export const POST_FORMULARIO_RAR_FIRMADO = "POST_FORMULARIO_RAR_FIRMADO";
export const POST_FORMULARIO_RGRL_FIRMADO = "POST_FORMULARIO_RGRL_FIRMADO";
export const CLEAR_VISUALIZACION_FORMULARIO_PDF =
  "CLEAR_VISUALIZACION_FORMULARIO_PDF";

export const getEstadoCumplimientoEstablecimientos = () => (dispatch) => {
  return dispatch({
    type: GET_ESTADO_CUMPLIMIENTO_ESTABLECIMIENTOS,
    payload: axios
      .get(`empleador/datosCompletosDependenciasEmpleador`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormulariosPrevencionEstablecimiento = (id) => (dispatch) => {
  return dispatch({
    type: GET_FORMULARIOS_PREVENCION_ESTABLECIMIENTO,
    payload: axios
      .get(
        `dependenciaEmpleador/formulariosPrevencionConEstados?idDependenciaEmpleador=${id}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const descargarFormularioRarPdf = (id) => (dispatch) => {
  return dispatch({
    type: DESCARGAR_FORMULARIO_RAR_PDF,
    payload: axios
      .get(`formularioPrevencion/descargarPrePresentado?idFormularioProgramaPrevencionEmpleador=${id}`, {
        responseType: "blob",
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const descargarFormularioRgrlPdf = (id) => (dispatch) => {
  return dispatch({
    type: DESCARGAR_FORMULARIO_RGRL_PDF,
    payload: axios
      .get(`formularioPrevencion/descargarPrePresentado?idFormularioProgramaPrevencionEmpleador=${id}`, {
        responseType: "blob",
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormularioRarPdf = (id) => (dispatch) => {
  return dispatch({
    type: GET_FORMULARIO_RAR_PDF,
    payload: axios
      .get(`rar/visualizarRAR?idFormularioProgramaPrevencionEmpleador=${id}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormularioRgrlPdf = (id) => (dispatch) => {
  return dispatch({
    type: GET_FORMULARIO_RGRL_PDF,
    payload: axios
      .get(
        `rgrl/verFormularioRGRL?idFormularioProgramaPrevencionEmpleador=${id}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postFormularioRarFirmado = (file, formulario) => (dispatch) => {
  const data = {
    // este idFormularioCorrespondiente viene a ser el idRAR que el back
    // resuelve por nosotros, idRAR **NO** es lo mismo que idFormularioProgramaPreven...
    idRAR: formulario?.idFormularioCorrespondiente,
    observaciones: "",
    archivosAsociados: [{ base64: file?.value, extension: "pdf" }],
  };

  return dispatch({
    type: POST_FORMULARIO_RAR_FIRMADO,
    payload: axios
      .post("rar/cargarAdjuntosRAR", data, {
        responseType: "blob",
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postFormularioRgrlFirmado = (file, formulario) => (dispatch) => {
  const data = {
    // este idFormularioCorrespondiente viene a ser el idRGRL que el back
    // resuelve por nosotros, idRGRL **NO** es lo mismo que idFormularioProgramaPreven...
    idRGRL: formulario?.idFormularioCorrespondiente,
    observaciones: "",
    archivosAsociados: [{ base64: file?.value, extension: "pdf" }],
  };

  return dispatch({
    type: POST_FORMULARIO_RGRL_FIRMADO,
    payload: axios
      .post("rgrl/cargarAdjuntosRGRL", data, {
        responseType: "blob",
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const clearVisualizacionFormularioPdf = () => (dispatch) => {
  return dispatch({
    type: CLEAR_VISUALIZACION_FORMULARIO_PDF,
  });
};
