import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSearchParams } from "../../../redux/actions/examenesPeriodicos";
import BackArrowHeader from "../../commons/arrow/BackArrowHeader";
import CircularLoading from "../../commons/loading/CircularLoading";
import CustomTab from "../../commons/tab/CustomTab";
import DependenciaSeleccionadaLabel from "../title/DependenciaSeleccionadaLabel";
import Notificaciones from "./notificaciones/Notificaciones";
import ResumenGestion from "./resumenGestion/ResumenGestionContainer";
import Siniestros from "./siniestros/SiniestrosContainer";

const optionsTabs = [
  {
    value: "1",
    label: "Resumen de Gestión",
    component: () => <ResumenGestion />,
    wrapped: false,
    disabled: false,
  },
  {
    value: "2",
    label: "Siniestros por Exámenes Periódicos",
    component: () => <Siniestros />,
    wrapped: false,
    disabled: false,
  },
  {
    value: "3",
    label: "Notificaciones",
    component: () => <Notificaciones />,
    wrapped: false,
    disabled: false,
  },
];

const configurationTabs = {
  textColor: "primary",
  indicatorColor: "primary",
  centered: true,
  variant: null,
  orientation: null,
};

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "center",
  },
});

const TabsContainer = ({ goBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const searchParams = useSelector(
    (state) => state.examenesPeriodicos.searchParams
  );
  const datosDependencia = useSelector(
    (state) => state.examenesPeriodicos.datosEstablecimiento.data
  );

  useEffect(() => {
    return () => {
      // por especificacion, cada vez que se navega fuera de
      // examenes periodicos, los filtros deben estar vacios
      dispatch(clearSearchParams());
    };
  });

  return (
    <div style={{ paddingTop: 10 }}>
      {!datosDependencia.loading ? (
        <>
          <BackArrowHeader onClick={goBack} />
          <div className={classes.title}>
            <DependenciaSeleccionadaLabel
              data={{ ...datosDependencia, ...searchParams }}
            />
          </div>
          <CustomTab
            optionsTabs={optionsTabs}
            configurationTabs={configurationTabs}
            selectedAccident={searchParams?.idEstablecimiento}
            withLabelData={false}
          />
        </>
      ) : (
        <CircularLoading />
      )}
    </div>
  );
};

export default TabsContainer;
