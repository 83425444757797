import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikTextField from "../../../../commons/formik/FormikTextField";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1vw",
  },
});

const initialValues = {
  cuit: "",
  razonsocial: "",
};

const validation = Yup.object({
  cuit: Yup.string()
    .matches(/^\d{2}\d{8}\d{1}$/, "El CUIT no es válido. Ingréselo sin guiones")
    .required("Campo obligatorio"),
  razonsocial: Yup.string().required("Campo obligatorio"),
});

const EmpresaForm = ({ handleSubmit, edit, handleClose, open, empresa }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{edit ? "Editar" : "Agregar"} Empresa</DialogTitle>
      <Formik
        initialValues={empresa ? empresa : initialValues}
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogContent className={classes.container}>
            <FormikTextField name="cuit" type="text" label="CUIT" />
            <FormikTextField
              name="razonsocial"
              type="text"
              label="Razón Social"
            />
          </DialogContent>
          <DialogActions>
            <FormikActions
              handleCancel={handleClose}
              submit="Agregar"
            ></FormikActions>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default EmpresaForm;
