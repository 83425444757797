import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfView = ({
  file,
  pageScale,
  pageNumberProps,
  setLoadingFalse,
  setTotalPagesProps,
  sendNotificacion,
  paginated,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
    if (setTotalPagesProps) {
      setTotalPagesProps(numPages);
    }
  }

  return (
    <Document
      file={`data:application/pdf;base64,${file}`}
      onLoadSuccess={(props) => {
        onDocumentLoadSuccess(props);
        if (setLoadingFalse) {
          setLoadingFalse();
        }
        if (sendNotificacion) {
          sendNotificacion();
        }
      }}
      error="Error al cargar el archivo PDF"
      loading="Cargando PDF..."
    >
      {paginated ?
        <Page
          pageNumber={pageNumberProps ? pageNumberProps : pageNumber}
          scale={pageScale ? pageScale : 1}
        />
        :
        Array.from(new Array(totalPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={pageScale ? pageScale : 1} />
        ))

      }
    </Document>
  );
};

export default PdfView;
