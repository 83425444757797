import { Typography, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
const useStyles = makeStyles((theme) => ({
  titleContainer: {
    backgroundColor: `${theme.palette.primary.main}`,
    borderBottom: "1px solid green",
    boxSizing: "border-box",
    textAlign: "center",
    color: "white",
    padding: "5px 120px 5px 120px",
  },
  dialogContent: {
    textAlign: "center",
    padding: 25,
  },
  cardContent: {
    padding: 0,
  },
  componentContainer: {
    padding: 14,
    overflow: "auto",
    height: "250px",
  },
}));
const CloseSessionDialog = ({
  open,
  handleClose,
  text,
  title,
  handleConfirm,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.titleContainer}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          <strong>{text}</strong>
        </Typography>
      </DialogContent>
      <DialogActions style={{ margin: 10 }}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseSessionDialog;
