import { GET_LIQUIDACIONES_DINERARIAS } from "../actions/dinerarias";

const INITIAL_STATE = {
  liquidaciones: {
    loading: false,
    error: false,
    data: [],
  },
};

const dinerarias = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_LIQUIDACIONES_DINERARIAS}_PENDING`:
      return {
        ...state,
        liquidaciones: {
          ...state.liquidaciones,
          error: false,
          loading: true,
        },
      };
    case `${GET_LIQUIDACIONES_DINERARIAS}_REJECTED`:
      return {
        ...state,
        liquidaciones: {
          ...state.liquidaciones,
          error: true,
          loading: false,
        },
      };
    case `${GET_LIQUIDACIONES_DINERARIAS}_FULFILLED`:
      return {
        ...state,
        liquidaciones: {
          ...state.liquidaciones,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };

    default:
      return state;
  }
};

export default dinerarias;
