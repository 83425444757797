import {
  Button,
  TablePagination,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

const columns = [
  { id: "rubro", label: "Rubro" },
  { id: "importe", label: "Importe" },
  { id: "actions", label: "Acciones" },
];

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 10,
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  buttonContainer: {
    margin: "30px 25px 0px 0px",
    "& button": {
      marginLeft: 10,
    },
  },
}));

const DatosComprobanteTable = ({
  data,
  handleOpenAddDialog,
  handleOpenEditDialog,
  handleDelete,
  totalDetalle,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div
        style={{ margin: 20, display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h5" color="primary">
          Detalle
        </Typography>
        <Typography variant="h6">
          Total:
          <span style={{ marginLeft: 5 }}>
            {totalDetalle.toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </Typography>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.nombre}</TableCell>
                    <TableCell>
                      ${" "}
                      {parseFloat(row.importe.replace(",", ".")).toLocaleString(
                        "es-AR",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </TableCell>
                    <TableCell width="15%">
                      <Tooltip title="Editar" placement="bottom">
                        <EditIcon
                          className={classes.icon}
                          onClick={() => handleOpenEditDialog(row)}
                        />
                      </Tooltip>
                      <Tooltip title="Eliminar" placement="bottom">
                        <DeleteIcon
                          className={classes.icon}
                          onClick={() => handleDelete(row)}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!data.length && (
            <Typography align="center" style={{ padding: 40 }}>
              No se han agregado detalles.
            </Typography>
          )}
          <div className={classes.tableFooter}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : to}`
              }
            />
            <Button
              variant="outlined"
              color="primary"
              style={{ height: "20%", margin: 25 }}
              onClick={handleOpenAddDialog}
            >
              Agregar
            </Button>
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default DatosComprobanteTable;
