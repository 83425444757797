import React from "react";
import styled from "styled-components";
import { Loop } from "@material-ui/icons";

const Title = styled.div`
  font-size: large;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin-right: 7px;
  color: #484848;
  margin-bottom: 8px;
`;
const Subtitle = styled.div`
  font-size: 17px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: grey;
  margin-bottom: 8px;
  margin-left: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 550px;
  margin-top: 20px;
`;

const LoopStyled = styled(Loop)`
cursor: pointer;
color: '#4a148c'
  &: hover {
  background - color: #deaede77;
  color: '#4a148c'
  cursor: none;
}
`;

const TitleWithFetchData = ({
  title,
  noRefresh,
  fetchData,
  subTitle,
  saldoALaFecha,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {!noRefresh && fetchData && <LoopStyled onClick={fetchData} />}
      {subTitle && <Subtitle>Saldo a la fecha: {saldoALaFecha}</Subtitle>}
    </Container>
  );
};

export default TitleWithFetchData;
