import { makeStyles } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { Paper, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearNotificacion,
  getNotificacion,
  getNotificacionesYCitas,
  marcarNotificacionLeida,
} from "../../../../../../../../redux/actions/notificacion";
import PdfDialog from "../../../../../../../commons/file/PdfDialog";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  emptyState: {
    textAlign: "center",
  },
}));

const NotificacionesCard = ({ data, idSiniestroCabecera }) => {
  const classes = useStyles();
  const [openNotificacionPdf, setOpenNotificacionPdf] = useState(false);
  const [notificacionSeleccionada, setNotificacionSeleccionada] =
    useState(null);
  const notificacionRedux = useSelector((state) => state.notificacion);
  const dispatch = useDispatch();

  const handleOpenDetail = (notificacion) => {
    setOpenNotificacionPdf(true);
    setNotificacionSeleccionada(notificacion);
    dispatch(getNotificacion(notificacion.idNotificacionFehaciente));
  };
  const handleClose = () => {
    setOpenNotificacionPdf(false);
    setNotificacionSeleccionada(null);
    dispatch(clearNotificacion());
    dispatch(getNotificacionesYCitas(idSiniestroCabecera));
  };

  const sendNotificacion = () => {
    dispatch(
      marcarNotificacionLeida(notificacionSeleccionada.idNotificacionFehaciente)
    ).then(() => toast.success("Notificación leída"));
  };

  return (
    <>
      <div>
        {data.length ? (
          data.map((notificacion) => {
            return (
              <Paper
                className={classes.card}
                key={notificacion.idNotificacionFehacienteSiniestroCabecera}
              >
                <div style={{ width: 350 }}>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    {moment(notificacion.fecha).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography variant="body2">
                    {notificacion.tipoNotificacion}
                  </Typography>
                </div>
                <div className={classes.actionsContainer}>
                  <Tooltip
                    title={"Ver notificación"}
                    className={classes.button}
                    onClick={() => handleOpenDetail(notificacion)}
                  >
                    <Visibility />
                  </Tooltip>
                </div>
              </Paper>
            );
          })
        ) : (
          <Typography className={classes.emptyState} variant="body2">
            No hay notificaciones o citas disponibles
          </Typography>
        )}
      </div>
      <div>
        <PdfDialog
          open={
            openNotificacionPdf && notificacionRedux.notificacion.data.base64
              ? true
              : false
          }
          title="Notificación"
          handleClose={handleClose}
          controllers={true}
          file={notificacionRedux.notificacion.data.base64}
          sendNotificacion={sendNotificacion}
        />
      </div>
    </>
  );
};

export default NotificacionesCard;
