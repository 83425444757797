import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  clearExamenesPeriodicos,
  getDatosEstablecimiento,
  getEstablecimientos,
  getLotesExamenesPeriodicos,
  saveSearchParams,
} from "../../redux/actions/examenesPeriodicos";
import { convertDateTimeToNet } from "../../util/datetime";
import HeaderForm from "../commons/labels/HeaderForm";
import SearchLoteForm from "./forms/SearchLoteForm";
import TabsContainer from "./tabs/TabsContainer";

const ExamenesPeriodicosContainer = () => {
  const dispatch = useDispatch();

  const [formDisplay, setFormDisplay] = useState(true);

  useEffect(() => {
    dispatch(getEstablecimientos());
  }, []);

  const handleSubmitSearchForm = (values) => {
    // guardamos los parametros en el store porque
    // el idEstablecimiento se utiliza en otros componentes
    dispatch(saveSearchParams(values));

    dispatch(getDatosEstablecimiento(values.idEstablecimiento)).then((res) => {
      // si la dependencia fue dada de baja,
      // no continuar al resumen de gestión
      const establecimientoDadoDeBaja = res?.value?.data?.baja;
      if (establecimientoDadoDeBaja) {
        toast.error(
          "El establecimiento se encuentra dado de baja, por favor comunicarse con la ART."
        );
      } else {
        dispatch(
          getLotesExamenesPeriodicos(
            values.idEstablecimiento,
            convertDateTimeToNet(values.fechaDesde),
            convertDateTimeToNet(values.fechaHasta)
          )
        ).then((resp) => {
          const establecimientoTieneLotes = resp?.value?.data?.length > 0;
          // por especificacion, si la dependencia no tiene datos para
          // las fechas seleccionadas, el usuario no puede continuar
          if (!establecimientoTieneLotes) {
            toast.error(
              "No se han encontrado datos para el establecimiento y fechas seleccionadas."
            );
          } else {
            setFormDisplay(false);
          }
        });
      }
    });
  };

  const handleGoBack = () => {
    // por especificacion, cada vez que el usuario vuelve
    // al formualrio de busqueda, los filtros deben estar vacios
    dispatch(clearExamenesPeriodicos());
    setFormDisplay(true);
  };

  return (
    <Paper>
      <HeaderForm title="Exámenes Médicos Periódicos" />
      {formDisplay ? (
        <SearchLoteForm handleSubmit={handleSubmitSearchForm} />
      ) : (
        <TabsContainer goBack={handleGoBack} />
      )}
    </Paper>
  );
};

export default ExamenesPeriodicosContainer;
