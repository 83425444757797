import { Drawer, IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import MenuAppBar from "./MenuAppBar";

const DRAWER_WIDTH = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    borderRight: 0,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "white",
  },
  drawerOpen: {
    // el width=drawerWidth esta repetido porque sino, las flechas
    // para abrir y cerrar los submenus quedan desalineadas
    width: DRAWER_WIDTH,
    // este top es necesario para que la parte superior
    // del drawer no quede oculta por el Header
    top: "65px",
    height: "calc(100vh - 65px)",
    overflow: "hidden",
    borderRight: 0,

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    "&:hover": {
      overflowY: "auto",
    },
  },
  drawerClose: {
    // este top es necesario para que la parte superior
    // del drawer no quede oculta por el Header
    top: "65px",
    width: theme.spacing(8),
    height: "calc(100vh - 65px)",
    overflow: "hidden",
    borderRight: 0,
    paddingRight: -15,

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    "&:hover": {
      overflowY: "overlay",
      "&::-webkit-scrollbar": {
        width: "0.4em !important",
      },
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 15,
    zIndex: 9999,
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    backgroundColor: "white",
  },
  arrowToolbar: {
    marginLeft: 15,
    color: theme.palette.primary.menuItem,
  },
  logo: {
    height: 50,
  },
}));

function ResponsiveDrawer({ open, handleDrawerToggle }) {
  const classes = useStyles();
  const theme = useTheme();

  const logo = useSelector((state) => state.logo.getLogo);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <img src={logo.data} alt="logo" className={classes.logo} />
        <IconButton
          className={classes.arrowToolbar}
          onClick={handleDrawerToggle}
          size="small"
        >
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <MenuAppBar />
    </Drawer>
  );
}

export default ResponsiveDrawer;
