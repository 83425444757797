import {
  Button,
  CircularProgress,
  FormControl,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SendIcon from "@mui/icons-material/Send";
import { FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DropZone from "../../../commons/dropZone/DropZone";
import NominaEmailDialog from "./NominaEmailDialog";
import NominaTable from "./NominaTable";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    width: "95%",
    margin: "auto",
  },
  contentContainer: {
    display: "flex",
    padding: 20,
    // para que en la vista mobile, la seleccion
    // de empleados quede arriba de la tabla de nominas
    [theme.breakpoints.down(601)]: {
      flexFlow: "column wrap",
      rowGap: "1vh",
    },
  },
  dropzone: {
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 20,
    // para que en la vista mobile, los botones
    // queden uno arriba del otro
    [theme.breakpoints.down(601)]: {
      flexFlow: "column wrap",
      gap: "2vh",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
}));

const NominaContainer = ({ handleSubmit }) => {
  const classes = useStyles();

  const descargarCertificado = useSelector(
    (state) => state.certificadoCobertura.descargar
  );
  const enviarCertificado = useSelector(
    (state) => state.certificadoCobertura.enviarMail
  );
  const validarNominas = useSelector(
    (state) => state.certificadoCobertura.validarNominas
  );

  const [value, setValue] = useState(null);
  const [nominasSelected, setNominasSelected] = useState([]);
  const [nominaFile, setNominaFile] = useState(null);
  const [openSendEmail, setOpenSendEmail] = useState(false);

  const disabled =
    enviarCertificado.loading === true ||
    descargarCertificado.loading === true ||
    validarNominas.loading === true ||
    value === null ||
    (value === "3" && nominasSelected.length === 0) ||
    (value === "4" && (nominaFile === null || nominaFile?.length <= 0));

  useEffect(() => {
    if (value !== "3") {
      setNominasSelected([]);
    }
    if (value !== "4") {
      setNominaFile(null);
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSendEmail = (email) => {
    handleSubmit(email, value, nominasSelected, nominaFile);
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <FormControl>
          <RadioGroup
            name="nomina"
            onChange={handleChange}
            value={value}
            style={{ rowGap: "1vw" }}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Sin nómina"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="Todas las nóminas"
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="Selección de nóminas"
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="Importar selección de nóminas"
            />
          </RadioGroup>
        </FormControl>
        {value === "3" && (
          <NominaTable
            nominasSelected={nominasSelected}
            setNominasSelected={setNominasSelected}
          />
        )}
        {value === "4" && (
          <div className={classes.dropzone}>
            <DropZone
              file={nominaFile}
              setFile={setNominaFile}
              loading={false}
              extension=".csv, .xls o .txt"
              accept={{
                "text/csv": [".csv"],
                "application/vnd.ms-excel": [".xls"],
                "text/plain": [".txt"],
              }}
              dropZoneText="Arrastre aquí o haga click en el recuadro para importar la nómina en formato xls, cvs o lista de CUILs."
              dropZoneEditText="Si desea cambiar el archivo arrastrelo nuevamente o haga click en
        el recuadro."
            />
          </div>
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          disabled={disabled}
          onClick={() => setOpenSendEmail(true)}
        >
          {enviarCertificado.loading || validarNominas.loading ? (
            <CircularProgress size={24} thickness={4} color="secondary" />
          ) : (
            "Enviar certificado de cobertura"
          )}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          endIcon={<FileDownloadIcon />}
          disabled={disabled}
          onClick={() => handleSubmit(null, value, nominasSelected, nominaFile)}
        >
          {descargarCertificado.loading || validarNominas.loading ? (
            <CircularProgress size={24} thickness={4} color="secondary" />
          ) : (
            "Descargar certificado de cobertura"
          )}
        </Button>
      </div>
      <NominaEmailDialog
        open={openSendEmail}
        handleClose={() => setOpenSendEmail(false)}
        handleSubmit={handleSendEmail}
      />
    </div>
  );
};

export default NominaContainer;
