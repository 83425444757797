import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    heigth: 20,
    marginTop: 10,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const InputLabel = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.chip}>
      <Typography>{title}</Typography>
    </div>
  );
};

export default InputLabel;
