import React from "react";
import Routing from "./routes/Routing";
import { persistor, store } from "./redux/store/store";
import { Provider } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";
import colors from "./config/colors";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const themeMuiTheme = createTheme(colors);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={themeMuiTheme}>
          <Routing />
          <ToastContainer
            position="bottom-right"
            hideProgressBar
            theme="colored"
          />
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
