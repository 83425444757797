import {
  IMPORTAR_NOMINA,
  EXISTE_NOMINA,
  POST_DATOS_PARTICULARES,
  CLEAR_EXISTE_NOMINA,
  POST_LAST_FORM,
  POST_DATOS_DOMICILIARIOS,
  POST_DATOS_NOMINALES,
  CLEAR_NOMINA,
  SAVE_CUIL,
  CLEAR_DATOS_POST_NOMINA,
} from "../actions/nomina";

const INITIAL_STATE = {
  cuil: 0,
  nomina: {
    loading: false,
    error: false,
    data: {},
  },
  existeNomina: {
    loading: false,
    error: false,
    data: null,
  },
  postNomina: {
    datosParticulares: {},
    datosDomiciliarios: {},
    datosNominales: {},
  },
};

const contrato = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${IMPORTAR_NOMINA}_PENDING`:
      return {
        ...state,
        nomina: { ...state.nomina, error: false, loading: true },
      };
    case `${IMPORTAR_NOMINA}_REJECTED`:
      return {
        ...state,
        nomina: { ...state.nomina, error: true, loading: false },
      };
    case `${IMPORTAR_NOMINA}_FULFILLED`:
      return {
        ...state,
        nomina: {
          ...state.nomina,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${EXISTE_NOMINA}_PENDING`:
      return {
        ...state,
        existeNomina: { ...state.existeNomina, error: false, loading: true },
      };
    case `${EXISTE_NOMINA}_REJECTED`:
      return {
        ...state,
        existeNomina: { ...state.existeNomina, error: true, loading: false },
      };
    case `${EXISTE_NOMINA}_FULFILLED`:
      return {
        ...state,
        existeNomina: {
          ...state.existeNomina,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_LAST_FORM}_PENDING`:
      return {
        ...state,
        sendNomina: { ...state.sendNomina, error: false, loading: true },
      };
    case `${POST_LAST_FORM}_REJECTED`:
      return {
        ...state,
        sendNomina: { ...state.sendNomina, error: true, loading: false },
      };
    case `${POST_LAST_FORM}_FULFILLED`:
      return {
        ...state,
        sendNomina: {
          ...state.sendNomina,
          error: false,
          loading: false,
          data: action.payload,
        },
      };
    case POST_DATOS_PARTICULARES:
      return {
        ...state,
        postNomina: {
          ...state.postNomina,
          datosParticulares: { ...action.payload },
        },
      };
    case POST_DATOS_DOMICILIARIOS:
      return {
        ...state,
        postNomina: {
          ...state.postNomina,
          datosDomiciliarios: { ...action.payload },
        },
      };
    case POST_DATOS_NOMINALES:
      return {
        ...state,
        postNomina: {
          ...state.postNomina,
          datosNominales: { ...action.payload },
        },
      };
    case CLEAR_DATOS_POST_NOMINA:
      return {
        ...state,
        postNomina: INITIAL_STATE.postNomina,
      };
    case SAVE_CUIL:
      return {
        ...state,
        cuil: action.payload,
      };
    case CLEAR_EXISTE_NOMINA:
      return {
        ...state,
        existeNomina: {
          ...INITIAL_STATE.existeNomina,
        },
      };
    case CLEAR_NOMINA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default contrato;
