import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { List, ListItem, ListItemText } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";

const DiagnosticosCard = ({ data }) => {
  const datosSiniestro = useSelector(
    (state) => state.siniestralidad.datosSiniestro.data
  );

  return (
    <div>
      {data.map((diagnostico) => (
        <Accordion key={diagnostico.diagnostico}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{ display: "flex" }}>
              <Typography
                sx={{
                  fontSize: "13.5px",
                  marginRight: "5px",
                  color: diagnostico.principal ? "green" : "",
                }}
              >
                {diagnostico.diagnostico}
              </Typography>
              {diagnostico.principal && (
                <Typography
                  sx={{ fontSize: "13.5px", fontWeight: 500, color: "green" }}
                >
                  Principal
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ display: "flex" }}>
              <ListItem>
                <ListItemText
                  primary={
                    datosSiniestro.tipoSiniestro === "Enfermedad Profesional"
                      ? "Ambito detección"
                      : "Naturaleza Lesión"
                  }
                  secondary={
                    datosSiniestro.tipoSiniestro === "Enfermedad Profesional"
                      ? diagnostico.ambitoDeteccion
                      : diagnostico.naturalezaLesion.split(" - ")[1]
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"Zona Afectada"}
                  secondary={diagnostico.zonaAfectada.split(" - ")[1]}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default DiagnosticosCard;
