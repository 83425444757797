import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGravedades,
  getNaturalezasLesion,
  getZonasAfectadas,
  putDiagnosticoAccidente,
  saveDiagnosticoAccidente,
} from "../../../../../redux/actions/denuncia";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import { agregarDiagnosticoAccidenteValidation } from "../validation/formValidation";

const useStyles = makeStyles({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  input: {
    flex: 1,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
  checkbox: {
    marginLeft: 20,
  },
});

const DiagnosticoAccidenteDialog = ({
  onEdit,
  handleClose,
  open,
  diagnostico,
  setOnEditFalse,
  disabledCheck,
  firstDiag,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    diagnosticoOms: null,
    gravedad: null,
    principal: firstDiag,
    naturalezaLesion: null,
    zonaAfectada: null,
  };

  const diagnosticoOMS = useSelector((state) => state.denuncia.diagnosticosOMS);
  const gravedades = useSelector((state) => state.denuncia.gravedades);
  const zonasAfectadas = useSelector((state) => state.denuncia.zonasAfectadas);
  const naturalezasLesion = useSelector(
    (state) => state.denuncia.naturalezasLesion
  );

  const [indice, setIndice] = useState(1);
  const [diagnosticoState, setDiagnosticoState] = useState(initialValues);
  const [descAmbitoDeteccion, setDescAmbitoDeteccion] = useState("");
  const [descGravedad, setDescGravedad] = useState("");
  const [descZonasAfectadas, setDescZonasAfectadas] = useState("");
  const [descNaturalezaLesion, setDescNaturalezaLesion] = useState("");
  const [descDiagnosticoOms, setDescDiagnosticoOms] = useState("");

  useEffect(() => {
    if (diagnostico) {
      setDiagnosticoState(diagnostico);
    }
  }, [diagnostico]);

  const handleSumbit = (values) => {
    if (onEdit) {
      const valores = { ...values, id: diagnosticoState.id };
      dispatch(putDiagnosticoAccidente(valores));

      setOnEditFalse();
    } else {
      values = {
        ...values,
        id: indice,
        descAmbitoDeteccion: descAmbitoDeteccion,
        descGravedad: descGravedad,
        descZonasAfectadas: descZonasAfectadas,
        descNaturalezaLesion: descNaturalezaLesion,
        descDiagnosticoOms: descDiagnosticoOms,
      };
      dispatch(saveDiagnosticoAccidente(values));
      setIndice(indice + 1);
    }
    handleClose();
  };

  const handleGetValues = (value) => {
    dispatch(getGravedades(value));
    dispatch(getNaturalezasLesion(value));
    dispatch(getZonasAfectadas(value));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        {onEdit ? "Editar" : "Agregar"} Diagnóstico de Accidente
      </DialogTitle>
      {!diagnosticoState ? (
        <DialogContent className={classes.loading}>
          <CircularLoading />
        </DialogContent>
      ) : (
        <Formik
          initialValues={onEdit ? diagnosticoState : initialValues}
          validationSchema={agregarDiagnosticoAccidenteValidation}
          onSubmit={handleSumbit}
        >
          {({ values }) => (
            <Form>
              <DialogContent>
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="diagnosticoOms"
                    label="Diagnóstico CIE-10 *"
                    className={classes.input}
                    options={diagnosticoOMS.data}
                    renderOption={(option) => option.nombre}
                    filterOptions={(option) => option.nombre}
                    callBack={(value) => {
                      if (value) {
                        let arr = value.nombre.split(" - ");
                        handleGetValues(value);
                        setDescDiagnosticoOms(arr[1]);
                      }
                    }}
                  />
                </div>
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="gravedad"
                    label="Gravedad *"
                    className={classes.input}
                    options={gravedades.data}
                    renderOption={(option) => option.nombre}
                    filterOptions={(option) => option.nombre}
                    disabled={!values.diagnosticoOms}
                    getOptions={() =>
                      dispatch(getGravedades(values.diagnosticoOms))
                    }
                    callBack={(value) => {
                      if (value) {
                        setDescGravedad(value.nombre);
                      }
                    }}
                  />
                  <FormikCheckbox
                    name="principal"
                    label="Principal"
                    className={classes.checkbox}
                    disabled={disabledCheck || firstDiag}
                  />
                </div>
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="naturalezaLesion"
                    label="Naturaleza de lesión *"
                    className={classes.input}
                    options={naturalezasLesion.data}
                    renderOption={(option) => option.nombre}
                    filterOptions={(option) => option.nombre}
                    disabled={!values.diagnosticoOms}
                    getOptions={() =>
                      dispatch(getNaturalezasLesion(values.diagnosticoOms))
                    }
                    callBack={(value) => {
                      if (value) {
                        let array = value.nombre.split(" - ");
                        setDescNaturalezaLesion(array[1]);
                      }
                    }}
                  />
                </div>
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="zonaAfectada"
                    label="Zona afectada *"
                    className={classes.input}
                    options={zonasAfectadas.data}
                    renderOption={(option) => option.nombre}
                    filterOptions={(option) => option.nombre}
                    disabled={!values.diagnosticoOms}
                    getOptions={() =>
                      dispatch(getZonasAfectadas(values.diagnosticoOms))
                    }
                    callBack={(value) => {
                      if (value) {
                        let array = value.nombre.split(" - ");
                        setDescZonasAfectadas(array[1]);
                      }
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <FormikActions handleCancel={handleClose}></FormikActions>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default DiagnosticoAccidenteDialog;
