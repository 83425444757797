import { Button, Switch, makeStyles } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTiposClausulasCertificado } from "../../../../redux/actions/certificadoCobertura";
import CircularLoading from "../../../commons/loading/CircularLoading";
import EmpresaContainer from "./empresa/EmpresaContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    height: "15vh",
  },
  contentContainer: {
    display: "flex",
    padding: 20,
    // para que en la vista mobile, la seleccion
    // del tipo de clausula quede arriba de
    // la tabla de empresas
    [theme.breakpoints.down(601)]: {
      flexFlow: "column wrap",
      rowGap: "1vh",
    },
  },
  clausulasContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "1vw",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
}));

const ClausulaContainer = ({ nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tiposClausulas = useSelector(
    (state) => state.certificadoCobertura.clausulas
  );

  const [clausulasChecked, setClausulasChecked] = useState([]);
  const [empresaList, setEmpresaList] = useState([]);
  const [usarCargaPorTexto, setUsarCargarPorTexto] = useState(false);

  const clausulaSubrogacionSeleccionada = clausulasChecked.some(
    (id) => id.toUpperCase() === "CS"
  );
  const mostrarTablaEmpresas = clausulaSubrogacionSeleccionada;
  const usuarioNoPuedeContinuar =
    clausulaSubrogacionSeleccionada && empresaList.length === 0;

  useEffect(() => {
    if (tiposClausulas.data.length === 0) {
      dispatch(getTiposClausulasCertificado());
    }
  }, []);

  const handleChange = (event, clausula) => {
    const clausulasAux = [...clausulasChecked];
    if (event.target.checked) {
      clausulasAux.push(clausula.id);
    } else {
      const index = clausulasAux.indexOf(clausula.id);
      if (index > -1) {
        clausulasAux.splice(index, 1);
      }
    }
    setClausulasChecked(clausulasAux);
  };

  // Si el usuario carga las empresas por texto, al back
  // le vamos a enviar ese string (con un formato arbitrario)
  // en el primer objeto de la lista de empresas.
  const handleSubmitEmpresasCargaDirecta = (empresas) => {
    // Tenemos que sobreescribir la lista de empresas cargadas por tabla.
    setEmpresaList([empresas]);
  };

  return (
    <div className={classes.container}>
      {tiposClausulas.loading ? (
        <div className={classes.loadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <div className={classes.contentContainer}>
          <div className={classes.clausulasContainer}>
            <FormControlLabel
              control={<Switch />}
              onChange={() => {
                // Activar el Switch de "carga por texto" deberia
                // borrar la lista de empresas cargadas por tabla.
                // Esto es porque, al cargar por texto, necesitamos enviar
                // 1 solo objeto en la lista de empresas.
                // La tabla carga 1 objeto por 1 fila, entonces no podemos
                // haber cargado empresas en la tabla y luego cargar por texto.
                setEmpresaList([]);
                setUsarCargarPorTexto((prev) => !prev);
              }}
              disabled={!clausulaSubrogacionSeleccionada}
              label="Carga por texto"
            />
            {tiposClausulas.data.map((clausula) => (
              <FormControlLabel
                key={clausula.id}
                control={
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: "#007864",
                      },
                    }}
                    onChange={(e) => handleChange(e, clausula)}
                  />
                }
                label={clausula.nombre}
              />
            ))}
          </div>
          {mostrarTablaEmpresas && (
            <EmpresaContainer
              empresaList={empresaList}
              setEmpresaList={setEmpresaList}
              mostrarCargaPorTexto={usarCargaPorTexto}
              handleCargarEmpresasPorTexto={handleSubmitEmpresasCargaDirecta}
            />
          )}
        </div>
      )}
      <div className={classes.button}>
        <Button
          color="primary"
          variant="contained"
          disabled={usuarioNoPuedeContinuar ? true : false}
          onClick={() => nextForm(clausulasChecked, empresaList)}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default ClausulaContainer;
