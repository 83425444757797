import { TablePagination } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React from "react";
import BackArrowHeader from "../../../../commons/arrow/BackArrowHeader";
import ExportButton from "../../../../commons/export/ExportButton";
import {
  localization,
  tableStyles,
} from "../../../../commons/table/tableConfig";
import TitleContainer from "../../../../commons/table/utilsTable/TitleWithFetchData";

const TrabajadoresTable = ({ data, loading, handleGoBack }) => {
  const columns = [
    {
      title: "Número de Documento",
      field: "numeroDocumento",
      render: (rowData) =>
        // que el numero de documento tenga formato de miles
        Intl.NumberFormat("es-AR").format(rowData.numeroDocumento),
    },
    {
      title: "Apellido, Nombre/s",
      field: "nombreCompleto",
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={{ ...tableStyles.container }}
      title={<TitleContainer title="Trabajadores Expuestos" />}
      options={{
        actionsColumnIndex: -1,
        headerStyle: tableStyles.headerStyle,
        rowStyle: tableStyles.rowStyle,
        pageSize: 10,
        search: false,
        filtering: true,
      }}
      components={{
        Toolbar: (props) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingTop: 10 }}>
                <BackArrowHeader
                  onClick={handleGoBack}
                  title="Resumen de gestión"
                />
              </div>
              <ExportButton {...props} title="Trabajadores Expuestos" />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MTableToolbar {...props} />
            </div>
          </>
        ),
        Pagination: (props) => {
          const { onChangePage, onChangeRowsPerPage, ...tablePaginationProps } =
            props;
          return (
            <TablePagination
              {...tablePaginationProps}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                  <props.ActionsComponent
                    {...actionsComponentProps}
                    onChangePage={onPageChange}
                  />
                );
              }}
            />
          );
        },
      }}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay datos disponibles para mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};

export default TrabajadoresTable;
