import {
  Button,
  TablePagination,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDiagnosticoAccidente } from "../../../../redux/actions/denuncia";
import DiagnosticoAccidenteDialog from "../forms/dialog/DiagnosticoAccidenteDialog";

const columns = [
  { id: "diagnosticoOms", label: "Diagnóstico" },
  { id: "gravedad", label: "Gravedad" },
  { id: "zonaAfectada", label: "Zona Afectada" },
  { id: "naturalezaLesion", label: "Naturaleza" },
  { id: "principal", label: "Principal" },
  { id: "acciones", label: "Acciones" },
];

const useStyles = makeStyles({
  icon: {
    marginLeft: 10,
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    margin: "30px 25px 0px 0px",
    "& button": {
      marginLeft: 10,
    },
  },
});

const AccidenteTable = ({ data, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cantidad = useSelector(
    (state) => state.denuncia.postDenuncia.diagnosticoAccidente.length
  );
  const listaAccidente = useSelector(
    (state) => state.denuncia.postDenuncia.diagnosticoAccidente
  );

  const [hayPrincipal, setHayPrincipal] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAddDiagnostico, setOpenAddDiagnostico] = useState(false);
  const [disabledCheck, setDisabledCheck] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [diagnostico, setDiagnostico] = useState({});
  const [firstDiag, setFirstDiag] = useState(true);

  useEffect(() => {
    if (cantidad) {
      setFirstDiag(false);
    } else {
      setFirstDiag(true);
    }
  }, [cantidad]);

  useEffect(() => {
    setHayPrincipal(listaAccidente.find((e) => e.principal === true));
    if (!hayPrincipal) {
      setDisabledCheck(false);
    }
  }, [listaAccidente, openAddDiagnostico]);

  const deleteDiagnostico = (id) => {
    dispatch(deleteDiagnosticoAccidente(id));
  };

  const addDiagnostico = () => {
    setOnEdit(false);
    openDialogWithConditional();
  };

  const openDialogWithConditional = (diagnostico, onEdit) => {
    if (hayPrincipal && onEdit) {
      if (diagnostico.id !== hayPrincipal.id) {
        setDisabledCheck(true);
      } else {
        setDisabledCheck(false);
      }
    } else if (hayPrincipal && !onEdit) {
      setDisabledCheck(true);
    }
    setOpenAddDiagnostico(true);
  };

  const editDiagnostico = (diagnostico) => {
    setDiagnostico(diagnostico);
    setOnEdit(true);
    openDialogWithConditional(diagnostico, true);
  };

  const handleSetHayPrincipal = (values) => {
    setHayPrincipal(values);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpenAddDiagnostico(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell style={{ width: "200px" }}>
                  {row.descDiagnosticoOms}
                </TableCell>
                <TableCell>{row.descGravedad}</TableCell>
                <TableCell>{row.descZonasAfectadas}</TableCell>
                <TableCell>{row.descNaturalezaLesion}</TableCell>
                <TableCell>
                  <Checkbox checked={row.principal} />
                </TableCell>
                <TableCell>
                  <Tooltip title="Editar" placement="bottom" arrow>
                    <EditIcon
                      onClick={() => editDiagnostico(row)}
                      className={classes.icon}
                    />
                  </Tooltip>
                  <Tooltip title="Eliminar" placement="bottom" arrow>
                    <DeleteIcon
                      onClick={() => deleteDiagnostico(row.id)}
                      className={classes.icon}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.tableFooter}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              onClick={addDiagnostico}
              disabled={cantidad === 3}
            >
              Agregar Diagnostico
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit(true)}
              disabled={!hayPrincipal && Boolean(cantidad)}
            >
              Guardar Formulario
            </Button>
          </div>
        </div>
      </TableContainer>
      <DiagnosticoAccidenteDialog
        open={openAddDiagnostico}
        onEdit={onEdit}
        diagnostico={diagnostico}
        handleClose={handleClose}
        setOnEditFalse={() => setOnEdit(false)}
        setHayPrincipal={handleSetHayPrincipal}
        disabledCheck={disabledCheck}
        firstDiag={firstDiag}
      />
    </>
  );
};

export default AccidenteTable;
