import { Button, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getDependenciasEmpleador,
  getModalidadesDenuncia,
  getTiposSiniestro,
} from "../../../redux/actions/denuncia";
import FormikAutocomplete from "../../commons/formik/FormikAutocomplete";
import FormikDatePicker from "../../commons/formik/FormikDatePicker";
import FormikTextField from "../../commons/formik/FormikTextField";

const validationSchema = Yup.object({
  nroSiniestroART: Yup.number().nullable().min(1, "Valor inválido"),
  nroDocumento: Yup.number().nullable().min(1, "Valor inválido"),
  fechaDesde: Yup.date().nullable().typeError("Fecha inválida"),
  fechaHasta: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor a la fecha desde",
      test: function (value) {
        return value && this.parent.fechaDesde
          ? value > this.parent.fechaDesde
          : true;
      },
    }),
});

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
  },
  filterItem: {
    margin: 10,
    width: 200,
  },
  establecimientoCombo: {
    margin: 10,
    width: 420,
  },
  buttonContainer: {
    margin: "20px 0px 0px 10px",
  },
}));

const SiniestroFilterForm = ({ handleSubmit, initialValues }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const modalidades = useSelector((state) => state.denuncia.modalidades);
  const tiposSiniestro = useSelector((state) => state.denuncia.tiposSiniestro);
  const dependenciasEmpleador = useSelector(
    (state) => state.denuncia.dependenciasEmpleador
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className={classes.formContainer}>
          <div className={classes.rowContainer}>
            <div className={classes.filterItem}>
              <FormikTextField
                name="nroSiniestroART"
                label="Nro. Siniestro"
                type="number"
              />
            </div>
            <div className={classes.filterItem}>
              <FormikAutocomplete
                name="idModalidadDenuncia"
                label="Modalidad de denuncia"
                options={modalidades.data}
                getOptions={() => dispatch(getModalidadesDenuncia())}
              />
            </div>
            <div className={classes.filterItem}>
              <FormikAutocomplete
                name="idTipoSiniestro"
                label="Tipo Siniestro"
                options={tiposSiniestro.data}
                getOptions={() => dispatch(getTiposSiniestro())}
              />
            </div>
            <div className={classes.establecimientoCombo}>
              <FormikAutocomplete
                name="idDependenciaEmpleador"
                label="Establecimiento"
                options={dependenciasEmpleador.data}
                getOptions={() => dispatch(getDependenciasEmpleador())}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.filterItem}>
              <FormikTextField
                name="nroDocumento"
                label="Nro. Documento"
                type="number"
              />
            </div>
            <div className={classes.filterItem}>
              <FormikTextField name="apellido" label="Apellido" type="text" />
            </div>
            <div className={classes.filterItem}>
              <FormikTextField name="nombres" label="Nombre" type="text" />
            </div>
            <div className={classes.filterItem}>
              <FormikDatePicker
                name="fechaDesde"
                label="Ocurrencia desde"
                ampm={false}
                format="dd/MM/yyyy"
                variant="inline"
              />
            </div>
            <div className={classes.filterItem}>
              <FormikDatePicker
                name="fechaHasta"
                label="Ocurrencia hasta"
                ampm={false}
                format="dd/MM/yyyy"
                variant="inline"
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  !(
                    values.nroSiniestroART !== null ||
                    values.idModalidadDenuncia !== "" ||
                    values.idTipoSiniestro !== "" ||
                    values.idDependenciaEmpleador !== "" ||
                    values.nroDocumento !== "" ||
                    values.apellido !== "" ||
                    values.nombres !== "" ||
                    values.fechaDesde !== null ||
                    values.fechaHasta !== null
                  )
                }
              >
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SiniestroFilterForm;
