import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: 0.5,
    display: "block",
    wordWrap: "break-word",
  },
}));
const GestionesParticularesCard = ({ data }) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem key="cronico" className={classes.listItem}>
        <ListItemText
          primary="Crónico"
          secondary={data?.cronico ? data.cronico : "Sin Información"}
        />
      </ListItem>
      <ListItem key="recalificacion" className={classes.listItem}>
        <ListItemText
          primary="Recalificación"
          secondary={
            data?.recalificacion ? data.recalificacion : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="demandaJudicial" className={classes.listItem}>
        <ListItemText
          primary="Demanda judicial"
          secondary={
            data?.demandaJudicial ? data.demandaJudicial : "Sin Información"
          }
        />
      </ListItem>
      <ListItem key="estado" className={classes.listItem}>
        <ListItemText
          primary="Estado"
          secondary={data?.estado ? data.estado : "Sin Información"}
        />
      </ListItem>
    </List>
  );
};

export default GestionesParticularesCard;
