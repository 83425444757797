import {
  Button,
  TablePagination,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ExportButton from "../commons/export/ExportButton";
import FormikDatePicker from "../commons/formik/FormikDatePicker";
import { localization, tableStyles } from "../commons/table/tableConfig";
import TitleContainer from "../commons/table/utilsTable/TitleWithFetchData";
import CuentaCorrienteAccountData from "./CuentaCorrienteAccountData";
import ReporteEstadoButton from "./ReporteEstadoButton";

const useStyles = makeStyles((theme) => ({
  filterItem: {
    margin: "10px 0px 10px 25px",
    "& p": {
      fontSize: 12,
      marginTop: 5,
      color: "red",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  buttonContainer: {
    margin: "20px 0px 0px 30px",
  },
  toolbarActionsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
  },
}));

const initialValues = {
  fechaDesde: null,
  fechaHasta: null,
};

const validationSchema = Yup.object().shape({
  fechaDesde: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaDesde",
      message: "La fecha desde debe ser menor o igual a la fecha hasta",
      test: function (value) {
        if (value <= this.parent.fechaHasta) {
          return true;
        }
        return false;
      },
    }),

  fechaHasta: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor o igual a la fecha desde",
      test: function (value) {
        if (value >= this.parent.fechaDesde) {
          return true;
        }
        return false;
      },
    }),
});

const CuentaCorrienteTable = ({
  title,
  data,
  loading,
  fetchData,
  noRefresh,
  secondaryTitle,
  handleFilter,
  fechaDesde,
  fechaHasta,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [saldoALaFecha, setSaldoALaFecha] = useState(0);
  const [filterModel, setfilterModel] = useState(initialValues);

  useEffect(() => {
    if (data) {
      const tableDataModel = data?.cuentas?.map((cuenta) => {
        return {
          ...cuenta,
          masaSalarial: new Intl.NumberFormat("es-AR").format(
            cuenta.masaSalarial || 0
          ),
          cuota: new Intl.NumberFormat("es-AR").format(cuenta.cuota || 0),
          ffep: new Intl.NumberFormat("es-AR").format(cuenta.ffep || 0),
          pago: new Intl.NumberFormat("es-AR").format(cuenta.pago || 0),
          interes: new Intl.NumberFormat("es-AR").format(cuenta.interes || 0),
          saldoPeriodo: new Intl.NumberFormat("es-AR").format(
            cuenta.saldoPeriodo || 0
          ),
        };
      });
      setTableData(tableDataModel);
      setSaldoALaFecha(
        new Intl.NumberFormat("es-AR").format(data?.saldoFecha) ||
        data?.saldoFecha
      );
    }
  }, [data]);

  useEffect(() => {
    if (fechaDesde && fechaHasta) {
      setfilterModel({
        ...initialValues,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
      });
    } else {
      setfilterModel(initialValues);
    }
  }, [fechaDesde, fechaHasta]);

  const columns = [
    {
      title: "Periodo",
      field: "periodo",
      width: "10%",
    },
    {
      title: "Cápitas",
      field: "capitas",
      width: "10%",
    },
    {
      title: "Masa Salarial",
      field: "masaSalarial",
      width: "10%",
    },
    {
      title: "Suma Fija Declarada",
      field: "sumaFijaDeclarada",
      width: "10%",
    },
    {
      title: "Alícuota Declarada",
      field: "alicuotaDeclarada",
      width: "10%",
    },
    {
      title: "Suma Fija Facturada",
      field: "sumaFijaFacturada",
      width: "10%",
    },
    {
      title: "Alícuota Facturada",
      field: "alicuotaFacturada",
      width: "10%",
    },
    {
      title: "Cuota",
      field: "cuota",
      width: "10%",
    },
    {
      title: "FFEP",
      field: "ffep",
      width: "10%",
    },
    {
      title: "Pago",
      field: "pago",
      width: "10%",
    },
    {
      title: "Intereses por Mora ",
      field: "interes",
      width: "10%",
    },
    {
      title: "Saldo",
      field: "saldoPeriodo",
      width: "10%",
    },
  ];

  return (
    <>
      <div>
        <MaterialTable
          columns={columns}
          data={tableData}
          isLoading={loading}
          className={classes.tableContainer}
          style={{ ...tableStyles.container }}
          title={
            <TitleContainer
              title={title}
              noRefresh={noRefresh}
              fetchData={fetchData}
              saldoALaFecha={saldoALaFecha}
              subTitle={secondaryTitle}
            />
          }
          options={{
            actionsColumnIndex: -1,
            headerStyle: tableStyles.headerStyle,
            rowStyle: tableStyles.rowStyle,
            pageSize: 10,
            search: false,
            filtering: true,
            exportButton: false,
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <div className={classes.titleContainer}>
                  <div>
                    <div className={classes.toolbarActionsContainer}>
                      <ExportButton {...props} title="Cuenta Corriente" />
                      <ReporteEstadoButton {...props} />
                    </div>
                    <MTableToolbar {...props} />
                  </div>
                  <CuentaCorrienteAccountData />
                </div>
                <Formik
                  initialValues={filterModel}
                  validationSchema={validationSchema}
                  onSubmit={handleFilter}
                >
                  {({ values }) => (
                    <Form className={classes.filterContainer}>
                      <div className={classes.filterItem}>
                        <FormikDatePicker
                          name="fechaDesde"
                          label="Fecha desde"
                          ampm={false}
                          format="dd/MM/yyyy"
                          variant="inline"
                          FormHelperTextProps={{
                            style: {
                              display: "none",
                            },
                          }}
                        />
                        {values.fechaDesde && values.fechaHasta ? (
                          <Typography>
                            <ErrorMessage
                              name="fechaDesde"
                              className={classes.error}
                            />
                          </Typography>
                        ) : null}
                      </div>
                      <div className={classes.filterItem}>
                        <FormikDatePicker
                          name="fechaHasta"
                          label="Fecha hasta"
                          ampm={false}
                          format="dd/MM/yyyy"
                          variant="inline"
                          FormHelperTextProps={{
                            style: {
                              display: "none",
                            },
                          }}
                        />
                        {values.fechaDesde && values.fechaHasta ? (
                          <Typography>
                            <ErrorMessage
                              name="fechaDesde"
                              className={classes.error}
                            />
                          </Typography>
                        ) : null}
                      </div>
                      <div className={classes.buttonContainer}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          disabled={
                            values.fechaDesde == null ||
                            values.fechaHasta == null
                          }
                        >
                          Buscar
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            ),
            Pagination: (props) => {
              const {
                onChangePage,
                onChangeRowsPerPage,
                ...tablePaginationProps
              } = props;
              return (
                <TablePagination
                  {...tablePaginationProps}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  ActionsComponent={(subprops) => {
                    const { onPageChange, ...actionsComponentProps } = subprops;
                    return (
                      <props.ActionsComponent
                        {...actionsComponentProps}
                        onChangePage={onPageChange}
                      />
                    );
                  }}
                />
              );
            },
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: "No hay datos que mostrar",
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default CuentaCorrienteTable;
