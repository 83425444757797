import { TablePagination, Typography, makeStyles } from "@material-ui/core";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import CircularLoading from "../../commons/loading/CircularLoading";

const columns = [
  { id: "selected", label: "" },
  { id: "nroSiniestro", label: "Nro. Siniestro" },
  { id: "nroDocumento", label: "Nro. Documento" },
  { id: "apellidoYNombre", label: "Apellido, Nombre" },
];

const useStyles = makeStyles((theme) => ({
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

const SiniestroTable = ({
  searchedSiniestro,
  handleSubmit,
  selectedSiniestro,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {searchedSiniestro.data.length
            ? (rowsPerPage > 0
                ? searchedSiniestro.data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : searchedSiniestro.data
              ).map((row) => (
                <TableRow key={row.numeroSiniestroART}>
                  <TableCell>
                    <Checkbox
                      checked={
                        row.numeroSiniestroART ===
                        selectedSiniestro?.numeroSiniestroART
                      }
                      onChange={() => {
                        handleSubmit(row);
                      }}
                    />
                  </TableCell>
                  <TableCell>{row.numeroSiniestroART}</TableCell>
                  <TableCell>{row.numeroDocumento}</TableCell>
                  <TableCell>{row.apellidoNombres}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
      {searchedSiniestro.loading && (
        <div style={{ padding: 20 }}>
          <CircularLoading />
        </div>
      )}
      {!searchedSiniestro.data.length && !searchedSiniestro.loading ? (
        <Typography align="center" style={{ padding: 40 }}>
          No se ha encontrado resultado con los datos ingresados, por favor
          comunicarse con la ART
        </Typography>
      ) : null}
      <div className={classes.tableFooter}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={searchedSiniestro.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : to}`
          }
        />
      </div>
    </TableContainer>
  );
};

export default SiniestroTable;
