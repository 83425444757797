import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveDireccionTrabajador } from "../../../../../redux/actions/denuncia";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DireccionForm from "./DireccionTrabajadorForm";

const initialValues = {
  pais: "",
  provincia: "",
  localidad: "",
  calle: "",
  nro: "",
  piso: "",
  departamento: "",
  codigoPostal: "",
  entreCalle: "",
  entreCalle1: "",
};

const DireccionContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.denuncia.nomina);

  const [formModel, setFormModel] = useState(null);

  const handleSubmit = (data) => {
    dispatch(saveDireccionTrabajador(data));
    nextForm("DatosNominaForm");
  };

  useEffect(() => {
    if (nomina.data) {
      setFormModel(() => ({
        ...initialValues,
        pais: nomina.data.pais?.valor || initialValues.pais,
        provincia: nomina.data.provincia?.valor || initialValues.provincia,
        localidad: nomina.data.localidad?.valor || initialValues.localidad,
        calle: nomina.data.calle?.valor || initialValues.calle,
        nro: nomina.data.nro?.valor || initialValues.nro,
        piso: nomina.data.piso?.valor || initialValues.piso,
        departamento:
          nomina.data.departamento?.valor || initialValues.departamento,
        codigoPostal:
          nomina.data.codigoPostal?.valor || initialValues.codigoPostal,
        entreCalle: nomina.data.entreCalle?.valor || initialValues.entreCalle,
        entreCalle1:
          nomina.data.entreCalle1?.valor || initialValues.entreCalle1,
      }));
    }
  }, [nomina.data]);

  return (
    <>
      <HeaderForm title="Dirección del Trabajador" />
      {formModel != null ? (
        <DireccionForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
        />
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default DireccionContainer;
