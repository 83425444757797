import { addDays, differenceInCalendarYears } from "date-fns";
import * as Yup from "yup";

export const datosNominalesValidation = () =>
  Yup.object({
    motivoBaja: Yup.string().when("bajaNomina", {
      is: true,
      then: Yup.string().required("Campo obligatorio"),
      otherwise: Yup.string().nullable(),
    }),
    fechaBaja: Yup.date().when("bajaNomina", {
      is: true,
      then: Yup.date()
        .required("Campo obligatorio")
        .typeError("Fecha invalida")
        .test({
          name: "horaBajaNomina",
          message: "Fecha inválida",
          test: function (value) {
            if (value >= this.parent.fechaIngresoEmpleador) {
              return true;
            }
            return false;
          },
        }),
      otherwise: Yup.date().nullable(),
    }),

    fechaIngresoEmpleador: Yup.date()
      .required("Campo obligatorio")
      .typeError("Fecha inválida")
      .max(addDays(new Date(), 1), "Debe ser menor a la fecha actual "),
    horaDesde: Yup.date().nullable().typeError("Hora inválida"),
    horaHasta: Yup.date().nullable().typeError("Hora inválida"),
    fechaIngresoDependencia: Yup.date()
      .max(addDays(new Date(), 1), "Debe ser menor a la fecha actual ")
      .test({
        name: "fecha-menor-a-nomina",
        message:
          "La fecha debe ser mayor o igual a la fecha de ingreso de la nómina",
        test: function (value) {
          if (value >= this.parent.fechaIngresoEmpleador) {
            return true;
          }
          return false;
        },
      }),
    establecimiento: Yup.string().required("Campo obligatorio"),
    categoriaDomestica: Yup.string().when("domestica", {
      is: true,
      then: Yup.string().required("Campo obligatorio"),
      otherwise: Yup.string(),
    }),
  });

export const datosParticularesValidation = () =>
  Yup.object({
    fechaNacimiento: Yup.date()
      .test("mayorDe16", "Debes ser mayor de 16 años", function (value) {
        const today = new Date();
        const age = differenceInCalendarYears(today, value);
        return age >= 16;
      })
      .typeError("Fecha inválida"),
    mail: Yup.string()
      .required("Este campo es obligatorio")
      .email("Ingresa un correo electrónico válido")
      .max(50, "El mail debe tener como máximo 100 caracteres"),
    nombres: Yup.string().max(
      40,
      "El nombre debe tener como máximo 40 caracteres"
    ),
    apellido: Yup.string().max(
      40,
      "El apellido debe tener como máximo 40 caracteres"
    ),
    nroDocumento: Yup.string()
      .max(9, "El número de documento debe tener como máximo 9 caracteres")
      .matches(/^[0-9]+$/, "El número debe contener dígitos numéricos"),
    telefono: Yup.string().max(
      100,
      "El celular debe tener como máximo 100 caracteres"
    ),
  });
export const datosDomiciliariosValidation = () =>
  Yup.object({
    calle: Yup.string().max(
      50,
      "La calle debe tener como máximo 50 caracteres"
    ),
    entreCalle: Yup.string().max(
      50,
      "La calle debe tener como máximo 50 caracteres"
    ),
    entreCalle1: Yup.string().max(
      50,
      "La calle debe tener como máximo 50 caracteres"
    ),
    nro: Yup.string()
      .max(5, "El número debe tener como máximo 5 caracteres")
      .matches(/^[0-9]+$/, "El número debe contener dígitos numéricos"),
    piso: Yup.string().max(5, "El piso debe tener como máximo 5 caracteres"),
    departamento: Yup.string().max(
      5,
      "El departamento debe tener como máximo 5 caracteres"
    ),
    codigoPostal: Yup.string()
      .max(9, "El código postal debe tener como máximo 9 caracteres")
      .matches(/^[0-9]+$/, "El codigo postal debe contener dígitos numéricos"),
  });
export const validateRequiredFields = (values, model) => {
  const errors = {};
  const errorMessage = "Campo obligatorio";

  for (var value in values) {
    //valida que el campo exista en los datos de la nomina para validar su obligatoriedad
    if (model[value]) {
      if (model[value].obligatorio && !values[value]) {
        errors[value] = errorMessage;
      }
    }
  }

  return errors;
};
