import { Paper, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  downloadCertificadoCobertura,
  enviarCertificadoCobertura,
  validarImportacionNominas,
} from "../../../redux/actions/certificadoCobertura";
import { downloadFile } from "../../../util/files";
import Breadcrumb from "../../commons/breadcrumb/Breadcrumb";
import HeaderForm from "../../commons/labels/HeaderForm";
import ClausulaContainer from "./clausula/ClausulaContainer";
import NominaContainer from "./nomina/NominaContainer";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid green",
    textAlign: "center",
    color: "white",
    width: "100%",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 20,
  },
  breadcrumbContainer: {
    display: "flex",
    margin: 15,
  },
}));

const CertificadoCoberturaContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [body, setBody] = useState({});
  const [form, setForm] = useState("InclusiónDeCláusulas");
  const [fromBread, setFromBread] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);

  const handleNext = (clausulas, empresas) => {
    setBody({
      tiposClausulaCertificado: clausulas,
      subrogacionEmpresa: empresas,
    });
    handleSetForm("SelecciónDeNóminas");
  };

  const handleSetForm = (form) => {
    if (!breadcrumbFields.includes(form)) {
      setBreadcrumbFields([...breadcrumbFields, form]);
    }
    setForm(form);
  };

  const handleSubmit = (email, nominasValue, nominaList, nominaFile) => {
    const bodyObj = {
      ...body,
      nominas: nominaList,
      seleccionEmpleados: parseInt(nominasValue),
      archivoBase64: {
        base64: nominaFile ? nominaFile[0].value : "",
        extension: nominaFile ? nominaFile[0].filename.split(".").pop() : "",
      },
      email,
    };
    if (bodyObj.archivoBase64.base64 !== "") {
      dispatch(validarImportacionNominas(bodyObj.archivoBase64)).then(() =>
        handleSubmitCertificado(bodyObj)
      );
    } else {
      handleSubmitCertificado(bodyObj);
    }
  };

  const handleSubmitCertificado = (bodyObj) => {
    if (bodyObj.email) {
      dispatch(enviarCertificadoCobertura(bodyObj)).then(() => {
        toast.success("Contrato enviado correctamente");
      });
    } else {
      dispatch(downloadCertificadoCobertura(bodyObj)).then((response) => {
        downloadFile(response.value.data, "Certificado Cobertura.pdf");
      });
    }
  };

  return (
    <Paper>
      <HeaderForm title="Certificado de Cobertura" />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
        />
      </div>
      {
        {
          InclusiónDeCláusulas: <ClausulaContainer nextForm={handleNext} />,
          SelecciónDeNóminas: <NominaContainer handleSubmit={handleSubmit} />,
        }[form]
      }
    </Paper>
  );
};

export default CertificadoCoberturaContainer;
