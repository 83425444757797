import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveDatosNominales } from "../../../../../redux/actions/nomina";
import { convertDateTimeToNet } from "../../../../../util/datetime";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DatosNominalesForm from "./DatosNominalesForm";

const initialValues = {
  fechaIngresoEmpleador: null,
  agropecuario: false,
  domestica: false,
  bajaNomina: false,
  motivoBaja: "",
  fechaBaja: null,
  categoriaDomestica: "",
  fechaIngresoDependencia: null,
  establecimiento: "",
  puestoTrabajo: "",
  turno: "",
  horaDesde: null,
  horaHasta: null,
};

const DatosNominalesContainer = ({ setLastFormSended, handleCancelNomina }) => {
  const nomina = useSelector((state) => state.nomina.existeNomina);
  const [formModel, setFormModel] = useState(null);
  const dispatch = useDispatch();
  const handleSubmit = (data) => {
    const model = {
      ...data,
      fechaIngresoEmpleador: convertDateTimeToNet(data.fechaIngresoEmpleador),
      fechaIngresoDependencia: convertDateTimeToNet(
        data.fechaIngresoDependencia
      ),
      horaDesde: convertDateTimeToNet(data.horaDesde),
      horaHasta: convertDateTimeToNet(data.horaHasta),
      fechaBaja: convertDateTimeToNet(data.fechaBaja),
    };
    dispatch(saveDatosNominales(model));
    setLastFormSended();
  };
  useEffect(() => {
    if (nomina.data) {
      setFormModel(() => ({
        ...initialValues,
        agropecuario:
          nomina.data.agropecuario?.valor || initialValues.agropecuario,
        domestica: nomina.data.domestica?.valor || initialValues.domestica,
        fechaIngresoEmpleador:
          nomina.data.fechaIngresoEmpleador?.valor ||
          initialValues.fechaIngresoEmpleador,
        categoriaDomestica:
          nomina.data.categoriaDomestica?.valor ||
          initialValues.categoriaDomestica,
        fechaIngresoDependencia:
          nomina.data.fechaIngresoDependencia?.valor ||
          initialValues.fechaIngresoDependencia,
        establecimiento:
          nomina.data.establecimiento?.valor || initialValues.establecimiento,
        puestoTrabajo:
          nomina.data.puestoTrabajo?.valor || initialValues.puestoTrabajo,
        turno: nomina.data.turno?.valor || initialValues.turno,
        horaDesde: nomina.data.horaDesde?.valor || initialValues.horaDesde,
        horaHasta: nomina.data.horaHasta?.valor || initialValues.horaHasta,
        bajaNomina: nomina.data.fechaBaja?.valor
          ? true
          : false || initialValues.bajaNomina,
        motivoBaja: nomina.data.motivoBaja?.valor || initialValues.motivoBaja,
        fechaBaja: nomina.data.fechaBaja?.valor || initialValues.fechaBaja,
      }));
    }
  }, [nomina.data]);
  return (
    <>
      {formModel != null ? (
        <DatosNominalesForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
          handleCancelNomina={handleCancelNomina}
        />
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default DatosNominalesContainer;
