import { Paper, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearNomina, sendAllNominaForm } from "../../../redux/actions/nomina";
import Breadcrumb from "../../commons/breadcrumb/Breadcrumb";
import HeaderForm from "../../commons/labels/HeaderForm";
import DatosDomiciliariosContainer from "./forms/datosDomiciliariosForm/DatosDomiciliariosContainer";
import DatosNominalesContainer from "./forms/datosNominalesForm/DatosNominalesContainer";
import DatosParticularesContainer from "./forms/datosParticularesForm/DatosParticularesContainer";

const useStyles = makeStyles({
  breadcrumbContainer: {
    display: "flex",
    margin: 15,
  },
});

const ABMNominaContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nomina = useSelector((state) => state.nomina);

  const [form, setForm] = useState("DatosParticulares");
  const [fromBread, setFromBread] = useState(false);
  const [lastFormSent, setLastFormSent] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);

  const handleCancelNomina = () => {
    dispatch(clearNomina());
    setForm("DatosParticulares");
    setBreadcrumbFields(["DatosParticulares"]);
  };

  const handleSetForm = (form) => {
    if (!breadcrumbFields.includes(form)) {
      const auxArray = [...breadcrumbFields, form];
      setBreadcrumbFields(auxArray);
    }
    setForm(form);
  };

  useEffect(() => {
    if (lastFormSent) {
      handleSumbitLastForm();
    }
  }, [lastFormSent]);

  const handleSumbitLastForm = () => {
    const aux = {
      cuil: nomina.cuil,
      idTrabajador: nomina.existeNomina.data.idTrabajador,
      telefonoCelular: nomina.postNomina.datosParticulares.telefono,
      idDependenciaNomina: nomina.existeNomina.data.idDependenciaNomina,
      ...nomina.postNomina.datosParticulares,
      ...nomina.postNomina.datosDomiciliarios,
      ...nomina.postNomina.datosNominales,
    };
    dispatch(sendAllNominaForm(aux)).then((res) => {
      dispatch(clearNomina());
      toast.success(`Nomina enviada correctamente`);
    });
    navigate("/");
  };

  return (
    <Paper style={{ overflowX: "hidden" }}>
      <HeaderForm title="ABM Nóminas" />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
        />
      </div>
      {
        {
          DatosParticulares: (
            <DatosParticularesContainer
              nextForm={handleSetForm}
              fromBread={fromBread}
            />
          ),
          DatosDomiciliarios: (
            <DatosDomiciliariosContainer nextForm={handleSetForm} />
          ),
          DatosNominales: (
            <DatosNominalesContainer
              setLastFormSended={() => setLastFormSent(true)}
              handleCancelNomina={handleCancelNomina}
            />
          ),
        }[form]
      }
    </Paper>
  );
};

export default ABMNominaContainer;
