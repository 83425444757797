import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100vh - 250px)",
    rowGap: "3vh",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 300px)",
  },
  row: {
    display: "flex",
    width: "95%",
    columnGap: "1vw",
  },
  observaciones: {
    width: "90%",
    margin: "auto",
  },
  dropzone: {
    display: "flex",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    boxShadow: "0px 0 5px lightGrey",
  },
});
