import { TablePagination } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import MaterialTable, { MTableToolbar } from "material-table";
import React from "react";
import ExportButton from "../../../commons/export/ExportButton";
import { localization, tableStyles } from "../../../commons/table/tableConfig";

const ResumenGestionTable = ({ data, loading, handleViewDetails, display }) => {
  const columns = [
    {
      title: "Período",
      field: "periodo",
      align: "center",
    },
    {
      title: "Lote",
      field: "idLote",
      align: "center",
    },
    {
      title: "Expuestos",
      field: "expuestos",
      align: "center",
    },
    {
      title: "Examinados",
      field: "examinados",
      align: "center",
    },
    {
      title: "Inculpables",
      field: "inculpables",
      align: "center",
    },
    {
      title: "Normales",
      field: "normales",
      align: "center",
    },
    {
      title: "Patológicos",
      field: "patologicos",
      align: "center",
    },
    {
      title: "Ausentes",
      field: "ausentes",
      align: "center",
    },
    {
      title: "En proceso",
      field: "enProceso",
      align: "center",
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={{ ...tableStyles.container, display: display }}
      title=""
      actions={[
        (rowData) => ({
          icon: () => <Visibility />,
          tooltip: "Ver trabajadores",
          onClick: (event, rowData) => {
            handleViewDetails(rowData.idLote);
          },
        }),
      ]}
      options={{
        actionsColumnIndex: -1,
        headerStyle: tableStyles.headerStyle,
        rowStyle: tableStyles.rowStyle,
        pageSize: 10,
        search: false,
        filtering: true,
      }}
      components={{
        Toolbar: (props) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MTableToolbar {...props} />
            <ExportButton
              {...props}
              disabled={data.length === 0}
              title="Resumen de Gestión"
            />
          </div>
        ),
        Pagination: (props) => {
          const { onChangePage, onChangeRowsPerPage, ...tablePaginationProps } =
            props;
          return (
            <TablePagination
              {...tablePaginationProps}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={(subprops) => {
                const { onPageChange, ...actionsComponentProps } = subprops;
                return (
                  <props.ActionsComponent
                    {...actionsComponentProps}
                    onChangePage={onPageChange}
                  />
                );
              }}
            />
          );
        },
      }}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay datos disponibles para mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};

export default ResumenGestionTable;
