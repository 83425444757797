import {
  IconButton,
  Paper,
  Typography,
  lighten,
  makeStyles,
} from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import FeedIcon from "@mui/icons-material/Feed";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
    columnGap: 5,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 10,
    backgroundColor: (props) =>
      props.adjuntosConColor
        ? lighten(theme.palette.tertiary.main, 0.6)
        : "white",
  },
  children: {
    // Los botones/iconos de material-ui tienen por defecto padding:12,
    // lo que es muy grande para los items de archivos adjuntos.
    padding: 5,
  },
  text: {
    maxWidth: "25vw",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const DropzoneFileAttachment = ({
  filename,
  adjuntosConColor,
  quitarArchivo,
}) => {
  const classes = useStyles({ adjuntosConColor });

  return (
    <>
      <Paper className={classes.paper} elevation={3}>
        <FeedIcon className={classes.children} />
        <Typography
          variant="body2"
          className={(classes.children, classes.text)}
        >
          {filename}
        </Typography>
        <IconButton className={classes.children} onClick={quitarArchivo}>
          <ClearIcon />
        </IconButton>
      </Paper>
    </>
  );
};

export default DropzoneFileAttachment;
