import { useSelector } from "react-redux";
import DataLabel from "../../../../commons/labels/DataLabel";

const DatosEstablecimientoHeaderLabel = () => {
  const datosEstablecimiento = useSelector(
    (state) => state.rgrl.datosEstablecimiento
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <DataLabel
        firstText={"MU: " + datosEstablecimiento.data?.matrizUnica}
        secondText={datosEstablecimiento.data?.nombre?.split("-")[0]}
        thirdText={datosEstablecimiento.data?.domicilio}
      />
    </div>
  );
};

export default DatosEstablecimientoHeaderLabel;
