import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikTextField from "../../../../../commons/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  formikInput: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  errorDiv: {
    height: "20px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "& button": {
      color: `${theme.palette.primary.main}`,
      marginTop: 5,
      borderRadius: "10px",
      "& :hover": {
        borderBottom: `${theme.palette.primary.main} 1px solid`,
      },
    },
  },
}));

const validationSchema = Yup.object().shape({
  calle: Yup.string().required("Este campo es obligatorio"),
  numero: Yup.string().required("Este campo es obligatorio"),
  provincia: Yup.string().required("Este campo es obligatorio"),
  localidad: Yup.string().required("Este campo es obligatorio"),
  codigoPostal: Yup.string().required("Este campo es obligatorio"),
  piso: Yup.string().required("Este campo es obligatorio"),
});

const DomicilioCard = ({ data }) => {
  const classes = useStyles();
  const initialValues = {
    calle: data.calle?.nombre || "Sin Información",
    numero: data.calle?.numero || "Sin Información",
    provincia: data.provincia?.nombre || "Sin Información",
    localidad: data.localidad?.nombre || "Sin Información",
    codigoPostal: data.calle?.codigoPostal || "Sin Información",
    piso:
      data.piso?.valor + " " + data.departamento?.valor || "Sin Información",
  };

  const handleSumbit = (data) => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSumbit}
    >
      {({ errors, touched }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={classes.formikInput}>
                <Typography>Provincia</Typography>
                <FormikTextField
                  id="provincia"
                  name="provincia"
                  type="text"
                  disabled={true}
                  FormHelperTextProps={{
                    style: {
                      display: "none",
                    },
                  }}
                />
                <div className={classes.errorDiv}>
                  {touched.provincia && errors.provincia && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {errors.provincia}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.formikInput}>
                <Typography>Calle</Typography>
                <FormikTextField
                  id="calle"
                  name="calle"
                  type="text"
                  disabled={true}
                  FormHelperTextProps={{
                    style: {
                      display: "none",
                    },
                  }}
                />
                <div className={classes.errorDiv}>
                  {touched.calle && errors.calle && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {errors.calle}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.formikInput}>
                <Typography>Piso / Dto</Typography>
                <FormikTextField
                  id="piso"
                  name="piso"
                  type="text"
                  disabled={true}
                  FormHelperTextProps={{
                    style: {
                      display: "none",
                    },
                  }}
                />
                <div className={classes.errorDiv}>
                  {touched.piso && errors.piso && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {errors.piso}
                    </Typography>
                  )}
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className={classes.formikInput}>
                <Typography>Localidad</Typography>
                <FormikTextField
                  id="localidad"
                  name="localidad"
                  type="text"
                  disabled={true}
                  FormHelperTextProps={{
                    style: {
                      display: "none",
                    },
                  }}
                />
                <div className={classes.errorDiv}>
                  {touched.localidad && errors.localidad && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {errors.localidad}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.formikInput}>
                <Typography>Nro</Typography>
                <FormikTextField
                  id="numero"
                  name="numero"
                  type="text"
                  disabled={true}
                  FormHelperTextProps={{
                    style: {
                      display: "none",
                    },
                  }}
                />
                <div className={classes.errorDiv}>
                  {touched.numero && errors.numero && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {errors.numero}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.formikInput}>
                <Typography>Código Postal</Typography>
                <FormikTextField
                  id="codigoPostal"
                  name="codigoPostal"
                  type="text"
                  disabled={true}
                  FormHelperTextProps={{
                    style: {
                      display: "none",
                    },
                  }}
                />
                <div className={classes.errorDiv}>
                  {touched.codigoPostal && errors.codigoPostal && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {errors.codigoPostal}
                    </Typography>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button type="submit" disabled={true}>
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DomicilioCard;
