import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import FormulariosPresentadosCard from "./cards/FormulariosPresentadosCard";
import DatosDelEstablecimientoCard from "./cards/DatosDelEstablecimientoCard";
import CardContainer from "../../../../commons/cards/CardContainer";
import { useSelector } from "react-redux";
import { useStyles } from "../../styles/formStyles";

const EstablecimientoForm = ({ searchedRgrl, nextForm }) => {
  const classes = useStyles();

  const datosEstablecimiento = useSelector(
    (state) => state.rgrl.datosEstablecimiento
  );

  const formulariosPresentados = useSelector(
    (state) => state.rgrl.formulariosPresentados
  );

  const validarDisponibilidadCargaRGRL = useSelector(
    (state) => state.rgrl.validarDisponibilidadCargaRgrl
  );

  const handleSubmit = () => {
    nextForm("Decreto");
  };

  // no dejamos que el usuario continue si se cumple
  // alguno de los siguientes 3 casos:
  // 1. hay error con el servidor
  // (porque no vamos a tener los datos del establecimiento para el resto de los formularios)
  // 2. el usuario no buscó ningun establecimiento (misma razon que 1.)
  // 3. el establecimiento ya tiene un formulario RGRL en curso
  // (esto es por especificacion del analista)
  const noPuedeContinuarCargaRgrl =
    !datosEstablecimiento.data?.nombre ||
    !searchedRgrl ||
    validarDisponibilidadCargaRGRL?.error
      ? true
      : false;

  return (
    <>
      <Grid container spacing={2} className={classes.paddingContainer}>
        {searchedRgrl ? (
          <>
            <Grid item xs={6} className={classes.flexDirectionColumn}>
              <CardContainer
                title="Datos del Establecimiento"
                loading={datosEstablecimiento.loading}
                componente={
                  <DatosDelEstablecimientoCard
                    data={datosEstablecimiento.data}
                  />
                }
                getData={() => {}}
              />
            </Grid>
            <Grid item xs={6} className={classes.flexDirectionColumn}>
              <CardContainer
                title="Formularios Presentados"
                loading={formulariosPresentados.loading}
                componente={
                  <FormulariosPresentadosCard
                    data={formulariosPresentados.data}
                  />
                }
                getData={() => {}}
              />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            className={classes.loadingContainer}
            style={{ height: 300 }}
          >
            <Typography
              style={{
                padding: "150px 20px 200px 50px",
                color: "grey",
                textAlign: "center",
              }}
            >
              Busque un establecimiento.
            </Typography>
          </Grid>
        )}
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            noPuedeContinuarCargaRgrl || validarDisponibilidadCargaRGRL.loading
          }
        >
          SIGUIENTE
        </Button>
      </div>
    </>
  );
};

export default EstablecimientoForm;
