import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCertificadoDeCobertura,
  saveDatosDelViajeExterior,
} from "../../../../../redux/actions/certificadoCobertura";
import DatosDelViaje from "./DatosDelViaje";

const initialValues = {
  fechaViajeDesde: null,
  fechaViajeHasta: null,
  destino: "",
  coberturaSaludExterior: "CardinalAssistance",
};

const DatosDelViajeContainer = ({ handleNext, fromBread }) => {
  const dispatch = useDispatch();

  const datosDelViaje = useSelector(
    (state) => state.certificadoCobertura.postCertificadoCobertura.datosDelViaje
  );

  const [formModel, setFormModel] = useState(null);

  const handleSubmit = (values) => {
    dispatch(saveDatosDelViajeExterior(values));
    handleNext("SelecciónDeEmpleados");
  };

  useEffect(() => {
    if (!fromBread) {
      dispatch(clearCertificadoDeCobertura());
    }
  }, []);

  useEffect(() => {
    setFormModel({
      ...initialValues,
      fechaViajeDesde:
        datosDelViaje?.fechaViajeDesde || initialValues.fechaViajeDesde,
      fechaViajeHasta:
        datosDelViaje?.fechaViajeHasta || initialValues.fechaViajeHasta,
      destino: datosDelViaje?.destino || initialValues.destino,
      coberturaSaludExterior:
        datosDelViaje?.coberturaSaludExterior ||
        initialValues.coberturaSaludExterior,
    });
  }, []);

  return (
    <div>
      <DatosDelViaje handleSubmit={handleSubmit} formModel={formModel} />
    </div>
  );
};

export default DatosDelViajeContainer;
