import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCargos,
  getRepresentacion,
  getTipoDocumento,
  getTipoMatricula,
} from "../../../../../redux/actions/rgrl";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import { validateNewResponsable } from "../../validation/formValidation";

const initialValues = {
  cargoEmpresa: null,
  responsableDatos: false,
  apellido: "",
  nombres: "",
  cuit: "",
  tipoDocumento: "",
  numeroDocumento: null,
  representacion: null,
  propio: false,
  telefono: "",
  email: "",
  tipoMatricula: null,
  nroMatricula: "",
  horasAsignadas: null,
  titulo: "",
  matriculaOtorgadaPor: "",
};

const AddResponsable = ({ open, handleClose, handleSubmit }) => {
  const dispatch = useDispatch();

  const cargos = useSelector((state) => state.rgrl.cargos);
  const tipoDocumento = useSelector((state) => state.rgrl.tipoDocumento);
  const representacion = useSelector((state) => state.rgrl.representacion);
  const tipoMatricula = useSelector((state) => state.rgrl.tipoMatricula);
  const postResponsables = useSelector((state) => state.rgrl.postResponsables);

  return (
    <Dialog open={open}>
      <DialogTitle>Nuevo responsable</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateNewResponsable}
      >
        <Form>
          <DialogContent>
            {postResponsables.loading ? (
              <CircularLoading />
            ) : (
              <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={7}>
                    <div>
                      <FormikAutocomplete
                        name="cargoEmpresa"
                        label="Cargo *"
                        options={cargos.data}
                        renderOption={(option) => option.nombre}
                        filterOptions={(option) => option.nombre}
                        getOptions={() => dispatch(getCargos())}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div>
                      <FormikCheckbox
                        name="responsableDatos"
                        label="Responsable datos"
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* segunda fila */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <div>
                      <FormikTextField
                        name="apellido"
                        label="Apellido *"
                        type="text"
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <FormikTextField
                        name="nombres"
                        label="Nombre"
                        type="text"
                        fullWidth
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* tercera fila */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <div>
                      <FormikTextField name="cuit" label="CUIT *" fullWidth />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <FormikAutocomplete
                        name="tipoDocumento"
                        label="Tipo documento *"
                        options={tipoDocumento.data}
                        renderOption={(option) => option.nombre}
                        filterOptions={(option) => option.nombre}
                        getOptions={() => dispatch(getTipoDocumento())}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <FormikTextField
                        name="numeroDocumento"
                        label="N.º documento *"
                        fullWidth
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* cuarta fila */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <div>
                      <FormikAutocomplete
                        name="representacion"
                        label="Representación"
                        options={representacion.data}
                        renderOption={(option) => option.nombre}
                        filterOptions={(option) => option.nombre}
                        getOptions={() => dispatch(getRepresentacion())}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <FormikCheckbox name="propio" label="Propio" />
                    </div>
                  </Grid>
                </Grid>
                {/* quinta fila */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <div>
                      <FormikTextField
                        name="telefono"
                        label="Teléfono"
                        type="text"
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <FormikTextField
                        name="email"
                        label="Mail"
                        type="text"
                        fullWidth
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* sexta fila */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <div>
                      <FormikAutocomplete
                        name="tipoMatricula"
                        label="Tipo matrícula *"
                        options={tipoMatricula.data}
                        renderOption={(option) => option.nombre}
                        filterOptions={(option) => option.nombre}
                        getOptions={() => dispatch(getTipoMatricula())}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <FormikTextField
                        name="nroMatricula"
                        label="N.º matrícula *"
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <FormikTextField
                        name="horasAsignadas"
                        label="Horas asignadas *"
                        fullWidth
                      />
                    </div>
                  </Grid>
                </Grid>
                {/* septima fila */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <div>
                      <FormikTextField
                        name="titulo"
                        label="Título"
                        type="text"
                        fullWidth
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <FormikTextField
                        name="matriculaOtorgadaPor"
                        label="Título otorgado por *"
                        type="text"
                        fullWidth
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <FormikActions handleCancel={handleClose} />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddResponsable;
