/**
 * @param {string} cuit - Recibimos el CUIT como string, sin
 * guiones
 *
 * @example
 * Recibimos "27314509345" y devolvemos "27-31450934-5"
 *
 * @returns {string} - Devuelve el CUIT con guiones
 */
export const formatCUIT = (cuit) => {
  const first = cuit.substring(0, 2);
  const last = cuit.slice(-1);
  const middle = cuit.substring(2, cuit.length - 1);

  return first + "-" + middle + "-" + last;
};

/**
 * @param {string} cuil - El CUIL como string sin guiones
 *
 * @example
 * Recibimos "27-31450934-5", retornamos {false}
 * Recibimos {27314509345} (int), retornamos {false}
 * Recibimos "27314509345", retornamos {true}
 *
 * @returns {true|false}
 */
export const validarCuilCuit = (cuil) => {
  // Verificar si el CUIL tiene 11 dígitos
  if (cuil.length !== 11 || !/^\d+$/.test(cuil)) {
    return false;
  }

  // Verificar si los primeros dos dígitos son 20, 23, 24 o 27
  const primerosDosDigitos = cuil.substring(0, 2);
  if (!["20", "23", "24", "27"].includes(primerosDosDigitos)) {
    return false;
  }

  // Calcular el dígito verificador
  const coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let acumulado = 0;

  for (let i = 0; i < 10; i++) {
    acumulado += parseInt(cuil[i]) * coeficientes[i];
  }

  let digitoVerificador = 11 - (acumulado % 11);
  if (digitoVerificador === 11) {
    digitoVerificador = 0;
  } else if (digitoVerificador === 10) {
    digitoVerificador = 9;
  }

  // Verificar el dígito verificador
  return digitoVerificador === parseInt(cuil[10]);
};
