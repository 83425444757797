import { compareAsc } from "date-fns";
import * as Yup from "yup";

export const siniestroFormValidation = () =>
  Yup.object().shape({
    nroSiniestro: Yup.string()
      .matches(
        /^[0-9]\d*$/,
        "El número de siniestro debe contener dígitos numéricos"
      )
      .max(8, "El número de siniestro debe contener 8 digitos")
      .notOneOf(["00000000"], "El número de siniestro no puede ser 00000000")
      .min(8, "El número de siniestro debe contener 8 digitos"),
    nroDocumento: Yup.string()
      .matches(
        /^[1-9]\d*$/,
        "El número de documento debe contener dígitos numéricos"
      )
      .max(8, "El número de documento debe contener 8 digitos")
      .min(8, "El número de documento debe contener 8 digitos"),
  });

export const datosComprobanteValidation = Yup.object().shape({
  fechaDelComprobante: Yup.date()
    .nullable()
    .typeError("Fecha inválida")
    .required("Campo obligatorio")
    .min(
      new Date("01/01/2000"),
      "La fecha del comprobante no puede ser menor al 01/01/2000"
    )
    .test(
      "is-valid-fecha",
      "La fecha del comprobante no puede ser mayor a la fecha de hoy",
      (value) => {
        // Compare the two dates and return
        // 1 if the first date is after the second,
        if (compareAsc(new Date(), value) === 1) {
          return true;
        } else {
          return false;
        }
      }
    ),
  tipoComprobante: Yup.string().required("Campo obligatorio").nullable(),
  ptoVenta: Yup.string()
    .matches(/^[1-9]*$/, "El Pto. Venta debe ser un número entero positivo")
    .max(4, "El máximo de Pto. Venta es 9999"),
  nro: Yup.string()
    .matches(/^\d+$/, "Solo se permiten números")
    .max(9, "El máximo Nro. es 200,000,000")
    .test(
      "is-valid-number",
      "El Nro. debe estar entre 1 y 200,000,000",
      (value) => {
        const numberValue = parseInt(value, 10);
        return numberValue >= 1 && numberValue <= 200000000;
      }
    )
    .required("Campo obligatorio"),
});

export const rubrosDelReintegroValidation = () =>
  Yup.object().shape({
    rubro: Yup.string().required("Obligatorio"),
    importe: Yup.string()
      .matches(
        /^[0-9]{1,11}(,[0-9]{1,4})?$/,
        "El importe debe ser un número con máximo 11 dígitos enteros y 4 dígitos decimales"
      )
      .required("Obligatorio"),
  });

export const beneficiarioFormValidation = () =>
  Yup.object().shape({
    cbu: Yup.string()
      .matches(
        /^[0-9]+$/,
        "El numero de siniestro debe contener dígitos numéricos"
      )
      .max(22, "El CBU - CVU debe contener 22 digitos")
      .min(22, "El CBU - CVU debe contener 22 digitos")
      .required("Obligatorio"),
  });

export const validateRequiredFields = (values, model) => {
  const errors = {};
  const errorMessage = "Campo obligatorio";

  for (var value in values) {
    //valida que el campo exista en los datos de la nomina para validar su obligatoriedad
    if (model[value]) {
      if (model[value].obligatorio && !values[value]) {
        errors[value] = errorMessage;
      }
    }
  }

  return errors;
};
