import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPostRgrl,
  getDecretos,
  postDecretoForm,
} from "../../../../redux/actions/rgrl";
import ConfirmDialog from "../../../commons/dialog/ConfirmDialog";
import CircularLoading from "../../../commons/loading/CircularLoading";
import { useStyles } from "../styles/formStyles";
import DecretoForm from "./DecretoForm";

const initialValues = {
  id: "",
  nombre: "",
  totalEstablecimientos: "",
  totalTrabajadoresEstablecimientos: "",
  superficie: "",
  observaciones: "",
};

const DecretoContainer = ({ nextForm }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const rgrl = useSelector((state) => state.rgrl.decretos);
  const searchedRgrl = useSelector((state) => state.rgrl.searchedRgrl);
  const postDecreto = useSelector((state) => state.rgrl.postRgrl.decretoForm);

  const [decretoSeleccionado, setDecretoSeleccionado] = useState(null);
  const [formModel, setFormModel] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const isLoading = !formModel || rgrl.loading;

  useEffect(() => {
    dispatch(getDecretos(searchedRgrl));
  }, []);

  useEffect(() => {
    if (rgrl.data?.opciones) {
      if (!postDecreto?.id) {
        setDecretoSeleccionado(
          rgrl.data.opciones.find((d) => d.id === rgrl.data.valor)
        );
      } else {
        setDecretoSeleccionado(postDecreto);
      }
    }
  }, [rgrl.data]);

  useEffect(() => {
    if (decretoSeleccionado != null) {
      setFormModel(() => ({
        ...initialValues,
        id: decretoSeleccionado.id || initialValues.id,
        nombre: decretoSeleccionado.nombre || initialValues.nombre,
        totalTrabajadoresEstablecimientos:
          decretoSeleccionado.totalTrabajadoresEstablecimientos ||
          initialValues.totalTrabajadoresEstablecimientos,
        totalEstablecimientos:
          decretoSeleccionado.totalEstablecimientos ||
          initialValues.totalEstablecimientos,
        superficie: decretoSeleccionado.superficie || initialValues.superficie,
        observaciones:
          decretoSeleccionado.observaciones || initialValues.observaciones,
      }));
    }
  }, [decretoSeleccionado]);

  const handleChange = (decreto) => {
    setSelectedValue(decreto);
    if (decreto.id !== decretoSeleccionado.id && !!postDecreto?.id) {
      setOpenConfirmDialog(true);
    } else {
      setDecretoSeleccionado(decreto);
    }
  };

  const handleSubmit = (values) => {
    const objetoAux = {
      ...decretoSeleccionado,
      ...values,
    };
    dispatch(postDecretoForm(objetoAux));
    nextForm("PreguntasDelDecreto");
  };

  return (
    <>
      {!isLoading ? (
        <DecretoForm
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          decretoSeleccionado={formModel}
          data={rgrl.data}
          setOpenConfirmDialog={setOpenConfirmDialog}
        />
      ) : (
        <div className={classes.loadingContainer}>
          <CircularLoading />
        </div>
      )}
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={() => setOpenConfirmDialog(false)}
        handleConfirm={() => {
          setOpenConfirmDialog(false);
          setDecretoSeleccionado(selectedValue);
          dispatch(clearPostRgrl());
        }}
        title="Perderá todos los datos completados"
        warningText={
          "Al seleccionar otro decreto los datos cargados en preguntas se perderán."
        }
        loading={false}
      />
    </>
  );
};

export default DecretoContainer;
