import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 10,
    cursor: "pointer",
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const ComprobanteDetail = ({ open, handleCloseDetail }) => {
  const classes = useStyles();

  const postReintegro = useSelector(
    (state) => state.reintegros.postReintegro.comprobantes
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const columns = [
    { id: "rubro", label: "Rubro" },
    { id: "importe", label: "Importe" },
  ];

  return (
    <Dialog open={open} className={classes.dialog}>
      <DialogTitle className={classes.dialogTitle}>
        Detalle del Comprobante{" "}
        {<Close className={classes.icon} onClick={handleCloseDetail} />}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? postReintegro.data.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : postReintegro.data
            ).map((row, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableCell>{row.nombre}</TableCell>
                  <TableCell>
                    ${" "}
                    {parseFloat(row.importe).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default ComprobanteDetail;
