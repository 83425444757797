import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentesCausantesEnfermedadProfesional,
  getAgentesMaterialEnfermedadProfesional,
  getTiposEnfermedadProfesional,
  saveEnfermedadProfesional,
} from "../../../../../redux/actions/denuncia";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DiagnosticoEnfermedadContainer from "../diagnosticoEnfermedadForm/DiagnosticoEnfermedadContainer";
import EnfermedadProfesionalForm from "./EnfermedadProfesionalForm";

const initialValues = {
  tipoEnfermedadProfesional: "",
  tiempoExposicionAgenteCausante: "",
  agenteCausante: "",
  agenteCausanteEnfermedadProfesional: "",
};

const EnfermedadProfesionalContainer = ({
  idTipoSiniestro,
  handleSubmitAllForm,
}) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.denuncia.nomina);
  const tiposEnfermedadProfesional = useSelector(
    (state) => state.denuncia.tiposEnfermedadProfesional
  );
  const listaAgenteMaterial = useSelector(
    (state) => state.denuncia.agentesMaterialEnfermedadProfesional
  );
  const listaAgenteCausante = useSelector(
    (state) => state.denuncia.agentesCausantesEnfermedadProfesional
  );

  const [formModel, setFormModel] = useState(null);
  const [openTable, setOpenTable] = useState(false);

  useEffect(() => {
    if (idTipoSiniestro && tiposEnfermedadProfesional.data.length === 0) {
      dispatch(getTiposEnfermedadProfesional());
    }
    dispatch(getAgentesCausantesEnfermedadProfesional());
  }, [idTipoSiniestro, tiposEnfermedadProfesional.data, dispatch]);

  const handleGetAgenteMaterialEnfermedadProfesional = (id) => {
    dispatch(getAgentesMaterialEnfermedadProfesional(id));
  };

  const handleSubmit = (data) => {
    dispatch(saveEnfermedadProfesional(data));
    setOpenTable(true);
  };

  useEffect(() => {
    if (nomina.data && tiposEnfermedadProfesional.data.length) {
      setFormModel(() => ({
        ...initialValues,
        tipoEnfermedadProfesional:
          tiposEnfermedadProfesional.data[0]?.id ||
          initialValues.tipoEnfermedadProfesional,
        tiempoExposicionAgenteCausante:
          nomina.data.tiempoExposicionAgenteCausante?.valor ||
          initialValues.tiempoExposicionAgenteCausante,
        agenteCausante:
          nomina.data.agenteMaterial?.valor || initialValues.agenteCausante,
        agenteCausanteEnfermedadProfesional:
          nomina.data.comoOcurrioAccidente?.valor ||
          initialValues.agenteCausanteEnfermedadProfesional,
      }));
    }
  }, [nomina.data, tiposEnfermedadProfesional.data.length]);

  return (
    <>
      {!openTable ? (
        <>
          {formModel != null && tiposEnfermedadProfesional.data.length ? (
            <>
              <HeaderForm title="Enfermedad Profesional" />
              <EnfermedadProfesionalForm
                handleSubmit={handleSubmit}
                model={formModel}
                fields={nomina.data}
                tiposEnfermedadProfesional={tiposEnfermedadProfesional.data}
                listaAgenteMaterial={listaAgenteMaterial.data}
                listaAgenteCausante={listaAgenteCausante.data}
                handleGetAgenteMaterialEnfermedadProfesional={
                  handleGetAgenteMaterialEnfermedadProfesional
                }
              />
            </>
          ) : (
            <CircularLoading />
          )}
        </>
      ) : (
        <DiagnosticoEnfermedadContainer
          handleSubmitAllForm={handleSubmitAllForm}
        />
      )}
    </>
  );
};

export default EnfermedadProfesionalContainer;
