import axios from "axios";
export const GET_NOTIFICACIONES_Y_CITAS = "GET_NOTIFICACIONES_Y_CITAS";
export const GET_NOTIFICACION = "GET_NOTIFICACION";
export const CLEAR_NOTIFICACION = "CLEAR_NOTIFICACION";
export const MARCAR_NOTIFICACION = "MARCAR_NOTIFICACION";
export const GET_CITACIONES = "GET_CITACIONES";

export const getNotificacionesYCitas = (idSiniestroCabecera) => (dispatch) => {
  return dispatch({
    type: GET_NOTIFICACIONES_Y_CITAS,
    payload: axios
      .get(
        `notificacionFehaciente/notificacionesSiniestro?idSiniestroCabecera=${idSiniestroCabecera}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getNotificacion = (idNotificacion) => (dispatch) => {
  return dispatch({
    type: GET_NOTIFICACION,
    payload: axios
      .get(
        `notificacionFehaciente/verNotificacion?idNotificacionFehaciente=${idNotificacion}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getCitaciones = (idSiniestroCabecera) => (dispatch) => {
  return dispatch({
    type: GET_CITACIONES,
    payload: axios
      .post(`denuncia/juntaMedica?idSiniestroCabecera=${idSiniestroCabecera}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const marcarNotificacionLeida = (idNotificacion) => (dispatch) => {
  return dispatch({
    type: MARCAR_NOTIFICACION,
    payload: axios
      .get(
        `notificacionFehaciente/marcarNotificacionLeida?idNotificacionFehaciente=${idNotificacion}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
export const clearNotificacion = () => (dispatch) => {
  return dispatch({
    type: CLEAR_NOTIFICACION,
  });
};
