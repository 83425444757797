import { makeStyles } from "@material-ui/core";
import { Paper, Typography } from "@mui/material";
import { compareDesc, format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  emptyState: {
    textAlign: "center",
  },
}));

const JuntaMedicaCard = ({ data }) => {
  const classes = useStyles();
  const [citaciones, setCitaciones] = useState(null);

  useEffect(() => {
    if (Array.isArray(data)) {
      const citasSorted = [...data].sort(function (a, b) {
        return compareDesc(parseISO(a.fecha), parseISO(b.fecha));
      });
      setCitaciones(citasSorted);
    }
  }, [data]);

  return (
    <>
      <div>
        {citaciones?.length ? (
          citaciones.map((cita) => (
            <Paper className={classes.card} key={cita.fecha}>
              <div style={{ width: "99%" }}>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {format(parseISO(cita.fecha), "dd/MM/yyyy HH:mm")}
                </Typography>
                {cita.nombre.split(" - ").map((campo) => {
                  return (
                    <Typography
                      variant="body2"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {campo}
                    </Typography>
                  );
                })}
              </div>
            </Paper>
          ))
        ) : (
          <Typography className={classes.emptyState} variant="body2">
            No hay citas disponibles
          </Typography>
        )}
      </div>
    </>
  );
};

export default JuntaMedicaCard;
