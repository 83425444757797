import { CircularProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Save } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import DropzoneFileAttachment from "./DropzoneFileAttachment";

const styles = {
  container: {
    width: "100%",
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    marginTop: 30,
    marginRight: 30,
  },
  fileContainer: {
    overflowY: "scroll",
    padding: 10,
    // Que el componente se expanda hasta acomodar 3 archivos máximo.
    // Si se adjuntan más, poner una barra de scroll.
    maxHeight: 220,
    marginBottom: 20,
  },
};

const baseStyle = {
  flex: 1,
  display: "flex",
  width: "60%",
  // minHeight: 300,
  // que el padding sea en base al viewport-width, ya
  // que si ponemos un numero (por ejemplo 100), en escritorio
  // se ve bien, pero en mobile el dropzone queda fuera del contenedor
  padding: "2vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 3,
  borderRadius: 20,
  borderColor: "#a1a1a1",
  borderStyle: "dashed",
  backgroundColor: "#f7f7f7",
  color: "#858585",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
  textAlign: "center",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1f4a",
};

const DropZone = ({
  onUpload,
  file,
  setFile,
  accept,
  extension,
  loading,
  dropZoneText,
  dropZoneEditText,
  maxFiles,
  colorearArchivosAdjuntos,
}) => {
  /**
   * @param {Object[]} archivosSeleccionadosUsuario - El array que contiene los archivos
   * seleccionados por el usuario en el explorador de archivos.
   */
  const onDropAccepted = async (archivosSeleccionadosUsuario) => {
    let archivosCargados = file ?? [];
    const readers = [];

    const superaLimiteArchivosAdjuntos =
      // Cuando maxFiles === 1 no contamos que se supera el limite
      // de archivos adjuntos. Se mantiene el comportamiento de
      // sobreescribir el archivo ya adjunto.
      maxFiles !== 1 &&
      (archivosSeleccionadosUsuario.length > maxFiles ||
        // Cuando ya tiene 3 adjuntos y, por ejemplo, carga 5 más cuando el máximo es 4
        archivosSeleccionadosUsuario.length >
          maxFiles - archivosCargados.length ||
        archivosCargados.length === maxFiles);

    if (superaLimiteArchivosAdjuntos) {
      const archivosMaximo = maxFiles > 1 ? "archivos" : "archivo";
      const archivosCargadosString =
        archivosCargados.length > 1 ? "archivos" : "archivo";
      const archivosSeleccionados =
        archivosSeleccionadosUsuario.length > 1 ? "archivos" : "archivo";
      const cargadosPluralOSingular =
        archivosCargadosString === "archivos" ? "cargados" : "cargado";

      toast.error(
        `Puede adjuntar hasta ${maxFiles} ${archivosMaximo}. Ya tiene ${cargadosPluralOSingular} ${archivosCargados.length} ${archivosCargadosString} y ha seleccionado ${archivosSeleccionadosUsuario.length} ${archivosSeleccionados} más`
      );
      return;
    }

    if (maxFiles === 1 || !maxFiles) {
      const f = archivosSeleccionadosUsuario[0];
      const reader = new FileReader();

      reader.readAsDataURL(f);

      reader.onload = () => {
        const index = reader.result.indexOf("base64,") + 7;
        archivosCargados = archivosCargados.toSpliced(0, 1, {
          filename: f.name,
          value: reader.result.substring(index),
          base64Header: reader.result.substring(0, index),
        });
        setFile(archivosCargados);
      };
      reader.onerror = function () {
        toast.error("Archivo inválido");
      };
    } else {
      archivosSeleccionadosUsuario.forEach((archivo) => {
        const reader = new FileReader();
        readers.push(reader);

        reader.readAsDataURL(archivo);

        reader.onload = function () {
          const index = reader.result.indexOf("base64,") + 7;
          const archivoYaAdjuntado = archivosCargados.findIndex(
            (a) => a.filename === archivo.name
          );
          if (archivoYaAdjuntado !== -1) {
            archivosCargados = archivosCargados.toSpliced(
              archivoYaAdjuntado,
              1,
              {
                filename: archivo.name,
                value: reader.result.substring(index),
                base64Header: reader.result.substring(0, index),
              }
            );
          } else {
            archivosCargados = archivosCargados.toSpliced(0, 0, {
              filename: archivo.name,
              value: reader.result.substring(index),
              base64Header: reader.result.substring(0, index),
            });
          }

          setFile(archivosCargados);
        };

        reader.onerror = () => {
          toast.error("Archivo inválido");
        };
      });
    }
  };

  const onDropRejected = (error) => {
    error[0].errors.forEach((error) => {
      switch (error.code) {
        case "file-invalid-type":
          toast.error(`La extensión del archivo debe ser ${extension}`);
          break;
        case "too-many-files":
          toast.error(`Máximo ${maxFiles} archivos`);
          break;
        default:
          toast.error("Archivo inválido");
          break;
      }
    });
  };

  const handleQuitarArchivo = (event, archivo) => {
    // Este stopPropagation() es necesario para impedir que el "click"
    // sea interpretado como que queremos abrir el dialog de importar archivos.
    event.stopPropagation();
    const index = file.findIndex((f) => f.filename === archivo.filename);
    const nuevaListaAdjuntos = file.toSpliced(index, 1);
    setFile(nuevaListaAdjuntos);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: maxFiles ?? 1,
    multiple: maxFiles > 1 ? true : false,
    accept: accept,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div style={styles.container}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {file?.length > 0 ? (
          <>
            <div style={styles.fileContainer}>
              {file.map((f) => (
                <DropzoneFileAttachment
                  key={f.filename}
                  filename={f.filename}
                  adjuntosConColor={colorearArchivosAdjuntos}
                  quitarArchivo={(event) => handleQuitarArchivo(event, f)}
                />
              ))}
            </div>
            <Typography variant="caption" style={{ textAlign: "center" }}>
              {dropZoneEditText}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle1">{dropZoneText}</Typography>
        )}
      </div>
      {file?.length > 0 && (
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CancelIcon />}
            style={styles.button}
            onClick={() => {
              setFile(null);
            }}
            disabled={loading}
          >
            Cancelar
          </Button>
          {onUpload && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              style={styles.button}
              onClick={onUpload}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} thickness={4} color="secondary" />
              ) : (
                "Enviar"
              )}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DropZone;
