import { Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearDenuncia } from "../../../redux/actions/denuncia";
import AccidenteOEnfermedadContainer from "./forms/AccidenteOEnfermedadContainer";
import DatosDenunciaContainer from "./forms/datosDenunciaForm/DatosDenunciaContainer";
import DatosNominaContainer from "./forms/datosNominaForm/DatosNominaContainer";
import DatosTrabajadorContainer from "./forms/datosTrabajadorForm/DatosTrabajadorContainer";
import DireccionTrabajadorContainer from "./forms/direccionTrabajadorForm/DireccionTrabajadorContainer";
import LugarOcurrenciaContainer from "./forms/lugarOcurrenciaForm/LugarOcurrenciaContainer";
import SiniestralidadTrabajadorContainer from "./forms/siniestralidadTrabajador/SiniestralidadTrabajadorContainer";

const NuevaDenunciaContainer = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState("SiniestralidadTrabajadorContainer");

  useEffect(() => {
    dispatch(clearDenuncia());

    return () => {
      dispatch(clearDenuncia());
    };
  }, []);

  return (
    <Paper style={{ overflowX: "hidden" }}>
      {
        {
          SiniestralidadTrabajadorContainer: (
            <SiniestralidadTrabajadorContainer nextForm={setForm} />
          ),
          DatosTrabajadorForm: <DatosTrabajadorContainer nextForm={setForm} />,
          DireccionTrabajadorForm: (
            <DireccionTrabajadorContainer nextForm={setForm} />
          ),
          DatosNominaForm: <DatosNominaContainer nextForm={setForm} />,
          DatosDenunciaForm: <DatosDenunciaContainer nextForm={setForm} />,
          LugarOcurrenciaForm: <LugarOcurrenciaContainer nextForm={setForm} />,
          AccidenteOEnfermedadForm: <AccidenteOEnfermedadContainer />,
        }[form]
      }
    </Paper>
  );
};

export default NuevaDenunciaContainer;
