import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatosEstablecimiento,
  saveDatosDenuncia,
} from "../../../../../redux/actions/denuncia";
import { convertDateTimeToNet } from "../../../../../util/datetime";
import HeaderForm from "../../../../commons/labels/HeaderForm";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import DatosDenunciaForm from "./DatosDenunciaForm";

const initialValues = {
  tipoSiniestro: "",
  fechaHoraAccidente: null,
  fechaAbandonoTrabajo: null,
  cuitOcurrencia: "",
  centroAsistencial: "",
  obraSocial: "",
  denunciaPolicial: "",
  comisaria: "",
  comoOcurrioAccidente: "",
};

const DatosDenunciaContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const nomina = useSelector((state) => state.denuncia.nomina);
  const datosEstablecimiento = useSelector(
    (state) => state.denuncia.datosEstablecimiento
  );

  const [formModel, setFormModel] = useState(null);

  useEffect(() => {
    const existenDatosEstablecimiento =
      Object.keys(datosEstablecimiento.data).length !== 0;
    const tieneEstablecimientoNomina = !!nomina.data?.establecimiento?.valor;
    if (!existenDatosEstablecimiento && tieneEstablecimientoNomina) {
      dispatch(getDatosEstablecimiento(nomina.data?.establecimiento?.valor));
    }
  }, []);

  const handleSubmit = (data) => {
    const model = {
      ...data,
      fechaHoraAccidente: convertDateTimeToNet(data.fechaHoraAccidente),
      fechaAbandonoTrabajo: convertDateTimeToNet(data.fechaAbandonoTrabajo),
    };
    dispatch(saveDatosDenuncia(model));
    nextForm("LugarOcurrenciaForm");
  };

  useEffect(() => {
    if (nomina.data) {
      setFormModel(() => ({
        ...initialValues,
        tipoSiniestro:
          nomina.data.tipoSiniestro?.valor || initialValues.tipoSiniestro,
        fechaHoraAccidente:
          nomina.data.fechaHoraOcurrencia?.valor ||
          initialValues.fechaHoraAccidente,
        fechaAbandonoTrabajo:
          nomina.data.fechaAbandonoTrabajo?.valor ||
          initialValues.fechaAbandonoTrabajo,
        cuitOcurrencia:
          datosEstablecimiento.data?.cuit ||
          nomina.data.cuitOcurrencia?.valor ||
          initialValues.cuitOcurrencia,
        centroAsistencial:
          nomina.data.centroAsistencial?.valor ||
          initialValues.centroAsistencial,
        obraSocial: nomina.data.obraSocial?.valor || initialValues.obraSocial,
        denunciaPolicial:
          nomina.data.denunciaPolicial?.valor || initialValues.tipoSiniestro,
        comisaria: nomina.data.comisaria?.valor || initialValues.comisaria,
        comoOcurrioAccidente:
          nomina.data.descripcionSiniestro?.valor ||
          initialValues.comoOcurrioAccidente,
      }));
    }
  }, [nomina.data, datosEstablecimiento.data]);

  return (
    <>
      <HeaderForm title="Datos de la Denuncia" />
      {formModel != null ? (
        <DatosDenunciaForm
          handleSubmit={handleSubmit}
          model={formModel}
          fields={nomina.data}
        />
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default DatosDenunciaContainer;
